import { Alert } from './alert';

export class Alerts {
    numberOfItems: number;
    pageNumber: number;
    items: Array<Alert>;

    constructor(numberOfItems: number, pageNumber: number, items: Array<Alert>) {
        this.numberOfItems = numberOfItems;
        this.pageNumber = pageNumber;
        this.items = items;
    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'app-kpi-doc-popover',
    templateUrl: './kpi-doc-popover.component.html',
    styleUrls: ['./kpi-doc-popover.component.scss'],
})
export class KpiDocPopoverComponent {
    kpiList = `<ul>
    <li><b>Errors</b>: Numero de alarmas activadas. Se utilizan las variables de tipo FAILURE. Cada equipo tiene su propia estadística aunque no tenga ninguna alarma definida. A nivel de línea se calcula el máximo número de errores entre sus equipos. Estas alarmas incluyen las alarmas que se han activado en el intervalo solicitado excluyendo las que son reprocesadas.</li>
    <li><b>Starts</b>: Son los arranques que se producen en cada línea en el intervalo solicitado. A nivel de equipo se calcula sumando el número de activaciones de la variable STATUS.RUN en el intervalo solicitado y si el equipo no tiene definida dicha variable, se le asigna el del equipo de su línea con más "Starts". A nivel de línea, se asigna el valor del equipo con más Starts.</li>
    <li><b>BoxCounts</b>: Conteo de cajas. Es la suma de las variables BOX_DETECTED y BOX_COUNTER para cada equipo y el máximo de su línea si no tienen ninguna definida. A nivel de línea también se elige el valor del equipo que más cajas haya procesado.</li>
    <li><b>TimeRunning</b>: Tiempo de funcionamiento de cada línea. Se calcula el tiempo que la variable STATUS.RUN está activa para un equipo. A nivel de línea y para los equipos que no tienen definida esta variable, se asigna el valor del equipo con mayor "TimeRunning"</li>
    <li><b>TimeError</b>: Tiempo durante el que había una alarma activa tanto a nivel de línea como de equipo. A nivel de línea se tienen en cuenta los solapes entre equipos para no sumar tiempo de más.</li>
    <li><b>TimeWaiting</b>: Tiempo de activación de la variable WITHOUT PERMISSION. Cada equipo tiene la suya individualmente y no se propaga a otros equipos. A nivel de línea, se obtiene el valor máximo entre sus equipos</li>
</ul>`;
}

/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, HostBinding, Input, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from '@app/map/home/shared/home.service';
import { AppLoadService } from '@app/app-load.service';
import { UserConfiguration } from '@app/shared/models/configurations';
import { Structure } from '@app/map/home/shared/structure';

@Component({
    selector: 'app-select-multilevel',
    templateUrl: './select-multilevel.component.html',
    styleUrls: ['./select-multilevel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMultilevelComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() key: string;
    @Input() label: string;
    @Input() columns: number;
    @Input() tags?: boolean;
    @Input() structure?: Structure;
    @Input() selected?: boolean;
    @Input() withAllValue?: boolean = true;
    @Input() withTopLevelsDisabled?: boolean = false;
    @HostBinding('class') classes;
    @ViewChild('selectMultilevel') private selectMultilevel: ElementRef;
    $selectMultilevel: JQuery;
    identifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private homeService: HomeService, private appLoadService: AppLoadService) {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
        });
    }

    ngOnInit() {
        this.classes = `col-12 col-md-6 col-lg-${this.columns || 2}`;
    }

    ngAfterViewInit(): void {
        this.$selectMultilevel = $(this.selectMultilevel.nativeElement);
        if (!this.structure) {
            this.homeService
                .getStructure()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((structure) => {
                    this.initializeCombo(structure);
                });
        } else {
            this.initializeCombo(this.structure);
        }
    }

    ngOnDestroy(): void {
        if (this.$selectMultilevel.data('select2')) {
            this.$selectMultilevel.select2('destroy');
        }
        if (this.ngUnsubscribe) {
            this.ngUnsubscribe.next(true);
            this.ngUnsubscribe.complete();
        }
    }

    initializeCombo(structure: Structure) {
        const s2Options: { [k: string]: any } = {
            formatSelection(item) {
                return item.text;
            },
            formatResult(item) {
                return item.text;
            },
            templateResult: this.formatResult,
        };
        const data = this.withAllValue
            ? [{ id: '', text: 'All', level: 0, disabled: false }]
            : this.withTopLevelsDisabled
            ? [{ id: '', text: 'Select zone', level: 0, disabled: false }]
            : [];
        structure.floors.forEach((floor) => {
            const floorId = this.identifiersPreferences.Floor ? floor.id : floor.id.replace('F', '');
            data.push({ id: floor.id, text: floorId, level: 0, disabled: this.withTopLevelsDisabled ?? true });
            floor.areas.forEach((area) => {
                const areaId = this.identifiersPreferences.Area ? area.id : area.id.replace('A', '');
                data.push({ id: `${floor.id}-${area.id}`, text: `${floorId}-${areaId}`, level: 1, disabled: this.withTopLevelsDisabled ?? true });
                area.zones.forEach((zone) => {
                    const zoneId = this.identifiersPreferences.Zone ? zone.id : zone.id.replace('Z', '');
                    data.push({ id: `${floor.id}-${area.id}-${zone.id}`, text: `${floorId}-${areaId}-${zoneId}`, level: 2, disabled: false });
                    //   zone.lines.forEach((line) => {
                    //   const lineId = this.identifiersPreferences.Line ? line.id : line.id.replace('EQL', '');
                    //   data.push({ id: `${floor.id}-${area.id}-${zone.id}-${line.id}`, text: `${floorId}-${areaId}-${zoneId}-${lineId}`, level: 3 });
                    // });
                });
            });
        });
        s2Options.data = data;
        if (this.tags !== undefined) {
            s2Options.tags = this.tags;
        }
        this.$selectMultilevel.select2(s2Options);
        if (this.tags) {
            const $search = this.$selectMultilevel.data('select2').dropdown.$search;
            this.$selectMultilevel.on('select2:opening', (e) => {
                $search.val(this.$selectMultilevel.val());
            });
        }
    }

    formatResult(node) {
        let styles = '';
        switch (node.level) {
            case 0:
                styles = 'font-size: 14px;';
                break;
            case 1:
                styles = 'font-size: 12px;';
                break;
            case 2:
                styles = 'font-size: 12px; font-weight: normal;';
                break;
            default:
                styles = 'font-size: 11px; font-weight: normal;';
                break;
        }
        const $result = $(`<span style="padding-left: ${20 * node.level}px; ${styles}">${node.text}</span>`);
        return $result;
    }

    val(value: string, triggerChange = false) {
        this.$selectMultilevel.val(value);
        if (triggerChange) {
            this.$selectMultilevel.trigger('change.select2');
        }
    }
}

import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { of } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, switchMap } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';

export const CanActivateRoute = () => {

    const msalBroadcastService = inject(MsalBroadcastService);
    const msalService = inject(MsalService);
    const router = inject(Router);
    const appLoadService = inject(AppLoadService);
    const authenticationService = inject(AuthenticationService);

    msalBroadcastService.inProgress$.pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(() => {
            if (msalService.instance.getAllAccounts().length > 0) {
                let warehouse;
                appLoadService.getCurrentWarehouse.subscribe((res) => (warehouse = res));
                const isAuth = authenticationService.checkGroups(warehouse);
                if (isAuth) {
                    return of(true);
                } else {
                    if (!appLoadService.rolesComplete) {
                        window.location.reload();
                    }
                    router.navigate(['/unauthorized']);
                    return of(false);
                }
            } else {
                return of(false);
            }
        })
    ).subscribe();
}

import { Component, Input, OnChanges, SimpleChanges, HostListener, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import { InputComponent } from '@ays/lib/components/forms/input/input.component';
import { AlertSnapshotComponent } from '../alerts-snapshot/alerts-snapshot.component';
import { AppLoadService } from '@app/app-load.service';
import { TableComponentDirective } from '@ays';
import { Alert } from '@alerts/shared/alert';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';
import * as dayjs from 'dayjs';

@Component({
    selector: 'shared-alerts-table-sm',
    templateUrl: './alerts-table-sm.component.html',
    styleUrls: ['./alerts-table-sm.component.scss'],
})
export class AlertsTableSmComponent extends TableComponentDirective<Alert> implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('equipmentSearch', { static: false }) equipmentSearch: InputComponent;
    @ViewChild('descriptionSearch', { static: false }) descriptionSearch: InputComponent;
    @ViewChild('prioritySearch', { static: false }) prioritySearch: SelectComponent;
    @Input() group: string;
    @Input() priority$: string[];
    @Input() items;
    @Input() filterItems; // Values to filter the items
    @Input() fqdnMode: string;
    @Input() globalFilter? = false;
    @ViewChild('tableAlertSnapshot') tableAlertSnapshot: AlertSnapshotComponent;
    desktopMode = true;
    alertSnapshot: Alert;
    filterItemsFirstChange: boolean = true;
    selectedRow = [];
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private appLoadService: AppLoadService,
    ) {
        super();
    }

    @HostListener('window:resize', ['$event']) onResize(event) {
        this.recalculate(event.target.innerWidth);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.recalculate(window.screen.width);
    }

    ngAfterViewInit(): void {
        const that = this;
        this.equipmentSearch.$input.on('keyup', function (e) {
            const equipmentVal = String($(this).val());
            $('.equipment-filter-resume').text(equipmentVal);
            that.setDatatableFilter('equipmentId', equipmentVal);
        });
        this.descriptionSearch.$input.on('keyup', function (e) {
            const descriptionVal = String($(this).val());
            $('.description-filter-resume').text(descriptionVal);
            that.setDatatableFilter('description', descriptionVal);
        });
        this.prioritySearch.$select.on('change', function (e) {
            const priorityVal = String($(this).val()) === 'All' ? '' : String($(this).val());
            $('.priority-filter-resume').text(priorityVal);
            that.setDatatableFilter('priority', priorityVal);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterItemsFirstChange = changes.filterItems?.firstChange ?? true;
        if (changes.items && changes.items.currentValue) {
            this.setApplyAndRefreshDatabaleItems(changes.items.currentValue);
        } else {
            if (changes.filterItems && changes.filterItems.currentValue) {
                if (this.items) {
                    this.setApplyAndRefreshDatabaleItems(this.items);
                }
            }
        }
    }

    setApplyAndRefreshDatabaleItems(alertsReceived) {
        let alertsToTable = this.setItemsToTable(alertsReceived);
        if (this.filterItems && this.filterItems.length !== 0) { alertsToTable = this.filterItemsToTable(alertsToTable) }
        else if (!this.filterItemsFirstChange) { alertsToTable = [] }

        this.setDatatableItems(alertsToTable);
        this.applyDatatableFilters();

        this.refreshSelected();
    }

    setItemsToTable(alerts): Array<any> {
        return alerts.map((alert) => {
            alert.isCritical = String(alert.equipmentType).toUpperCase() === 'EMERGENCY';
            alert.alarmSourceTimeStamp = dayjs(alert.alarmSourceTimeStamp).toDate();
            return alert;
        });
    }

    filterItemsToTable(alerts): Array<any> {
        return alerts.filter((alert) => {
            return this.filterItems.some((lineFqn) => {
                return lineFqn.equipmentId
                    ? lineFqn.equipmentId === alert.equipmentId
                    : lineFqn.floorId === alert.floorId &&
                    lineFqn.areaId === alert.areaId &&
                    lineFqn.zoneId === alert.zoneId &&
                    lineFqn.lineId === alert.lineId;
            });
        });
    }

    getRawTitle(title: string): string {
        return title.replace(/(<([^>]+)>)/gi, '');
    }

    getRowClass(row) {
        return row.isCritical ? 'table-critical w-100' : 'w-100';
    }

    recalculate(width) {
        this.desktopMode = width < 992 ? false : true;
    }

    processRealTimeAlert(alert: Alert) {
        alert.isCritical = String(alert.equipmentType).toUpperCase() === 'EMERGENCY';

        let mustAdd = true;
        if (this.filterItems && this.filterItems.length !== 0) {
            mustAdd = this.filterItems.some((lineFqn) => {
                return (
                    lineFqn.floorId === alert.floorId && lineFqn.areaId === alert.areaId && lineFqn.zoneId === alert.zoneId && lineFqn.lineId === alert.lineId
                );
            });
        }

        alert.state !== 'resolved' && mustAdd ? this.addDatatableItem(alert) : this.removeForceDatatableItem(alert);

        this.refreshSelected();
    }

    navigateToLine(event, item) {
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            if (this.globalFilter) {
                //const url = `warehouse/${res.warehouse}/floor/${item.floorId}/area/${item.areaId}/zone/${item.zoneId}/line/${item.lineId}`;
                const url = `warehouse/${res.warehouse}/floor/${item.floorId};selectedAreaZone=${item.floorId}-${item.areaId}-${item.zoneId}-${item.lineId}`
                this.router.navigate([]).then(result => { window.open(url, '_blank'); });
            } else {
                this.router.navigate([`warehouse/${res.warehouse}/floor/${item.floorId}/area/${item.areaId}/zone/${item.zoneId}/line/${item.lineId}`]);
            }
        });
    }

    displayAlarmDetails(alarm: Alert) {
        this.alertSnapshot = alarm;
        setTimeout(() => this.tableAlertSnapshot.displayAlertSnapshot());
    }

    showFilter(e, name, mode?) {
        const left = mode === 'first' ? e.clientX - 5 : e.clientX - 148;
        $('.' + name)
            .off('focusout')
            .css({ left, top: e.clientY - 80 })
            .on('focusout', function () {
                $(this).css({ left: '-100px', top: '-100px' });
                $(this).off('focusout');
            });
        if ($('.' + name).find('input').length === 1) {
            $('.' + name)
                .find('input')
                .trigger('focus');
        }
        if ($('.' + name).find('select').length === 1) {
            $('.' + name)
                .find('select')
                .select2('open')
                .trigger('focus');
        }
    }

    refreshSelected() {
        if (this.selectedRow && this.selectedRow.length !== 0) {
            const { equipmentId, equipmentType, lineId, description } = this.selectedRow[0];

            const filtered = this.itemsVariable.filter(
                (currentAlert) =>
                    currentAlert.equipmentId === equipmentId &&
                    currentAlert.equipmentType === equipmentType &&
                    currentAlert.lineId === lineId &&
                    currentAlert.description === description,
            );
            if (filtered.length !== 0) this.selectedRow = filtered;
        }
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from '@home/home.component';
import { SharedModule } from '@shared/shared.module';
import { HomeService } from '@home/shared/home.service';
import { HomeResolver } from '@home/shared/home.resolver';
import { HomeLinesComponent } from './components/lines/home-lines.component';
import { HomeCustomsComponent } from './components/customs/home-customs.component';
import { HomeLinesMapComponent } from './components/lines/home-lines-map.component';
import { VariablesDescriptionService } from '@variables/shared/variables-description.service';

@NgModule({
    imports: [RouterModule, SharedModule],
    declarations: [HomeComponent, HomeLinesComponent, HomeLinesMapComponent, HomeCustomsComponent],
    providers: [HomeService, HomeResolver, VariablesDescriptionService],
})
export class HomeModule {}

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SignalRHubService, HubConnection } from '@notifications/signalr-hub.service';
import { AppLoadService } from '@app/app-load.service';
import { environment } from '@environments/environment';
import { ReconnectionHub } from '../interfaces/reconnection-hub';

@Injectable()
export class ActiveAlertsClient {
    static SupportedEvents = {
        ACTIVE_ALERTS_COUNT_CHANGED: 'ActiveAlertsCountChanged',
        WAREHOUSE_MAP_CHANGED: 'WarehouseMapChanged',
    };
    endPoint: string;
    connection: HubConnection;
    private reconnectionHandlers: ReconnectionHub = {};

    constructor(private hubService: SignalRHubService, private appLoadService: AppLoadService) {}

    public connectToHub(
        onReconnected?: () => Promise<void>,
        onDisconnected?: () => Promise<void>,
        onReconnecting?: () => Promise<void>,
        onAcquireLockFailed?: () => Promise<void>
    ) {
        if (onReconnected) {
            this.reconnectionHandlers['ActiveAlertsClient'] = {
                ['ConnectionLost']: onReconnected,
            };
        }
        return new Promise((resolve) => {
            if (environment.mode !== 'front') {
                this.appLoadService.getCurrentWarehouse.subscribe((res) => {
                    this.endPoint = `${res.hostName}/activeAlertsCount`.toLowerCase();
                    this.hubService
                        .connect(this.endPoint, this.handleReconnection.bind(this), onDisconnected, onReconnecting, onAcquireLockFailed)
                        .then((connection) => (this.connection = connection))
                        .then(resolve);
                });
            } else {
                resolve(true);
            }
        });
    }

    public disconnectFromHub() {
        if (environment.mode !== 'front') {
            this.hubService.disconnect(this.connection);
            Object.keys(this.reconnectionHandlers || {}).forEach((groupName) => {
                Object.keys(this.reconnectionHandlers[groupName] || {}).forEach((eventName) => {
                    this.reconnectionHandlers[groupName][eventName] = null;
                    delete this.reconnectionHandlers[groupName][eventName];
                });
            });
        }
    }

    public subscribe(eventName: string, groupName: string, notificationHandler: (...args: any[]) => void, onReconnect: (...args: any[]) => Promise<void>) {
        const that = this;
        if (onReconnect) {
            if (!this.reconnectionHandlers[groupName]) {
                this.reconnectionHandlers[groupName] = {};
            }
            this.reconnectionHandlers[groupName][eventName] = onReconnect;
        }
        return new Promise((resolve) => {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            (function subscribe(innerEventName, innerGroupName, innerHandler) {
                if (that.connection) {
                    resolve(that.hubService.subscribe(that.connection, innerEventName, innerGroupName, innerHandler));
                } else {
                    // console.warn(`Unable to subscribe to ${innerEventName}`);
                    setTimeout(() => subscribe(innerEventName, innerGroupName, innerHandler), 5000);
                }
            })(eventName, groupName, notificationHandler);
        });
    }

    public async unsubscribe(eventName: string, groupName: string) {
        if (this.reconnectionHandlers?.[groupName]?.[eventName]) {
            this.reconnectionHandlers[groupName][eventName] = null;
            delete this.reconnectionHandlers[groupName][eventName];
        }
        await this.hubService.unsubscribe(this.connection, eventName, groupName); //, notificationHandler);
    }

    private handleReconnection() {
        Object.values(this.reconnectionHandlers).forEach(async (group) => {
            await Object.values(group).forEach(async (handler) => {
                await handler();
            });
        });
    }
}

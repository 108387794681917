<div class="row mt-3 mb-3 justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
        <div class="row mt-4 mb-5">
            <div class="col-12 col-md-6 col-lg-7">
                <h5 class="float-start text-start ms-5 me-3 mt-2">BOX DETECTED COLORS</h5>
            </div>
            <div class="col-6 offset-3 col-md-6 col-lg-3 offset-md-0">
            </div>
            <div class="container">
                <div class="row ms-md-5 mt-2 mb-2">
                    <div class="col-12 ms-5 mt-0">
                        <table class="table-compact table-hover w-75 mt-1 mb-5">
                            <thead>
                                <tr>
                                    <th style="width: 20%;">Color for</th>
                                    <th class="text-center w-25">Color</th>
                                    <th class="text-center w-25">Preview</th>
                                    <th style="width: 30%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="force-one-line">
                                            DEFAULT
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <shared-color-picker
                                            class="level-2 badge me-1 w-50 py-2 my-1"
                                            [colorRange]="colorBoxes"
                                            [fromDataRange]="''"
                                            [toDataRange]="''"
                                            [symbol]="''"
                                            [middle]="colorBoxes"
                                            okButtonText="SELECT"
                                            [disabled]="true"
                                            [ngStyle]="{ 'background-color': colorBoxes, color: colorBoxes | textColor }"
                                        ></shared-color-picker>
                                    </td>
                                    <td class="text-center">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 105 45" style="width: 47%; float: left;">
                                            <rect x="0" y="0" width="100%" height="100%" fill="#ffffff" />
                                            <path data-element="line" d="M10,10L95 10 95 35 10 35z" class="box" [attr.stroke]="colorBoxes" [attr.fill]="boxDetectedFillColor$ | async"></path>
                                        </svg>
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 105 45" style="width: 47%; float: right;">
                                            <rect x="0" y="0" width="100%" height="100%" fill="#000000" />
                                            <path data-element="line" d="M10,10L95 10 95 35 10 35z" class="box" [attr.stroke]="colorBoxes" [attr.fill]="boxDetectedFillColor$ | async"></path>
                                        </svg>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="force-one-line">
                                            LIGHT MODE
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <shared-color-picker
                                            class="level-2 badge me-1 w-50 py-2 my-1"
                                            [colorRange]="boxDetectedColors.lightMode"
                                            [fromDataRange]="''"
                                            [toDataRange]="''"
                                            [symbol]="''"
                                            [middle]="boxDetectedColors.lightMode"
                                            okButtonText="SELECT"
                                            (colorSelected)="updateBoxDetectedColor($event, true)"
                                            [ngStyle]="{ 'background-color': boxDetectedColors.lightMode, color: boxDetectedColors.lightMode | textColor }"
                                        ></shared-color-picker>
                                    </td>
                                    <td class="text-center">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 210 45">
                                            <rect x="0" y="0" width="100%" height="100%" fill="#ffffff" />
                                            <path data-element="line" d="M10,10L200 10 200 35 10 35z" class="box" [attr.stroke]="boxDetectedColors.lightMode" [attr.fill]="boxDetectedFillColor$ | async"></path>
                                        </svg>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-primary my-1 ms-3 px-2" title="Return to default color" (click)="updateBoxDetectedColor(colorBoxes, true)">
                                            Default
                                            <i class="fas fa-fill-drip"></i>
                                        </button>
                                        <button *ngIf="userConfiguration" class="btn btn-sm btn-primary my-1 ms-3" title="Return to warehouse color" (click)="updateBoxDetectedColor(parentBoxDetectedColors.lightMode, true)">
                                            Warehouse
                                            <i class="fas fa-fill-drip"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DARK MODE</td>
                                    <td class="text-center">
                                        <shared-color-picker
                                            class="level-2 badge me-1 w-50 py-2 my-1"
                                            [colorRange]="boxDetectedColors.darkMode"
                                            [fromDataRange]="''"
                                            [toDataRange]="''"
                                            [symbol]="''"
                                            [middle]="boxDetectedColors.darkMode"
                                            okButtonText="SELECT"
                                            (colorSelected)="updateBoxDetectedColor($event, false)"
                                            [ngStyle]="{ 'background-color': boxDetectedColors.darkMode, color: boxDetectedColors.darkMode | textColor }"
                                        ></shared-color-picker>
                                    </td>
                                    <td class="text-center">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 210 45">
                                            <rect x="0" y="0" width="100%" height="100%" fill="#000000" />
                                            <path data-element="line" d="M10,10L200 10 200 35 10 35z" class="box" [attr.stroke]="boxDetectedColors.darkMode" [attr.fill]="boxDetectedFillColor$ | async"></path>
                                        </svg>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-primary my-1 ms-3 px-2" title="Return to default color" (click)="updateBoxDetectedColor(colorBoxes, false)">
                                            Default
                                            <i class="fas fa-fill-drip"></i>
                                        </button>
                                        <button *ngIf="userConfiguration" class="btn btn-sm btn-primary my-1 ms-3" title="Return to warehouse color" (click)="updateBoxDetectedColor(parentBoxDetectedColors.darkMode, false)">
                                            Warehouse
                                            <i class="fas fa-fill-drip"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable()
export class VariablesDescriptionService {
    baseUrl = '';
    warehouse = '';
    times = 0;

    variablesDescriptions: string[] = [];

    constructor(private http: HttpClient) {}

    getVariableDescriptions(): Observable<any> {
        if (this.baseUrl !== '' && this.warehouse !== '') {
            const endpoint = `variables-description/${this.warehouse}`;
            return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
        } else {
            return of(null);
        }
    }

    setVariableDescriptionsLocal(value) {
        this.times++;
        this.variablesDescriptions.push(JSON.stringify(value).toUpperCase());
    }
    getVariableDescriptionsLocal(baseUrl: string = '', warehouse: string = ''): any {
        if (this.times <= 0 && baseUrl !== '' && warehouse !== '') {
            this.baseUrl = baseUrl;
            this.warehouse = warehouse;
            this.getVariableDescriptions()
                .pipe(
                    take(1),
                    catchError((err) => {
                        console.log('Ha ocurrido un problema al recuperar las Variables Descriptions', err);
                        return of(null);
                    })
                )
                .subscribe({
                    next: (res) => {
                        if (res) this.setVariableDescriptionsLocal(res);
                        else this.times++;
                    },
                    error: (error) => {
                        console.log(error);
                    },
                    complete:() => {
                        this.getVariableDescriptionsLocal();
                    }
                });
        } else {
            return this.variablesDescriptions[0];
        }
    }
}

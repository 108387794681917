import { Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { MonitoringService } from '@app/shared/services/monitoring.service';
import * as signalR from '@microsoft/signalr';
import * as signalRMsgPack from '@microsoft/signalr-protocol-msgpack';
import { throttle } from 'lodash-es';
import { take } from 'rxjs/operators';
/* eslint-disable @typescript-eslint/naming-convention */
const COLORS = {
    RECONNECTING: 'fuchsia',
    RECONNECTED: 'deepskyblue',
    CLOSE: 'crimson',
    LOCK: 'lime',
};
/* eslint-enable @typescript-eslint/naming-convention */

export type SignalRHandler = (...args: any[]) => void;

export interface HubConnection {
    connectionId: string;
    socket: string;
    hubConnection: signalR.HubConnection;
    subscriptions: {
        [groupName: string]: {
            [eventName: string]: SignalRHandler;
        };
    };
    logSubscriptionDetails: (...args: any[]) => void;
}

@Injectable()
export class SignalRHubService {
    currentWarehouse: Warehouse;
    acquireLockFailed: () => void;
    webLockRelease: any;

    constructor(private monitoringService: MonitoringService, private appLoadService: AppLoadService) {
        this.appLoadService.getCurrentWarehouse.pipe(take(1)).subscribe((res) => {
            this.currentWarehouse = res;
        });
    }

    async connect(
        url: string,
        onReconnected?: () => Promise<void>,
        onDisconnected?: () => Promise<void>,
        onReconnecting?: () => Promise<void>,
        onAcquireLockFailed?: () => Promise<void>
    ): Promise<HubConnection> {
        const socket = url.split('/').pop();
        let connection: HubConnection = null;
        const loggingLevel = localStorage.getItem('signalrLoggingLevel') || signalR.LogLevel.None;
        try {
            // Signal R Hub Connection
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(url)
                .withHubProtocol(new signalRMsgPack.MessagePackHubProtocol())
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => {
                        onReconnecting?.();
                        const elapsed = retryContext.elapsedMilliseconds;
                        if (elapsed > 60000) {
                            onDisconnected?.();
                        }
                        return elapsed <= 10000 ? 15000 : elapsed <= 20000 ? 30000 : 60000;
                    },
                })
                /*.withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => {
                        onReconnecting?.();
                        if (retryContext.elapsedMilliseconds < 60000) {
                            // If we've been reconnecting for less than 60 seconds so far,
                            // wait between 0 and 10 seconds before the next reconnect attempt.
                            return Math.random() * 10000;
                        } else {
                            onDisconnected?.();
                            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                            return null;
                        }
                    },
                })*/
                .configureLogging(loggingLevel)
                .build();

            hubConnection.onreconnecting((error) => {
                // tslint:disable-next-line:max-line-length
                console.log(`%c Reconnecting to ${hubConnection.baseUrl} due to "${error}"`, `color: ${COLORS.RECONNECTING}`);
            });

            hubConnection.onreconnected((id) => {
                console.log(`%c Reconnected to ${hubConnection.baseUrl}`, `color: ${COLORS.RECONNECTED}`);
                (onReconnected?.() ?? Promise.resolve()).then(() => {
                    connectionId = id;
                    Object.keys(connection.subscriptions).forEach((group) => {
                        hubConnection.invoke('JoinGroup', group);
                    });
                });
            });

            hubConnection.onclose((error) => {
                onDisconnected?.();
                this.monitoringService.logMetric('Auwa.Web.SignalR.SocketDisconnected', 1, null, null, null, {
                    warehouseId: this.currentWarehouse.warehouse,
                    socket,
                    visibilityState: document.visibilityState,
                });
                // tslint:disable-next-line:max-line-length
                console.log(`%c Disconnected to ${hubConnection.baseUrl} due to ${error || 'navigation'}`, `color: ${COLORS.CLOSE}`);
                // Release tab freezing lock
                this.webLockRelease?.();
            });

            //Start the connection
            await hubConnection.start();
            if (!this.acquireLockFailed) {
                this.acquireLockFailed = onAcquireLockFailed;
            }
            let connectionId = hubConnection.connectionId;
            connection = {
                connectionId,
                socket,
                hubConnection,
                subscriptions: {},
                logSubscriptionDetails: throttle(this.logSubscriptionCount, 1000, { trailing: true }),
            };
        } catch (error) {
            console.warn(`Error connecting to ${url}: "${error}"`);
            return new Promise((resolve) => {
                setTimeout(() => resolve(this.connect(url, onReconnected, onDisconnected, onReconnecting, onAcquireLockFailed)), 5000);
            });
        }
        console.log(`Connected to ${connection?.hubConnection.baseUrl}`);
        return connection;
    }

    async subscribe(connection: HubConnection, eventName: string, groupName: string, eventHandler: SignalRHandler) {
        try {
            if (connection && connection.hubConnection.state === signalR.HubConnectionState.Connected) {
                connection.hubConnection.on(eventName, eventHandler);
                const mustJoinGroup =
                    !connection.subscriptions[groupName] ||
                    !Object.keys(connection.subscriptions[groupName]).length ||
                    !connection.subscriptions[groupName][eventName] ||
                    !Object.keys(connection.subscriptions[groupName][eventName]).length;
                connection.subscriptions[groupName] = {
                    ...(connection.subscriptions[groupName] || {}),
                    [eventName]: eventHandler,
                };
                if (mustJoinGroup) {
                    await connection.hubConnection.invoke('JoinGroup', groupName);
                }
                connection.logSubscriptionDetails(connection);
            }
        } catch (err) {
            console.error(`${err} - eventName = ${eventName} - groupName = ${groupName}`);
        }
    }

    async unsubscribe(connection: HubConnection, eventName: string, groupName: string) {
        try {
            if (connection && connection.hubConnection.state === signalR.HubConnectionState.Connected && connection.subscriptions[groupName]?.[eventName]) {
                const eventHandler = connection.subscriptions[groupName][eventName];
                connection.hubConnection.off(eventName, eventHandler);
                connection.subscriptions[groupName][eventName] = null;
                delete connection.subscriptions[groupName][eventName];
                if (!Object.keys(connection.subscriptions[groupName] || {}).length) {
                    await connection.hubConnection.invoke('LeaveGroup', groupName);
                    delete connection.subscriptions[groupName];
                }
                connection.logSubscriptionDetails(connection);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async subscribe$(
        connection: HubConnection,
        groupName: string,
        supportedEvents: Array<string>,
        notifyEvent: (groupName: string, eventName: string, eventGroup: any[]) => void
    ): Promise<void> {
        try {
            if (connection && connection.hubConnection.state === signalR.HubConnectionState.Connected) {
                supportedEvents.forEach((eventName) => {
                    const eventHandler = (eventGroup) => notifyEvent(groupName, eventName, eventGroup);
                    connection.hubConnection.on(eventName, eventHandler);
                    connection.subscriptions[groupName] = {
                        ...(connection.subscriptions[groupName] || {}),
                        [eventName]: eventHandler,
                    };
                });
                await connection.hubConnection.invoke('JoinGroup', groupName);
                connection.logSubscriptionDetails(connection);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async unsubscribe$(connection: HubConnection, groupName: string, supportedEvents: string[]) {
        try {
            if (connection && connection.hubConnection.state === signalR.HubConnectionState.Connected && connection.subscriptions[groupName]) {
                supportedEvents.forEach((eventName) => {
                    const eventHandler = connection.subscriptions[groupName][eventName];
                    connection.hubConnection.off(eventName, eventHandler);
                    connection.subscriptions[groupName][eventName] = null;
                    delete connection.subscriptions[groupName][eventName];
                });
                if (!Object.keys(connection.subscriptions[groupName] || {}).length) {
                    await connection.hubConnection.invoke('LeaveGroup', groupName);
                    delete connection.subscriptions[groupName];
                }
                connection.logSubscriptionDetails(connection);
            }
        } catch (err) {
            console.error(err);
        }
    }

    logSubscriptionCount(connection: HubConnection) {
        if (!localStorage.getItem('showLogSubscriptions')) return;
        console.log(`${connection.socket}:: Active subscriptions for ${connection.socket}: ${Object.keys(connection.subscriptions).length}`);
        Object.keys(connection.subscriptions).forEach((groupName) => {
            console.log(`     ${groupName}:: Listening to ${Object.keys(connection.subscriptions[groupName]).join(', ')}`);
        });
    }

    disconnect(connection: HubConnection): void {
        try {
            const { hubConnection } = connection;
            if (hubConnection) {
                if (hubConnection.state === signalR.HubConnectionState.Connected) {
                    Promise.all(
                        Object.keys(connection.subscriptions).reduce((acc, eventName) => {
                            const groupNames = Object.keys(connection.subscriptions);
                            // tslint:disable-next-line:max-line-length
                            const leaveGroup = (groupName) =>
                                hubConnection.invoke('LeaveGroup', groupName).catch((error) => console.warn('Unable to LeaveGroup.', error.message));
                            return [...acc, ...groupNames.map(leaveGroup)];
                        }, [])
                    )
                        .then(() => {
                            Object.keys(connection.subscriptions).forEach(hubConnection.off);
                            hubConnection.stop();
                        })
                        .catch((error) => console.warn('Unable to disconnect', error.message));
                } else {
                    hubConnection.stop();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    acquireWebLock(connection: HubConnection) {
        try {
            // Lock to avoid tab freezing
            // https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-7.0&tabs=visual-studio#bsleep
            if (navigator && navigator.locks && navigator.locks.request) {
                const promise = new Promise((res) => (this.webLockRelease = res));
                navigator.locks.request(`${connection.hubConnection.baseUrl}_lock`, { mode: 'shared' }, () => promise);
                console.log(`%c Lock ${connection.hubConnection.baseUrl} adquired to avoid tab freezing`, `color: ${COLORS.LOCK}`);
            } else {
                this.acquireLockFailed?.();
            }
        } catch (error) {
            console.error(error);
        }
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VariableDashboardItem } from '@variables/components/dashboard/variables-dashboard.component';
import { VariableDashboardType } from '@variables/shared/variable';

@Component({
    selector: 'shared-variable-status',
    templateUrl: './variable-status.component.html',
    styleUrls: ['./variable-status.component.scss'],
})
export class VariableStatusComponent {
    @Output() removeView = new EventEmitter();
    @Input() dashboardItem: VariableDashboardItem;

    variableDashboardType = VariableDashboardType;

    constructor() {}

    closeView(itemName) {
        this.removeView.emit(itemName);
    }
}

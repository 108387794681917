<div class="row search-nav mt-3">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-lg-3 pt-1 mobile-margin-bottom">
                <h2>{{ header }}</h2>
            </div>
            <!-- <div class="row"> -->
            <app-select
                #linesSelector
                [item$]="line$"
                key="data-lines-dashboard-search"
                label="Equipment-line"
                columns="3"
                placeholder="Please select an Equipment-line"
                searchable="true"
            ></app-select>
            <app-select
                #equipmentsSelector
                [item$]="lineEquipments$"
                key="data-equipments-dashboard-search"
                label="Equipment"
                columns="3"
                placeholder="Please select an Equipment"
                searchable="true"
                [disabled]="!selectedLine"
            ></app-select>
            <app-select
                #variableSelector
                [item$]="variable$"
                key="variable-dashboard-search"
                label="Variable"
                columns="3"
                placeholder="Please select a Variable"
                searchable="true"
                [disabled]="!selectedLine || !selectedEquipment"
            ></app-select>
            <div class="col-1 text-center d-inline" style="width: 20px">
                <a
                    tabindex="0"
                    class="dashboard-table-doc-popover d-block"
                    role="button"
                    data-bs-toggle="popover"
                    data-bs-placement="bottom"
                    data-bs-html="true"
                    title="Table colours information"
                    [attr.data-bs-content]="tableColours"
                >
                    <i class="fa fa-info-circle" title="Table colours information"></i>
                </a>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>
<div *ngIf="isDashboardItemsEmpty" class="col-12 mt-3">
    <div style="display: table; height: 300px; width: 100%">
        <div style="display: table-cell; vertical-align: middle">
            <p class="mt-3 text-center font-weight-bold" style="display: block; text-align: center">
                <span>
                    <i class="fa fa-times-circle text-danger pe-none"></i>
                    A variable must be set to view data
                </span>
            </p>
        </div>
    </div>
</div>
<div *ngIf="isFirstLoadDashboardData && promisesCounter > 1" class="loading-overlay">
    <div class="loading-header"></div>
    <div class="loading-wrapper">
        <div class="loader-wrapper text-center">
            <img src="assets/img/loader.gif" />
        </div>
        <h4 class="mt-3 text-center font-weight-bold">Loading {{ dashboardItems.length }} of {{ promisesCounter }} tables...</h4>
    </div>
</div>
<div *ngIf="isFirstLoadDashboardData && promisesCounter <= 1; else displayDashboardData">
    <div class="analytics-loader-wrapper text-center">
        <img src="assets/img/loader.gif" />
        <h4 class="mt-3 text-center font-weight-bold">Loading data...</h4>
    </div>
</div>
<ng-template #displayDashboardData>
    <p *ngIf="!isDashboardItemsEmpty" class="text-center mb-1 mt-2 small">History data shown may have a 10 minute delay</p>
    <div class="row mt-2">
        <div class="col-6 col-md-3 mb-4" *ngFor="let dashboardItem of dashboardItems">
            <shared-variable-status [dashboardItem]="dashboardItem" (removeView)="removeView($event)"></shared-variable-status>
        </div>
        <div class="col-6 col-md-3 mb-4" *ngIf="isLoadingDataTable">
            <div class="row">
                <div class="col-12">
                    <div class="card placeholder-wave">
                        <div class="col-12 placeholder custom-title">
                            <span class="float-start p-1">
                                <small class="force-one-line ps-2"><strong>Loading data...</strong> </small>
                            </span>
                        </div>
                        <div class="card-body placeholder p-0">
                            <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <tbody>
                                        <tr *ngFor="let _ of [].constructor(10)" style="height: 40px">
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

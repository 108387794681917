<div class="collapse" id="filterMenu" [ngClass]="{ show: !isFullscreen || showFilters }">
    <div class="row search-nav mt-3 pb-2">
        <div class="col-12 col-lg-6 col-xl-6">
            <app-breadcrumbs [columns]="9" [breadcrumbs]="[floorId, areaId, zoneId, lineId]"></app-breadcrumbs>
        </div>
        <div class="col-12 col-lg-2 col-xl-2">
            <app-dropdown-checkbox
                label="States"
                [icon]="filterIcon"
                [iconTitle]="filterTitle"
                columns="3"
                [availableItems]="availableStates"
                (changeFilter)="handleChangeVisibleStates($event)"
            ></app-dropdown-checkbox>
        </div>
        <div class="col-12 col-lg-4 col-xl-4">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsView }">Alerts</button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-xl-8 col-lg-6 col-12">
        <div class="map-wrapper">
            <app-lines-map
                *ngIf="mapRender"
                [warehouse]="warehouse.warehouse"
                [markedEquipment]="equipment"
                [openVariableModal]="openVariableModal"
                [line]="lineMap"
                [icons]="icons"
                [visibleStates]="visibleStates"
                [equipmentChange$]="equipmentChange$"
                (filterTableAlarms)="filterTableAlarms($event)"
                [tabState]="tabState"
                (visibleEquipment)="refreshAlertsTable($event)"
            >
            </app-lines-map>
            <h5 class="text-center" *ngIf="!lineMap">There are no devices associated with this line</h5>
        </div>
    </div>

    <div class="col-12 col-lg-6 col-xl-4 px-lg-0">
        <div [ngClass]="{ 'hide-me': !alertsView }">
            <shared-alerts-table-sm
                #alertsTable
                [items]="lineAlerts"
                [filterItems]="equipmentVisible"
                [group]="floorId + '_' + areaId + '_' + zoneId + '_' + lineId"
                [priority$]="priority$"
                fqdnMode="line"
            ></shared-alerts-table-sm>
        </div>
    </div>
</div>

<div class="line-parameter" [ngClass]="{ 'opc-error': variable.isOpcError }" [attr.data-variable-raw]="variable | json">
    <span class="key" [title]="variable?.type?.charAt(0).toUpperCase() + variable.type.slice(1) + ' - ' + variable.name">
        <span>
            <span [innerHTML]="variable | variableTypeIcon"></span>
            {{ variable.name }}
        </span>
        <small
            >{{ variable.sourceTimeStamp | date : 'd/M/yy HH:mm:ss'
            }}<span *ngIf="variable.opcErrorType"> | OPC Error: {{ variable.opcErrorType }}</span></small
        >
    </span>
    <!-- <span class="value" [ngClass]="isActiveVariable(variable.value) ? 'bold' : ''">{{variable.value}}</span> -->
    <ng-container *ngIf="!isRefreshing && !hasRefreshError; else loadingDots">
        <shared-variable-input
            [fqn]="fqn"
            [variable]="variable"
            [variableState]="state"
            [refreshable]="false"
            [style.background-color]="
                variable?.type?.toLowerCase() !== COMMAND.toLowerCase()
                    ? 'white'
                    : variable?.valueType?.toLowerCase() !== variableValueType.Boolean.toLowerCase()
                    ? 'aliceblue'
                    : 'transparent'
            "
            [style.cursor]="variable?.type?.toLowerCase() === COMMAND.toLowerCase() ? 'pointer' : 'default'"
            [style.font-size]="'13px'"
            [style.font-weight]="'bold'"
            [style.color]="'#95a6a7'"
            [style.padding]="
                variable?.type?.toLowerCase() === COMMAND.toLowerCase() &&
                variable?.valueType?.toLowerCase() === variableValueType.Boolean.toLowerCase() &&
                !variable?.name?.toUpperCase().includes('RESET')
                    ? '0px'
                    : '6px'
            "
            [style.margin-top]="
                variable?.type?.toLowerCase() === COMMAND.toLowerCase() &&
                variable?.valueType?.toLowerCase() === variableValueType.Boolean.toLowerCase() &&
                !variable?.name?.toUpperCase().includes('RESET')
                    ? '0px'
                    : '3px'
            "
            [style.margin-bottom]="'3px'"
            [style.margin-right]="
                variable?.type?.toLowerCase() === COMMAND.toLowerCase() &&
                variable?.valueType?.toLowerCase() === variableValueType.Boolean.toLowerCase() &&
                !variable?.name?.toUpperCase().includes('RESET')
                    ? '15px'
                    : '3px'
            "
            [style.text-align]="'left'"
            [style.height]="'32px'"
            (editValue)="editValue($event)"
            (updateValue)="updateValue($event)"
            (undoEditValue)="undoEditValue($event)"
            (saveInput)="saveValue($event)"
            (focusLost)="focusLost($event)"
            (refreshValue)="refreshValue($event)"
        >
        </shared-variable-input>
    </ng-container>
    <ng-template #loadingDots>
        <ng-container *ngIf="isRefreshing && !hasRefreshError; else errorMessage">
            <div class="dot-pulse-wrapper">
                <div class="dot-pulse"></div>
            </div>
        </ng-container>
    </ng-template>
    <ng-template #errorMessage>
        <span title="Error message" class="error-message"> Unable to refresh variable </span>
    </ng-template>
</div>

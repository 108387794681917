import { Component, OnDestroy, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { BarChartComponent } from '@ays/lib/components/charts/bar-chart/bar-chart.component';
import { ChartItem } from '@app/shared/components/charts/chart-item';
import { UserConfiguration } from '@app/shared/models/configurations';
import { Warehouse } from '@app/core/shared/warehouse';
import { AppLoadService } from '@app/app-load.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '@app/core/utils';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-table-chart',
    templateUrl: './table-chart.component.html',
    styleUrls: ['./table-chart.component.scss'],
})
export class TableChartComponent implements OnChanges, OnDestroy {
    @ViewChild('barChart') barChart: BarChartComponent;
    @Input() data: Array<any>;
    @Input() config: any;
    @Output() expandTable = new EventEmitter();
    @Output() pressChartLabel = new EventEmitter();
    identifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    chartData = new ChartItem(null, null);
    percentage = 'false';
    type = 'bar';
    buttonBack = false;
    radarColors = ['236, 219, 83', '108, 160, 220', ' 0, 166, 140', '108, 79, 60', '188, 108, 167', '191, 216, 51', '147, 71, 66'];
    warehouse: Warehouse;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private appLoadService: AppLoadService) {
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.warehouse = res));
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
        });
    }

    ngOnChanges() {
        if (this.data && this.config) {
            this.type = this.config.type;
            this.percentage = this.config.percentage;
            this.mountBaseChart();
            // DRILLDOWN
            if (this.barChart) {
                const canvas = this.barChart.canvas.nativeElement as HTMLCanvasElement;
                canvas.onclick = (evt) => {
                    const activePoints = this.barChart.chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
                    if (activePoints[0]) {
                        this.mountDrilldownChart(this.barChart.data.labels[activePoints[0].index]);
                        this.buttonBack = true;
                    }
                };
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    pressLabel(e) {
        this.pressChartLabel.emit(e);
    }

    mountBaseChart() {
        const data = this.data.slice(0, 50);
        this.chartData = new ChartItem(null, null);
        let labels;
        let datasets;
        if (this.config.type !== 'radar') {
            const dataToProcess = data.map((item) => Utils.removeHighlightFromString(item[this.config.x.param]));
            if (dataToProcess[0]?.includes('-A')) {
                labels = dataToProcess.map((mappedLabels) => {
                    let [floor, area, zone, line] = mappedLabels.split('-');
                    floor = this.identifiersPreferences.Floor ? floor : floor?.replace('F', '');
                    area = this.identifiersPreferences.Area ? area : area?.replace('A', '');
                    zone = this.identifiersPreferences.Zone ? zone : zone?.replace('Z', '');
                    line = this.identifiersPreferences.Line ? line : line?.replace('EQL', '');
                    if (line) {
                        return `${floor}-${area}-${zone}-${line}`;
                    } else {
                        return `${floor}-${area}-${zone}`;
                    }
                });
            } else {
                labels = dataToProcess?.map((mappedLabels) => (this.identifiersPreferences.Line ? mappedLabels : mappedLabels?.replace('EQL', '')));
            }
            datasets = this.config.y.map((ydata) => {
                return {
                    label: ydata.label,
                    data: data.map((item) => item[ydata.param]),
                    backgroundColor: ydata.color,
                    borderColor: ydata.color,
                    fill: this.config.type === 'bar' ? true : false,
                    borderWidth: 2,
                    lineTension: 0,
                };
            });
        } else {
            labels = this.config.y.map((ydata) => ydata.label);
            datasets = this.data.map((item1, index) => {
                return {
                    label: item1[this.config.x.param],
                    data: this.config.y.map((ydata) => item1[ydata.param]),
                    fill: true,
                    backgroundColor: `rgba(${this.radarColors[index]},0.2)`,
                    borderColor: `rgba(${this.radarColors[index]},1)`,
                    pointBorderColor: '#fff',
                    pointBackgroundColor: `rgba(${this.radarColors[index]},1)`,
                };
            });
        }
        this.buttonBack = false;
        this.chartData = new ChartItem(labels, datasets);
    }

    mountDrilldownChart(id) {
        const fqdn = `${this.warehouse.warehouse}-${id}`;
        this.expandTable.emit(fqdn);
    }
}

import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { OffcanvasComponent } from '@ays/commons/lib/components/offcanvas/offcanvas.component';

@Component({
    selector: 'app-home-lines-minified-container',
    templateUrl: './home-lines-minified-container.component.html',
    styleUrls: ['./home-lines-minified-container.component.scss'],
})
export class HomeLinesMinifiedContainerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() lineToShowOff: string = '';
    @Input() visibleStatesOff: Array<string> = [];
    @Output() offcanvasToggle = new EventEmitter();
    @ViewChild('offcanvasMapaMinified', { static: false }) offcanvasMapaMinified: OffcanvasComponent;

    lineToShowOffTemp = {warehouseId:'',floorId:'',areaId:'',zoneId:'',lineId:''};

    constructor() {
    }

    ngOnInit() {}

    async ngAfterViewInit(): Promise<void> {
        const myOffcanvasEquipments = document.getElementById('offcanvasMapaMinifiedDOM');
        myOffcanvasEquipments.addEventListener('show.bs.offcanvas', () => {
            this.offcanvasToggle.emit(true);
        });
        myOffcanvasEquipments.addEventListener('hide.bs.offcanvas', () => {
            this.offcanvasToggle.emit(false);
        });
    }
    
    hideOffcanvas(event) {
        if (!event) {
            this.offcanvasMapaMinified.hideDetails();
        }
    }

    async ngOnDestroy() {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.lineToShowOff?.currentValue) {
            
            this.lineToShowOffTemp = JSON.parse(changes.lineToShowOff?.currentValue);
            
            if (this.lineToShowOffTemp && this.lineToShowOffTemp?.lineId !== '') {
                this.offcanvasMapaMinified.showDetails();
            }
        }
    }
}

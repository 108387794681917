import { Component, Output, Input, EventEmitter, HostBinding, OnInit } from '@angular/core';

export interface DropdownItems {
    id: string;
    position: number;
    label: string;
    visible: boolean;
}

@Component({
    selector: 'app-dropdown-checkbox',
    templateUrl: './dropdown-checkbox.component.html',
    styleUrls: ['./dropdown-checkbox.component.scss'],
})
export class DropdownCheckboxComponent implements OnInit {
    @Input() availableItems: Array<DropdownItems> = [];
    @Input() extraClasses?: string;
    @Input() columns: number;
    @Input() label: string;
    @Input() icon: string;
    @Input() iconTitle: string;
    @Output() changeFilter = new EventEmitter<Array<DropdownItems>>();
    @HostBinding('class') classes;

    constructor() {}
    ngOnInit(): void {
        this.classes = `col-12 col-md-6 col-lg-${this.columns || 1} ${this.extraClasses || ''}`;
    }

    onCheck(event, state: DropdownItems) {
        event.stopPropagation();
        event.preventDefault();
        this.availableItems = this.availableItems
            .map((st: DropdownItems) => {
                if (st.id === state.id) st.visible = !st.visible;
                return st;
            })
            .sort((s1, s2) => s1.position - s2.position);
    }

    changeVisibleItems() {
        this.changeFilter.emit(this.availableItems);
    }
}

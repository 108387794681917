import { Component, AfterViewInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import 'chartjs-adapter-dayjs-3';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-table-comparator',
    templateUrl: './table-comparator.component.html',
    styleUrls: ['./table-comparator.component.scss'],
})
export class TableComparatorComponent implements OnChanges, AfterViewInit {
    @ViewChild('comparatorA') comparatorA: SelectComponent;
    @ViewChild('comparatorB') comparatorB: SelectComponent;
    @Input() data: Array<any>;
    $comparatorA: JQuery;
    $comparatorB: JQuery;
    chartData: any;
    fullscreen = false;
    totalItems = [
        { value: 'numberOfBoxes', name: 'Boxes' },
        { value: 'numberOfErrors', name: 'Errors' },
        { value: 'numberOfStarts', name: 'Starts' },
        { value: 'percentageRunning', name: 'Running' },
        { value: 'percentageStopped', name: 'Stopped' },
        { value: 'percentageError', name: 'On error' },
        { value: 'percentageNoError', name: 'Availability' },
        { value: 'percentageLoadedWithoutDischargeOpt', name: 'LWDO' },
        { value: 'performance', name: 'Performance' },
    ];
    itemsA = _.cloneDeep(this.totalItems);
    itemsB = _.cloneDeep(this.totalItems);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.itemsA.splice(1, 1);
        this.itemsB.splice(0, 1);
    }

    ngOnChanges() {
        if (this.data) {
            this.mountChart();
        }
    }

    ngAfterViewInit(): void {
        this.$comparatorA = this.comparatorA.$select;
        this.$comparatorA.on('change', () => {
            this.mountChart();
        });
        this.$comparatorB = this.comparatorB.$select;
        this.$comparatorB.on('change', () => {
            this.mountChart();
        });
    }

    mountChart() {
        this.chartData = {
            labels: [this.$comparatorA.find('option:selected').text(), this.$comparatorB.find('option:selected').text()],
            datasets: this.data.map((item) => {
                return {
                    label: [item.id],
                    backgroundColor: 'rgba(252, 222, 130, 0.5)',
                    borderColor: 'rgba(252, 222, 130, 1)',
                    data: [
                        {
                            x: this.$comparatorA ? item[String(this.$comparatorA.val())] : item.numberOfBoxes,
                            y: this.$comparatorB ? item[String(this.$comparatorB.val())] : item.numberOfErrors,
                            r: 5,
                        },
                    ],
                };
            }),
        };
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { AreasComponent } from '@areas/components/areas.component';
import { AreasService } from '@areas/shared/areas.service';
import { AreasResolver } from '@areas/shared/areas.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [SharedModule, NgxDatatableModule, RouterModule],
    declarations: [AreasComponent],
    providers: [AreasService, AreasResolver],
})
export class AreasModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { AysCommonsModule } from '@ays';
@NgModule({
    imports: [CommonModule, AysCommonsModule, FormsModule, SharedComponentsModule, DirectivesModule, PipesModule],
    exports: [CommonModule, AysCommonsModule, FormsModule, SharedComponentsModule, DirectivesModule, PipesModule],
})
export class SharedModule {}

<span
    class="auwa-alerts-popover auwa-alerts-info d-block"
    data-bs-toggle="popover"
    data-bs-trigger="focus"
    title="Alarm Details"
    data-bs-html="true"
    data-bs-content="static"
    data-bs-placement="bottom"
>
</span>

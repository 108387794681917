import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { Area, Floor } from '@app/map/home/shared/structure';
import { isInViewport } from '@app/map/shared/svg-functions';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { VariableFilter, VariableFilters, VariableNotification, WILD_CARD_CHARACTER } from '@app/notifications/shared/events/variable-status';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { UserConfiguration } from '@app/shared/models/configurations';
import { yieldToMain } from '@app/shared/performance/auwa-scheduler';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { PageLifecycleService, VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { VariableColors } from '@variables/shared/variable';
import { Observable, Subject } from 'rxjs';
import { bufferTime, switchMap, takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import '@svgdotjs/svg.panzoom.js';
import fastdom from 'fastdom';
import * as Svg from '@svgdotjs/svg.js';

const MAX_NOTIFICATIONS = 50;
const ORDER_COLUMN = 'SourceTimeStamp';
const ORDER_TYPE = 'desc';
@Component({
    selector: 'app-areas-map',
    templateUrl: './areas-map.component.html',
    styleUrls: ['./areas-map.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient, TextColorPipe],
})
export class AreasMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() map: Floor;
    @Input() alternativeId? = { floorId: null, areaId: null, zoneId: null, lineId: null };
    @Input() visibleStates: Array<string>;
    @Input() lineChange$: Observable<LineStatusNotification>;
    @Input() tabState: VisibilityStates;
    @Input() activateZoom?: boolean = true;
    @Output() newLineSelected = new EventEmitter();
    @Output() clickZone = new EventEmitter();
    @Output() visibleLines = new EventEmitter();
    @ViewChild('svg', { read: ElementRef, static: true }) svg;
    @ViewChild('states', { read: ElementRef, static: true }) states;
    @ViewChild('freeBacks', { read: ElementRef }) freeBacks;
    @ViewChild('freeFronts', { read: ElementRef }) freeFronts;
    @ViewChild('buttons', { read: ElementRef, static: false }) buttons;
    @ViewChild('buttonsOverMap', { read: ElementRef, static: false }) buttonsOverMap;
    $svg: JQuery;
    $states: JQuery;
    $freeBacks: JQuery;
    $freeFronts: JQuery;
    $buttons: JQuery;
    $buttonsOverMap: JQuery;
    identifiersPreferences = { Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    area: Area = { id: null, viewBox: '', freeBacksLines: null, freeBacksZones: null, freeFrontsLines: null, freeFrontsZones: null, zones: [] };
    setViewBox: string;
    areaStates: any;
    floorId: string;
    areaId: string;
    liteView: boolean;
    areaSVG: any;
    zonesOverMap: Array<any> = [];
    currentZoom = 1;
    viewExtraData;
    viewFullSize = false;
    firstRender = true;

    currentRequestId: string;
    currentFilters: VariableFilter[] = [];
    subscribed = false;
    waitForResponse = false;
    waitForResponseTimeout = null;
    isVisibilityEnabled = false;
    metadataBlocks: any = {};
    shapesVariables: any = [];
    textVariables: string[];
    allVariablesToShow: string[];
    variableColorsConfiguration: VariableColors[] = [];
    warehouse: Warehouse;
    linesCache: Map<string, JQuery<HTMLElement>> = new Map();
    firstMapLoad = true;

    keySelectedWithRightClick = '';
    rightClickX = 0;
    rightClickY = 0;

    private acceptedArrows = ['hl', 'hr', 'vu', 'vd'];
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private appLoadService: AppLoadService,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private variablesStatusClient: VariablesStatusClient,
        private pageLifeCycleService: PageLifecycleService,
        private textColorPipe: TextColorPipe,
    ) {
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.warehouse = res));
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.viewExtraData = res.viewExtraData;
            this.liteView = res.liteMode;
            this.identifiersPreferences.Area = this.userConfiguration.identifiersPreferences.Area;
            this.identifiersPreferences.Zone = this.userConfiguration.identifiersPreferences.Zone;
            this.identifiersPreferences.Line = this.userConfiguration.identifiersPreferences.Line;
        });
    }

    ngOnInit() {
        this.variablesStatusClient.connectToHub().then(() => {
            console.warn('Connected to Variables status Hub');
        });
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            if (params.floorId && params.areaId) {
                this.floorId = params.floorId;
                this.areaId = params.areaId;
            } else if (!this.floorId) {
                this.floorId = this.alternativeId.floorId;
                this.areaId = this.alternativeId.areaId;
            }
            this.area = this.map.areas?.filter((a) => a.id === this.areaId)[0];
        })
        this.area.zones.forEach((zone) => {
            zone.lines.forEach((line) => {
                if (line.data && line.data !== 'null') {
                    if (line.orientation) {
                        const allCoords = line.data.split(' ').filter((el) => {
                            return el != null && el.trim() !== '';
                        });
                        // CALCULAR LAS COORDENADAS X
                        const allXCoords = allCoords
                            .map((coord) => {
                                let finalCoord = coord.split(',')[0];
                                if (finalCoord.indexOf('L') !== -1) {
                                    const checkLvalues = finalCoord.split('L');
                                    finalCoord = checkLvalues.length > 1 ? checkLvalues[1] : checkLvalues[0];
                                }
                                if (finalCoord.indexOf('l') !== -1) {
                                    const checkLvalues = finalCoord.split('l');
                                    finalCoord = checkLvalues.length > 1 ? checkLvalues[1] : checkLvalues[0];
                                }
                                if (finalCoord.indexOf('M') !== -1) {
                                    const checkMvalues = finalCoord.split('M');
                                    finalCoord = checkMvalues[0];
                                }
                                if (finalCoord.indexOf('m') !== -1) {
                                    const checkMvalues = finalCoord.split('m');
                                    finalCoord = checkMvalues[0];
                                }
                                if (finalCoord.indexOf('Z') !== -1 || finalCoord.indexOf('z') !== -1) {
                                    finalCoord = null;
                                }
                                return Number(finalCoord);
                            })
                            .filter((el) => el !== 0 && !isNaN(el));

                        // CALCULAR LAS COORDENADAS Y
                        const allYCoords = allCoords
                            .map((coord) => {
                                const splittedCord = coord.split(',');
                                let finalCoord = splittedCord.length > 1 ? splittedCord[1] : splittedCord[0];
                                if (finalCoord.indexOf('L') !== -1) {
                                    const checkLvalues = finalCoord.split('L');
                                    finalCoord = checkLvalues[0];
                                }
                                if (finalCoord.indexOf('l') !== -1) {
                                    const checkLvalues = finalCoord.split('l');
                                    finalCoord = checkLvalues[0];
                                }
                                if (finalCoord.indexOf('Z') !== -1) {
                                    const checkZvalues = finalCoord.split('Z');
                                    finalCoord = checkZvalues[0];
                                }
                                if (finalCoord.indexOf('z') !== -1) {
                                    const checkZvalues = finalCoord.split('z');
                                    finalCoord = checkZvalues[0];
                                }
                                if (finalCoord.indexOf('M') !== -1 || finalCoord.indexOf('m') !== -1) {
                                    finalCoord = null;
                                }
                                return Number(finalCoord);
                            })
                            .filter((el) => el !== 0 && !isNaN(el));

                        if (line.orientation === 'HL') {
                            line.xArrow = Math.max.apply(null, allXCoords) - 8;
                        } else if (line.orientation === 'HR') {
                            line.xArrow = Math.min.apply(null, allXCoords);
                        }
                        if (line.orientation === 'HL' || line.orientation === 'HR') {
                            let firstNode = line.data.split(' ')[0].split(',')[1];
                            firstNode = firstNode ? firstNode : line.data.split(' ')[1].split(',')[0];
                            const splited = firstNode.toUpperCase().split('L');
                            line.yArrow = Number(splited.length > 1 ? splited[0] : firstNode) + 1;
                        }

                        if (line.orientation === 'VU') {
                            line.yArrow = Math.max.apply(null, allYCoords) - 8;
                        } else if (line.orientation === 'VD') {
                            line.yArrow = Math.min.apply(null, allYCoords);
                        }
                        if (line.orientation === 'VU' || line.orientation === 'VD') {
                            const bufferX = line.data.toUpperCase()
                                .split(' ')[0]
                                .split(',')[0]
                                .split('M')
                                .filter((el) => el !== '');
                            line.xArrow = bufferX[0] ? Number(bufferX[0]) + 1 : 1;
                        }
                    }
                }
            });
        });

        let totalLines = 0;
        let totalLinesOverMap = 0;
        this.setViewBox = this.area.viewBox;
        this.$svg = $(this.svg.nativeElement);
        this.$states = $(this.states.nativeElement);
        // this.$buttons = $(this.buttons.nativeElement).add(this.buttonsOverMap.nativeElement);
        this.zonesOverMap = this.area.zones
            .map((zone) => {
                const linesOverMap = zone.lines.filter((line) => !line.data || line.data === 'null');
                totalLines += zone.lines.length;
                totalLinesOverMap += linesOverMap.length;
                return {
                    id: zone.id,
                    lines: linesOverMap,
                };
            })
            .filter((zone) => zone.lines.length > 0);

        if (totalLines === totalLinesOverMap) { this.liteView = true; }
        // if (totalLines === totalLinesOverMap) {
        //     this.liteView = true;
        // } else {
        //     this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
        //         this.liteView = res.liteMode;
        //     });
        // }
        this.isVisibilityEnabled = this.map.variablesLines?.variablesVisibility?.Area ?? this.map.variablesLines?.variablesVisibility?.area;
        this.metadataBlocks.metadata = [];
        if (this.isVisibilityEnabled) {
            this.map.variablesLines?.metadata?.forEach((data) => {
                const dataJsonFormat = JSON.parse(data);
                if (dataJsonFormat.variable && dataJsonFormat.variable !== '') this.metadataBlocks.metadata.push(dataJsonFormat);
            });
            this.textVariables = this.map?.variablesLines?.texts?.map((t) => t.variable ?? t.selectedVariable).filter((v) => v && v !== '');
            this.shapesVariables = this.map?.variablesLines?.shapes.map((s) => s.variable).filter((v) => v && v !== '');
            const metadataVariables: string[] = this.metadataBlocks?.metadata?.map((t) => t.variable).filter((v) => v && v !== '');
            this.allVariablesToShow = [...new Set<string>(metadataVariables.concat(this.textVariables).concat(this.shapesVariables))];
        }
        $(() => {
            $('[data-element="line"]').tooltip({
                container: 'body',
                html: true,
                placement: 'top',
            });
        });
    }

    async ngAfterViewInit(): Promise<void> {
        this.$buttons = this.liteView ? $(this.buttons.nativeElement) : null;
        this.$buttonsOverMap = !this.liteView ? $(this.buttonsOverMap.nativeElement) : null;

        this.$freeBacks = $(this.freeBacks?.nativeElement);
        this.$freeFronts = $(this.freeFronts?.nativeElement);

        this.areaSVG = Svg.adopt(this.svg.nativeElement);
        this.areaStates = Svg.adopt(this.states.nativeElement);
        this.filterShapes();
        this.currentZoom = this.areaSVG.zoom();

        this.areaSVG.panZoom({ zoomMax: 10, zoomMin: this.currentZoom, zoomFactor: 0.5 });

        this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
            if (
                prevState !== VisibilityStates.active &&
                [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
                this.variablesActiveStatusService.isConnected()
            ) {
                this.handleReconnection();
            }
        });
        if (this.isVisibilityEnabled && this.map.variablesLines) {
            setTimeout(() => {
                this.loadVariableRT();
            });
        }

        this.appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.variableColorsConfiguration = res['variableColors'] ?? [];
        });
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.visibleStates?.currentValue || changes.lineChange$?.currentValue) {
            await this.startRealtimeNotifications();
        }
    }

    async ngOnDestroy(): Promise<void> {
        this.$svg?.remove();
        $('[data-element="line"]').tooltip('dispose');
        if (this.$buttons) {
            this.$states?.add(this.$buttons)?.find('[data-element="marker"]').popover('hide').off();
            this.$buttons = null;
        }
        if (this.$buttonsOverMap) {
            this.$states?.add(this.$buttonsOverMap)?.find('[data-element="marker"]').popover('hide').off();
            this.$buttonsOverMap = null;
        }
        this.$svg = null;
        this.$states = null;
        this.$freeBacks = null;
        this.$freeFronts = null;
        this.variablesActiveStatusService.unsubscribeFromVariable();
        this.variablesStatusClient.disconnectFromHub();
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
        this.linesCache.clear();
        this.linesCache = null;
    }

    toogleFullScreen(): void {
        this.viewFullSize = !this.viewFullSize;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.viewFullSize ? $('html').addClass('no-scroll') : $('html').removeClass('no-scroll');
    }

    filterShapes() {
        if (this.area.viewBox) {
            const viewboxProps = this.area.viewBox.split(' ');
            const minWidth = viewboxProps[0];
            const maxWidth = viewboxProps[0] + viewboxProps[2];
            const minHeight = viewboxProps[1];
            const maxHeight = viewboxProps[1] + viewboxProps[3];
            this.$freeBacks?.find('path, text').each(function () {
                const currentBbox = Svg.adopt(this).bbox();
                if (
                    currentBbox.x < Number(minWidth) ||
                    currentBbox.x2 > Number(maxWidth) ||
                    currentBbox.y < Number(minHeight) ||
                    currentBbox.y2 > Number(maxHeight)
                ) {
                    this.remove();
                }
            });
            this.$freeFronts?.find('path, text').each(function () {
                const currentBbox = Svg.adopt(this).bbox();
                if (
                    currentBbox.x < Number(minWidth) ||
                    currentBbox.x2 > Number(maxWidth) ||
                    currentBbox.y < Number(minHeight) ||
                    currentBbox.y2 > Number(maxHeight)
                ) {
                    this.remove();
                }
            });
        }
    }

    emitZone(event) {
        this.clickZone.emit(event);
    }

    async startRealtimeNotifications() {
        try {
            if (!this.lineChange$) return;
            await this.cleanLineMapStates();
            this.lineChange$
                ?.pipe(
                    bufferTime(200),
                    switchMap(async (notifications) => (this.$svg ? notifications : [])),
                    switchMap(async (notifications) => notifications.filter((notification) => this.floorId === notification.floorId)), //paint all floor notifications, to have close area's fresh states, to area  (&& this.areaId === notification.areaId)
                    switchMap(async (notifications) => notifications.map((notification) => this.getElementToUpdateState(notification))),
                    takeUntil(this.ngUnsubscribe),
                )
                .subscribe({
                    next: this.drawLineState.bind(this),
                    error: console.error,
                });
        } catch (error) {
            console.error(error);
        }
    }

    async cleanLineMapStates() {
        if (!this.$svg) return;
        if (this.firstMapLoad) {
            this.firstMapLoad = false;
            return;
        }
        for (const zone of this.area.zones) {
            for (const line of zone.lines) {
                const key = `${this.floorId}-${this.area.id}-${zone.id}-${line.id}`;
                const el = this.$svg.find(`[data-key="${key}"]`);
                if (el) el.removeClass().removeData('state');

                if (this.$buttons) {
                    const button = this.$buttons.find(`[data-key="${key}"]`);
                    if (button) button.removeClass().removeData('state');
                }
                if (this.$buttonsOverMap) {
                    const button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
                    if (button) button.removeClass().removeData('state');
                }
            }
            // await yieldToMain();
        }
    }

    getElementToUpdateState(notification) {
        const key = `${notification.floorId}-${notification.areaId}-${notification.zoneId}-${notification.lineId}`;
        if (this.$svg) {
            const el = this.getOrAddLineCache(key);
            let button;
            if (this.$buttons) {
                button = this.$buttons.find(`[data-key="${key}"]`);
            } else if (this.$buttonsOverMap) {
                button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
            }
            return { notification, el, button };
        } /*else if (this.$buttons) {
            const button = this.$buttons.find(`[data-key="${key}"]`);
            return { notification, button };
        }*/
        return undefined;
    }

    drawLineState(items: Array<any>) {
        for (const item of items) {
            if (!item) continue;
            const { notification, el, button } = item;
            // TODO: hace falta??
            // this.$svg.find(`[data-icon-key="${key}"]`).remove();
            // this.$buttons.find(`[data-icon-key="${key}"]`).remove();
            const state = notification.state.filter((s) => s === 'unknown' || this.visibleStates?.includes(s)).join(' ');
            const newClass = this.liteView ? `btn btn-default ${state}` : el.length > 0 ? state : `btn btn-default ${state}`;
            const element = this.liteView ? button : el.length > 0 ? el : button;

            fastdom.mutate(() => element.removeClass().addClass(newClass).data('state', state));
        }
    }

    resetZoom() {
        if (this.areaSVG) {
            this.areaSVG.zoom(this.currentZoom);
            this.$svg.attr('viewBox', this.area.viewBox);
        }
    }

    refreshVisibleLines() {
        const rectSVG = this.svg.nativeElement.getBoundingClientRect();
        const visibleLines = this.$svg
            ?.find(`[data-key]`)
            .get()
            .filter((svg: HTMLElement) => isInViewport(svg, rectSVG))
            .map((svg: HTMLElement) => {
                return svg.dataset?.['key'];
            });
        this.visibleLines.emit(visibleLines);
    }

    createArrow(data) {
        if (data) {
            const orientation = data.toLowerCase();
            if (this.acceptedArrows.includes(orientation)) {
                return `assets/img/arrows/${orientation}.svg#${orientation}-full`;
            } else {
                return ``;
            }
        } else {
            return ``;
        }
    }

    setTitleY() {
        return Number(this.setViewBox.split(' ')[1]) - 8;
    }

    async handleReconnection(this) {
        this.variablesActiveStatusService.unsubscribeFromVariable();
        this.subscribed = false;
        this.currentFilters = [];
        //this.selectedVariablesFromDT.forEach((element) => this.loadVariableRT(element.selectedVariable));
        this.loadVariableRT();
    }


    loadVariableRT() {
        if (!this.allVariablesToShow || this.allVariablesToShow.length === 0) return;

        if (!this.subscribed) this.currentRequestId = 'AreaMap---' + uuid();

        this.allVariablesToShow.forEach((variableToShow) => {
            let [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow?.split('.');
            variableType = variableType === 'FAILURE' ? 'ALARM' : variableType;
            const firstFilter: VariableFilter = {
                warehouseId: this.warehouse.warehouse,
                floorId,
                areaId,
                zoneId,
                lineId,
                equipmentId,
                //equipmentType, //in DT this property is *
                variableType,
                variableName,
            };
            this.currentFilters.push(firstFilter);
        });
        const filters: VariableFilters = {
            requestId: this.currentRequestId,
            filters: this.currentFilters,
            maxNotificationItems: MAX_NOTIFICATIONS,
            orderColumn: ORDER_COLUMN,
            orderType: ORDER_TYPE,
        };

        if (!this.subscribed) {
            this.subscribed = true;
            this.variablesActiveStatusService.subscribeToVariable(filters, this.receiveRealTimeVariables.bind(this), this.handleReconnection.bind(this));
        } else {
            this.variablesActiveStatusService.applyFilter(filters);
        }
        this.waitForResponseTimeout = setTimeout(() => {
            this.waitForResponse = false;
            this.waitForResponseTimeout = null;
        }, 3000);
    }

    receiveRealTimeVariables(notification: VariableNotification) {
        if (notification.requestId === this.currentRequestId) {
            if (notification.sourceTimeStamp === undefined && ![undefined, null, 'ApiDefault'].includes(notification.source)) {
                console.error('SourceTimeStamp from backend is null/undefined.', notification);
            }
            this.allVariablesToShow.forEach((item) => {
                const [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = item.split('.'); //item.variable.split('.');
                if (
                    notification.floorId === floorId &&
                    notification.areaId === areaId &&
                    notification.zoneId === zoneId &&
                    notification.lineId === lineId &&
                    //notification.equipmentType === equipmentType &&
                    notification.equipmentId === equipmentId &&
                    notification.variableType.toLowerCase() === variableType.toLowerCase() &&
                    notification.variableName === variableName
                ) {
                    //cause in DT u dont know the EquipmentType, is used an * for this value
                    const key = `${areaId}.${zoneId}.${floorId}.${lineId}.${equipmentId}.*.${variableType}.${variableName}`.toUpperCase();

                    const variableValue = notification.variableValueType.toUpperCase() !== 'BOOLEAN' ? notification.variableValue : notification.variableValue.toString().toUpperCase() === 'TRUE' ? 1 : 0;

                    this.map.variablesLines?.texts
                        ?.filter((v) => v['variable'] === key)
                        .forEach((text) => (text['text'] = String(variableValue)));

                    this.metadataBlocks.metadata
                        .filter((b) => b['variable'] === key)
                        .forEach((block) => {
                            block.texts.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = String(variableValue)));
                        });

                    const icon = this.$svg?.find(`path.shape[data-key="${key}"]`);
                    const blockElement = this.$svg?.find(`g.shape.block > path.withVariable[data-key="${key}"]`);
                    const blockTextElement = this.$svg?.find(`[data-key="${'text' + key}"]`);

                    if ((blockElement?.length && blockTextElement?.length) || icon?.length) {
                        let pathTextFillColor = '#eeeeee';

                        const variableFound: VariableColors = this.findVariableColor(areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName);

                        if (variableFound) {
                            const defaultColor = variableFound.settings?.find((color) => +color.to === 0);
                            const foundedColor = variableFound.settings?.find((color) => +variableValue <= +color.to);
                            const finalColor = foundedColor ?? defaultColor;
                            if (finalColor) {
                                pathTextFillColor = finalColor.color;
                                if (icon?.length) {
                                    if (finalColor.visible !== false) {
                                        icon.attr('style', 'fill:' + pathTextFillColor);
                                    } else {
                                        icon.attr('style', 'display:none');
                                    }
                                }
                                if (blockTextElement?.length) {
                                    const foreColor = this.textColorPipe.transform(pathTextFillColor);
                                    blockTextElement.attr('style', 'fill: ' + foreColor + '; stroke: ' + foreColor);
                                }
                            }
                            blockElement?.attr('style', 'fill:' + pathTextFillColor);
                        }
                    }
                }
            });

            this.waitForResponse = false;
            if (this.waitForResponseTimeout) {
                clearTimeout(this.waitForResponseTimeout);
                this.waitForResponseTimeout = null;
            }
        }
    }

    findVariableColor(areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName): VariableColors {
        //first find the whole fqn, then with wildcards
        const variableFoundTemp: VariableColors = this.variableColorsConfiguration.find((setting) => {
            const [
                settingAreaId,
                settingZoneId,
                settingFloorId,
                settingLineId,
                settingEquipmentId,
                settingEquipmentType,
                settingVariableType,
                settingVariableName,
            ] = setting.variable.split('.');

            return (
                areaId === settingAreaId &&
                zoneId === settingZoneId &&
                floorId === settingFloorId &&
                lineId === settingLineId &&
                equipmentId === settingEquipmentId &&
                // settingEquipmentType === equipmentType &&
                variableType === settingVariableType &&
                settingVariableName === variableName
            );
        });
        if (variableFoundTemp) return variableFoundTemp;

        const variableFound = this.variableColorsConfiguration.find((setting) => {
            const [
                settingAreaId,
                settingZoneId,
                settingFloorId,
                settingLineId,
                settingEquipmentId,
                settingEquipmentType,
                settingVariableType,
                settingVariableName,
            ] = setting.variable.split('.');

            return (
                [areaId, WILD_CARD_CHARACTER].includes(settingAreaId) &&
                [zoneId, WILD_CARD_CHARACTER].includes(settingZoneId) &&
                [floorId, WILD_CARD_CHARACTER].includes(settingFloorId) &&
                [lineId, WILD_CARD_CHARACTER].includes(settingLineId) &&
                [equipmentId, WILD_CARD_CHARACTER].includes(settingEquipmentId) &&
                // settingEquipmentType === equipmentType &&
                [variableType, WILD_CARD_CHARACTER].includes(settingVariableType) &&
                settingVariableName === variableName
            );
        });

        return variableFound;
    }

    emitLine(event, newLineObject) {
        this.activateZoom = false;
        if (newLineObject) {
            this.newLineSelected.emit(newLineObject);
        } else if (event) {
            const key = $(event.target).data('key');
            const keys = key.split('-');

            this.newLineSelected.emit({
                warehouseId: this.warehouse.warehouse,
                floorId: keys[0],
                areaId: keys[1],
                zoneId: keys[2],
                lineId: keys[3],
            });
        }
    }

    getOrAddLineCache(key: string): JQuery<HTMLElement> {
        if (!this.linesCache?.has(key)) {
            const el = this.$svg.find(`[data-key="${key}"]`);
            this.linesCache.set(key, el);
        }
        return this.linesCache.get(key);
    }

    onRightClick(e) {
        this.$svg?.find('path').tooltip('hide');

        this.keySelectedWithRightClick = $(e.target).data('key');

        this.rightClickX = e.pageX;
        this.rightClickY = e.pageY;

        return false;
    }
    hideContextMenu() {
        this.keySelectedWithRightClick = '';
        this.rightClickX = 0;
        this.rightClickY = 0;
    }
}

<div class="collapse" id="filterMenu" [ngClass]="{ show: !isFullscreen || showFilters }">
    <div class="row search-nav mt-3">
        <div class="col-md-12 col-lg-10">
            <h2>{{ warehouse.displayName | titlecase }}</h2>
        </div>
        <app-select columns="1" extraClasses="ps-lg-0" #homeMapMode key="homeMap-mode" label="Mode" [item$]="mode$" [selectedValue]="comboMode"></app-select>
        <app-dropdown-checkbox
            label="States"
            [icon]="filterIcon"
            [iconTitle]="filterTitle"
            [availableItems]="availableStates"
            (changeFilter)="handleChangeVisibleStates($event)"
            extraClasses="ps-lg-0"
        ></app-dropdown-checkbox>
    </div>
</div>

<router-outlet></router-outlet>

/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { StateService } from '@app/core/shared/state/state.service';
import { ActiveAlertsClient } from '../clients/active-alerts.client';
import { Subscription } from 'rxjs';

export interface AlertsCountStatus {
    newCount: number;
    sourcets: Date;
    serverts: Date;
    timestamp: string;
    warehouseId: string;
}
export interface WarehouseMapStatus {
    whid: string;
    version: string;
    sourcets: Date;
}

const ALERTS_COUNT = 'AlertsCount';
const WAREHOUSE_MAP = 'WarehouseMap';

@Injectable()
export class AlertsCountStatusService {
    onAlertsCount: EventEmitter<AlertsCountStatus> = new EventEmitter<AlertsCountStatus>();
    onWarehouseMap: EventEmitter<WarehouseMapStatus> = new EventEmitter<WarehouseMapStatus>();
    alertsCountSubscription: Subscription;
    alertsCountSubscriptionId: string;
    warehouseMapSubscription: Subscription;
    warehouseMapSubscriptionId: string;
    endPoint: string;
    warehouse: string;
    onReconnectAlertCount: () => Promise<void>;
    handleReconnectionAlertCountReference: () => Promise<void>;
    onReconnectWarehouseMapChanged: () => Promise<void>;
    handleReconnectionWarehouseMapChangedReference: () => Promise<void>;

    private readonly alertsCountCallback = (alertsCountStatus: AlertsCountStatus) => {
        if (!this.onAlertsCount.isStopped) {
            this.onAlertsCount.emit(alertsCountStatus);
        }
    };
    private readonly warehouseMapChangedCallback = (warehouseMapStatus: WarehouseMapStatus) => {
        this.onWarehouseMap.emit(warehouseMapStatus);
    };

    constructor(private activeAlertsClient: ActiveAlertsClient, private stateService: StateService) {}

    public async subscribeToAlertCount(
        warehouse: string,
        context: any,
        delegateFunc: (context: any, alertsCount: number) => void,
        onReconnect: () => Promise<void>
    ) {
        const eventName = ActiveAlertsClient.SupportedEvents.ACTIVE_ALERTS_COUNT_CHANGED;
        // tslint:disable-next-line:max-line-length
        this.alertsCountSubscription = this.onAlertsCount.subscribe({
            next: (event: AlertsCountStatus) => {
                const notificationTS = event.serverts;
                // tslint:disable-next-line: max-line-length
                if (this.stateService.isNewerNotification(this.constructor.name, ALERTS_COUNT, eventName, notificationTS, event)) {
                    delegateFunc(context, event.newCount);
                }
            },
        });
        this.stateService.clearNotificationState(this.constructor.name, ALERTS_COUNT, eventName);
        this.onReconnectAlertCount = onReconnect;
        this.handleReconnectionAlertCountReference = this.handleReconnectionAlertCount.bind(this);
        await this.activeAlertsClient.subscribe(eventName, warehouse, this.alertsCountCallback, this.handleReconnectionAlertCountReference);
    }

    async handleReconnectionAlertCount() {
        const eventName = ActiveAlertsClient.SupportedEvents.ACTIVE_ALERTS_COUNT_CHANGED;
        this.stateService.clearNotificationState(this.constructor.name, ALERTS_COUNT, eventName);
        await this.onReconnectAlertCount?.();
    }

    public async unsubscribeFromAlertCount(warehouse: string) {
        this.alertsCountSubscription?.unsubscribe();
        const eventName = ActiveAlertsClient.SupportedEvents.ACTIVE_ALERTS_COUNT_CHANGED;
        await this.activeAlertsClient.unsubscribe(eventName, warehouse);
    }

    public async subscribeToWarehouseMapChanged(
        warehouse: string,
        context: any,
        delegateFunc: (context: any, warehouseMap: any) => void,
        onReconnect: () => Promise<void>
    ) {
        const eventName = ActiveAlertsClient.SupportedEvents.WAREHOUSE_MAP_CHANGED;
        // tslint:disable-next-line:max-line-length
        this.warehouseMapSubscription = this.onWarehouseMap.subscribe({
            next: (event: WarehouseMapStatus) => {
                const storedVersion = this.stateService.getLastNotification(this.constructor.name, WAREHOUSE_MAP, eventName, event);
                const candidateVersion = event.version;
                const key = `${this.warehouse}.${eventName}`;
                if (!candidateVersion || !storedVersion || candidateVersion.localeCompare(storedVersion.timestamp) >= 0) {
                    delegateFunc(context, event);
                    if (candidateVersion) {
                        // tslint:disable-next-line: max-line-length
                        this.stateService.setLastNotification(this.constructor.name, WAREHOUSE_MAP, eventName, key, {
                            timestamp: event.version,
                            notification: event,
                        });
                    }
                } else {
                    // tslint:disable-next-line: max-line-length
                    const { timestamp: currentVersion } = storedVersion;
                    console.log(
                        `%cSkip notification ${key}
current:   ${currentVersion}
candidate: ${candidateVersion}`,
                        'color: orange;'
                    );
                }
            },
        });
        this.stateService.clearNotificationState(this.constructor.name, WAREHOUSE_MAP, eventName);
        this.onReconnectWarehouseMapChanged = onReconnect;
        this.handleReconnectionWarehouseMapChangedReference = this.handleReconnectionWarehouseMapChanged.bind(this);
        await this.activeAlertsClient.subscribe(eventName, warehouse, this.warehouseMapChangedCallback, this.handleReconnectionWarehouseMapChangedReference);
    }

    async handleReconnectionWarehouseMapChanged() {
        const eventName = ActiveAlertsClient.SupportedEvents.WAREHOUSE_MAP_CHANGED;
        this.stateService.clearNotificationState(this.constructor.name, ALERTS_COUNT, eventName);
        await this.onReconnectWarehouseMapChanged?.();
    }

    public async unsubscribeFromWarehouseMapChanged(warehouse: string) {
        this.warehouseMapSubscription?.unsubscribe();
        const eventName = ActiveAlertsClient.SupportedEvents.WAREHOUSE_MAP_CHANGED;
        await this.activeAlertsClient.unsubscribe(eventName, warehouse);
    }

    isConnected() {
        return this.activeAlertsClient.connection?.hubConnection.state === 'Connected';
    }
}

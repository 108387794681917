import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Structure, Floor } from '@home/shared/structure';
import { shareReplay, catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AppLoadService } from '@app/app-load.service';

@Injectable()
export class FloorsService {
    baseUrl: string;
    warehouse: string;

    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) {}

    getFloor(idFloor: string): Observable<Structure | Floor> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'map' : `map/${this.warehouse}/definitions/floors/${idFloor}`;
                return this.http.get<Structure>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp: any) => {
                if (environment.mode !== 'front') {
                    return resp;
                } else {
                    const floor = resp.floors.filter((f) => {
                        return String(f.id) === idFloor;
                    });
                    return floor[0];
                }
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar la planta', err);
                return of(null);
            }),
            shareReplay(),
        );
    }

    floorNotificationReceieved(notificacion: any): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'state' : `state/${this.warehouse}/notifications/notificationReceived`;
                return this.http.post<any>(`${this.baseUrl}/${endpoint}`, notificacion);
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema al mandar la recepción de una notificación de la planta -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }
}

import { NgModule } from '@angular/core';

import { SvgMappingDirective } from '@shared/directives/svg-mapping.directive';
import { IsLastDirective } from '@shared/directives/is-last.directive';
import { OpenLinkInNewTabDirective } from './open-new-tab.directive';
import { SvgZoomDirective } from './svg-zoom.directive';

@NgModule({
    declarations: [SvgMappingDirective, SvgZoomDirective, IsLastDirective, OpenLinkInNewTabDirective],
    exports: [SvgMappingDirective, SvgZoomDirective, IsLastDirective, OpenLinkInNewTabDirective],
    providers: [{ provide: Window, useValue: window }],
})
export class DirectivesModule {}

import { Component, OnDestroy } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    template: `<div style="padding-top: 100px; text-align: center;">
        <h1>404</h1>
        <h2>We can't seem to find the page you're looking for.</h2>
        <br />
        <a [routerLink]="['/warehouse', warehouse]">Take me back to homepage</a>
    </div>`,
    styles: [],
})
export class NotFoundComponent implements OnDestroy {
    warehouse: string;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private appLoadService: AppLoadService) {
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.warehouse = res.warehouse;
        });
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }
}

<div class="row mt-4">
    <div class="col-12 col-lg-2 mt-3">Compare:</div>
    <app-select columns="4" #comparatorA key="comparator-a" label="First dimension" [item$]="itemsA"></app-select>
    <div class="col-12 col-lg-1 mt-3">with</div>
    <app-select columns="4" #comparatorB key="comparator-b" label="Second dimension" [item$]="itemsB"></app-select>
</div>
<div class="row">
    <div class="col-12">
        <!-- <div class="wrapper-chart" style="height: 330px;" [ngClass]="{'full-screen-chart' : fullscreen}">
      <canvas #scatterPlotChart>{{ chart }}</canvas>
    </div> -->
        <shared-bubble-chart [data]="chartData" height="330px"></shared-bubble-chart>
    </div>
</div>

<span
    class="force-one-line"
    [(colorPicker)]="colorRange"
    (colorPickerSelect)="saveColorPicker($event)"
    cpDisableInput="true"
    cpAlphaChannel="disabled"
    cpPosition="auto"
    [cpPositionOffset]="'50%'"
    [cpDisabled]="disabled"
    cpPositionRelativeToArrow="true"
    [cpPresetColors]="[colorRunning, colorErrors, colorCritical, colorLWDO, colorStarts, colorNoData]"
    [cpOKButton]="true"
    [cpOKButtonText]="okButtonText"
    [cpOKButtonClass]="'auwa-color-picker'"
    [cpSaveClickOutside]="false"
    [cpCancelButton]="true"
    [cpCancelButtonClass]="'auwa-color-picker'"
    [cpCloseClickOutside]
    >{{ fromDataRange }}{{ symbol }} {{ middle }} {{ toDataRange }}{{ symbol }}</span
>

<div class="row">
    <!-- <div class="col-12">
    <p *ngIf="buttonBack" class="pb-2"><a (click)="mountBaseChart()"><strong><< Volver</strong></a></p>
  </div> -->
    <ng-container *ngIf="type === 'bar'">
        <div class="col-12">
            <shared-bar-chart #barChart [data]="chartData" height="380px" [percentage]="percentage"></shared-bar-chart>
        </div>
    </ng-container>
    <ng-container *ngIf="type === 'linear'">
        <div class="col-12">
            <shared-linear-chart [data]="chartData" height="380px"></shared-linear-chart>
        </div>
    </ng-container>
    <ng-container *ngIf="type === 'radar'">
        <div class="col-12">
            <shared-radar-chart [data]="chartData" height="435px" (pressLabel)="pressLabel($event)"></shared-radar-chart>
        </div>
    </ng-container>
</div>

/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MapState, MapStates } from '@app/shared/models/map-state';

const availableStates: Array<MapState> = [
    { position: 0, id: MapStates.run, label: 'Run', visible: true },
    { position: 1, id: MapStates.box, label: 'Box', visible: true },
    { position: 2, id: MapStates.without_permission, label: 'Without Permission', visible: true },
    { position: 3, id: MapStates.alert_low, label: 'Alert Low', visible: true },
    { position: 4, id: MapStates.alert, label: 'Alert', visible: true },
    { position: 5, id: MapStates.critical, label: 'Critical', visible: true },
    { position: 6, id: MapStates.warning, label: 'Warning', visible: true },
    { position: 7, id: MapStates.disconnected, label: 'Disconnected', visible: true },
];

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private versionMap = new BehaviorSubject(null);
    getVersionMap = this.versionMap.asObservable();
    notificationsMap = new Map<string, NotificationDetails>();
    private filterStates = new BehaviorSubject(availableStates);
    getFilterStates = this.filterStates.asObservable();
    private fullscreenState = new BehaviorSubject(false);
    isFullscreen = this.fullscreenState.asObservable();
    private showFiltersState = new BehaviorSubject(false);
    showFilters = this.showFiltersState.asObservable();
    private apiUri = new BehaviorSubject('');
    getApiUri = this.apiUri.asObservable();
    private reconnectWS = new Subject();
    getReconnectWS = this.reconnectWS.asObservable();

    constructor() {}

    setApiUri(uri: string) {
        this.apiUri.next(uri);
    }

    setVersionMap(version: string) {
        this.versionMap.next(version);
    }

    mapKey(serviceName: string, groupName: string, eventName: string, key: string) {
        return `lastNotification::${serviceName}.${groupName}.${eventName}-${key}`;
    }

    setLastNotification(serviceName: string, groupName: string, eventName: string, key: string, notificationDetails: NotificationDetails) {
        this.notificationsMap.set(this.mapKey(serviceName, groupName, eventName, key), notificationDetails);
    }

    getLastNotification(serviceName: string, groupName: string, eventName: string, notification: any): NotificationDetails {
        const key = this.generateKey(notification);
        return this.notificationsMap.get(this.mapKey(serviceName, groupName, eventName, key));
    }

    setFilterStates(states: Array<MapState>) {
        this.filterStates.next(states);
    }

    setFullscreen(isFullscreen: boolean) {
        this.fullscreenState.next(isFullscreen);
    }

    setShowFilters(showFilters: boolean) {
        this.showFiltersState.next(showFilters);
    }

    getNotificationData(n: any) {
        return n.state || n.newCount || n.isConnected;
    }

    logUpdateSkip(key: string, current: NotificationDetails, notification: any, newts: string) {
        console.log(
            `%cSkip update ${key}
        current:   ${current.timestamp} | ${this.getNotificationData(current.notification)}
        candidate: ${newts} | ${this.getNotificationData(notification)}`,
            'color: orange;',
        );
    }

    checkValues(current, candidate) {
        if (current instanceof Array) {
            return current.length === candidate.length && current.every((v, i) => v === candidate[i]);
        } else {
            return current === candidate;
        }
    }

    checkDates(candidate, current) {
        return current?.getTime && candidate?.getTime ? candidate.getTime() <= current.getTime() : candidate <= current;
    }

    isNewerNotification(serviceName: string, groupName: string, eventName: string, notificationTimestamp: any, notification: any): boolean {
        const key = this.generateKey(notification);
        const current = this.notificationsMap.get(this.mapKey(serviceName, groupName, eventName, key));
        let isNewer = true;
        if (current && JSON.stringify(current.notification) === JSON.stringify(notification)) {
            // skip duplicated messages
            isNewer = false;
        } else if (!notificationTimestamp && notification.source) {
            // if no ts is provided
            isNewer = true;
        } else if (current) {
            // check if is a new notification
            const currentTimestamp: any = current.timestamp;
            if (this.checkDates(notificationTimestamp, currentTimestamp)) {
                isNewer = false;
            }
        }

        if (isNewer) {
            const n = notification;
            this.setLastNotification(serviceName, groupName, eventName, key, { timestamp: notificationTimestamp, notification: n });
        }
        return isNewer;
    }

    appendProperty(currentKey: string, property: string) {
        return !property ? currentKey : currentKey ? `${currentKey}-${property}` : property;
    }

    private generateKey(notification: any) {
        let key = ``;
        key = this.appendProperty(key, notification.requestId);
        key = this.appendProperty(key, notification.warehouseId);
        key = this.appendProperty(key, notification.whid);
        key = this.appendProperty(key, notification.floorId);
        key = this.appendProperty(key, notification.areaId);
        key = this.appendProperty(key, notification.zoneId);
        key = this.appendProperty(key, notification.lineId);
        key = this.appendProperty(key, notification.equipmentId);
        key = this.appendProperty(key, notification.fieldGatewayId);
        key = this.appendProperty(key, notification.opcServerId);
        key = this.appendProperty(key, notification.nodeId);
        key = this.appendProperty(key, notification.variableName);
        key = this.appendProperty(key, notification.variableType);
        key = this.appendProperty(key, notification.version);
        key = this.appendProperty(key, notification.versionId);
        return key;
    }

    public clearNotificationState(constructorName: string, groupName: string, eventName: string) {
        const keyToDelete = `lastNotification::${constructorName}.${groupName}.${eventName}`;
        this.notificationsMap.forEach((notification, notificationKey, map) => {
            if (notificationKey.startsWith(keyToDelete)) {
                map.delete(notificationKey);
            }
        });
    }

    public forceReconnectWS() {
        this.reconnectWS.next(true);
    }
}

interface NotificationDetails {
    timestamp: string;
    notification: any;
}

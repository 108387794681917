import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { LINES } from '@app/map/floors/components/floors.component';
import { isInViewport } from '@app/map/shared/svg-functions';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { VariableFilter, VariableFilters, VariableNotification, WILD_CARD_CHARACTER } from '@app/notifications/shared/events/variable-status';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { UserConfiguration } from '@app/shared/models/configurations';
import { yieldToMain } from '@app/shared/performance/auwa-scheduler';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { PageLifecycleService, VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { environment } from '@environments/environment';
import { Floor } from '@home/shared/structure';
import { Observable, Subject } from 'rxjs';
import { bufferTime, switchMap, takeUntil } from 'rxjs/operators';
import { VariableColors } from '@variables/shared/variable';
import { Chart } from 'chart.js';
import { Router } from '@angular/router';
import '@svgdotjs/svg.panzoom.js';
import 'chartjs-adapter-dayjs-3';
import fastdom from 'fastdom';
import { v4 as uuid } from 'uuid';
import * as Svg from '@svgdotjs/svg.js';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const MAX_NOTIFICATIONS = 50;
const ORDER_COLUMN = 'SourceTimeStamp';
const ORDER_TYPE = 'desc';

@Component({
    selector: 'app-floors-map',
    templateUrl: './floors-map.component.html',
    styleUrls: ['./floors-map.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient, TextColorPipe],
})
export class FloorsMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() warehouse: string;
    @Input() floor: Floor;
    @Input() mode: string;
    @Input() type: string;
    @Input() visibleStates: Array<string>;
    @Input() currentMode: string;
    @Input() lineChange$: Observable<LineStatusNotification>;
    @Input() tabState: VisibilityStates;
    @Input() markedAreaZone?: any;
    @Input() activateZoom?: boolean = true;
    @Output() newLineSelected = new EventEmitter();
    @Output() clickArea = new EventEmitter();
    @Output() visibleLines = new EventEmitter();
    @ViewChild('svg2', { read: ElementRef, static: true }) svg2;
    @ViewChild('buttons', { read: ElementRef, static: false }) buttons;
    @ViewChild('buttonsOverMap', { read: ElementRef, static: false }) buttonsOverMap;
    @ViewChild('contextMenu', { static: true }) contextMenu: ElementRef;
    $svg2: JQuery;
    $buttons: JQuery;
    $buttonsOverMap: JQuery;
    identifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    currentWarehouse: any;
    userName: string;
    userEmail: string;
    liteView: boolean;
    currentRequestId: string;
    currentFilters: VariableFilter[] = [];
    subscribed = false;
    waitForResponse = false;
    waitForResponseTimeout = null;
    //selectedVariablesFromDT: Array<any> = [];
    avaliabilityChart: Chart;
    performanceChart: Chart;
    timeoutConst = null;
    zonesOverMap: Array<any> = [];
    linesOverMap: Array<any> = [];
    countZonesOverMap = 0;
    countLinesOverMap = 0;
    lineSVG: any;
    currentZoom = 1;
    viewExtraData;
    viewFullSize = false;

    isVisibilityEnabled = false;
    metadataBlocks: any = {};
    shapesVariables: any = [];
    textVariables: string[];
    allVariablesToShow: string[];
    variableColorsConfiguration: VariableColors[] = [];
    linesCache: Map<string, JQuery<HTMLElement>> = new Map();
    firstMapLoad = true;

    keySelectedWithRightClick = '';
    rightClickX = 0;
    rightClickY = 0;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private variablesStatusClient: VariablesStatusClient,
        private pageLifeCycleService: PageLifecycleService,
        private authenticationService: AuthenticationService,
        private configurationsService: ConfigurationsService,
        private textColorPipe: TextColorPipe,
        private router: Router
    ) {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
            this.liteView = this.userConfiguration.liteMode;
            this.viewExtraData = this.userConfiguration.viewExtraData;
            this.viewFullSize = this.userConfiguration.viewFullSize === this.floor?.id;
        });
    }

    ngOnInit() {
        this.variablesStatusClient.connectToHub().then(() => {
            console.warn('Connected to Variables status Hub');
        });
        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.currentWarehouse = res));
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }

        this.$svg2 = $(this.svg2.nativeElement);

        this.floor.areas.forEach((area) => {
            const areaOverMap = {
                id: area.id,
                zones: area.zones.filter((zone) => !zone.data || zone.data === 'null'),
            };
            if (areaOverMap.zones.length > 0) {
                this.zonesOverMap.push(areaOverMap);
                this.countZonesOverMap += areaOverMap.zones.length;
            }
            area.zones.forEach((zone) => {
                const zoneOverMap = {
                    id: `${area.id}-${zone.id}`,
                    lines: zone.lines.filter((line) => !line.data || line.data === 'null'),
                };
                if (zoneOverMap.lines.length > 0) {
                    this.linesOverMap.push(zoneOverMap);
                    this.countLinesOverMap += zoneOverMap.lines.length;
                }
            });
        });

        this.isVisibilityEnabled = this.floor.variablesLines?.variablesVisibility?.Floor ?? this.floor.variablesLines?.variablesVisibility?.floor;
        this.metadataBlocks.metadata = [];
        if (this.isVisibilityEnabled) {
            this.floor?.variablesLines?.metadata?.forEach((data) => {
                const dataJsonFormat = JSON.parse(data);
                if (dataJsonFormat.variable && dataJsonFormat.variable !== '') this.metadataBlocks.metadata.push(dataJsonFormat);
            });
            this.textVariables = this.floor?.variablesLines?.texts?.map((t) => t.variable).filter((v) => v && v !== '');
            this.shapesVariables = this.floor?.variablesLines?.shapes.map((s) => s.variable).filter((v) => v && v !== '');
            const metadataVariables: string[] = this.metadataBlocks?.metadata?.map((t) => t.variable).filter((v) => v && v !== '');
            this.allVariablesToShow = [...new Set<string>(metadataVariables.concat(this.textVariables).concat(this.shapesVariables))];
        }

        $(() => {
            $('[data-element="line"]').tooltip({
                container: 'body',
                html: true,
                placement: 'top',
            });
        });
    }

    ngAfterViewInit(): void {
        this.$buttons = this.liteView ? $(this.buttons.nativeElement) : null;
        this.$buttonsOverMap = !this.liteView ? $(this.buttonsOverMap.nativeElement) : null;

        this.lineSVG = Svg.adopt(this.svg2.nativeElement);
        this.currentZoom = this.lineSVG.zoom();

        this.lineSVG.panZoom({ zoomMax: 10, zoomMin: this.currentZoom, zoomFactor: 0.5 });

        this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
            if (
                prevState !== VisibilityStates.active &&
                [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
                this.variablesActiveStatusService.isConnected()
            ) {
                this.handleReconnection();
            }
        });
        if (this.isVisibilityEnabled && this.floor.variablesLines) {
            setTimeout(() => {
                this.loadVariableRT();
            });
        }

        this.appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.variableColorsConfiguration = res['variableColors'] ?? [];
        });
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.visibleStates?.currentValue || changes.lineChange$?.currentValue) {
            await this.startRealtimeNotifications();
        }
    }

    async ngOnDestroy(): Promise<void> {
        this.$svg2?.remove();
        $('path').tooltip('dispose');
        this.$svg2 = null;
        this.$buttons = null;
        this.$buttonsOverMap = null;
        this.variablesActiveStatusService.unsubscribeFromVariable();
        this.variablesStatusClient.disconnectFromHub();
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
        this.linesCache.clear();
        this.linesCache = null;
    }

    refreshVisibleLines() {
        const rectSVG = this.svg2.nativeElement.getBoundingClientRect();

        const visibleLines = this.$svg2
            ?.find(`[data-key]`)
            .get()
            .filter((svg: HTMLElement) => isInViewport(svg, rectSVG))
            .map((svg: HTMLElement) => {
                return svg.dataset?.['key'];
            });

        this.visibleLines.emit(visibleLines);
    }

    loadVariableRT() {
        if (!this.allVariablesToShow || this.allVariablesToShow.length === 0) return;

        if (!this.subscribed) this.currentRequestId = 'FloorMap---' + uuid();

        this.allVariablesToShow.forEach((variableToShow) => {
            let [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow?.split('.');
            variableType = variableType === 'FAILURE' ? 'ALARM' : variableType;
            const firstFilter: VariableFilter = {
                warehouseId: this.warehouse,
                floorId,
                areaId,
                zoneId,
                lineId,
                equipmentId,
                //equipmentType, //in DT this property is *
                variableType,
                variableName,
            };
            this.currentFilters.push(firstFilter);
        });
        const filters: VariableFilters = {
            requestId: this.currentRequestId,
            filters: this.currentFilters,
            maxNotificationItems: MAX_NOTIFICATIONS,
            orderColumn: ORDER_COLUMN,
            orderType: ORDER_TYPE,
        };

        if (!this.subscribed) {
            this.subscribed = true;
            this.variablesActiveStatusService.subscribeToVariable(filters, this.receiveRealTimeVariables.bind(this), this.handleReconnection.bind(this));
        } else {
            this.variablesActiveStatusService.applyFilter(filters);
        }
        this.waitForResponseTimeout = setTimeout(() => {
            this.waitForResponse = false;
            this.waitForResponseTimeout = null;
        }, 3000);
    }

    receiveRealTimeVariables(notification: VariableNotification) {
        if (notification.requestId === this.currentRequestId) {
            if (notification.sourceTimeStamp === undefined && ![undefined, null, 'ApiDefault'].includes(notification.source)) {
                console.error('SourceTimeStamp from backend is null/undefined.', notification);
            }

            this.allVariablesToShow.forEach((item) => {
                const [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = item.split('.'); //item.variable.split('.');
                if (
                    notification.floorId === floorId &&
                    notification.areaId === areaId &&
                    notification.zoneId === zoneId &&
                    notification.lineId === lineId &&
                    //notification.equipmentType === equipmentType &&
                    notification.equipmentId === equipmentId &&
                    notification.variableType.toLowerCase() === variableType.toLowerCase() &&
                    notification.variableName === variableName
                ) {
                    //cause in DT u dont know the EquipmentType, is used an * for this value
                    const key = `${areaId}.${zoneId}.${floorId}.${lineId}.${equipmentId}.*.${variableType}.${variableName}`.toUpperCase();

                    const variableValue = notification.variableValueType.toUpperCase() !== 'BOOLEAN' ? notification.variableValue : notification.variableValue.toString().toUpperCase() === 'TRUE' ? 1 : 0;

                    this.floor.variablesLines?.texts
                        ?.filter((v) => v['variable'] === key)
                        .forEach((text) => (text['text'] = String(variableValue)));

                    this.metadataBlocks.metadata
                        .filter((b) => b['variable'] === key)
                        .forEach((block) => {
                            block.texts.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = String(variableValue)));
                        });

                    const icon = this.$svg2?.find(`path.shape[data-key="${key}"]`);
                    const blockElement = this.$svg2?.find(`g.shape.block > path.withVariable[data-key="${key}"]`);
                    const blockTextElement = this.$svg2?.find(`[data-key="${'text' + key}"]`);

                    if ((blockElement?.length && blockTextElement?.length) || icon?.length) {
                        let pathTextFillColor = '#eeeeee';
                        const variableFound: VariableColors = this.findVariableColor(areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName);
                        if (variableFound) {
                            const defaultColor = variableFound.settings?.find((color) => +color.to === 0);
                            const foundedColor = variableFound.settings?.find((color) => +variableValue <= +color.to);
                            const finalColor = foundedColor ?? defaultColor;
                            if (finalColor) {
                                pathTextFillColor = finalColor.color;
                                if (icon?.length) {
                                    if (finalColor.visible !== false) {
                                        icon.attr('style', 'fill:' + pathTextFillColor);
                                    } else {
                                        icon.attr('style', 'display:none');
                                    }
                                }
                                if (blockTextElement?.length) {
                                    const foreColor = this.textColorPipe.transform(pathTextFillColor);
                                    blockTextElement.attr('style', 'fill: ' + foreColor + '; stroke: ' + foreColor);
                                }
                            }
                            blockElement?.attr('style', 'fill:' + pathTextFillColor);
                        }
                    }
                }
            });

            this.waitForResponse = false;
            if (this.waitForResponseTimeout) {
                clearTimeout(this.waitForResponseTimeout);
                this.waitForResponseTimeout = null;
            }
        }
    }

    findVariableColor(areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName): VariableColors {
        //first find the whole fqn, then with wildcards
        const variableFoundTemp: VariableColors = this.variableColorsConfiguration.find((setting) => {
            const [
                settingAreaId,
                settingZoneId,
                settingFloorId,
                settingLineId,
                settingEquipmentId,
                settingEquipmentType,
                settingVariableType,
                settingVariableName,
            ] = setting.variable.split('.');

            return (
                areaId === settingAreaId &&
                zoneId === settingZoneId &&
                floorId === settingFloorId &&
                lineId === settingLineId &&
                equipmentId === settingEquipmentId &&
                // settingEquipmentType === equipmentType &&
                variableType === settingVariableType &&
                settingVariableName === variableName
            );
        });
        if (variableFoundTemp) return variableFoundTemp;

        const variableFound = this.variableColorsConfiguration.find((setting) => {
            const [
                settingAreaId,
                settingZoneId,
                settingFloorId,
                settingLineId,
                settingEquipmentId,
                settingEquipmentType,
                settingVariableType,
                settingVariableName,
            ] = setting.variable.split('.');

            return (
                [areaId, WILD_CARD_CHARACTER].includes(settingAreaId) &&
                [zoneId, WILD_CARD_CHARACTER].includes(settingZoneId) &&
                [floorId, WILD_CARD_CHARACTER].includes(settingFloorId) &&
                [lineId, WILD_CARD_CHARACTER].includes(settingLineId) &&
                [equipmentId, WILD_CARD_CHARACTER].includes(settingEquipmentId) &&
                // settingEquipmentType === equipmentType &&
                [variableType, WILD_CARD_CHARACTER].includes(settingVariableType) &&
                settingVariableName === variableName
            );
        });

        return variableFound;
    }

    async startRealtimeNotifications() {
        try {
            if (this.currentMode !== LINES) return;
            await this.cleanLineMapStates();
            this.lineChange$
                ?.pipe(
                    bufferTime(200),
                    switchMap(async (notifications) => (this.$svg2 ? notifications : [])),
                    switchMap(async (notifications) => notifications.filter((notification) => this.floor.id === notification.floorId)),
                    switchMap(async (notifications) => notifications.map((notification) => this.getElementToUpdateState(notification))),
                    takeUntil(this.ngUnsubscribe),
                )
                .subscribe({
                    next: this.drawLineState.bind(this),
                    error: console.error,
                });
        } catch (error) {
            console.error(error);
        }
    }

    getElementToUpdateState(notification) {
        const key = `${notification.floorId}-${notification.areaId}-${notification.zoneId}-${notification.lineId}`;
        if (this.$svg2) {
            const el = this.getOrAddLineCache(key);
            let button;
            if (this.$buttons) {
                const zoneKey = `${notification.floorId}-${notification.areaId}-${notification.zoneId}`;
                button = this.$buttons.find(`[data-key="${zoneKey}"]`);
            } else if (this.$buttonsOverMap) {
                button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
            }
            return { notification, el, button };
        } /*else if (this.$buttons) {
            const button = this.$buttons.find(`[data-key="${key}"]`);
            return { notification, button };
        }*/
        return undefined;
    }

    drawLineState(items: Array<any>) {
        for (const item of items) {
            if (!item) continue;
            const { notification, el, button } = item;
            const state = notification.state.filter((s) => s === 'unknown' || this.visibleStates?.includes(s)).join(' ');
            const newClass = this.liteView ? `btn btn-default ${state}` : el.length > 0 ? state : `btn btn-default ${state}`;
            const element = this.liteView ? button : el.length > 0 ? el : button;

            fastdom.mutate(() => element.removeClass().addClass(newClass).data('state', state));
        }
    }

    async handleReconnection(this) {
        this.variablesActiveStatusService.unsubscribeFromVariable();
        this.subscribed = false;
        this.currentFilters = [];
        //this.selectedVariablesFromDT.forEach((element) => this.loadVariableRT(element.selectedVariable));
        this.loadVariableRT();
    }

    toogleFullScreen(): void {
        this.viewFullSize = !this.viewFullSize;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.viewFullSize ? $('html').addClass('no-scroll') : $('html').removeClass('no-scroll');
        this.userConfiguration.viewFullSize = this.viewFullSize ? this.floor.id : null;
        this.configurationsService.saveConfiguration(this.currentWarehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    }

    emitArea(event) {
        this.clickArea.emit(event);
    }

    emitLine(event, newLineObject) {
        this.activateZoom = false;
        if (newLineObject) {
            this.newLineSelected.emit(newLineObject);
        } else if (event) {
            const key = $(event.target).data('key');
            const keys = key.split('-');

            this.newLineSelected.emit({
                warehouseId: this.warehouse,
                floorId: keys[0],
                areaId: keys[1],
                zoneId: keys[2],
                lineId: keys[3],
            });
        }
    }

    navigateToZone(event) {
        const key = $(event.target).data('key');
        const keys = key.split('-');
        this.router.navigate([`warehouse/${this.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}`]);
    }

    async cleanLineMapStates() {
        if (!this.$svg2) return;
        if (this.firstMapLoad) {
            this.firstMapLoad = false;
            return;
        }
        for (const area of this.floor.areas || []) {
            for (const zone of area.zones || []) {
                for (const line of zone.lines || []) {
                    const key = `${this.floor.id}-${area.id}-${zone.id}-${line.id}`;
                    const el = this.getOrAddLineCache(key);
                    if (el) el.removeClass().removeData('state');

                    if (this.$buttons) {
                        const zoneKey = `${this.floor.id}-${area.id}-${zone.id}`;
                        const button = this.$buttons.find(`[data-key="${zoneKey}"]`);
                        if (button) button.removeClass().removeData('state');
                    }
                    if (this.$buttonsOverMap) {
                        const button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
                        if (button) button.removeClass().removeData('state');
                    }
                }
                // await yieldToMain();
            }
        }
    }

    resetZoom() {
        if (this.lineSVG) {
            this.lineSVG.zoom(this.currentZoom);
            this.$svg2.attr('viewBox', this.floor.linesViewBox);
            this.refreshVisibleLines();
        }
    }

    trackByAreas(index: number, area: any): string {
        return area.id;
    }
    trackByZones(index: number, zone: any): string {
        return zone.id;
    }
    trackByLines(index: number, line: any): string {
        return line.id;
    }
    trackByAreasLite(index: number, area: any): string {
        return area.id;
    }

    getOrAddLineCache(key: string) {
        if (!this.linesCache?.has(key)) {
            const el = this.$svg2.find(`[data-key="${key}"]`);
            this.linesCache.set(key, el);
        }
        return this.linesCache.get(key);
    }

    onRightClick(e) {
        this.$svg2?.find('path').tooltip('hide');

        this.keySelectedWithRightClick = $(e.target).data('key');

        this.rightClickX = e.pageX;
        this.rightClickY = e.pageY;

        return false;
    }
    hideContextMenu() {
        this.keySelectedWithRightClick = '';
        this.rightClickX = 0;
        this.rightClickY = 0;
    }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LandingComponent } from '@landing/landing.component';

@NgModule({
    imports: [SharedModule, NgxDatatableModule],
    declarations: [LandingComponent],
})
export class LandingModule {}

import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { CustomsComponent } from '@customs/components/customs.component';
import { CustomsService } from '@customs/shared/customs.service';
import { CustomsResolver } from '@customs/shared/customs.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [SharedModule, NgxDatatableModule, RouterModule],
    declarations: [CustomsComponent],
    providers: [CustomsService, CustomsResolver],
})
export class CustomsModule {}

import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { AlertsTableSmComponent } from '@app/shared/components/alerts-table-sm/alerts-table-sm.component';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { EquipmentAlarmNotification } from '@app/notifications/shared/events/alert-status';
import { environment } from '@environments/environment';
import { AppLoadService } from '@app/app-load.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { Floor } from '@home/shared/structure';
import { Alert } from '@alerts/shared/alert';
import { AlertsService } from '@alerts/shared/alerts.service';
import { PageLifecycleService, VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { MapGlobalState, MapState, initialStates } from '@app/shared/models/map-state';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import * as _ from 'lodash-es';

const { ALERT_STATE_CHANGED, LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;
export const LINES = 'Lines';
@Component({
    templateUrl: './floors.component.html',
    styleUrls: ['./floors.component.scss'],
})
export class FloorsComponent extends MapContainerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('alertsTable') alertsTableSmComponent: AlertsTableSmComponent;
    floorId = '0';
    liteView: boolean;
    warehouse: Warehouse;
    alertsView: boolean;
    priority$ = ['All', '1', '2', '3'];
    minDate: any;
    maxDate: any;
    floorMap: Floor;
    floorKpis: any;
    floorAlerts: Array<Alert>;
    equipmentTypes: Array<string> = [];
    linesVisible: Array<{
        floorId;
        areaId;
        zoneId;
        lineId;
    }> = [];

    currentMode = LINES;
    configuration: any;
    userName: string;
    userEmail: string;
    isFullscreen = false;
    showFilters = false;
    mapRender = false;
    groupName: string;
    configurationFilterValues: Array<MapGlobalState>;
    lineChange$: Observable<LineStatusNotification>;
    supportedEvents = [ALERT_STATE_CHANGED, LINE_STATE_CHANGED];
    tabState: VisibilityStates = VisibilityStates.active;
    toSelectAreaZone = '';
    toSelectAreaZoneBuffer = '';
    toSelectAreaZoneType = '';

    lineToShow = '';
    activateZoom = true;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private alertsService: AlertsService,
        private warehouseStatusService: WarehouseStatusService,
        private pageLifeCycleService: PageLifecycleService
    ) {
        super();
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            this.floorId = params.floorId;
        });
        this.configuration = this.route.snapshot.data.floor.configuration;
        this.warehouse = this.route.snapshot.data.floor.warehouse;
        this.floorMap = this.route.snapshot.data.floor.map;
        this.floorAlerts = this.route.snapshot.data.floor.alerts;
        this.equipmentTypes = ['All', ...(this.route.snapshot.data.floor.equipmentsTypes || []).sort()];
        this.groupName = `${this.warehouse.warehouse}-${this.floorMap.id}`;

        if (this.floorMap === undefined || this.floorMap === null) this.router.navigate(['warehouse', this.warehouse.warehouse]);
        else this.mapRender = true;

        if (this.configuration.stateFilter) {
            this.configurationFilterValues = JSON.parse(this.configuration.stateFilter);
            const valueInFloor = this.configurationFilterValues.filter((item) => item.id === 'floor')[0]?.states;
            this.availableStates = valueInFloor ?? initialStates;
        } else {
            this.availableStates = initialStates;
        }
        this.changeVisibleStates(this.availableStates);

        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }

        this.toSelectAreaZone = this.route.snapshot.paramMap.get('selectedAreaZone') || '';
        if (this.toSelectAreaZone) {
            this.toSelectAreaZoneBuffer = this.toSelectAreaZone;
            this.toSelectAreaZoneType =
                this.toSelectAreaZone.split('-').length === 2 ? 'Area' : this.toSelectAreaZone.split('-').length === 3 ? 'Zone' : 'Line';
        }
    }

    async ngAfterViewInit(): Promise<void> {
        this.stateService.isFullscreen.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isFullscreen) => {
            this.isFullscreen = isFullscreen;
        });
        this.stateService.showFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe((showFilters) => {
            this.showFilters = showFilters;
        });

        this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
            this.tabState = state;
            if (
                prevState !== VisibilityStates.active &&
                [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
                this.warehouseStatusService.isConnected()
            ) {
                this.onReconnect();
            }
        });
        await this.subscribeToRealTimeEvents();
    }

    ngOnInit() {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.liteView = res.liteMode;
            this.changeView('alerts');
        });
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    navigateToArea(event) {
        const key = $(event.target).data('key');
        const keys = key.split('-');
        this.router.navigate([`warehouse/${this.warehouse.warehouse}/floor/${keys[0]}/area/${keys[1]}`]);
    }
    navigateToLine(event, item) {
        // tslint:disable-next-line: max-line-length
        this.router.navigate([`warehouse/${this.warehouse.warehouse}/floor/${item.floorId}/area/${item.areaId}/zone/${item.zoneId}/line/${item.lineId}`]);
    }

    receiveRealTimeAlert(item: EquipmentAlarmNotification) {
        if (item && item.floorId && this.floorId === item.floorId) {
            if (
                (!this.visibleStates.some((element) => element === 'alert_low') && !this.visibleStates.some((element) => element === 'alert')) ||
                (!this.visibleStates.some((element) => element === 'alert_low') && item.priority.toString() === '3') ||
                (!this.visibleStates.some((element) => element === 'alert') && item.priority.toString() !== '3')
            ) {
                return;
            } else {
                const alert = new Alert(
                    new Date(),
                    new Date(),
                    item.equipmentId,
                    item.equipmentType,
                    item.stateText,
                    item.priority,
                    item.state,
                    item.floorId,
                    item.areaId,
                    item.zoneId,
                    item.lineId
                );
                this.alertsTableSmComponent.processRealTimeAlert(alert);
            }
        } else if (localStorage.getItem('showWrongNotifications') === 'true') {
            console.warn('Notification received for wrong fqn', {
                contextFloor: this.floorId,
                notificationFloor: item?.floorId,
            });
        }
    }

    setPriorityComboItems() {
        if (this.visibleStates.some((item) => item === 'alert_low') && this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2', '3'];
        } else if (this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2'];
        } else if (this.visibleStates.some((item) => item === 'alert_low')) {
            this.priority$ = ['All', '3'];
        } else {
            this.priority$ = [];
        }
    }

    checkVisibleStates(alerts) {
        if (!this.visibleStates.some((item) => item === 'alert_low') && !this.visibleStates.some((item) => item === 'alert')) {
            this.floorAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2' && item.priority !== '3');
        } else if (!this.visibleStates.some((item) => item === 'alert_low')) {
            this.floorAlerts = alerts.filter((item) => item.priority !== '3');
        } else if (!this.visibleStates.some((item) => item === 'alert')) {
            this.floorAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2');
        } else {
            this.floorAlerts = alerts;
        }
    }

    changeView(view) {
        this.alertsView = 'alerts' === view;
        if (this.alertsView) {
            this.alertsService
                .getAlertsByFloor(this.floorId)
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    take(1),
                    catchError((err) => {
                        console.log('Ha ocurrido un problema en el provider al recuperar las alertas de la planta');
                        return of(null);
                    })
                )
                .subscribe((res) => {
                    this.checkVisibleStates(res);
                    this.setPriorityComboItems();
                });
        }
    }

    handleChangeVisibleStates(states: Array<MapState>) {
        const newFloorConfiguration = {
            id: 'floor',
            states,
        };
        this.configurationFilterValues = [...this.configurationFilterValues, newFloorConfiguration];
        this.configurationFilterValues = _.uniqBy(this.configurationFilterValues, (item) => item.id);
        this.configuration.stateFilter = JSON.stringify(this.configurationFilterValues);
        this.changeVisibleStates(states);
        this.setPriorityComboItems();
        this.saveConfiguration();
    }

    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
    }

    async onReconnect() {
        this.alertsService
            .getAlertsByFloor(this.floorMap.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(async (alerts) => {
                this.checkVisibleStates(alerts);
                this.alertsTableSmComponent.setDatatableItems(this.floorAlerts);
                await this.subscribeToRealTimeEvents();
            });
    }

    async subscribeToRealTimeEvents(): Promise<void> {
        try {
            await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
            await this.warehouseStatusService.startSubscription(this.groupName, this.supportedEvents);

            const alertObservable = await this.warehouseStatusService.listenNotifications(this.groupName, ALERT_STATE_CHANGED);
            alertObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.receiveRealTimeAlert.bind(this));

            if (this.currentMode === LINES) {
                this.lineChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, LINE_STATE_CHANGED);
                // } else {
                //     this.zoneChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, ZONE_STATE_CHANGED);
            }

            this.warehouseStatusService.onReconnecting.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.onReconnect());
        } catch (error) {
            console.error(error);
        }
    }

    refreshAlertsTable(linesVisible) {
        this.linesVisible = linesVisible.map((line) => {
            const [floorId, areaId, zoneId, lineId] = line.split('-');
            return {
                floorId,
                areaId,
                zoneId,
                lineId,
            };
        });
        this.linesVisible = [...this.linesVisible];
    }

    newLineSelected($event) {
        this.lineToShow = JSON.stringify($event);
    }
    offcanvasToggle(visible: boolean) {
        this.activateZoom = !visible;
        if (!visible) this.lineToShow = '';
    }
}

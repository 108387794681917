import * as _ from 'lodash-es';
import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {
    constructor() {}

    goToFloor(warehouse, data) {
        const entity = new NavigationEntity();
        entity.floorId = this.getFloorId(data);
        const url = `warehouse/${warehouse}/floor/${entity.floorId}`;
        window.open(url, '_blank');
    }

    goToFloorWithSelected(warehouse, data, level) {
        const entity = new NavigationEntity();
        entity.floorId = this.getFloorId(data);
        entity.areaId = this.getAreaId(data);
        entity.zoneId = this.getZoneId(data);
        const selectedAreaZone = level === 'AREA' ? `${entity.floorId}-${entity.areaId}` : `${entity.floorId}-${entity.areaId}-${entity.zoneId}`;
        const url = `warehouse/${warehouse}/floor/${entity.floorId};selectedAreaZone=${selectedAreaZone}`;
        window.open(url, '_blank');
    }

    goToArea(warehouse, data) {
        const entity = new NavigationEntity();
        entity.floorId = this.getFloorId(data);
        entity.areaId = this.getAreaId(data);
        const url = `warehouse/${warehouse}/floor/${entity.floorId}/area/${entity.areaId}`;
        window.open(url, '_blank');
    }

    goToZone(warehouse, data) {
        const entity = new NavigationEntity();
        entity.floorId = this.getFloorId(data);
        entity.areaId = this.getAreaId(data);
        entity.zoneId = this.getZoneId(data);
        const url = `warehouse/${warehouse}/floor/${entity.floorId}/area/${entity.areaId}/zone/${entity.zoneId}`;
        window.open(url, '_blank');
    }

    goToLine(warehouse, data) {
        const entity = new NavigationEntity();
        entity.floorId = this.getFloorId(data);
        entity.areaId = this.getAreaId(data);
        entity.zoneId = this.getZoneId(data);
        entity.lineId = this.getLineId(data);
        const url = `warehouse/${warehouse}/floor/${entity.floorId}/area/${entity.areaId}/zone/${entity.zoneId}/line/${entity.lineId}`;
        window.open(url, '_blank');
    }

    private deleteHighlight(data) {
        return data.replace('<span class="highlight">', '').replace('</span>', '');
    }

    private getFloorId(data): string {
        const floorId = data.floor || data.floorid || data.floorId;
        return this.deleteHighlight(floorId);
    }

    private getAreaId(data): string {
        const areaId = data.area || data.areaid || data.areaId;
        return this.deleteHighlight(areaId);
    }

    private getZoneId(data): string {
        const zoneId = data.zone || data.zoneid || data.zoneId;
        return this.deleteHighlight(zoneId);
    }

    private getLineId(data): string {
        const lineId = data.line || data.lineid || data.lineId;
        return this.deleteHighlight(lineId);
    }
}

class NavigationEntity {
    floorId: string;
    areaId: string;
    zoneId: string;
    lineId: string;
}

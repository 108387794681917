import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { isActiveVariable, VariableType } from '@variables/shared/variable';
import { EquipmentVariable } from '@app/map/equipments/equipment-variable';
import { VariableInputState } from '@app/shared/components/variable-input/variable-input.component';
import { VariableValueType } from '@app/notifications/shared/events/variable-status';

const { COMMAND } = VariableType;

@Component({
    selector: 'app-line-parameter',
    templateUrl: './line-parameter.component.html',
    styleUrls: ['./line-parameter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineParameterComponent {
    @Input() variable: EquipmentVariable;
    @Input() fqn: string;
    @Input() isRefreshing = false;
    @Input() hasRefreshError = false;
    isActiveVariable = isActiveVariable;
    state: VariableInputState = {};
    // eslint-disable-next-line @typescript-eslint/naming-convention
    COMMAND = COMMAND;
    variableValueType = VariableValueType;

    constructor() {}

    trackById(index: number, variable: { id: number }): number {
        return variable.id;
    }

    updateValue(event) {
        const { state } = event;
        this.state = state;
    }

    undoEditValue(event) {
        const { state } = event;
        this.state = state;
    }

    focusLost(event) {
        const { state } = event;
        this.state = state;
    }

    saveValue(event) {
        const { state, newValue } = event;
        if (!state.error) {
            const variableValue = newValue === true ? '1' : newValue === false ? '0' : newValue;
            this.variable.value = variableValue;
        }
        this.state = state;
    }

    editValue(event) {
        if (this.variable?.type?.toLowerCase() === COMMAND.toLowerCase()) {
            // TODO: set view mode in the other inputs
            const { state } = event;
            this.state = state;
        }
    }

    refreshValue(event) {
        const { state, newValue } = event;
        if (!event.state.error) {
            const refreshedValue = newValue;
            const value = refreshedValue === true ? '1' : refreshedValue === false ? '0' : refreshedValue;
            this.variable.value = value;
        }
        this.state = state;
    }
}

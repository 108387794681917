import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'archiveType',
})
export class ArchiveTypePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(report: { reportFileName: string }): any {
        const { reportFileName } = report;
        const response = reportFileName.startsWith('AlertsHistoryReport_') ? `Alerts` : `Variables`;
        return this.sanitizer.bypassSecurityTrustHtml(response);
    }
}

import { Component, OnInit, OnChanges, OnDestroy, Input, ViewChild, ElementRef, SimpleChanges, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EquipmentStatusNotification } from '@app/notifications/shared/events/equipment-status';
import { MapStates } from '@app/shared/models/map-state';
import { Line } from '@app/map/home/shared/structure';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { minViewBoxSizeToHaveZoom, createPathUrl, createEquipmentUrl, createArrow } from '@app/map/shared/svg-functions';
import { LinesService } from '@app/map/lines/shared/lines.service';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { Router } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import * as Svg from '@svgdotjs/svg.js';
import '@svgdotjs/svg.panzoom.js';
//import * as _ from 'lodash-es';

const { EQUIPMENT_STATE_CHANGED } = WarehouseStatusSupportedEvents;
const {
    unknown: UNKNOWN,
    alert: ALERT,
    alert_low: ALERT_LOW,
    box: BOX,
    critical: CRITICAL,
    disconnected: DISCONNECTED,
    run: RUN,
    warning: WARNING,
    without_permission: LWDO,
} = MapStates;

@Component({
    selector: 'app-lines-map-minified',
    templateUrl: './lines-map-minified.component.html',
    styleUrls: ['./lines-map-minified.component.scss'],
})
export class LinesMapMinifiedComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() lineToShow: { warehouseId: string, floorId: string, areaId: string, zoneId: string, lineId: string }
    @Input() visibleStates: Array<string>;
    @Output() hideOffcanvas = new EventEmitter();
    @ViewChild('svgMinified', { read: ElementRef, static: true }) svgMinified;
    @ViewChild('buttonsMinified', { read: ElementRef, static: false }) buttonsMinified;

    equipmentChange$: Observable<EquipmentStatusNotification>;
    supportedEvents = [EQUIPMENT_STATE_CHANGED];
    groupNameLineToShow = '';

    icons: any;
    line: Line;

    liteView: boolean = true;

    $svgMinified: JQuery;
    $buttonsMinified: JQuery;
    warehouseId = '';
    floorId = '0';
    areaId = '0';
    zoneId = '0';
    lineId = '0';
    setViewBox: string;
    iconNames = [];
    iconsContent = [];
    lineSVG: any;
    currentZoom = 40;
    reduceZoom = 0;
    viewZoom = false;

    loading = false;

    viewLineIconsText = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private sanitizer: DomSanitizer,
        private linesService: LinesService,
        private warehouseStatusService: WarehouseStatusService,
        private appLoadService: AppLoadService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.lineToShow?.currentValue) {
            this.line = null;
            this.loading = true;

            this.warehouseId = changes.lineToShow?.currentValue.warehouseId ?? '';
            this.floorId = changes.lineToShow?.currentValue.floorId ?? '';
            this.areaId = changes.lineToShow?.currentValue.areaId ?? '';
            this.zoneId = changes.lineToShow?.currentValue.zoneId ?? '';
            this.lineId = changes.lineToShow?.currentValue.lineId ?? '';

            if (this.floorId !== '' && this.areaId !== '' && this.zoneId !== '' && this.lineId !== '') {
                this.linesService.getLine(this.floorId, this.areaId, this.zoneId, this.lineId).pipe(
                    takeUntil(this.ngUnsubscribe),
                    catchError((err) => {
                        console.log('Ha ocurrido un problema en el provider al recuperar el mapa de la linea de equipos -> ' + err);
                        return of(null);
                    })
                ).subscribe(
                    (resultLine) => {
                        if (resultLine) {
                            this.line = resultLine
                            this.setViewBox = this.line.viewBox;

                            this.configureDOMElements();
                            this.configureEquipmentChange();
                        }
                        this.loading = false;
                    }
                );
            }
        }
        if (changes.visibleStates?.currentValue || changes.equipmentChange$?.currentValue) {
            this.startRealtimeNotifications();
        }
    }

    ngOnInit(): void {
        this.$svgMinified = $(this.svgMinified.nativeElement);

        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.liteView = res?.liteMode ?? false;
        });

        this.linesService.getIcons().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema en el provider al recuperar los iconos -> ' + err);
                return of(null);
            })
        ).subscribe(
            (resultIcons) => {
                this.icons = resultIcons;
                this.iconNames = this.icons.map((icon) => icon.name);
                this.iconsContent = this.icons.map((icon) => {
                    return { name: icon.name, data: icon.content };
                });
            }
        );
    }

    ngAfterViewInit() {
        if (this.buttonsMinified) this.$buttonsMinified = $(this.buttonsMinified.nativeElement);
    }

    async configureEquipmentChange() {
        this.groupNameLineToShow = '';
        if (this.line && this.lineId !== '') {
            this.groupNameLineToShow = `${this.warehouseId}-${this.floorId}-${this.areaId}-${this.zoneId}-${this.lineId}`
        }
        await this.subscribeToRealTimeEvents();
    }

    async subscribeToRealTimeEvents(): Promise<void> {
        try {
            if (this.groupNameLineToShow !== '') {
                await this.warehouseStatusService.endSubscription(this.groupNameLineToShow, this.supportedEvents);
                await this.warehouseStatusService.startSubscription(this.groupNameLineToShow, this.supportedEvents);

                this.equipmentChange$ = await this.warehouseStatusService.listenNotifications(this.groupNameLineToShow, EQUIPMENT_STATE_CHANGED);

                this.startRealtimeNotifications();
            }
        } catch (error) {
            console.error(error);
        }
    }

    configureDOMElements() {
        const myOffcanvasEquipments = document.getElementById('offcanvasMapaMinifiedDOM');
        myOffcanvasEquipments.addEventListener('hide.bs.offcanvas', () => {
            this.warehouseStatusService.endSubscription(this.groupNameLineToShow, this.supportedEvents);
        })

        $(() => {
            $('.my_rect_minified').tooltip({
                container: 'body',
                placement: 'top',
            });
        });

        if (!this.setViewBox) return;
        const [left, top, right, bottom] = this.setViewBox?.split(' ');
        this.viewZoom = Math.max(minViewBoxSizeToHaveZoom, +right || 0, +bottom || 0) !== minViewBoxSizeToHaveZoom;

        if (this.viewZoom) {
            this.lineSVG = Svg.adopt(this.svgMinified.nativeElement);
            this.reduceZoom = +bottom >= 32 ? -1 : +bottom >= 25 ? 0 : +bottom >= 20 ? 1 : +bottom >= 15 ? 2 : 3;
            this.lineSVG.panZoom({ zoomMax: 100, zoomMin: this.currentZoom, zoomFactor: 0.5 });
        }
    }

    async ngOnDestroy(): Promise<void> {

        $('.my_rect_minified')?.tooltip('dispose');

        if (this.groupNameLineToShow !== '') await this.warehouseStatusService.endSubscription(this.groupNameLineToShow, this.supportedEvents);

        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe?.complete();

        this.$svgMinified?.remove();
        this.$svgMinified = null;

        this.$buttonsMinified = null;

        this.iconNames = null;
        this.iconsContent = null;
    }

    startRealtimeNotifications() {
        try {
            if (!this.equipmentChange$) return;
            this.cleanLineMapStates();
            this.equipmentChange$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
                next: this.changeEquipmentStatus.bind(this),
            });
        } catch (error) {
            console.error(error);
        }
    }

    createPathUrl(device) {
        return createPathUrl(device);
    }

    createEquipmentUrl(device) {
        return createEquipmentUrl(device, this.iconNames);
    }

    createArrow(data) {
        return createArrow(data);
    }

    changeEquipmentStatus(equipment: EquipmentStatusNotification) {
        if (this.floorId === equipment.floorId && this.areaId === equipment.areaId && this.zoneId === equipment.zoneId && this.lineId === equipment.lineId) {
            requestAnimationFrame(() => this.setColorEquipment(equipment, this.visibleStates));
        } else if (localStorage.getItem('showWrongNotifications') === 'true') {
            console.warn('Notification received for wrong fqn', {
                contextFloor: this.floorId,
                contextArea: this.areaId,
                contextZone: this.zoneId,
                contextLine: this.line.id,
                notificationFloor: equipment.floorId,
                notificationArea: equipment.areaId,
                notificationZone: equipment.zoneId,
                notificationLine: equipment.lineId,
            });
        }
    }

    cleanLineMapStates() {
        if (!this.$svgMinified) return;
        if (!this.line) return;
        this.line.equipments.forEach((equipment) => {
            const type = equipment.type.toLowerCase();
            const equipmentKey = `${equipment.id}-${type}-minified`;
            const el = this.$svgMinified.find(`[data-key="${equipmentKey}"]`);
            el.removeClass(
                [
                    ALERT,
                    ALERT_LOW,
                    BOX,
                    CRITICAL,
                    DISCONNECTED,
                    RUN,
                    WARNING,
                    LWDO,
                ].join(' '),
            ).removeData('state');
            if (this.$buttonsMinified) {
                const button = this.$buttonsMinified.find(`[data-key="${equipmentKey}"]`);
                button
                    .removeClass(
                        [
                            MapStates.alert,
                            MapStates.alert_low,
                            MapStates.box,
                            MapStates.critical,
                            MapStates.disconnected,
                            MapStates.run,
                            MapStates.warning,
                            MapStates.without_permission,
                        ].join(' '),
                    )
                    .removeData('state');
            }
        });
    }

    setColorEquipment(equipment: EquipmentStatusNotification, visibleStates: Array<string>) {
        if (!this.$svgMinified) return;
        const type = equipment.equipmentType.toLowerCase();
        const equipmentKey = `${equipment.equipmentId}-${type}-minified`;
        const state = equipment.state.filter((s) => s === UNKNOWN || this.visibleStates?.includes(s)).join(' ');
        const stateList = state.split(' ');

        // Get the svg/buttons which state will be changed
        const equipmentBox = this.getEquipmentElements()?.find(`[data-key="${equipmentKey}"]`);
        const lineEquipments = this.isSvg() ? this.$svgMinified?.find('.my_piece') : this.$buttonsMinified.find('[data-key]');
        const equipmentBoxWrapper = this.isSvg() ? this.$svgMinified?.find(`[data-key="${equipmentKey}-wrapper"]`) : null;

        // Set current state of the equipment so it can be checked by other equipments in the line
        equipmentBox.data('state', state);

        // Get the state of the other equipments in the line
        const elementsByState = this.countElementsByState(this.visibleStates);

        // Clear the state applied to the equipment
        equipmentBox.removeClass();

        /* 
        The rules applied to the equipment are:
            - If the equipment state has disconnected, all equipments in the line must be shown as disconnected
            - If the equipment state has alert, alert_low or critical, only the equipment must be shown with its alert level
              plus other states such as box or warning
            - If the equipment state has lwdo, all the equipments in the line must be shown as lwdo plus other states such as box or warning
            - If the equipment state has run, all the equipments in the line must be shown as run plus other states such as box or warning
            - If the equipment state has warning, only the equipment must be shown with the secondary state warning
            - If the equipment state has box, only the equipment wrapper must be shown with box dots
            - If there isn't any state that matches this rules, the equipment must be shown as unknown by default

        The states are categorized in the following order:
            - Main equipment states: alert, alert_low, critical
            - Main line states: lwdo, run
            - Secondary equipment states: box, warning
        */
        //    debugger
        if (stateList.includes(DISCONNECTED) || elementsByState[DISCONNECTED] > 0) {
            // There is a PLC_NOT_COMM alert in the line, the equipment must be shown as disconnected
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${DISCONNECTED}`);

        } else if ([ALERT, ALERT_LOW, CRITICAL].some((s) => stateList.includes(s))) {
            // The equipment has an alert. As a main state at equipment level, the state shown must override next states
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${state}`);

        } else if (elementsByState[LWDO] > 0 || elementsByState[RUN] > 0) {
            // As line state, the 
            const lineState = elementsByState[LWDO] > 0 ? LWDO : RUN;
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${lineState} ${state}`);
            this.setLineEquipmentsState(lineEquipments, equipmentKey, this.getEquipmentBaseClass(), lineState);

        } else {
            // Default status is unknown
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${UNKNOWN} ${state}`);
            this.setLineEquipmentsState(lineEquipments, equipmentKey, this.getEquipmentBaseClass(), UNKNOWN);
        }

        // Box state must be applied separately from the other states
        // as affects only to the equipment wrapper for SVGs
        if (!!equipmentBoxWrapper && state.includes(BOX)) {
            equipmentBoxWrapper.removeClass().addClass(`my_rect_minified ${BOX}`);
        }

        // Add tooltip showing equipmentId, equipmentType and state
        const equipmentTooltip = `${equipment.equipmentId} <small class="tooltip-equipment-type">${equipment.equipmentType}</small>`;
        const tooltipContent = equipment.stateText ? `${equipmentTooltip}<br><i>${equipment.stateText}</i>` : equipmentTooltip;
        equipmentBoxWrapper?.find(`[data-key="${equipmentKey}-wrapper"]`).attr('data-original-title', tooltipContent);
    }

    private countElementsByState(visibleStates: Array<string>) {
        let elementStates = {};
        const elements = this.getEquipmentElements();
        elements?.find('[data-state]').each(function (index) {
            $(this).data('state').split(' ').forEach((state) => {
                if (visibleStates.includes(state)) {
                    elementStates = {
                        ...elementStates,
                        [state]: (elementStates[state] || 0) + 1,
                    };
                }
            });
        });
        return elementStates;
    }

    isSvg() {
        return !this.$buttonsMinified;
    }
    getEquipmentElements() {
        return this.isSvg() ? this.$svgMinified : this.$buttonsMinified;
    }

    getEquipmentBaseClass() {
        return this.isSvg() ? 'my_piece' : 'btn btn-default';
    }

    setLineEquipmentsState(
        lineEquipments: JQuery<HTMLElement>,
        currentEquipmentKey: string,
        baseClass: string, // Svg or button class
        lineState: string, // Must be lwdo or run
    ) {
        lineEquipments.each(function (index) {
            const equipmentKey = $(this).data('key');
            const equipmentState = $(this).data('state').split(' ').filter((s) => s !== lineState).join(' ');
            if (currentEquipmentKey !== equipmentKey && !(equipmentState.includes(ALERT) || equipmentState.includes(ALERT_LOW) || equipmentState.includes(CRITICAL))) {
                $(this).removeClass().addClass(`${baseClass} ${lineState} ${equipmentState}`);
            }
        });
    }

    handleElementsState(
        elements: JQuery<HTMLElement>,
        baseClass: string,
        equipmentKey: string,
        equipmentStates: string[],
        globalType: string,
        visibleStates: Array<string>,
    ) {
        const filteredElements = elements.filter(function (index) {
            return !$(this).data('state').includes(MapStates.alert);
        });
        filteredElements.each(function (index, element) {
            const isCurrentEquipment = $(this).data('key') === equipmentKey;

            let alreadyHasBoxClass: boolean;
            let mustHaveBox = '';
            const alreadyHasWarningClass = $(this).hasClass(MapStates.warning);
            const alreadyHasDisconnectedClass = $(this).hasClass(MapStates.disconnected);

            if (baseClass === 'btn btn-default') {
                alreadyHasBoxClass = $(this).hasClass(MapStates.box);
                mustHaveBox = !visibleStates.includes(MapStates.box)
                    ? ''
                    : isCurrentEquipment && equipmentStates.includes(MapStates.box)
                        ? MapStates.box
                        : !isCurrentEquipment && alreadyHasBoxClass
                            ? MapStates.box
                            : '';
            }

            const mustHaveWarning = !visibleStates.includes(MapStates.warning)
                ? ''
                : isCurrentEquipment && equipmentStates.includes(MapStates.warning)
                    ? MapStates.warning
                    : !isCurrentEquipment && alreadyHasWarningClass
                        ? MapStates.warning
                        : '';
            const mustHaveDisconnected = !visibleStates.includes(MapStates.disconnected)
                ? ''
                : isCurrentEquipment && equipmentStates.includes(MapStates.disconnected)
                    ? MapStates.disconnected
                    : !isCurrentEquipment && alreadyHasDisconnectedClass
                        ? MapStates.disconnected
                        : '';
            /* eslint-enable prettier/prettier */
            $(this).removeClass().addClass(`${baseClass} ${globalType} ${mustHaveBox} ${mustHaveWarning} ${mustHaveDisconnected}`);
        });
    }

    sanitizeIcon(icon) {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    resetZoom() {
        if (this.lineSVG) {
            this.lineSVG.zoom(this.currentZoom);
            this.$svgMinified.attr('viewBox', this.setViewBox);
        }
    }

    private countStateItems(state: string, visibleStates: Array<string>) {
        let itemsCount = 0;
        if (this.$buttonsMinified) {
            itemsCount = this.$buttonsMinified?.find('[data-state]').filter(function (index) {
                return visibleStates?.includes(state) && $(this).data('state')?.includes(state);
            }).length;
        } else if (this.$svgMinified) {
            itemsCount = this.$svgMinified?.find('[data-state]').filter(function (index) {
                return visibleStates.includes(state) && $(this).data('state').includes(state);
            }).length;
        }
        return itemsCount || 0;
    }

    goToLine(equipment: string = null) {
        //this.router.navigate([`warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}/area/${this.lineToShow.areaId}/zone/${this.lineToShow.zoneId}/line/${this.lineToShow.lineId}`]);
        let url = `warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}/area/${this.lineToShow.areaId}/zone/${this.lineToShow.zoneId}/line/${this.lineToShow.lineId}`;
        if (equipment) {
            url += `;equipment=${equipment};openVariableModal=true`
        }
        window.open(url, '_blank');
    }
    goToFloor() {
        //this.router.navigate([`warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}`]);
        const url = `warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}`;
        window.open(url, '_blank');
    }
    goToArea() {
        //this.router.navigate([`warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}/area/${this.lineToShow.areaId}/zone/${this.lineToShow.zoneId}`]);
        const url = `warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}/area/${this.lineToShow.areaId}`;
        window.open(url, '_blank');
    }
    goToZone() {
        //this.router.navigate([`warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}/area/${this.lineToShow.areaId}/zone/${this.lineToShow.zoneId}`]);
        const url = `warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}/area/${this.lineToShow.areaId}/zone/${this.lineToShow.zoneId}`;
        window.open(url, '_blank');
    }
    navigateToEquipment(equipment) {
        let url = `warehouse/${this.lineToShow.warehouseId}/floor/${this.lineToShow.floorId}/area/${this.lineToShow.areaId}/zone/${this.lineToShow.zoneId}/line/${this.lineToShow.lineId}`;
        if (equipment) {
            url += `;equipment=${equipment};openVariableModal=true`
        }
        this.hideOffcanvas.emit(false);
        this.router.navigateByUrl(url);
    }
}

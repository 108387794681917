import { Component, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { TableComponentDirective } from '@ays';
import { Alert, AlertSnapshotVariable } from '@alerts/shared/alert';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertsService } from '@alerts/shared/alerts.service';
import { ColorsService } from '@app/shared/services/colors.service';
import * as _ from 'lodash-es';
import * as dayjs from 'dayjs';

@Component({
    selector: 'shared-alerts-snapshot',
    templateUrl: './alerts-snapshot.component.html',
    styleUrls: ['./alerts-snapshot.component.scss'],
})
export class AlertSnapshotComponent extends TableComponentDirective<Alert> implements AfterViewInit, OnDestroy {
    @Input() alertSnapshot: Alert;
    spinner = `Loading...`;

    colorStatus: string = this.colorsService.getColor('status-status'); //'#17a2b8';

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private alertsService: AlertsService,
        private colorsService: ColorsService,
    ) {
        super();
    }

    ngAfterViewInit() {
        $('.auwa-alerts-popover').popover();
    }

    ngOnDestroy(): void {
        $('.auwa-alerts-popover').popover('dispose');
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    displayAlertSnapshot() {
        // $('.auwa-alerts-popover').attr(
        //     'title',
        //     `${this.alertSnapshot.equipmentId}-${this.alertSnapshot.description}<span class="close-popover float-end"><i class="fas fa-times" role="button"></i></span>`
        // );
        this.fillPopoverContent(this.spinner);
        this.alertsService
            .getAlertDetails(this.alertSnapshot)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(async (alertSnapshot: Array<AlertSnapshotVariable>) => {
                const content = alertSnapshot.length > 0 ? this.getVariableDetails(alertSnapshot) : 'No data related to show';
                this.fillPopoverContent(content);
            });
    }

    fillPopoverContent(content: string) {
        // $('.auwa-alerts-popover').attr(
        //     'title',
        //     `${this.alertSnapshot.equipmentId}-${this.alertSnapshot.description}<span class="close-popover float-end"><i class="fas fa-times" role="button"></i></span>`
        // );
        const title = `${this.alertSnapshot.equipmentId}-${this.alertSnapshot.description} <div class="close-popover position-absolute top-0 end-0 mt-2 me-1"><i class="fas fa-times" role="button"></i></div>`;
        $('.auwa-alerts-popover').attr('data-bs-title', title);
        $('.auwa-alerts-popover').attr('data-bs-content', content);
        $('.auwa-alerts-popover').popover('dispose');
        $('.auwa-alerts-popover').popover('show');
        $(document).on('click', '.close-popover', () => {
            $('.auwa-alerts-popover').popover('hide');
        });
    }

    getVariableDetails(snapshotVariables: Array<AlertSnapshotVariable>) {
        let list = `<div class="variable-detail justify-content-start">`;
        snapshotVariables.forEach((variable) => {
            const [variableType, variableName] = [...variable.fqn.split('.').slice(-2)];
            const variableDate = variable.sourceTimestamp ? dayjs(variable.sourceTimestamp.toString()).format('D/M/YY HH:mm:ss') : '';
            list += `<div class="badge-wrapper">
    <span class="badge bg-dark col-12 text-start mb-1 pt-2 position-relative">
        <span class="key" title="${variableType + '-' + variableName}">
            <span>
                ${this.getVariableTypeIcon(variableType)} <span>${variableName}</span>
            </span>
            <br/>
            <small>${variableDate}</small>
        </span>
        <span class="badge bg-white text-dark float-end">${variable.value}</span>
    </span>
</div>`;
        });
        list += `</div>`;
        return list;
    }

    getVariableTypeIcon(variableType: string): string {
        const icon = 'fa-file';
        const iconColor = this.colorStatus;
        const title = `Variable type: ${variableType}`;
        const htmlIcon = `<i aria-hidden="true" class="fa ${icon} fa-lg" style="color: ${iconColor}" title="${title}"></i>`;
        return htmlIcon;
    }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ZonesComponent } from '@zones/components/zones.component';
import { ZonesService } from '@zones/shared/zones.service';
import { ZonesResolver } from '@zones/shared/zones.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [SharedModule, NgxDatatableModule, RouterModule],
    declarations: [ZonesComponent],
    providers: [ZonesService, ZonesResolver],
})
export class ZonesModule {}

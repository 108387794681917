import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { HomeService } from '@app/map/home/shared/home.service';
import { Structure } from '@home/shared/structure';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { AppLoadService } from '@app/app-load.service';

@Injectable()
export class CustomsResolver  {
    constructor(
        private homeService: HomeService,
        private equipmentsService: EquipmentsService,
        private appLoadService: AppLoadService
    ) {}

    getStructure(): Observable<Structure> {
        return this.homeService.getStructure().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar la estructura');
                return of(null);
            })
        );
    }

    getEquipmentTypes(): Observable<string[]> {
        return this.equipmentsService.getEquipmentTypes().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar los tipos de equipos');
                return of(null);
            })
        );
    }

    // getCustomAlerts(route): Observable<Alert> {
    //     return this.alertsService.getAlertsByCustom(route.params.customId).pipe(
    //         take(1),
    //         catchError((err) => {
    //             console.log('Ha ocurrido un problema en el provider al recuperar las alertas de ');
    //             return of(null);
    //         })
    //     );
    // }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin([
            this.getStructure(),
            this.getEquipmentTypes(),
            // this.getCustomAlerts(route),
            this.appLoadService.getCurrentWarehouse.pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar el almacen actual', err);
                    return of(null);
                })
            ),
            this.appLoadService.getCurrentConfiguration.pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar la configuracion', err);
                    return of(null);
                })
            ),
        ]).pipe(
            map((x) => {
                return {
                    structure: x[0],
                    equipmentTypes: x[1],
                    // alerts: x[2],
                    warehouse: x[2],
                    configuration: x[3],
                };
            })
        );
    }
}

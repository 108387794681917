import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

const GoToLink = {
  '': '',
  FLOOR: 'Floor',
  AREA: 'Area',
  ZONE: 'Zone',
};

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnChanges {
  @Input() warehouse;
  @Input() keySelectedWithRightClick;
  @Input() contextId;
  @Input() x;
  @Input() y;
  @Input() hideLink: string = GoToLink[''];
  @Output() newLineSelectedFromContextMenu = new EventEmitter
  @ViewChild('contextMenu', { static: true }) contextMenu: ElementRef;

  GoToLink = GoToLink;

  constructor(
    private router: Router,
    private renderer2: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes?.keySelectedWithRightClick?.currentValue === '' &&
      changes?.x?.currentValue === 0 &&
      changes?.y?.currentValue === 0
      ) {
        this.hideContextMenu()
      }
      if ((this.keySelectedWithRightClick || changes?.keySelectedWithRightClick?.currentValue) &&
        (this.x || changes?.x?.currentValue) &&
        (this.y || changes?.y?.currentValue)) {
        this.onRightClick()
      }
    } catch (error) {
      console.log(`%c error => ${error}`, `background: red; color: gold`);
    }
  }

  onRightClick() {

    let left = this.x;
    let top = this.y;
    this.renderer2.setStyle(this.contextMenu.nativeElement, 'display', 'block');
    this.renderer2.addClass(this.contextMenu.nativeElement, 'show');

    const contextMenuWidth = this.contextMenu.nativeElement.clientWidth;
    const contextMenuHeight = this.contextMenu.nativeElement.clientHeight;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    if (contextMenuWidth + left - 5 > screenWidth) left -= contextMenuWidth;
    if (contextMenuHeight + top - 5 > screenHeight) top -= contextMenuHeight;

    this.renderer2.setStyle(this.contextMenu.nativeElement, 'left', `${left < 0 ? 0 : left}px`);
    this.renderer2.setStyle(this.contextMenu.nativeElement, 'top', `${top < 0 ? 0 : top}px`);
  }
  goToLine() {
    if (this.keySelectedWithRightClick !== '') {
      this.hideContextMenu();

      const keys = this.keySelectedWithRightClick.split('-');
      this.router.navigate([`warehouse/${this.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}/line/${keys[3]}`]);
    }
  }
  goToFloor() {
    if (this.keySelectedWithRightClick !== '') {
      this.hideContextMenu();

      const keys = this.keySelectedWithRightClick.split('-');
      this.router.navigate([`warehouse/${this.warehouse}/floor/${keys[0]}`]);
    }
  }
  goToZone() {
    if (this.keySelectedWithRightClick !== '') {
      this.hideContextMenu();

      const keys = this.keySelectedWithRightClick.split('-');
      this.router.navigate([`warehouse/${this.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}`]);
    }
  }
  goToArea() {
    if (this.keySelectedWithRightClick !== '') {
      this.hideContextMenu();

      const keys = this.keySelectedWithRightClick.split('-');
      this.router.navigate([`warehouse/${this.warehouse}/floor/${keys[0]}/area/${keys[1]}`]);
    }
  }
  showLine() {
    if (this.keySelectedWithRightClick !== '') {
      this.hideContextMenu();

      const keys = this.keySelectedWithRightClick.split('-');
      
      this.newLineSelectedFromContextMenu.emit({
        warehouseId: this.warehouse,
        floorId: keys[0],
        areaId: keys[1],
        zoneId: keys[2],
        lineId: keys[3]
      });
    }
  }
  copyLineURL() {
    if (this.keySelectedWithRightClick !== '') {
      this.hideContextMenu();

      const keys = this.keySelectedWithRightClick.split('-');
      const lineUrl = `${document.baseURI}warehouse/${this.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}/line/${keys[3]}`;

      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', lineUrl);
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
    }
  }

  hideContextMenu() {
    this.renderer2.setStyle(this.contextMenu.nativeElement,'display','none');
  }
}

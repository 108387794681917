import { Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { Warehouse } from '@app/core/shared/warehouse';
import { AppLoadService } from '@app/app-load.service';
import { Structure } from '../../shared/structure';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapGlobalState, initialStates } from '@app/shared/models/map-state';

@Component({
    templateUrl: './home-lines.component.html',
    styleUrls: ['./home-lines.component.scss'],
})
export class HomeLinesComponent extends MapContainerComponent implements OnInit, OnDestroy {
    warehouse: Warehouse;
    structure: Structure;
    //liteView: boolean;
    configurationFilterValues: Array<MapGlobalState>;

    lineToShow = '';
  
    activateZoom = true;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private route: ActivatedRoute, 
            private appLoadService: AppLoadService) {
        super();
        this.warehouse = this.route.parent.snapshot.data.home.warehouse;
        this.structure = this.route.parent.snapshot.data.home.structure;
    }

    ngOnInit() {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            //this.liteView = res.liteMode;
            if (res['stateFilter']) {
                this.configurationFilterValues = JSON.parse(res['stateFilter']);
                const valueInFloor = this.configurationFilterValues.filter((item) => item.id === 'floor')[0]?.states;
                this.availableStates = valueInFloor ?? initialStates;
            } else {
                this.availableStates = initialStates;
            }
            this.changeVisibleStates(this.availableStates);
        });
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe?.complete();
    }
    
    newLineSelected($event) {
        this.lineToShow = JSON.stringify($event);// {...$event};
    }

    offcanvasToggle(visible: boolean) {
        this.activateZoom = !visible;
        if (!visible) this.lineToShow = '';
    }
}

import { Component, HostBinding, Input, OnInit, OnDestroy } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { HomeService } from '@app/map/home/shared/home.service';
import { Structure } from '@app/map/home/shared/structure';
import { UserConfiguration } from '@app/shared/models/configurations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
    @Input() customName: string = null;
    @Input() breadcrumbs: Array<string>;
    @Input() columns = '6';
    @HostBinding('class') classes;
    identifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    warehouse: string;
    structure: Structure;
    floors: Array<string>;
    areas: Array<string>;
    zones: Array<string>;
    lines: Array<string>;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private homeService: HomeService,
    ) {
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.warehouse = res.warehouse;
        });
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
        });
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.classes = `col-md-12 col-lg-${this.columns} mb-2`;
        this.homeService
            .getStructure()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((structure) => {
                this.structure = structure;
                this.floors = structure.floors.map((floor) => floor.id).filter((floor) => floor !== this.breadcrumbs[0]);
                const floorBuffer = structure.floors.filter((floor) => floor.id === this.breadcrumbs[0]);
                if (floorBuffer && floorBuffer.length > 0) {
                    this.areas = floorBuffer[0].areas.map((area) => area.id).filter((area) => area !== this.breadcrumbs[1]);
                    const areasBuffer = floorBuffer[0].areas.filter((area) => area.id === this.breadcrumbs[1]);
                    if (areasBuffer && areasBuffer.length > 0) {
                        this.zones = areasBuffer[0].zones.map((zone) => zone.id).filter((zone) => zone !== this.breadcrumbs[2]);
                        const zonesBuffer = areasBuffer[0].zones.filter((zone) => zone.id === this.breadcrumbs[2]);
                        if (zonesBuffer && zonesBuffer.length > 0) {
                            this.lines = zonesBuffer[0].lines.map((line) => line.id).filter((line) => line !== this.breadcrumbs[3]);
                        }
                    }
                }
            });
    }
    breadcrumbsRedirect(floor, area?, zone?, line?) {
        let redirection = `/warehouse/${this.warehouse}/floor/${floor}`;
        if (area) {
            redirection += `/area/${area}`;
        }
        if (zone) {
            redirection += `/zone/${zone}`;
        }
        if (line) {
            redirection += `/line/${line}`;
        }
        window.location.href = redirection;
    }
    breadcrumbsCustomsRedirect(name) {
        const encodedName = name.replaceAll('/', '%2F');
        const redirection = `/warehouse/${this.warehouse}/customs/${encodedName}`;
        window.location.href = redirection;
    }
}

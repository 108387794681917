import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { EquipmentVariable } from './equipment-variable';
import { catchError, map, retry, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Kpis } from '@app/shared/models/kpis';
import { AppLoadService } from '@app/app-load.service';
import { Level } from '@app/notifications/shared/events/variable-status';

@Injectable()
export class EquipmentsService {
    baseUrl: string;
    warehouse: string;

    constructor(private http: HttpClient, private appLoadService: AppLoadService) {}

    getEquipmentNames(equipmentType?: string, floorId?: string, areaId?: string, zoneId?: string): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                if (res.warehouse && res.displayName) {
                    this.baseUrl = `${res.hostName}/api`.toLowerCase();
                    this.warehouse = res.warehouse;
                    let endpoint = environment.mode === 'front' ? 'map' : `map/${this.warehouse}/equipments`;
                    // endpoint = equipmentType ? `${endpoint}?equipmentType=${equipmentType}` : endpoint;
                    if (equipmentType || floorId || areaId || zoneId) {
                        endpoint += `?`;
                        endpoint += equipmentType ? `equipmentType=${equipmentType}&` : '';
                        endpoint += floorId && floorId !== 'All' ? `floorId=${floorId}&` : '';
                        endpoint += areaId ? `areaId=${areaId}&` : '';
                        endpoint += zoneId ? `zoneId=${zoneId}&` : '';
                    }
                    return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
                }
            }),
            map((resp) => {
                if (environment.mode !== 'front') {
                    console.log(resp.length, 'equipos recuperados')
                    return resp;
                } else {
                    const lineNames: Array<string> = [];
                    resp.floors.forEach((floor) => {
                        floor.areas.forEach((area) => {
                            area.zones.forEach((zone) => {
                                zone.lines.forEach((line) => {
                                    lineNames.push(`${this.warehouse}-${floor.id}-${area.id}-${zone.id}-${line.id}`);
                                });
                            });
                        });
                    });
                    return lineNames;
                }
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar los nombres de equipos', err);
                return of(null);
            }),
            // shareReplay()
        );
    }

    getEquipmentLocation(equipment): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'equipments' : `equipments/${this.warehouse}/equipments/${equipment}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar la planta', err);
                return of(null);
            }),
            shareReplay()
        );
    }

    getEquipmentNamesByLine(idFloor: string, idArea: string, idZone: string, idLine: string): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint =
                    environment.mode === 'front'
                        ? 'map'
                        : `map/${this.warehouse}/definitions/floors/${idFloor}/areas/${idArea}/zones/${idZone}/lines/${idLine}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                const equipmentNames: Array<string> = [];
                if (environment.mode !== 'front') {
                    resp.equipments.forEach((equipment) => {
                        if (equipment.type !== 'end') {
                            equipmentNames.push(`${equipment.id}-${equipment.type}`);
                        }
                    });
                    return equipmentNames;
                } else {
                    const floor = resp.floors.filter((f) => String(f.id) === idFloor);
                    const area = floor[0].areas.filter((a) => String(a.id) === idArea);
                    const zone = area[0].zones.filter((z) => String(z.id) === idZone);
                    const line = zone[0].lines.filter((l) => String(l.id) === idLine);
                    line[0].equipments.forEach((equipment) => {
                        if (equipment.type !== 'end') {
                            equipmentNames.push(`${equipment.id}-${equipment.type}`);
                        }
                    });
                    return equipmentNames;
                }
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar los nombres de equipos`, err);
                return of(null);
            }),
            shareReplay()
        );
    }

    // tslint:disable-next-line:max-line-length
    getVariablesFromEquipment(
        idFloor: string,
        idArea: string,
        idZone: string,
        idLine: string,
        idEquipment: string,
        type: string
    ): Observable<EquipmentVariable[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint =
                    environment.mode === 'front'
                        ? 'variables'
                        : `equipments/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}/lines/${idLine}/equipmentids/${idEquipment}/equipmenttypes/${type}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                if (environment.mode !== 'front') {
                    const variables = resp?.variables || [];
                    variables.forEach((variable) => {
                        variable.name = variable.name.split('.').pop();
                    });
                    return variables;
                } else {
                    return resp;
                }
            }),
            shareReplay()
        );
    }

    getKpisFromEquipment(idFloor: string, idArea: string, idZone: string, idLine: string, idEquipment: string): Observable<Kpis[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = environment.mode === 'front' ? 'kpis' : `kpis/${this.warehouse}-${idFloor}-${idArea}-${idZone}-${idLine}-${idEquipment}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            shareReplay()
        );
    }

    getEquipmentTypes(): Observable<string[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'equipmenttypes' : `designtool/canonical/${this.warehouse}/equipmenttypes`;
                return this.http.get<string[]>(`${this.baseUrl}/${endpoint}`);
            }),
            shareReplay(),
        );
    }

    getEquipmentPhotoAndMetadata(type, level: string = Level.Equipment): Observable<string[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'equipmenttypes' : `designtool/ar-images/${level}/${this.warehouse}/typologies/${type}`;
                return this.http.get<string[]>(`${this.baseUrl}/${endpoint}`);
            }),
            catchError((err) => {
                console.warn('Ha ocurrido un problema al recuperar Photo y Metadata de ', type, err);
                return of(null);
            }),
            retry(1),
            shareReplay()
        );
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum VisibilityStates {
    active = 'active',
    passive = 'passive',
    hidden = 'hidden',
    frozen = 'frozen',
    terminated = 'terminated',
}

interface VisibilityStateChange {
    prevState: VisibilityStates;
    state: VisibilityStates;
}

@Injectable({
    providedIn: 'root',
})
export class PageLifecycleService extends EventTarget {
    state: any;
    private visibilityStateChange = new Subject<VisibilityStateChange>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    listenVisibilityChange = this.visibilityStateChange.asObservable();

    constructor() {
        super();
        this.state = this.getState();
        const fn = () => this.stateChange(this.getState());
        const opt = { capture: true };
        const events = {
            pageshow: fn,
            focus: fn,
            blur: fn,
            visibilitychange: fn,
            resume: fn,
            freeze: () => this.stateChange(VisibilityStates.frozen),
            pagehide: (event) => this.stateChange(event.persisted ? VisibilityStates.frozen : VisibilityStates.terminated),
        };
        // eslint-disable-next-line @typescript-eslint/no-shadow
        for (const [event, fn] of Object.entries(events)) window.addEventListener(event, fn, opt);
    }

    private getState() {
        /* eslint-disable prettier/prettier */
        return document.visibilityState === VisibilityStates.hidden
            ? VisibilityStates.hidden
            : document.hasFocus()
              ? VisibilityStates.active
              : VisibilityStates.passive;
        /* eslint-enable prettier/prettier */
    }

    private stateChange(nextState) {
        if (nextState === this.state) return;
        const prevState = this.state;
        this.state = nextState;
        this.visibilityStateChange.next({ prevState, state: this.state });
    }
}

<div [ngClass]="{ 'd-none': liteView }">
    <div class="svg-wrapper" [ngClass]="viewFullSize ? 'expand' : ''">
        <button class="btn btn-light view-full-size" (click)="toogleFullScreen()" [title]="viewFullSize ? 'Hide extra info' : 'Show extra info'">
            <i class="fa" [ngClass]="viewFullSize ? 'fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'"></i>
        </button>
        <div class="map-content">
            <button class="btn btn-light" (click)="resetZoom()" title="Reposition map"><i class="fa fa-crosshairs" aria-hidden="true"></i></button>
            <svg
                #svg
                auwaSvgZoom
                directiveCurrentZoom="{{ currentZoom }}"
                directiveViewBox="{{ setViewBox }}"
                directiveActivateZoom="{{ activateZoom }}"
                (svgZoomEventEmmiter)="hideContextMenu(); refreshVisibleLines()"
                [attr.viewBox]="setViewBox"
                fill="#C9CCCD"
            >
                <rect
                    class="area-rectangle-marker"
                    [attr.x]="setViewBox.split(' ')[0]"
                    [attr.y]="setViewBox.split(' ')[1]"
                    [attr.width]="setViewBox.split(' ')[2]"
                    [attr.height]="setViewBox.split(' ')[3]"
                />
                <svg:g #freeBacks class="freeBacksGroup" *ngIf="viewExtraData">
                    <ng-container *ngFor="let item of area.freeBacksLines.shapes">
                        <svg:path class="shape" [attr.data-element]="'shape'" [attr.d]="item.data" [attr.style]="item.style"></svg:path>
                    </ng-container>
                    <ng-container *ngFor="let item of area.freeBacksLines.texts">
                        <svg:text
                            filter="url(#outline)"
                            class="shape"
                            [attr.font-size]="item.size || '16'"
                            [attr.data-element]="'shape'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                </svg:g>
                <ng-container *ngFor="let area of map.areas">
                    <ng-container *ngFor="let zone of area.zones">
                        <svg:g [id]="zone.id">
                            <ng-container *ngFor="let lines of zone.lines">
                                <svg:path
                                    *ngIf="lines.data && lines.data !== 'null'"
                                    attr.title="Zone {{ zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []] }} - <strong>Line {{
                                        lines.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]]
                                    }}</strong>"
                                    data-element="line"
                                    attr.data-key="{{ floorId }}-{{ area.id }}-{{ zone.id }}-{{ lines.id }}"
                                    [attr.d]="lines.data"
                                    (click)="emitLine($event, undefined)"
                                    (contextmenu)="onRightClick($event)"
                                ></svg:path>
                                <!--emitZone($event)"-->
                            </ng-container>
                            <ng-container *ngFor="let lines of zone.lines">
                                <svg:use
                                    class="arrow"
                                    (click)="emitLine($event, undefined)"
                                    attr.data-arrow="{{ floorId }}-{{ area.id }}-{{ zone.id }}-{{ lines.id }}"
                                    [attr.xlink:href]="createArrow(lines.orientation)"
                                    [attr.x]="lines.xArrow"
                                    [attr.y]="lines.yArrow"
                                    width="8"
                                    height="8"
                                ></svg:use>
                                <!--"emitZone($event)"-->
                            </ng-container>
                        </svg:g>
                    </ng-container>
                </ng-container>
                <svg:g #freeFronts class="freeFrontsGroup" *ngIf="viewExtraData">
                    <ng-container *ngFor="let item of area.freeFrontsLines.shapes">
                        <svg:path class="shape" [attr.data-element]="'shape'" [attr.d]="item.data" [attr.style]="item.style"></svg:path>
                    </ng-container>
                    <ng-container *ngFor="let item of area.freeFrontsLines.texts">
                        <svg:text
                            filter="url(#outline)"
                            class="shape"
                            [attr.font-size]="item.size || '16'"
                            [attr.data-element]="'shape'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                </svg:g>
                <svg:g *ngIf="isVisibilityEnabled" class="variablesGroup">
                    <ng-container *ngFor="let block of metadataBlocks?.metadata">
                        <svg:g
                            class="shape block"
                            [attr.transform]="block?.transform"
                            [attr.variable]="block?.variable"
                            [attr.description]="block?.description"
                        >
                            <ng-container *ngFor="let item of block?.shapes">
                                <svg:path
                                    *ngIf="item && item.data !== 'null'"
                                    [attr.data-element]="'shape'"
                                    [attr.data-key]="item.variable"
                                    [ngClass]="{ withVariable: item.variable, highlightPath: item.highlightPath }"
                                    [attr.d]="item.data"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <title>{{ block.variable }} - {{ block.description }}</title>
                                </svg:path>
                            </ng-container>
                            <ng-container *ngFor="let item of block?.texts">
                                <svg:text
                                    class="shape"
                                    [attr.data-element]="'shape'"
                                    [attr.font-size]="item.size || '16'"
                                    [attr.x]="item.x"
                                    [attr.y]="item.y"
                                    [attr.data-key]="item.variable ? 'text' + item.variable : ''"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <tspan [attr.text-anchor]="item.variable ? 'middle' : 'left'" dy="20">{{ item.text }}</tspan>
                                    <title>{{ item.variable }}</title>
                                </svg:text>
                            </ng-container>
                        </svg:g>
                    </ng-container>
                    <ng-container *ngFor="let item of map.variablesLines?.texts">
                        <svg:text
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.font-size]="item.size || '16'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                            [attr.variable]="item.variable"
                        >
                            <!--[attr.data-key]="item.variable ? item.variable : ''"-->
                            <tspan dy="20">{{ item.text }}</tspan>
                            <!--[attr.text-anchor]="item.variable ? 'middle' : 'left'" -->
                            <title>{{ item.variable }}</title>
                        </svg:text>
                    </ng-container>
                    <ng-container *ngFor="let item of map.variablesLines?.shapes">
                        <svg:path
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.d]="item.data"
                            [attr.data-key]="item.variable"
                            [attr.variable]="item.variable"
                            *ngIf="item && item.data && item.data !== 'null'"
                        >
                            <title>{{ item.variable }}</title>
                        </svg:path>
                    </ng-container>
                </svg:g>
                <text [attr.x]="setViewBox.split(' ')[0]" [attr.y]="setTitleY()" font-family="Verdana" font-size="22" fill="white">
                    {{ area.id | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []] }}
                </text>
                <svg:g #states></svg:g>
            </svg>
        </div>
    </div>
    <div class="row" #buttonsOverMap>
        <div [id]="'accordion-' + floorId + '-' + area.id" class="accordion" *ngIf="zonesOverMap.length > 0">
            <div class="accordion-item">
                <h2 class="accordion-header" [id]="'heading-' + floorId + '-' + area.id">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse-' + floorId + '-' + area.id"
                        aria-expanded="false"
                        [attr.aria-controls]="'collapse-' + floorId + '-' + area.id"
                    >
                        <h6 class="linesOverMap-title my-2">
                            <span class="badge bg-dark me-2">{{ zonesOverMap.length }}</span> Equipment-Lines without map
                        </h6>
                    </button>
                </h2>
                <div
                    [id]="'collapse-' + floorId + '-' + area.id"
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="'heading-' + floorId + '-' + area.id"
                    [attr.data-bs-parent]="'#accordion-' + floorId + '-' + area.id"
                >
                    <div class="accordion-body">
                        <ng-template ngFor let-zone [ngForOf]="zonesOverMap">
                            <div class="row" *ngIf="zone.lines.length > 0">
                                <h5 class="svg-zone-title">
                                    <strong>Zone {{ zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []] }}</strong>
                                </h5>
                            </div>
                            <div class="row" *ngIf="zone.lines.length > 0" style="margin: 0">
                                <div class="btn-zones col-6 col-md-4 col-lg-3" *ngFor="let line of zone.lines">
                                    <div
                                        role="button"
                                        attr.data-key="{{ floorId }}-{{ area.id }}-{{ zone.id }}-{{ line.id }}"
                                        data-element="zone"
                                        class="btn btn-default"
                                        (click)="emitZone($event)"
                                    >
                                        {{ line.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="liteView">
    <div #buttons class="col-12 pb-3">
        <ng-template ngFor let-zone [ngForOf]="area.zones">
            <div class="row">
                <h4 class="area-title pb-2">
                    <strong>Zone {{ zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []] }}</strong>
                    <a [routerLink]="['/warehouse', warehouse.warehouse, 'floor', floorId, 'area', area.id, 'zone', zone.id]" style="padding-left: 10px">
                        View complete Zone
                    </a>
                </h4>
            </div>
            <div class="row" *ngIf="zone.lines.length > 0" style="margin: 0">
                <div class="btn-zones col-6 col-md-4 col-lg-3" *ngFor="let line of zone.lines">
                    <div
                        role="button"
                        attr.data-key="{{ floorId }}-{{ area.id }}-{{ zone.id }}-{{ line.id }}"
                        data-element="zone"
                        class="btn btn-default"
                        (click)="emitZone($event)"
                    >
                        {{ line.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}
                    </div>
                </div>
            </div>
            <h5 class="text-center mt-5 mb-5" *ngIf="zone.lines.length === 0">No hay lineas de equipos</h5>
        </ng-template>
    </div>
</div>
<app-context-menu
    [keySelectedWithRightClick]="keySelectedWithRightClick"
    [warehouse]="warehouse.warehouse"
    [contextId]="area.id"
    [x]="rightClickX"
    [y]="rightClickY"
    (newLineSelectedFromContextMenu)="emitLine(undefined, $event)"
    hideLink="Area"
></app-context-menu>

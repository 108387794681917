/* eslint-disable @typescript-eslint/naming-convention */
export interface MapGlobalState {
    id: string;
    states: MapState[];
}
export interface MapState {
    id: string;
    position: number;
    label: string;
    visible: boolean;
}

export enum MapStates {
    unknown = 'unknown',
    run = 'run',
    without_permission = 'lwdo',
    alert_low = 'alert_low',
    alert = 'alert',
    critical = 'critical',
    box = 'box',
    warning = 'warning',
    disconnected = 'disconnected',
}

export const initialStates: Array<MapState> = [
    { position: 0, id: MapStates.run, label: 'Run', visible: true },
    { position: 1, id: MapStates.box, label: 'Box', visible: true },
    { position: 2, id: MapStates.without_permission, label: 'Without Permission', visible: true },
    { position: 3, id: MapStates.alert_low, label: 'Alert Low', visible: true },
    { position: 4, id: MapStates.alert, label: 'Alert', visible: true },
    { position: 5, id: MapStates.critical, label: 'Critical', visible: true },
    { position: 6, id: MapStates.warning, label: 'Warning', visible: true },
    { position: 7, id: MapStates.disconnected, label: 'Disconnected', visible: true },
];

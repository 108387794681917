<div class="dropdown">
    <button
        class="btn btn-auwa w-100 dropdown-toggle pe-2 ps-2"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style="height: 40px"
    >
        {{ label }}
        <i class="fas" [ngClass]="icon" [title]="iconTitle" aria-hidden="true" *ngIf="icon"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" *ngFor="let item of availableItems">
            <div class="row">
                <app-checkbox
                    [key]="item.id"
                    columns="12"
                    extraClasses="ps-0"
                    [label]="item.label"
                    [checked]="item.visible"
                    (click)="onCheck($event, item)"
                ></app-checkbox>
            </div>
        </a>
        <div class="dropdown-divider"></div>
        <div class="pe-2 ps-2">
            <button class="btn btn-auwa w-100" (click)="changeVisibleItems()">Apply</button>
        </div>
    </div>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-lines-map-icons',
    templateUrl: './lines-map-icons.component.html',
    styleUrls: ['./lines-map-icons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinesMapIconsComponent {
    @Input() icons: any;

    constructor(private sanitizer: DomSanitizer) {}

    sanitizeIcon(icon) {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }
}

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { Warehouse } from '@app/core/shared/warehouse';
import { AppLoadService } from '@app/app-load.service';
import { Structure } from '../../shared/structure';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { PageLifecycleService, VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { MapGlobalState, initialStates } from '@app/shared/models/map-state';

const { LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

@Component({
    templateUrl: './home-customs.component.html',
    styleUrls: ['./home-customs.component.scss'],
})
export class HomeCustomsComponent extends MapContainerComponent implements OnInit, OnDestroy, AfterViewInit {
    warehouse: Warehouse;
    structure: Structure;
    liteView: boolean;
    mode = 'home-customs'
    supportedEvents: Array<string> = [LINE_STATE_CHANGED];
    configurationFilterValues: Array<MapGlobalState>;
    customsChange$: {
        [customName: string]: Observable<LineStatusNotification>;
    } = {};
    tabState: VisibilityStates = VisibilityStates.active;

    lineToShow = '';
    activateZoom = true;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appLoadService: AppLoadService,
        private pageLifeCycleService: PageLifecycleService,
        private warehouseStatusService: WarehouseStatusService,
    ) {
        super();
        this.warehouse = this.route.parent.snapshot.data.home.warehouse;
        this.structure = this.route.parent.snapshot.data.home.structure;
    }

    ngOnInit() {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.liteView = res.liteMode;

            if (res['stateFilter']) {
                this.configurationFilterValues = JSON.parse(res['stateFilter']);
                const valueInCustoms = this.configurationFilterValues.filter((item) => item.id === 'customs')[0]?.states;
                this.availableStates = valueInCustoms ?? initialStates;
            } else {
                this.availableStates = initialStates;
            }
            this.changeVisibleStates(this.availableStates);
        });
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        await this.structure.custom.forEach(async (custom) => {
            const groupName = `${this.warehouse.warehouse}-CUSTOMS-${custom.name}`;
            await this.warehouseStatusService.endSubscription(groupName, this.supportedEvents);
        });
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe?.complete();
    }

    async ngAfterViewInit(): Promise<void> {
        try {
            this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ prevState, state }) => {
                this.tabState = state;
                if (
                    prevState !== VisibilityStates.active &&
                    [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
                    this.warehouseStatusService.isConnected()
                ) {
                    await this.subscribeToRealTimeEvents();
                }
            });
            await this.subscribeToRealTimeEvents();
        } catch (error) {
            console.error(error);
        }
    }

    navigateToArea(event) {
        const key = $(event.target).data('key');
        const keys = key.split('-');
        this.router.navigate([`warehouse/${this.warehouse.warehouse}/floor/${keys[0]}/area/${keys[1]}`]);
    }

    async subscribeToRealTimeEvents() {
        try {
            await this.structure.custom.forEach(async (custom) => {
                const groupName = `${this.warehouse.warehouse}-CUSTOMS-${custom.name}`;
                await this.warehouseStatusService.endSubscription(groupName, this.supportedEvents);
                await this.warehouseStatusService.startSubscription(groupName, this.supportedEvents);
                this.customsChange$[custom.name] = await this.warehouseStatusService.listenNotifications(groupName, LINE_STATE_CHANGED);
                this.warehouseStatusService.onReconnecting.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.subscribeToRealTimeEvents());
            });
        } catch (error) {
            console.error(error);
        }
    }

    newLineSelected($event) {
        this.lineToShow = JSON.stringify($event);
    }

    offcanvasToggle(visible: boolean) {
        this.activateZoom = !visible;
        if (!visible) this.lineToShow = '';
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
    name: 'fazlDecorator',
})
export class FazlDecoratorPipe implements PipeTransform {
    @memo({
        resolver: (fullValue, fazl) => ({ fullValue, fazl }),
        cache: new WeakMap(),
    })
    transform(fullValue: string, fazl: any[][]) {
        let result = fullValue;

        if (fullValue.includes('-')) {
            let warehouse;
            let floor;
            let area;
            let zone;
            let line;
            let equipmentId;
            if (fullValue.includes('-F')) [warehouse, floor, area, zone, line, equipmentId = ''] = fullValue.split('-');
            else [floor, area, zone, line, equipmentId = ''] = fullValue.split('-');

            if (fazl[0][0] === 'F' && fazl[0][1] === false && floor) floor = floor.replace('F', '');
            if (fazl[1][0] === 'A' && fazl[1][1] === false && area) area = area.replace('A', '');
            if (fazl[2][0] === 'Z' && fazl[2][1] === false && zone) zone = zone.replace('Z', '');
            if (fazl[3][0] === 'EQL' && fazl[3][1] === false && line) line = line.replace('EQL', '');

            result = '';
            if (floor) result += `${floor}`;
            if (area) result += `-${area}`;
            if (zone) result += `-${zone}`;
            if (line) result += `-${line}`;
            if (equipmentId) result += `-${equipmentId}`;
        } else {
            if (fazl[0][0] === 'F' && fazl[0][1] === false) result = result.replace('F', '');
            if (fazl[1] && fazl[1][0] && fazl[1][0] === 'A' && fazl[1][1] === false) result = result.replace('A', '');
            if (fazl[2] && fazl[2][0] && fazl[2][0] === 'Z' && fazl[2][1] === false) result = result.replace('Z', '');
            if (fazl[3] && fazl[3][0] && fazl[3][0] === 'EQL' && fazl[3][1] === false) result = result.replace('EQL', '');
        }
        return result;
    }
}

<app-lines-map-icons [icons]="iconsContent"></app-lines-map-icons>
<h5 class="navigate-to-line mt-2">
    <a class="mx-1" (click)="goToFloor()"
        >Open <strong>Floor {{ lineToShow?.floorId }}</strong></a
    >
    |
    <a class="mx-1" (click)="goToArea()"
        >Open <strong>Area {{ lineToShow?.areaId }}</strong></a
    >
    |
    <a class="mx-1" (click)="goToZone()"
        >Open <strong>Zone {{ lineToShow?.zoneId }}</strong></a
    >
    |
    <a class="mx-1" (click)="goToLine()"
        >Open <strong>Line {{ lineToShow?.lineId }}</strong></a
    >
</h5>
<div style="position: relative" class="mt-2 border border-4 rounded-4" [hidden]="loading || liteView">
    <button class="btn btn-light ms-2 mt-2" (click)="resetZoom()" title="Reposition map">
        <i class="fa fa-crosshairs" aria-hidden="true"></i>
    </button>
    <button
        class="btn btn-light ms-1 mt-2"
        (click)="viewLineIconsText = !viewLineIconsText"
        [title]="viewLineIconsText ? 'Hide equipment names' : 'Show equipment names'"
    >
        <i class="fas" [ngClass]="viewLineIconsText ? 'fa-remove-format' : 'fa-font'"></i>
    </button>
    <svg
        #svgMinified
        auwaSvgZoom
        directiveCurrentZoom="{{ currentZoom }}"
        directiveViewBox="{{ setViewBox }}"
        directiveReduceZoom="{{ reduceZoom }}"
        [attr.viewBox]="setViewBox"
        class="icon"
    >
        <ng-container *ngFor="let equipment of line?.equipments; let i = index">
            {{ i }}
            <ng-container *ngIf="equipment.grid">
                <svg:use
                    [attr.x]="equipment.grid.x"
                    [attr.y]="equipment.grid.y"
                    [attr.width]="1"
                    [attr.height]="1"
                    [attr.xlink:href]="createPathUrl(equipment)"
                ></svg:use>
                <svg:use
                    class="my_piece"
                    data-state=""
                    [attr.xlink:href]="createEquipmentUrl(equipment)"
                    attr.data-key="{{ equipment.id }}-{{ equipment.type | lowercase }}-minified"
                    [attr.x]="equipment.grid.x"
                    [attr.y]="equipment.grid.y"
                    [attr.width]="1"
                    [attr.height]="1"
                ></svg:use>
                <svg:rect
                    class="my_rect_minified"
                    *ngIf="equipment.type !== 'end'"
                    attr.title="{{ equipment.id }} <small class='tooltip-equipment-type'>{{ equipment.type }}</small>"
                    attr.data-key="{{ equipment.id }}-{{ equipment.type | lowercase }}-minified-wrapper"
                    [attr.data-bs-toogle]="'tooltip'"
                    [attr.data-bs-html]="'true'"
                    [attr.x]="equipment.grid.x"
                    [attr.y]="equipment.grid.y"
                    [attr.width]="1"
                    [attr.height]="1"
                    (click)="navigateToEquipment(equipment.id)"
                ></svg:rect>
                <ng-container *ngIf="i === 1">
                    <svg:use
                        class="arrow"
                        [attr.xlink:href]="createArrow(line?.orientation)"
                        [attr.x]="equipment.grid.x"
                        [attr.y]="equipment.grid.y"
                        [attr.width]="1"
                        [attr.height]="1"
                    ></svg:use>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let equipment of line?.equipments; let i = index">
            <svg:text
                *ngIf="viewLineIconsText"
                class="label-text"
                [attr.font-size]="'0.15'"
                [attr.x]="equipment.grid.x"
                [attr.y]="equipment.grid.y"
                [attr.z]="'100'"
            >
                <tspan
                    [attr.dy]="line.equipments[i - 1]?.grid.x === equipment.grid.x ? 0.5 : i % 2 === 0 ? 0.05 : 1.015"
                    [attr.dx]="line.equipments[i - 1]?.grid.y === equipment.grid.y ? 0.5 : 0.95"
                    [attr.text-anchor]="line?.equipments[i - 1]?.grid.y === equipment.grid.y ? 'middle' : 'start'"
                >
                    {{ equipment.id }}
                </tspan>
            </svg:text>
        </ng-container>
    </svg>
</div>
<div class="mt-2 border border-4 rounded-4" *ngIf="liteView">
    <div #buttonsMinified class="col-12 buttons-wrapper">
        <div class="row m-0 justify-content-center" *ngIf="line?.equipments.length > 0">
            <ng-container *ngFor="let equipment of line.equipments">
                <div class="btn-zones col-6 col-lg-4 col-xl-3" *ngIf="equipment.id">
                    <div
                        role="button"
                        class="btn btn-default"
                        data-state=""
                        attr.data-key="{{ equipment.id }}-{{ equipment.type | lowercase }}-minified"
                        (click)="navigateToEquipment(equipment.id)"
                        [title]="equipment.id"
                    >
                        {{ equipment.id }}
                    </div>
                </div>
            </ng-container>
        </div>
        <h5 class="text-center py-5" *ngIf="line && line?.equipments.length === 0">No hay equipos</h5>
    </div>
</div>

/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { StateService } from '@app/core/shared/state/state.service';
import { MonitoringService } from '@app/shared/services/monitoring.service';
import { VariableNotification, VariableActiveStatusGroup, VariableFilters } from '../events/variable-status';
import { VariablesStatusClient } from '../clients/variables-status.client';
import { bufferTime, tap, filter } from 'rxjs/operators';
import * as _ from 'lodash-es';
import * as dayjs from 'dayjs';

const ACTIVE_VARIABLES = 'ActiveVariables';

@Injectable()
export class VariablesActiveStatusService {
    onNotification: EventEmitter<VariableNotification> = new EventEmitter<VariableNotification>();
    onNewNotification: EventEmitter<VariableNotification> = new EventEmitter<VariableNotification>();
    groupName: string;
    delegateFunc: (notification: VariableNotification) => void;
    onReconnect: () => Promise<void>;
    handleReconnectionReference: () => Promise<void>;
    variableSubscription: any;
    variableSubscriptionTracking: any;
    notificationTSMap = new WeakMap();

    private readonly variablesCallback = (variableActiveStatusGroup: VariableActiveStatusGroup) => {
        variableActiveStatusGroup.notifications.forEach((statusUpdate) => {
            this.onNotification.emit({
                requestId: variableActiveStatusGroup.requestId,
                floorId: statusUpdate.floorid,
                areaId: statusUpdate.areaid,
                lineId: statusUpdate.lineid,
                zoneId: statusUpdate.zoneid,
                equipmentId: statusUpdate.equipmentId,
                equipmentType: statusUpdate.equipmentType,
                fqn: statusUpdate.fqn,
                source: statusUpdate.source,
                timestamp: statusUpdate.timestamp,
                sourceTimeStamp: statusUpdate.sourceTimeStamp?.toString(),
                serverTimeStamp: statusUpdate.serverTimeStamp?.toString(),
                variableName: statusUpdate.variableName,
                variableType: statusUpdate.variableType,
                variableValue: statusUpdate.value,
                variableValueType: statusUpdate.valueType,
                isOpcError: statusUpdate.isOpcError || null,
                opcErrorType: statusUpdate.opcErrorType || null,
            });
        });
    };

    constructor(private variableStatusClient: VariablesStatusClient, private stateService: StateService, private monitoringService: MonitoringService) {}

    public async subscribeToVariable(
        variableFilters: VariableFilters,
        delegateFunc: (notification: VariableNotification) => void,
        onReconnect: () => Promise<void>
    ) {
        const eventName = VariablesStatusClient.SupportedEvents.VARIABLE_STATE_CHANGED;
        this.variableSubscription = this.onNotification.subscribe({
            next: (event: VariableNotification) => {
                const notificationTS = event.serverTimeStamp;
                // tslint:disable-next-line: max-line-length
                if (this.stateService.isNewerNotification(this.constructor.name, ACTIVE_VARIABLES, eventName, notificationTS, event)) {
                    delegateFunc(event);
                    this.onNewNotification.emit(event);
                }
            },
        });
        this.variableSubscriptionTracking = this.onNewNotification
            .pipe(
                filter((event: VariableNotification) => event.source === 'EventHub'),

                tap((event: VariableNotification) =>
                    this.notificationTSMap.set({ fqn: event.fqn, ts: event.sourceTimeStamp, value: event.variableValue }, new Date().getTime())
                ),
                bufferTime(5000)
            )
            .subscribe({
                next: (variableActiveStatusGroup: VariableNotification[]) => {
                    // log metrics if enabled
                    if (localStorage.getItem('VariableLatencyMetricEnabled') != null) {
                        try {
                            const metrics = variableActiveStatusGroup.reduce(
                                (acc, statusUpdate) => {
                                    const { warehouseId, values, min, max } = acc;
                                    const sourcets = dayjs(statusUpdate.sourceTimeStamp).toDate();

                                    const notificationTime =
                                        this.notificationTSMap.get({
                                            fqn: statusUpdate.fqn,
                                            ts: statusUpdate.sourceTimeStamp,
                                            value: statusUpdate.variableValue,
                                        }) || new Date().getTime();
                                    const timeDiff = (notificationTime - sourcets.getTime()) / 1000;
                                    return {
                                        warehouseId: warehouseId || statusUpdate.fqn.split('.')[0],
                                        values: [...values, timeDiff],
                                        max: !max || timeDiff > max ? timeDiff : max,
                                        min: !min || timeDiff < min ? timeDiff : min,
                                    };
                                },
                                { warehouseId: undefined, values: [], min: undefined, max: undefined }
                            );
                            if (!_.isEmpty(metrics.values)) {
                                this.monitoringService.logMetric('Auwa.Web.SignalR.VariableLatency', _.mean(metrics.values), null, metrics.min, metrics.max, {
                                    warehouseId: metrics.warehouseId,
                                });
                            }
                        } catch {
                            console.error('Fallo al monitorear el socket de variables RT');
                        }
                    }
                },
            });
        this.delegateFunc = delegateFunc;
        this.onReconnect = onReconnect;
        this.handleReconnectionReference = this.handleReconnection.bind(this);
        this.groupName = JSON.stringify(variableFilters);
        await this.variableStatusClient.subscribe(eventName, this.groupName, this.variablesCallback, this.handleReconnectionReference);
    }

    public async unsubscribeFromVariable() {
        if (this.groupName) {
            const eventName = VariablesStatusClient.SupportedEvents.VARIABLE_STATE_CHANGED;
            this.stateService.clearNotificationState(this.constructor.name, ACTIVE_VARIABLES, eventName);
            this.variableSubscription?.unsubscribe();
            this.variableSubscription = null;
            this.variableSubscriptionTracking?.unsubscribe();
            this.variableSubscriptionTracking = null;
            await this.variableStatusClient.unsubscribe(eventName, this.groupName);
        }
    }

    public async pauseNotifications() {
        const eventName = VariablesStatusClient.SupportedEvents.VARIABLE_STATE_CHANGED;
        this.stateService.clearNotificationState(this.constructor.name, ACTIVE_VARIABLES, eventName);
        try {
            await this.unsubscribeFromVariable();
        } catch (error) {
            console.log(`Unable to LeaveGroup ${this.groupName}`);
        }
    }

    async handleReconnection() {
        const eventName = VariablesStatusClient.SupportedEvents.VARIABLE_STATE_CHANGED;
        this.stateService.clearNotificationState(this.constructor.name, ACTIVE_VARIABLES, eventName);
        await this.onReconnect();
    }

    public async applyFilter(variableFilters: VariableFilters) {
        const eventName = VariablesStatusClient.SupportedEvents.VARIABLE_STATE_CHANGED;
        this.stateService.clearNotificationState(this.constructor.name, ACTIVE_VARIABLES, eventName);
        try {
            await this.unsubscribeFromVariable();
        } catch (error) {
            console.log(`Unable to LeaveGroup ${this.groupName}`);
        }
        this.subscribeToVariable(variableFilters, this.delegateFunc, this.handleReconnection);
    }

    isConnected() {
        return this.variableStatusClient.connection?.hubConnection.state === 'Connected';
    }
}

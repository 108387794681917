import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CheckboxComponent } from '@ays/commons/lib/components/forms/checkbox/checkbox.component';
import { SelectMultilevelComponent } from '@app/shared/components/forms/select-multilevel/select-multilevel.component';
import { AppLoadService } from '@app/app-load.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { environment } from '@environments/environment';
import { UserConfiguration, WarehouseConfiguration } from '@app/shared/models/configurations';
import { LinesService } from '@app/map/lines/shared/lines.service';
import { SelectComponent } from '@ays/commons/lib/components/forms/select/select.component';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { VariableValueType, WILD_CARD_CHARACTER } from '@app/notifications/shared/events/variable-status';
import { ModalConfirmationComponent } from '@ays/commons/lib/components/modal-confirmation/modal-confirmation.component';
import { ModalCenteredComponent } from '@ays/commons/lib/components/modal-centered/modal-centered.component';
import { SearchComponent } from '@ays/lib/components/forms/search/search.component';
import { ScannerTrendColors, ErrorTrendColors, VariableColorSettings, VariableColors, ZoneColors, setFqnPreferences, setIdentifiers, BoxDetectedColors } from '@variables/shared/variable';
import { ColorsService } from '@app/shared/services/colors.service';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash-es';

const FLOOR_IDENTIFIER_VALUE = 'F0';
const AREA_IDENTIFIER_VALUE = 'AWH';
const ZONE_IDENTIFIER_VALUE = 'Z04TD101';
const LINE_IDENTIFIER_VALUE = 'EQLTRASLO_01';

const FIRST_DEFAULT_COLOR = '#2da45d';
const SECOND_DEFAULT_COLOR = '#fe840e';
const THIRD_DEFAULT_COLOR = '#ff2800';

const FIRST_SCANNER_DEFAULT_COLOR = '#ffffe5';
const SECOND_SCANNER_DEFAULT_COLOR = '#fff2e5';
const THIRD_SCANNER_DEFAULT_COLOR = '#ffe5e5';

const FIRST_ERROR_DEFAULT_COLOR = '#ffffe5';
const SECOND_ERROR_DEFAULT_COLOR = '#fff2e5';
const THIRD_ERROR_DEFAULT_COLOR = '#ffe5e5';

const MIN_DEFAULT_RANGE = 20;
const ERRORS_MIDDLE_DEFAULT_RANGE = 60;
const MAX_DEFAULT_RANGE = 80;

const FIRST_DEFAULT_ERRORS_NAME = 'Range 1';
const SECOND_DEFAULT_ERRORS_NAME = 'Range 2';
const THIRD_DEFAULT_ERRORS_NAME = 'Range 3';

//#region COLORS
const INITIAL_COLOR_SELECTED = '#eeeeee';
const INITIAL_ENVIROMENT = 'DEFAULT';
//#endregion

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('floorCheck', { static: false }) floorCheck: CheckboxComponent;
    @ViewChild('areaCheck', { static: false }) areaCheck: CheckboxComponent;
    @ViewChild('zoneCheck', { static: false }) zoneCheck: CheckboxComponent;
    @ViewChild('lineCheck', { static: false }) lineCheck: CheckboxComponent;

    //#region COLORS
    @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalConfirmationComponent;
    @ViewChild('newVariableColorModal', { static: false }) newVariableColorModal: ModalCenteredComponent;
    @ViewChild('applyForAllVariablesCheck', { static: false }) applyForAllVariablesCheck: CheckboxComponent;
    @ViewChild('variableFilter', { static: false }) variableFilter: SearchComponent;
    @ViewChild('alertsZonesFilter', { static: false }) alertsZonesFilter: SearchComponent;
    @ViewChild('scannerTrendFilter', { static: false }) scannerTrendFilter: SearchComponent;
    @ViewChild('settingsFAZ') settingsFAZ: SelectMultilevelComponent;
    @ViewChild('linesSelector') linesSelector: SelectComponent;
    @ViewChild('equipmentsSelector') equipmentsSelector: SelectComponent;
    @ViewChild('variableSelector') variableSelector: SelectComponent;
    @ViewChild('scannersSelector') scannersSelector: SelectComponent;
    $settingsFAZ: JQuery;
    $linesSelector: JQuery;
    $equipmentsSelector: JQuery;
    $variableSelector: JQuery;
    $scannersSelector: JQuery;
    selectedZone: string;
    selectedLine: string;
    selectedEquipment: string;
    selectedVariable: string;
    selectedVariableCompleteFQN: string;
    selectedVariableDataType: string;
    selectedScanner: string;
    newVariableSettingsColor: VariableColorSettings[] = [];
    newColorSelected: string = INITIAL_COLOR_SELECTED;
    newColorSelectedZone: string = INITIAL_COLOR_SELECTED;
    editingVariableColorTo = '';
    editingVariableColorEnviroment = '';
    alertsZonesTemporal: ZoneColors[] = [];
    variableColorsTemporal: VariableColors[] = [];
    scannerTrendTemporal: ScannerTrendColors[] = [];
    warehouseConfigurationBoxDetectedColors: BoxDetectedColors = { lightMode: '', darkMode: '' };
    userConfigurationBoxDetectedColors: BoxDetectedColors = { lightMode: '', darkMode: '' };

    colorBoxes: string = this.colorsService.getColor('default-status-boxes'); //'#8B572A';

    scannerRangeColorsTemporal: {
        firstColorRange: string;
        secondColorRange: string;
        thirdColorRange: string;
        firstRange: number;
        secondRange: number;
    };
    errorTrendTemporal: ErrorTrendColors[] = [];
    errorRangeColorsTemporal: {
        firstColorRange: string;
        secondColorRange: string;
        thirdColorRange: string;
        firstRange: number;
        secondRange: number;
    };
    line$: Array<string> = [];
    lineEquipments$: Array<string> = [];
    scanner$: string[];
    variable$: Array<any> = [];
    variableColorAlreadyExists = '';

    initialEnviroment: string = INITIAL_ENVIROMENT;
    variableNumericType = VariableValueType.Numeric.toLowerCase();

    confirmationApplyToAllDone = false;
    confirmationMessage = {
        messageId: 0,
        parameters: {},
        text: null,
    };

    variableFilterValue = '';
    alertsZonesFilterValue = '';
    scannerTrendFilterValue = '';

    dangerMode = true;
    //#endregion

    warehouse: Warehouse;
    warehouses: Array<Warehouse> = [];
    userConfiguration: UserConfiguration;
    warehouseConfiguration: WarehouseConfiguration;
    floorIdentifierValue = FLOOR_IDENTIFIER_VALUE;
    areaIdentifierValue = AREA_IDENTIFIER_VALUE;
    zoneIdentifierValue = ZONE_IDENTIFIER_VALUE;
    lineIdentifierValue = LINE_IDENTIFIER_VALUE;
    isColoringTextFloor = false;
    isColoringTextArea = false;
    isColoringTextZone = false;
    isColoringTextLine = false;
    identifiersPreferences = { Floor: null, Area: null, Zone: null, Line: null };
    liteView: boolean;
    darkMode: boolean;
    userName: string;
    userEmail: string;
    currentRunningHeatMapConfiguration = { ranges: [], colors: [] };
    currentStoppedHeatMapConfiguration = { ranges: [], colors: [] };
    currentOnErrorHeatMapConfiguration = { ranges: [], colors: [] };
    currentErrorsHeatMapConfiguration = { ranges: [], colors: [], names: [] };
    currentAvailabilityHeatMapConfiguration = { ranges: [], colors: [] };
    currentLWDOHeatMapConfiguration = { ranges: [], colors: [] };
    currentDimension: string;
    firstRange = MIN_DEFAULT_RANGE;
    errorsMiddleRange = ERRORS_MIDDLE_DEFAULT_RANGE;
    secondRange = MAX_DEFAULT_RANGE;
    firstErrorsName = FIRST_DEFAULT_ERRORS_NAME;
    secondErrorsName = SECOND_DEFAULT_ERRORS_NAME;
    thirdErrorsName = THIRD_DEFAULT_ERRORS_NAME;
    firstColorRange = FIRST_DEFAULT_COLOR;
    secondColorRange = SECOND_DEFAULT_COLOR;
    thirdColorRange = THIRD_DEFAULT_COLOR;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private authenticationService: AuthenticationService,
        private linesService: LinesService,
        private equipmentService: EquipmentsService,
        private equipmentsService: EquipmentsService,
        private colorsService: ColorsService
    ) {
        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
        appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.warehouse = res;
        });
        appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.liteView = this.userConfiguration?.liteMode ?? false;
            this.darkMode = this.userConfiguration?.darkMode ?? false;

            this.identifiersPreferences.Floor = this.userConfiguration.identifiersPreferences?.Floor ?? true;
            this.identifiersPreferences.Area = this.userConfiguration.identifiersPreferences?.Area ?? true;
            this.identifiersPreferences.Zone = this.userConfiguration.identifiersPreferences?.Zone ?? true;
            this.identifiersPreferences.Line = this.userConfiguration.identifiersPreferences?.Line ?? true;

            this.userConfigurationBoxDetectedColors = this.userConfiguration?.boxDetectedColors ?? { lightMode: this.colorBoxes, darkMode: this.colorBoxes };
            this.userConfigurationBoxDetectedColors.lightMode = (this.userConfigurationBoxDetectedColors.lightMode !== '') ? this.userConfigurationBoxDetectedColors.lightMode : this.colorBoxes;
            this.userConfigurationBoxDetectedColors.darkMode = (this.userConfigurationBoxDetectedColors.darkMode !== '') ? this.userConfigurationBoxDetectedColors.darkMode : this.colorBoxes;
        });
        appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            try {
                this.warehouseConfiguration = res;
                this.variableColorsTemporal = this.warehouseConfiguration?.variableColors?.map(v => {
                    return {
                        variable: v?.variable,
                        settings: v?.settings,
                        dataType: v?.dataType ? v?.dataType.toLowerCase() : this.variableNumericType
                    }
                }) ?? [];
                this.alertsZonesTemporal = this.warehouseConfiguration?.alertsZonesColors ?? [];
                this.scannerTrendTemporal = this.warehouseConfiguration?.scannerTrendColors ?? [];
                this.errorTrendTemporal = this.warehouseConfiguration?.errorTrendColors ?? [];
    
                this.warehouseConfigurationBoxDetectedColors = this.warehouseConfiguration?.boxDetectedColors ?? { lightMode: this.colorBoxes, darkMode: this.colorBoxes };
                this.warehouseConfigurationBoxDetectedColors.lightMode = (this.warehouseConfigurationBoxDetectedColors.lightMode !== '') ? this.warehouseConfigurationBoxDetectedColors.lightMode : this.colorBoxes;
                this.warehouseConfigurationBoxDetectedColors.darkMode = (this.warehouseConfigurationBoxDetectedColors.darkMode !== '') ? this.warehouseConfigurationBoxDetectedColors.darkMode : this.colorBoxes;
            } catch (error) {
                console.log(`%c Error => ${error}`, `background: ; color: red`);
            }
        });
    }

    ngOnInit() {
        this.currentRunningHeatMapConfiguration.ranges = this.warehouseConfiguration.heatMap?.Running?.ranges || [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
        this.currentRunningHeatMapConfiguration.colors = this.warehouseConfiguration.heatMap?.Running?.colors || [
            FIRST_DEFAULT_COLOR,
            SECOND_DEFAULT_COLOR,
            THIRD_DEFAULT_COLOR,
        ];
        this.currentStoppedHeatMapConfiguration.ranges = this.warehouseConfiguration.heatMap?.Stopped?.ranges || [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
        this.currentStoppedHeatMapConfiguration.colors = this.warehouseConfiguration.heatMap?.Stopped?.colors || [
            THIRD_DEFAULT_COLOR,
            SECOND_DEFAULT_COLOR,
            FIRST_DEFAULT_COLOR,
        ];
        this.currentOnErrorHeatMapConfiguration.ranges =
            this.warehouseConfiguration.heatMap && this.warehouseConfiguration.heatMap['On error']?.ranges
                ? this.warehouseConfiguration.heatMap['On error'].ranges
                : [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
        this.currentOnErrorHeatMapConfiguration.colors =
            this.warehouseConfiguration.heatMap && this.warehouseConfiguration.heatMap['On error']?.colors
                ? this.warehouseConfiguration.heatMap['On error'].colors
                : [THIRD_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, FIRST_DEFAULT_COLOR];
        this.currentErrorsHeatMapConfiguration.ranges = this.warehouseConfiguration.heatMap?.Errors?.ranges || [
            MIN_DEFAULT_RANGE,
            ERRORS_MIDDLE_DEFAULT_RANGE,
            MAX_DEFAULT_RANGE,
        ];
        this.currentErrorsHeatMapConfiguration.colors = this.warehouseConfiguration.heatMap?.Errors?.colors || [
            FIRST_DEFAULT_COLOR,
            SECOND_DEFAULT_COLOR,
            THIRD_DEFAULT_COLOR,
        ];
        this.currentErrorsHeatMapConfiguration.names = this.warehouseConfiguration.heatMap?.Errors?.names || [
            FIRST_DEFAULT_ERRORS_NAME,
            SECOND_DEFAULT_ERRORS_NAME,
            THIRD_DEFAULT_ERRORS_NAME,
        ];

        this.currentAvailabilityHeatMapConfiguration.ranges =
            this.warehouseConfiguration.heatMap && this.warehouseConfiguration.heatMap['No error']?.ranges
                ? this.warehouseConfiguration.heatMap['No error'].ranges
                : [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
        this.currentAvailabilityHeatMapConfiguration.colors =
            this.warehouseConfiguration.heatMap && this.warehouseConfiguration.heatMap['No error']?.colors
                ? this.warehouseConfiguration.heatMap['No error'].colors
                : [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR];
        this.currentLWDOHeatMapConfiguration.ranges =
            this.warehouseConfiguration.heatMap && this.warehouseConfiguration.heatMap['LWDO']?.ranges
                ? this.warehouseConfiguration.heatMap['LWDO'].ranges
                : [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
        this.currentLWDOHeatMapConfiguration.colors =
            this.warehouseConfiguration.heatMap && this.warehouseConfiguration.heatMap['LWDO']?.colors
                ? this.warehouseConfiguration.heatMap['LWDO'].colors
                : [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR];

        //#region COLORS
        this.linesService
            .getLinesNames()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((lines) => {
                this.line$ = this.setFAZLPreferences(lines);
            });

        this.equipmentService
            .getEquipmentNames('SCANNER')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((scanners) => {
                this.scanner$ = scanners?.sort((a, b) => (a > b ? 1 : -1));
            });

        this.initializeScannerRangeColors();
        this.initializeErrorRangeColors();
        //#endregion
    }

    ngAfterViewInit() {
        this.$settingsFAZ = this.settingsFAZ.$selectMultilevel;

        this.floorCheck.$checkbox.prop('checked', this.identifiersPreferences.Floor);
        this.areaCheck.$checkbox.prop('checked', this.identifiersPreferences.Area);
        this.zoneCheck.$checkbox.prop('checked', this.identifiersPreferences.Zone);
        this.lineCheck.$checkbox.prop('checked', this.identifiersPreferences.Line);

        this.$settingsFAZ.on('change', () => {
            this.selectedZone = this.$settingsFAZ.val().toString();
        });

        //#region COLORS
        this.$scannersSelector = this.scannersSelector.$select;
        this.$scannersSelector.off('change').on('change', () => {
            this.selectedScanner = String(this.$scannersSelector.find('option:selected').val());
        });

        $('#automationConfirmationModal > div > div > div.modal-footer').css('border-top', 'none');

        const visibilityCheckboxes = document.querySelectorAll('.colors-and-visibility table div.form-check');
        visibilityCheckboxes.forEach(checkbox => checkbox.setAttribute('style', 'padding-left:0px'));
        
        this.$linesSelector = this.linesSelector.$select;
        this.$linesSelector.off('change').on('change', () => {
            this.selectedLine = String(this.$linesSelector.find('option:selected').val());
            this.listEquipments(this.selectedLine);
        });
        this.$equipmentsSelector = this.equipmentsSelector.$select;
        this.$equipmentsSelector.off('change').on('change', () => {
            this.selectedEquipment = String(this.$equipmentsSelector.find('option:selected').val());
            this.listVariables(this.selectedLine, this.selectedEquipment);
        });
        this.$variableSelector = this.variableSelector.$select;
        this.$variableSelector.off('change').on('change', () => {
            this.selectedVariable = String(this.$variableSelector.find('option:selected').val());

            this.selectedVariableDataType = this.variable$.find(v => v.value === this.selectedVariable)?.dataType;

            this.configureSelectedVariableCompleteFQN();

            const newVariable: VariableColorSettings = {
                id: uuid(),
                enviroment: INITIAL_ENVIROMENT,
                to: '0',
                color: INITIAL_COLOR_SELECTED,
                visible : true,
            };
            this.newVariableSettingsColor = [];
            this.newVariableSettingsColor.push(newVariable);

            if (this.selectedVariableDataType === VariableValueType.Boolean.toLowerCase()) {
                const newVariable: VariableColorSettings = {
                    id: uuid(),
                    enviroment: INITIAL_ENVIROMENT,
                    to: '1',
                    color: INITIAL_COLOR_SELECTED,
                    visible : true,
                };
                this.newVariableSettingsColor.push(newVariable);
            }
        });

        const that = this;
        this.variableFilter.$input.off('keyup').on('keyup', function (e) {
            that.variableFilterValue = String($(this).val()).toUpperCase();
        });
        this.alertsZonesFilter.$input.off('keyup').on('keyup', function (e) {
            that.alertsZonesFilterValue = String($(this).val()).toUpperCase();
        });
        this.scannerTrendFilter.$input.off('keyup').on('keyup', function (e) {
            that.scannerTrendFilterValue = String($(this).val()).toUpperCase();
        });
        //#endregion
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    handleCheckBoxes(name: string) {
        switch (name) {
            case 'Floor':
                this.identifiersPreferences.Floor = this.floorCheck.$checkbox.prop('checked');
                this.userConfiguration.identifiersPreferences.Floor = this.identifiersPreferences.Floor;
                this.isColoringTextFloor = true;
                setTimeout(() => (this.isColoringTextFloor = false), 2000);
                break;
            case 'Area':
                this.identifiersPreferences.Area = this.areaCheck.$checkbox.prop('checked');
                this.userConfiguration.identifiersPreferences.Area = this.identifiersPreferences.Area;
                this.isColoringTextArea = true;
                setTimeout(() => (this.isColoringTextArea = false), 2000);
                break;
            case 'Zone':
                this.identifiersPreferences.Zone = this.zoneCheck.$checkbox.prop('checked');
                this.userConfiguration.identifiersPreferences.Zone = this.identifiersPreferences.Zone;
                this.isColoringTextZone = true;
                setTimeout(() => (this.isColoringTextZone = false), 2000);
                break;
            case 'Line':
                this.identifiersPreferences.Line = this.lineCheck.$checkbox.prop('checked');
                this.userConfiguration.identifiersPreferences.Line = this.identifiersPreferences.Line;
                this.isColoringTextLine = true;
                setTimeout(() => (this.isColoringTextLine = false), 2000);
                break;
        }
        this.saveUserConfiguration(() => this.appLoadService.setCurrentConfiguration(this.userConfiguration));
    }

    toggleViewMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.userConfiguration.liteMode = !this.liteView;
            this.saveUserConfiguration(() => this.appLoadService.setCurrentConfiguration(this.userConfiguration));
        }
    }

    toggleDarkMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.userConfiguration.darkMode = !this.darkMode;
            this.saveUserConfiguration(() => this.appLoadService.setCurrentConfiguration(this.userConfiguration));
        }
    }
    calculateNewRanges(event) {
        if (event[1].minVal && event[1].maxVal) {
            if (Number.isInteger(event.minVal)) this.firstRange = event.minVal;
            if (Number.isInteger(event.maxVal)) this.secondRange = event.maxVal;
            this.currentDimension = event[0];
            this.firstRange = event[1].minVal;
            this.secondRange = event[1].maxVal;
            this.warehouseConfiguration = {
                ...this.warehouseConfiguration,
                heatMap: {
                    ...this.warehouseConfiguration.heatMap,
                    [this.currentDimension]: {
                        ranges: [this.firstRange, this.secondRange],
                        colors: [
                            ...(this.warehouseConfiguration.heatMap[this.currentDimension]?.colors || [
                                this.firstColorRange,
                                this.secondColorRange,
                                this.thirdColorRange,
                            ]),
                        ],
                    },
                },
            };
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }
    checkCurrentColors(currentDimension: string): string[] {
        if (this.warehouseConfiguration.heatMap[currentDimension]?.colors) {
            return this.warehouseConfiguration.heatMap[currentDimension].colors;
        } else {
            return [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR];
        }
    }
    saveErrorsFromValue({ rangeId, newValue }) {
        switch (rangeId) {
            case '0':
                this.firstRange = newValue;
                this.currentErrorsHeatMapConfiguration.ranges[0] = this.firstRange;
                if (this.warehouseConfiguration.heatMap.Errors?.ranges) {
                    this.warehouseConfiguration.heatMap.Errors.ranges[0] = this.firstRange;
                }
                break;
            case '1':
                this.errorsMiddleRange = newValue;
                this.currentErrorsHeatMapConfiguration.ranges[1] = this.errorsMiddleRange;
                if (this.warehouseConfiguration.heatMap.Errors?.ranges) {
                    this.warehouseConfiguration.heatMap.Errors.ranges[1] = this.errorsMiddleRange;
                }
                break;
            case '2':
                this.secondRange = newValue;
                this.currentErrorsHeatMapConfiguration.ranges[2] = this.secondRange;
                if (this.warehouseConfiguration.heatMap.Errors?.ranges) {
                    this.warehouseConfiguration.heatMap.Errors.ranges[2] = this.secondRange;
                }
        }
        if (!this.warehouseConfiguration.heatMap.Errors?.ranges) {
            this.warehouseConfiguration = {
                ...this.warehouseConfiguration,
                heatMap: {
                    ...this.warehouseConfiguration.heatMap,
                    Errors: {
                        ...this.warehouseConfiguration.heatMap.Errors,
                        ranges: [this.firstRange, this.errorsMiddleRange, this.secondRange],
                    },
                },
            };
        }
        this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
    }

    saveErrorsNameValue({ rangeId, newValue }) {
        switch (rangeId) {
            case '0':
                this.firstErrorsName = newValue;
                this.currentErrorsHeatMapConfiguration.names[0] = this.firstErrorsName;
                if (this.warehouseConfiguration.heatMap.Errors?.names) {
                    this.warehouseConfiguration.heatMap.Errors.names[0] = this.firstErrorsName;
                }
                break;
            case '1':
                this.secondErrorsName = newValue;
                this.currentErrorsHeatMapConfiguration.names[1] = this.secondErrorsName;
                if (this.warehouseConfiguration.heatMap.Errors?.names) {
                    this.warehouseConfiguration.heatMap.Errors.names[1] = this.secondErrorsName;
                }
                break;
            case '2':
                this.thirdErrorsName = newValue;
                this.currentErrorsHeatMapConfiguration.names[2] = this.thirdErrorsName;
                if (this.warehouseConfiguration.heatMap.Errors?.names) {
                    this.warehouseConfiguration.heatMap.Errors.names[2] = this.thirdErrorsName;
                }
        }
        if (!this.warehouseConfiguration.heatMap.Errors?.names) {
            this.warehouseConfiguration = {
                ...this.warehouseConfiguration,
                heatMap: {
                    ...this.warehouseConfiguration.heatMap,
                    Errors: {
                        ...this.warehouseConfiguration.heatMap.Errors,
                        names: [this.firstErrorsName, this.secondErrorsName, this.thirdErrorsName],
                    },
                },
            };
        }
        this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
    }
    saveColorPicker(event) {
        this.currentDimension = event[0];
        switch (event[1]) {
            case 'firstColorRange':
                this.firstColorRange = event[2];
                [, this.secondColorRange, this.thirdColorRange] = this.checkCurrentColors(this.currentDimension);
                break;
            case 'secondColorRange':
                this.secondColorRange = event[2];
                [this.firstColorRange, , this.thirdColorRange] = this.checkCurrentColors(this.currentDimension);
                break;
            case 'thirdColorRange':
                this.thirdColorRange = event[2];
                [this.firstColorRange, this.secondColorRange, ,] = this.checkCurrentColors(this.currentDimension);
                break;
        }
        if (this.currentDimension !== 'Errors') {
            this.warehouseConfiguration = {
                ...this.warehouseConfiguration,
                heatMap: {
                    ...this.warehouseConfiguration.heatMap,
                    [this.currentDimension]: {
                        ranges: [...(this.warehouseConfiguration.heatMap[this.currentDimension]?.ranges || [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE])],
                        colors: [this.firstColorRange, this.secondColorRange, this.thirdColorRange],
                    },
                },
            };
        } else {
            if (this.warehouseConfiguration.heatMap.Errors?.colors) {
                this.warehouseConfiguration.heatMap.Errors.colors[0] = this.firstColorRange;
                this.warehouseConfiguration.heatMap.Errors.colors[1] = this.secondColorRange;
                this.warehouseConfiguration.heatMap.Errors.colors[2] = this.thirdColorRange;
            } else {
                this.warehouseConfiguration = {
                    ...this.warehouseConfiguration,
                    heatMap: {
                        ...this.warehouseConfiguration.heatMap,
                        Errors: {
                            ...this.warehouseConfiguration.heatMap.Errors,
                            colors: [this.firstColorRange, this.secondColorRange, this.thirdColorRange],
                        },
                    },
                };
            }
        }
        this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
    }
    saveUserConfiguration(afterSave: (any) => void) {
        this.saveConfiguration(this.userEmail, this.userName, this.userConfiguration, afterSave);
    }

    saveWarehouseConfiguration(afterSave: (any) => void) {
        this.saveConfiguration(this.warehouse.warehouse, this.warehouse.warehouse, this.warehouseConfiguration, afterSave);
    }

    saveConfiguration(user: string, name: string, configuration: any, afterSave: (any) => void) {
        this.configurationsService
            .readConfiguration(this.warehouse.hostName, user)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                const newConfiguration = {
                    ...(data || {
                        application: 'Auwa',
                        user,
                        name,
                    }),
                    data: JSON.stringify(configuration),
                };
                /* eslint-disable */
                const configurationObservable = !data
                    ? this.configurationsService.createConfiguration(this.warehouse.hostName, newConfiguration)
                    : this.configurationsService.updateConfiguration(this.warehouse.hostName, newConfiguration);
                /* eslint-enable */
                configurationObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(afterSave);
            });
    }

    //#region COLORS
    colorSelected(color, variable, id /*from,to*/) {
        const variableToSetColor = this.variableColorsTemporal?.find((v) => v.variable === variable).settings?.find((s) => s.id === id);
        if (variableToSetColor) {
            variableToSetColor.color = color;
        }
    }

    selectedNewColor(color) {
        this.newColorSelected = color;
    }

    setFAZLPreferences(items: string[]): string[] {
        const result = [];
        let item = '';
        items.map((e) => {
            const id = e.split('-');
            const warehouseId = id.shift();
            item = setFqnPreferences(
                id[0],
                this.identifiersPreferences.Floor,
                id[1],
                this.identifiersPreferences.Area,
                id[2],
                this.identifiersPreferences.Zone,
                id[3],
                this.identifiersPreferences.Line,
            );
            result.push(item);
            result.sort();
        });
        return result;
    }

    listEquipments(selectedLine: string) {
        this.selectedVariableCompleteFQN = '';
        let [floorId, areaId, zoneId, lineId] = selectedLine.split('-') || [];
        if (floorId && areaId && zoneId && lineId) {
            [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
            //this.selectedVariableCompleteFQN = [areaId, zoneId, floorId, lineId].join('.');
            this.equipmentsService
                .getEquipmentNamesByLine(floorId, areaId, zoneId, lineId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    this.lineEquipments$ = res;
                });
        } else {
            this.lineEquipments$ = [];
        }
    }

    listVariables(selectedLine: string, selectedEquipment: string) {
        const [floorId, areaId, zoneId, lineId] = selectedLine.split('-') || [];
        const [equipmentSystem, equipmentType] = selectedEquipment.split('-') || [];
        if (equipmentSystem && equipmentType) {
            this.equipmentsService
                .getVariablesFromEquipment(floorId, areaId, zoneId, lineId, equipmentSystem, equipmentType)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((variables) => {
                    /* only NUMERIC and BOOLEAN datatypes */
                    this.variable$ = variables
                    ?.filter((numOrBoolVariable) => (numOrBoolVariable.datatype.toLowerCase() === VariableValueType.Numeric.toLowerCase() || numOrBoolVariable.datatype.toLowerCase() === VariableValueType.Boolean.toLowerCase()))
                        ?.map((v) => {
                            return { value: [v.name, v.type].join('-').toUpperCase(), name: v.name, dataType: v.datatype };
                        });
                });
        } else {
            this.variable$ = [];
        }
    }

    updateVariableSettingColor(color, id, variable) {
        if (variable === '') {
            const variableToSetColor = this.newVariableSettingsColor?.find((s) => s.id === id);
            if (variableToSetColor) {
                variableToSetColor.color = color;
            }
        } else {
            const variableToSetColorAndUpdate = this.warehouseConfiguration.variableColors
                .find((v) => v.variable === variable)
                ?.settings?.find((s) => s.id === id);
            if (variableToSetColorAndUpdate) {
                variableToSetColorAndUpdate.color = color;
                this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
            }
        }
    }

    deleteVariableSettingColor(id, variable) {
        /* only id when delete a new color setting */
        /* only variable when delete the entire color setting for a variable */
        /* id and variable when delete an enviroment of color setting */
        if (id !== '' && variable === '') {
            const index = this.newVariableSettingsColor.findIndex((v) => v.id === id);
            if (index !== -1) this.newVariableSettingsColor.splice(index, 1);
        }
        if (id === '' && variable !== '') {
            const index = this.variableColorsTemporal.findIndex((v) => v.variable === variable);
            if (index !== -1) this.variableColorsTemporal.splice(index, 1);
            this.warehouseConfiguration.variableColors = this.variableColorsTemporal;

            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
        if (id !== '' && variable !== '') {
            const variableToUpdate = this.warehouseConfiguration.variableColors.find((v) => v.variable === variable);
            if (variableToUpdate) {
                //variableToUpdate = variableToUpdate.settings?.filter((s) => s.id !== id);
                const index = variableToUpdate.settings.findIndex((s) => s.id === id);
                if (index !== -1) variableToUpdate.settings.splice(index, 1);

                this.warehouseConfiguration.variableColors.find((v) => v.variable === variable).settings = variableToUpdate.settings;

                this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
            }
        }
    }

    addNewEnviroment(variable, txtEnviroment, txtTo, txtVisibility) {
        const enviroment = txtEnviroment.$input.val().toUpperCase().trim();
        const to = txtTo.$input.val().trim();
        const visibility = txtVisibility.$checkbox.prop('checked');

        if (enviroment.length === 0 || to.length === 0) {
            return;
        }
        const newVariable: VariableColorSettings = {
            id: uuid(),
            enviroment,
            //from: '0',
            to: (+to).toString(),
            color: this.newColorSelected,
            visible: visibility,
        };

        if (variable === '') {
            this.newVariableSettingsColor.push(newVariable);
            this.newVariableSettingsColor.sort((a, b) => (+a.to > +b.to ? 1 : -1));
        } else {
            const variableToUpdate = this.warehouseConfiguration.variableColors.find((v) => v.variable === variable);
            if (variableToUpdate) {
                variableToUpdate.settings.push(newVariable);
                this.warehouseConfiguration.variableColors.find((v) => v.variable === variable)?.settings.sort((a, b) => (+a.to > +b.to ? 1 : -1));
                
                this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
                this.editingVariableColorEnviroment = '';
            }
        }
        
        setTimeout(() => {
            const visibilityCheckboxes = document.querySelectorAll('.colors-and-visibility table div.form-check');
            visibilityCheckboxes.forEach(checkbox => checkbox.setAttribute('style', 'padding-left:0px'));
        }, 0);

        setTimeout(() => {
            const visibilityCheckboxes = document.querySelectorAll('.colors-and-visibility table div.form-check');
            visibilityCheckboxes.forEach(checkbox => checkbox.setAttribute('style', 'padding-left:0px'));
        }, 0);
        

        txtEnviroment.$input.val('');
        txtTo.$input.val('');

        this.newColorSelected = INITIAL_COLOR_SELECTED;
    }

    saveNewVariableSettingColor() {
        const existe = this.warehouseConfiguration?.variableColors?.findIndex((v) => v.variable === this.selectedVariableCompleteFQN);

        if (existe && existe !== -1) {
            this.variableColorAlreadyExists = `Already exists a Color setting form variable ${this.selectedVariableCompleteFQN}.`;
            return;
        }

        if (this.applyForAllVariablesCheck.$checkbox.prop('checked') && !this.confirmationApplyToAllDone) {
            this.dangerMode = false;
            this.confirmationMessage = {
                messageId: 1,
                parameters: null,
                text: `This configuration will apply to <strong>ALL</strong> variables with name <strong>${this.selectedVariable?.split('-')[0]}</strong>.`,
            };
            this.confirmationModal.openModal();
            return;
        }
        this.confirmationApplyToAllDone = false;

        const newVariableSettingColorToAdd: VariableColors = {
            variable: this.selectedVariableCompleteFQN, //selectedVariable,
            settings: this.newVariableSettingsColor,
            dataType: this.selectedVariableDataType
        };

        this.variableColorsTemporal.push(newVariableSettingColorToAdd);

        this.variableColorsTemporal.sort((a, b) => (a.variable > b.variable ? 1 : -1));

        this.warehouseConfiguration.variableColors = this.variableColorsTemporal;
        this.newVariableSettingsColor = [];

        this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
    }

    editingValue(setting, txtControl, enviromentOrTo) {
        if (setting.enviroment.toUpperCase() !== INITIAL_ENVIROMENT.toUpperCase()) {
            const value = enviromentOrTo === 'to' ? setting.to : setting.enviroment;

            txtControl.$input.val(value);
            txtControl.$input.trigger('blur');

            if (enviromentOrTo === 'enviroment') {
                this.editingVariableColorEnviroment = setting.id;
            }
            if (enviromentOrTo === 'to') {
                this.editingVariableColorTo = setting.id;
            }
        }
    }

    saveValue(id, variable, txtControl, enviromentOrTo) {
        let newValue;
        if (enviromentOrTo === 'enviroment') {
            newValue = txtControl.$input.val().toUpperCase() || '';
            if (!newValue) {
                return;
            }
        } else if (enviromentOrTo === 'to') {
            newValue = txtControl.$input.val() || 0;
        }

        const variableToUpdate = this.warehouseConfiguration.variableColors.find((v) => v.variable === variable)?.settings?.find((s) => s.id === id);
        if (variableToUpdate) {
            if (enviromentOrTo === 'enviroment') {
                variableToUpdate.enviroment = newValue;
                this.editingVariableColorEnviroment = '';
            }
            if (enviromentOrTo === 'to') {
                variableToUpdate.to = +newValue;
                this.editingVariableColorTo = '';

                this.warehouseConfiguration.variableColors.find((v) => v.variable === variable)?.settings.sort((a, b) => (+a.to > +b.to ? 1 : -1));
            }
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }

    trackById(index: number, element: any) {
        return element?.id;
    }
    trackByVariable(index: number, element: any) {
        return element?.variable;
    }

    clickChkApplyForAll() {
        this.configureSelectedVariableCompleteFQN();
    }

    clickChkSettingVariablesVisibility(variable, id, visibility) {
        const variableToUpdate = this.warehouseConfiguration.variableColors.find((v) => v.variable === variable)?.settings?.find((s) => s.id === id);
        if (variableToUpdate) {
            this.warehouseConfiguration.variableColors.find((v) => v.variable === variable).settings.find((s) => s.id === id).visible = !visibility;
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }

    clickChkSettingBooleanTypeVisibility(element, id) {
        const elementToUpdate = this.newVariableSettingsColor.find(setting => setting.id === id);
        elementToUpdate.visible = element.$checkbox.prop('checked');
    }

    configureSelectedVariableCompleteFQN() {
        this.variableColorAlreadyExists = '';

        let [floorId, areaId, zoneId, lineId] = this.selectedLine.split('-') || [];
        [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
        const [equipmentSystem, equipmentType] = this.selectedEquipment.split('-') || [];
        const [variable, type] = this.selectedVariable.split('-');

        if (this.applyForAllVariablesCheck.$checkbox.prop('checked')) {
            const myDefaultChar = WILD_CARD_CHARACTER;
            this.selectedVariableCompleteFQN = [...myDefaultChar.repeat(7).split(''), variable].join('.');
        } else {
            this.selectedVariableCompleteFQN = [areaId, zoneId, floorId, lineId, equipmentSystem, equipmentType, type, variable].join('.');
        }
    }

    confirmationEmited(value) {
        if (value) {
            switch (this.confirmationMessage.messageId) {
                case 1: {
                    this.confirmationApplyToAllDone = true;
                    this.saveNewVariableSettingColor();
                    break;
                }
                case 2: {
                    const deleteId = this.confirmationMessage?.parameters['id'];
                    this.deleteVariableSettingColor(deleteId, '');
                    break;
                }
                case 3: {
                    const deleteId = this.confirmationMessage?.parameters['id'];
                    const deleteVariable = this.confirmationMessage?.parameters['variable'];
                    this.deleteVariableSettingColor(deleteId, deleteVariable);
                    break;
                }
                case 4: {
                    const deleteVariable = this.confirmationMessage?.parameters['variable'];
                    this.deleteVariableSettingColor('', deleteVariable);
                    break;
                }
                //Allocated case to alert colors settings
                case 5: {
                    const deleteZone = this.confirmationMessage?.parameters['zone'];
                    this.deleteZoneColor(deleteZone);
                    break;
                }
                case 6: {
                    const deleteScanner = this.confirmationMessage?.parameters['variable'];
                    this.deleteScannerColor(deleteScanner);
                    break;
                }
                default: {
                }
            }
        }
        this.confirmationMessage = { messageId: 0, parameters: null, text: '' };
        this.confirmationModal.closeModal();
    }

    onClickAddNewVariableColor() {
        this.newVariableColorModal.openModal();
    }

    deleteConfirmation(messageId: number, id: string, variable: string, enviroment: string) {
        this.dangerMode = true;
        switch (messageId) {
            case 2: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { id },
                    text: `Do you want to delete the <strong>${enviroment}</strong> enviroment?`,
                };
                break;
            }
            case 3: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { id, variable },
                    text: `Do you want to delete the <strong>${enviroment}</strong> enviroment from the <strong>${variable
                        .split('.')
                        .pop()}</strong> variable?`,
                };
                break;
            }
            case 4: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { variable },
                    text: `Do you want to delete the <strong>${variable.split('.').pop()}</strong> color configuration?`,
                };
                break;
            }
            case 5: {
                this.confirmationMessage = {
                    messageId: 5,
                    parameters: { zone: variable },
                    text: `Do you want to delete the zone <strong>${variable}</strong> color configuration?`,
                };
                break;
            }
            case 6: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { variable },
                    text: `Do you want to delete the scanner <strong>${variable}</strong> color configuration?`,
                };
                break;
            }
        }
        this.confirmationModal.openModal();
    }
    //#endregion
    //#region ZoneColor
    selectedNewColorZone(color) {
        this.newColorSelectedZone = color;
    }
    addNewZone() {
        if (!this.selectedZone || this.selectedZone === 'Select zone') {
            return;
        }
        const elementAlreadyExists = this.alertsZonesTemporal.find((element) => element.zone === this.selectedZone);
        if (!elementAlreadyExists) {
            const newZone = {
                zone: this.selectedZone,
                color: this.newColorSelectedZone,
            };
            this.alertsZonesTemporal.push(newZone);
            this.alertsZonesTemporal.sort();
            this.warehouseConfiguration.alertsZonesColors = [...this.alertsZonesTemporal];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
        this.settingsFAZ.val('', true);
        this.newColorSelectedZone = INITIAL_COLOR_SELECTED;
    }

    updateZoneColor(newColor: string, zone: string) {
        const zoneToUpdate = this.warehouseConfiguration?.alertsZonesColors.find((element) => element.zone === zone);
        zoneToUpdate.color = newColor;
        this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
    }

    deleteZoneColor(zone: string) {
        this.alertsZonesTemporal = this.alertsZonesTemporal.filter((element) => element.zone !== zone);
        this.warehouseConfiguration.alertsZonesColors = [...this.alertsZonesTemporal];
        this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
    }
    //#endregion

    //#region ScannerColor
    addNewScanner() {
        if (!this.selectedScanner) return;

        const elementAlreadyExists = this.scannerTrendTemporal.find((element) => element.scanner === this.selectedScanner);
        if (!elementAlreadyExists) {
            const newScanner: ScannerTrendColors = {
                scanner: `${this.selectedScanner}`,
                ...this.scannerRangeColorsTemporal,
            };
            this.scannerTrendTemporal.push(newScanner);
            this.scannerTrendTemporal.sort();
            this.warehouseConfiguration.scannerTrendColors = [...this.scannerTrendTemporal];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));

            this.$scannersSelector.val('').trigger('change');
        }
    }

    deleteScannerColor(scanner: string) {
        this.scannerTrendTemporal = this.scannerTrendTemporal.filter((element) => element.scanner !== scanner);
        this.warehouseConfiguration.scannerTrendColors = [...this.scannerTrendTemporal];
        this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
    }

    initializeScannerRangeColors() {
        this.scannerRangeColorsTemporal = {
            firstColorRange: FIRST_SCANNER_DEFAULT_COLOR,
            secondColorRange: SECOND_SCANNER_DEFAULT_COLOR,
            thirdColorRange: THIRD_SCANNER_DEFAULT_COLOR,
            firstRange: MIN_DEFAULT_RANGE,
            secondRange: MAX_DEFAULT_RANGE,
        };

        if (!this.scannerTrendTemporal.find((element) => element.scanner === INITIAL_ENVIROMENT)) {
            const defaultScanner: ScannerTrendColors = {
                scanner: INITIAL_ENVIROMENT,
                ...this.scannerRangeColorsTemporal,
            };
            this.scannerTrendTemporal.push(defaultScanner);
            this.warehouseConfiguration.scannerTrendColors = [...this.scannerTrendTemporal];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }

    saveColorPickerScanner(event, scanner: string = '') {
        //event[0] = empty
        //event[1] = string firstColorRange | secondColorRange | thirdColorRange
        //event[2] = color value
        if (scanner === '') {
            this.scannerRangeColorsTemporal[event[1]] = event[2];
        } else {
            const scannerToUpdate = this.warehouseConfiguration?.scannerTrendColors.find((element) => element.scanner === scanner);
            scannerToUpdate[event[1]] = event[2];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }

    calculateNewRangesScanner(event, scanner: string = '') {
        //event[0] = empty
        //event[1] = {"minVal":number,"maxVal":number}
        if (scanner === '') {
            this.scannerRangeColorsTemporal['firstRange'] = event[1].minVal || MIN_DEFAULT_RANGE;
            this.scannerRangeColorsTemporal['secondRange'] = event[1].maxVal || MAX_DEFAULT_RANGE;
        } else {
            const scannerToUpdate = this.warehouseConfiguration?.scannerTrendColors.find((element) => element.scanner === scanner);
            scannerToUpdate['firstRange'] = event[1].minVal || scannerToUpdate['firstRange'];
            scannerToUpdate['secondRange'] = event[1].maxVal || scannerToUpdate['secondRange'];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }
    //#endregion

    //#region ErrorTrendColor
    initializeErrorRangeColors() {
        this.errorRangeColorsTemporal = {
            firstColorRange: FIRST_ERROR_DEFAULT_COLOR,
            secondColorRange: SECOND_ERROR_DEFAULT_COLOR,
            thirdColorRange: THIRD_ERROR_DEFAULT_COLOR,
            firstRange: MIN_DEFAULT_RANGE,
            secondRange: MAX_DEFAULT_RANGE,
        };

        if (!this.errorTrendTemporal.find((element) => element.fqn === INITIAL_ENVIROMENT)) {
            const defaultError: ErrorTrendColors = {
                fqn: INITIAL_ENVIROMENT,
                ...this.errorRangeColorsTemporal,
            };
            this.errorTrendTemporal.push(defaultError);
            this.warehouseConfiguration.errorTrendColors = [...this.errorTrendTemporal];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }

    saveColorPickerErrors(event, fqn: string = '') {
        if (fqn === '') {
            this.errorRangeColorsTemporal[event[1]] = event[2];
        } else {
            const errorToUpdate = this.warehouseConfiguration?.errorTrendColors.find((element) => element.fqn === fqn);
            errorToUpdate[event[1]] = event[2];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }

    calculateNewRangesErrors(event, fqn: string = '') {
        //event[0] = empty
        //event[1] = {"minVal":number,"maxVal":number}
        if (fqn === '') {
            this.errorRangeColorsTemporal['firstRange'] = event[1].minVal || MIN_DEFAULT_RANGE;
            this.errorRangeColorsTemporal['secondRange'] = event[1].maxVal || MAX_DEFAULT_RANGE;
        } else {
            const errorToUpdate = this.warehouseConfiguration?.errorTrendColors.find((element) => element.fqn === fqn);
            errorToUpdate['firstRange'] = event[1].minVal || errorToUpdate['firstRange'];
            errorToUpdate['secondRange'] = event[1].maxVal || errorToUpdate['secondRange'];
            this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
        }
    }
    //#endregion

    // //#region boxDetectedColor
    updateBoxDetectedColor(e) {
        try {
            if (!e.userConfiguration) {
                if (e.lightMode) {
                    this.warehouseConfigurationBoxDetectedColors.lightMode = e.newColor
                } else {
                    this.warehouseConfigurationBoxDetectedColors.darkMode = e.newColor
                }
                this.warehouseConfiguration.boxDetectedColors = this.warehouseConfigurationBoxDetectedColors
                this.colorsService.setColor('status-boxes', e.newColor);

                this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
            } else {
                if (e.lightMode) {
                    this.userConfigurationBoxDetectedColors.lightMode = e.newColor
                } else {
                    this.userConfigurationBoxDetectedColors.darkMode = e.newColor
                }
                this.userConfiguration.boxDetectedColors = this.userConfigurationBoxDetectedColors
                this.colorsService.setColor('status-boxes', e.newColor);

                this.saveUserConfiguration(() => this.appLoadService.setCurrentConfiguration(this.userConfiguration));
            }
        } catch (error) {
            console.log(`%c error => ${error}`, `background: red; color: gold`);
        }

    }
    // //#endregion
}

<div class="form-group">
    <label>{{ label }}</label>
    <ng-select
        id="{{ key }}"
        [items]="item$"
        [virtualScroll]="true"
        [bindLabel]="label"
        [placeholder]="placeholder"
        [searchable]="searchable"
        [clearable]="!disabled"
        [dropdownPosition]="'bottom'"
        class="ays"
        (change)="onChange($event)"
        (search)="onSearch($event)"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
    >
        <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ item }}
        </ng-template>
    </ng-select>
</div>

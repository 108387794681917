<app-floors-map
    [warehouse]="warehouse.warehouse"
    [floor]="view.content"
    *ngIf="view.mode === 'Floor'"
    type="Lines"
    mode="floor"
    currentMode="Lines"
    [visibleStates]="visibleStates"
    [lineChange$]="lineChange$"
    [tabState]="tabState"
    (clickArea)="clickItem($event, 'Area')"
></app-floors-map>
<app-areas-map
    [alternativeId]="view.fqdn"
    [map]="view.content"
    [tabState]="tabState"
    *ngIf="view.mode === 'Area'"
    [lineChange$]="lineChange$"
    [visibleStates]="visibleStates"
    (clickZone)="clickItem($event, 'Zone')"
    >Area ></app-areas-map
>
<app-zones-map
    [warehouse]="warehouse.warehouse"
    [map]="view.content"
    [alternativeIds]="view.fqdn"
    [tabState]="tabState"
    *ngIf="view.mode === 'Zone'"
    [lineChange$]="lineChange$"
    [visibleStates]="visibleStates"
    (clickLine)="clickItem($event, 'Line')"
></app-zones-map>
<!--app-lines-map
    [warehouse]="warehouse.warehouse"
    [line]="view.content"
    [icons]="icons"
    [alternativeIds]="view.fqdn"
    [tabState]="tabState"
    [lineChange$]="lineChange$"
    *ngIf="view.mode === 'Line'"
    [visibleStates]="visibleStates"
    (displayEquipmentVariables)="clickItem($event, 'Equipment')"
></app-lines-map-->

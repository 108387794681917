import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { FloorsComponent } from '@floors/components/floors.component';
import { AreasComponent } from '@areas/components/areas.component';
import { ZonesComponent } from '@zones/components/zones.component';
import { LinesComponent } from '@lines/components/lines.component';
import { FloorsResolver } from '@floors/shared/floors.resolver';
import { PowerBiComponent } from './powerbi/powerbi.component';
import { LandingComponent } from './landing/landing.component';
import { SettingsComponent } from './shared/components/settings/settings.component';
import { LinesResolver } from '@lines/shared/lines.resolver';
import { ZonesResolver } from '@zones/shared/zones.resolver';
import { AreasResolver } from '@areas/shared/areas.resolver';
import { PowerBiResolver } from './powerbi/powerbi.resolver';
import { HomeResolver } from '@home/shared/home.resolver';
import { HomeComponent } from '@home/home.component';

const routes: Routes = [
    {
        path: 'landing',
        component: LandingComponent,
    },
    {
        path: 'warehouse/:id',
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                runGuardsAndResolvers: 'always',
                component: HomeComponent,
                resolve: { home: HomeResolver },
            },
            {
                path: 'floor/:floorId',
                children: [
                    {
                        path: '',
                        runGuardsAndResolvers: 'always',
                        component: FloorsComponent,
                        resolve: { floor: FloorsResolver },
                    },
                    {
                        path: 'area/:areaId',
                        children: [
                            {
                                path: '',
                                runGuardsAndResolvers: 'always',
                                component: AreasComponent,
                                resolve: { area: AreasResolver },
                            },
                            {
                                path: 'zone/:zoneId',
                                children: [
                                    {
                                        path: '',
                                        runGuardsAndResolvers: 'always',
                                        component: ZonesComponent,
                                        resolve: { zone: ZonesResolver },
                                    },
                                    {
                                        path: 'line/:lineId',
                                        children: [
                                            {
                                                path: '',
                                                runGuardsAndResolvers: 'always',
                                                component: LinesComponent,
                                                resolve: { line: LinesResolver },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    // lazy loading - goes here to avoid dependency loops
    {
        path: 'warehouse/:id/alerts',
        loadChildren: () => import('./alerts/alerts.module').then((m) => m.AlertsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'warehouse/:id/warnings',
        loadChildren: () => import('./warnings/warnings.module').then((m) => m.WarningsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'warehouse/:id/connections',
        loadChildren: () => import('./connections/connections.module').then((m) => m.ConnectionsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'warehouse/:id/reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'warehouse/:id/variables',
        loadChildren: () => import('./variables/variables.module').then((m) => m.VariablesModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'powerbi',
        runGuardsAndResolvers: 'always',
        component: PowerBiComponent,
        resolve: { powerbi: PowerBiResolver },
    },
    {
        path: 'settings',
        runGuardsAndResolvers: 'always',
        component: SettingsComponent,
    },
    // generic
    { path: '404', component: NotFoundComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', initialNavigation: 'disabled' })],
    exports: [RouterModule],
})
export class AppRoutingFrontModule {}

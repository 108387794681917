<div class="row mt-3">
    <div class="col-12 col-md-6">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a
                    class="nav-link active"
                    id="user-tab"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#user-tab-pane"
                    aria-controls="user-tab-pane"
                    aria-selected="true"
                >
                    User
                </a>
            </li>
            <li class="nav-item d-none d-lg-block" role="presentation">
                <a
                    class="nav-link"
                    id="warehouse-tab"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#warehouse-tab-pane"
                    aria-controls="warehouse-tab-pane"
                    aria-selected="false"
                >
                    Warehouse
                </a>
            </li>
        </ul>
    </div>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-3" id="user-tab-pane" role="tabpanel" style="margin: 0 auto" aria-labelledby="user-tab">
            <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-12 col-md-8 col-lg-6 mt-3 p-3 settings-box">
                    <div class="row mt-4 mb-3 justify-content-center">
                        <div class="col-md-8 col-lg-6">
                            <h5>MODE</h5>
                            <div class="col-12 col-md-8 col-lg-6 mt-3">
                                <div (click)="toggleDarkMode($event)">
                                    <app-toggle leftLabel="LIGHT" rightLabel="DARK" [checked]="darkMode"></app-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row mt-4 mb-3 justify-content-center">
                        <div class="col-md-8 col-lg-6">
                            <h5>VIEW</h5>
                            <div class="col-12 col-md-8 col-lg-6 mt-3">
                                <div (click)="toggleViewMode($event)">
                                    <app-toggle leftLabel="MAP" rightLabel="LITE" [checked]="liteView"></app-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row mt-4 mb-3 justify-content-center">
                        <div class="col-12 col-md-8 col-lg-6">
                            <h5>IDENTIFIERS</h5>
                            <div class="row mt-3">
                                <app-checkbox
                                    [key]="'Floor'"
                                    #floorCheck
                                    [label]="'Floor'"
                                    columns="3"
                                    extraClasses="ps-0 pe-0"
                                    (clicked)="handleCheckBoxes('Floor')"
                                ></app-checkbox>
                                <app-checkbox
                                    [key]="'Area'"
                                    #areaCheck
                                    [label]="'Area'"
                                    columns="3"
                                    extraClasses="ps-0"
                                    (clicked)="handleCheckBoxes('Area')"
                                ></app-checkbox>
                            </div>
                            <div class="row mt-3">
                                <app-checkbox
                                    [key]="'Zone'"
                                    #zoneCheck
                                    [label]="'Zone'"
                                    columns="3"
                                    extraClasses="ps-0 pe-0"
                                    (clicked)="handleCheckBoxes('Zone')"
                                ></app-checkbox>
                                <app-checkbox
                                    [key]="'Line'"
                                    #lineCheck
                                    [label]="'Line'"
                                    columns="3"
                                    extraClasses="ps-0"
                                    (clicked)="handleCheckBoxes('Line')"
                                ></app-checkbox>
                            </div>
                            <div class="mt-3"><strong>Preview</strong></div>
                            <div>
                                <span [ngClass]="{ 'coloring-text': isColoringTextFloor }">{{
                                    floorIdentifierValue | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []]
                                }}</span>
                                -
                                <span [ngClass]="{ 'coloring-text': isColoringTextArea }">{{
                                    areaIdentifierValue | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []]
                                }}</span>
                                -
                                <span [ngClass]="{ 'coloring-text': isColoringTextZone }">{{
                                    zoneIdentifierValue | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []]
                                }}</span>
                                -
                                <span [ngClass]="{ 'coloring-text': isColoringTextLine }">{{
                                    lineIdentifierValue | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]]
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <settings-boxdetected
                    [boxDetectedColors]="userConfigurationBoxDetectedColors"
                    [parentBoxDetectedColors]="warehouseConfigurationBoxDetectedColors"
                    [userConfiguration]="true"
                    (newColor)="updateBoxDetectedColor($event)"
                ></settings-boxdetected>
            </div>
        </div>
        <div class="tab-pane fade p-3" id="warehouse-tab-pane" role="tabpanel" style="margin: 0 auto" aria-labelledby="warehouse-tab">
            <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
                    <h5 class="text-start ms-5 mt-4">HEAT MAP</h5>
                    <div class="mt-3 mb-3">
                        <shared-config-color-picker
                            [dimension]="'Running'"
                            [firstRange]="this.currentRunningHeatMapConfiguration.ranges[0]"
                            [secondRange]="this.currentRunningHeatMapConfiguration.ranges[1]"
                            [firstColorRange]="this.currentRunningHeatMapConfiguration.colors[0]"
                            [secondColorRange]="this.currentRunningHeatMapConfiguration.colors[1]"
                            [thirdColorRange]="this.currentRunningHeatMapConfiguration.colors[2]"
                            (colorSelected)="saveColorPicker($event)"
                            (applyRanges)="calculateNewRanges($event)"
                        ></shared-config-color-picker>
                    </div>
                    <div class="mt-3 mb-3">
                        <shared-config-color-picker
                            [dimension]="'Stopped'"
                            [firstRange]="this.currentStoppedHeatMapConfiguration.ranges[0]"
                            [secondRange]="this.currentStoppedHeatMapConfiguration.ranges[1]"
                            [firstColorRange]="this.currentStoppedHeatMapConfiguration.colors[0]"
                            [secondColorRange]="this.currentStoppedHeatMapConfiguration.colors[1]"
                            [thirdColorRange]="this.currentStoppedHeatMapConfiguration.colors[2]"
                            (colorSelected)="saveColorPicker($event)"
                            (applyRanges)="calculateNewRanges($event)"
                        ></shared-config-color-picker>
                    </div>
                    <div class="mt-3 mb-3">
                        <shared-config-color-picker
                            [dimension]="'On Error'"
                            [firstRange]="this.currentOnErrorHeatMapConfiguration.ranges[0]"
                            [secondRange]="this.currentOnErrorHeatMapConfiguration.ranges[1]"
                            [firstColorRange]="this.currentOnErrorHeatMapConfiguration.colors[0]"
                            [secondColorRange]="this.currentOnErrorHeatMapConfiguration.colors[1]"
                            [thirdColorRange]="this.currentOnErrorHeatMapConfiguration.colors[2]"
                            (colorSelected)="saveColorPicker($event)"
                            (applyRanges)="calculateNewRanges($event)"
                        ></shared-config-color-picker>
                    </div>
                    <div class="mt-3 mb-3">
                        <shared-config-color-picker
                            [dimension]="'Errors'"
                            [firstRange]="this.currentErrorsHeatMapConfiguration.ranges[0]"
                            [errorsMiddleRange]="this.currentErrorsHeatMapConfiguration.ranges[1]"
                            [secondRange]="this.currentErrorsHeatMapConfiguration.ranges[2]"
                            [firstColorRange]="this.currentErrorsHeatMapConfiguration.colors[0]"
                            [secondColorRange]="this.currentErrorsHeatMapConfiguration.colors[1]"
                            [thirdColorRange]="this.currentErrorsHeatMapConfiguration.colors[2]"
                            [firstName]="this.currentErrorsHeatMapConfiguration.names[0]"
                            [secondName]="this.currentErrorsHeatMapConfiguration.names[1]"
                            [thirdName]="this.currentErrorsHeatMapConfiguration.names[2]"
                            (colorSelected)="saveColorPicker($event)"
                            (applyRanges)="calculateNewRanges($event)"
                            (updatedErrorsFromValue)="saveErrorsFromValue($event)"
                            (updatedErrorsNameValue)="saveErrorsNameValue($event)"
                        ></shared-config-color-picker>
                    </div>
                    <div class="mt-3 mb-3">
                        <shared-config-color-picker
                            [dimension]="'Availability'"
                            [firstRange]="this.currentAvailabilityHeatMapConfiguration.ranges[0]"
                            [secondRange]="this.currentAvailabilityHeatMapConfiguration.ranges[1]"
                            [firstColorRange]="this.currentAvailabilityHeatMapConfiguration.colors[0]"
                            [secondColorRange]="this.currentAvailabilityHeatMapConfiguration.colors[1]"
                            [thirdColorRange]="this.currentAvailabilityHeatMapConfiguration.colors[2]"
                            (colorSelected)="saveColorPicker($event)"
                            (applyRanges)="calculateNewRanges($event)"
                        ></shared-config-color-picker>
                    </div>
                    <div class="mt-3">
                        <shared-config-color-picker
                            [dimension]="'LWDO'"
                            [firstRange]="this.currentLWDOHeatMapConfiguration.ranges[0]"
                            [secondRange]="this.currentLWDOHeatMapConfiguration.ranges[1]"
                            [firstColorRange]="this.currentLWDOHeatMapConfiguration.colors[0]"
                            [secondColorRange]="this.currentLWDOHeatMapConfiguration.colors[1]"
                            [thirdColorRange]="this.currentLWDOHeatMapConfiguration.colors[2]"
                            (colorSelected)="saveColorPicker($event)"
                            (applyRanges)="calculateNewRanges($event)"
                        ></shared-config-color-picker>
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box colors-and-visibility">
                    <div class="row mt-4 mb-5">
                        <div class="col-7 d-flex">
                            <h5 class="text-start ms-5 me-3 mt-2">VARIABLE'S COLORS AND VISIBILITY</h5>
                            <button
                                class="btn btn-auwa px-4 ms-auto"
                                title="Add new Variable Color Setting"
                                data-bs-toggle="tooltip"
                                (click)="onClickAddNewVariableColor()"
                            >
                                Add
                            </button>
                        </div>
                        <div class="col-4 ms-auto me-5">
                            <app-search
                                #variableFilter
                                key="txtFindVariableColorConfiguration"
                                placeholder="Filter Variables"
                                class=""
                                icon="fas fa-search"
                                (searchValueDeleted)="variableFilterValue = ''"
                            >
                            </app-search>
                        </div>
                    </div>
                    <div class="container">
                        <div
                            class="row ms-5 mt-2 mb-2"
                            *ngFor="let variableColor of variableColorsTemporal; index as i; trackBy: trackByVariable"
                            [hidden]="variableFilterValue !== '' && !variableColor.variable.includes(variableFilterValue)"
                        >
                            <div class="col-12 fw-bold force-one-line">
                                {{ variableColor.variable.split('.').pop() }}
                                <button
                                    class="float-end btn btn-sm btn-danger me-5 d-inline-block"
                                    (click)="deleteConfirmation(4, '', variableColor.variable, '')"
                                    title="Delete Variable Color Setting"
                                    data-bs-toggle="tooltip"
                                >
                                    <i class="fas fa-times-circle"></i>
                                </button>
                                <a
                                    class="float-end btn btn-sm btn-light fw-bold me-5 py-1 d-inline-block"
                                    data-bs-toggle="collapse"
                                    href="#collapseTable{{ i }}"
                                    role="button"
                                    aria-expanded="false"
                                    attr.aria-controls="collapseTable{{ i }}"
                                    title="View/Edit Variable Color Setting"
                                >
                                    {{ variableColor.settings?.length }} ITEMS
                                </a>
                                <div style="overflow-x: auto">
                                    <p class="small fw-normal">
                                        {{ variableColor.variable.split('.').slice(0, -1).join('.') }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 ms-5 mt-0 collapse" [id]="'collapseTable' + i">
                                <table class="table-compact table-hover w-75 mt-1 mb-5">
                                    <thead>
                                        <tr>
                                            <th class="w-25">Environment</th>
                                            <th class="w-25 text-end">{{ variableColor?.dataType === variableNumericType ? 'To' : 'Value' }}</th>
                                            <th class="w-25 text-center">Color</th>
                                            <th class="w-25 text-center">Visibility (icons only)</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let setting of variableColor.settings; index as i; trackBy: trackById">
                                            <td>
                                                <div style="overflow-x: auto" [ngStyle]="{'cursor': variableColor?.dataType !== variableNumericType || i === 0 ? 'auto': 'pointer'}">
                                                    <span
                                                        (dblclick)="editingValue(setting, txtNewEnviroment, 'enviroment')"
                                                        [hidden]="editingVariableColorEnviroment === setting.id"
                                                        >
                                                        {{ setting.enviroment }}
                                                        <span *ngIf="variableColor?.dataType !== variableNumericType">
                                                            {{ setting.to === '1' ? ' - TRUE' : ' - FALSE' }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div [hidden]="editingVariableColorEnviroment !== setting.id">
                                                    <app-input
                                                        #txtNewEnviroment
                                                        key="txtNewEnviroment{{ setting.id }}"
                                                        label="Environment"
                                                        extraClasses="w-100"
                                                    ></app-input>
                                                    <button
                                                        class="btn btn-sm btn-editor float-end ms-1"
                                                        (click)="saveValue(setting.id, variableColor.variable, txtNewEnviroment, 'enviroment')"
                                                        title="SAVE"
                                                    >
                                                        <i class="fa fa-save" aria-hidden="true"></i>
                                                    </button>
                                                    <button
                                                        class="btn btn-sm btn-editor float-end ms-1"
                                                        (click)="editingVariableColorEnviroment = ''"
                                                        title="CANCEL"
                                                    >
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td class="text-end">
                                                <span 
                                                    (dblclick)="editingValue(setting, txtNewTo, 'to')" 
                                                    [hidden]="editingVariableColorTo === setting.id"
                                                    [ngStyle]="{'cursor': variableColor?.dataType !== variableNumericType ? 'auto': 'pointer'}"
                                                >
                                                    {{ setting.to }}
                                                </span>
                                                <div [hidden]="editingVariableColorTo !== setting.id">
                                                    <app-input
                                                        #txtNewTo
                                                        key="txtNewTo{{ setting.id }}"
                                                        label="To"
                                                        type="number"
                                                        withoutArrows="true"
                                                        extraClasses="w-100 float-end text-end"
                                                    ></app-input>

                                                    <button
                                                        class="btn btn-sm btn-editor float-end ms-1"
                                                        (click)="saveValue(setting.id, variableColor.variable, txtNewTo, 'to')"
                                                        title="SAVE"
                                                    >
                                                        <i class="fa fa-save" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-editor float-end ms-1" (click)="editingVariableColorTo = ''" title="CANCEL">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <shared-color-picker
                                                    class="level-2 badge me-1 w-50 py-2 my-1"
                                                    [colorRange]="setting.color"
                                                    [fromDataRange]="''"
                                                    [toDataRange]="''"
                                                    [symbol]="''"
                                                    [middle]="setting.color"
                                                    okButtonText="SELECT"
                                                    (colorSelected)="updateVariableSettingColor($event, setting.id, variableColor.variable)"
                                                    [ngStyle]="{ 'background-color': setting.color, color: setting.color | textColor }"
                                                ></shared-color-picker>
                                            </td>
                                            <td class="text-center">
                                                <app-checkbox
                                                    [key]="setting.id"
                                                    columns="12"
                                                    [checked]="setting.visible?? true"
                                                    (clicked)="clickChkSettingVariablesVisibility(variableColor.variable,setting.id, setting.visible === undefined? true : setting.visible)"
                                                ></app-checkbox>
                                            </td>
                                            <td class="text-center">
                                                <button
                                                    class="btn btn-sm btn-danger my-1"
                                                    (click)="deleteConfirmation(3, setting.id, variableColor.variable, setting.enviroment)"
                                                    [hidden]="setting.enviroment === initialEnviroment"
                                                >
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="variableColor?.dataType === variableNumericType">
                                            <td>
                                                <app-input
                                                    #txtVariableSettingColorEnviromentWithVariable
                                                    [key]="'txtVariableSettingColorEnviroment' + variableColor.variable"
                                                    label="Environment"
                                                ></app-input>
                                            </td>
                                            <td>
                                                <app-input
                                                    #txtVariableSettingColorToWithVariable
                                                    [key]="'txtVariableSettingColorTo' + variableColor.variable"
                                                    label="To"
                                                    type="number"
                                                    withoutArrows="true"
                                                    extraClasses="w-75 float-end"
                                                ></app-input>
                                            </td>
                                            <td class="text-center">
                                                <shared-color-picker
                                                    class="level-2 badge me-1 w-50 py-2"
                                                    [colorRange]="newColorSelected"
                                                    [fromDataRange]="''"
                                                    [toDataRange]="''"
                                                    [symbol]="''"
                                                    [middle]="newColorSelected"
                                                    okButtonText="SELECT"
                                                    (colorSelected)="selectedNewColor($event)"
                                                    [ngStyle]="{ 'background-color': newColorSelected, color: newColorSelected | textColor }"
                                                ></shared-color-picker>
                                            </td>
                                            <td class="text-center">
                                                <app-checkbox
                                                    #txtVariableSettingVisibilityWithVariable
                                                    [key]="'txtVariableSettingVisibility' + variableColor.variable"
                                                    columns="12"
                                                    checked="true"
                                                ></app-checkbox>
                                            </td>
                                            <td class="text-center">
                                                <button
                                                    class="btn btn-sm btn-primary my-1"
                                                    (click)="
                                                        addNewEnviroment(
                                                            variableColor.variable,
                                                            txtVariableSettingColorEnviromentWithVariable,
                                                            txtVariableSettingColorToWithVariable,
                                                            txtVariableSettingVisibilityWithVariable
                                                        )
                                                    "
                                                >
                                                    <i class="fas fa-check" title="Add environment color/visibility"></i>
                                                </button>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
                    <div class="row mt-4 mb-5">
                        <div class="col-12 col-md-6 col-lg-7">
                            <h5 class="float-start text-start ms-5 me-3 mt-2">ALERTS/WARNINGS TABLE - ZONE COLORS</h5>
                        </div>
                        <div class="col-6 offset-3 col-md-6 col-lg-3 offset-md-0">
                            <app-search
                                #alertsZonesFilter
                                key="txtFindAlertsZonesConfiguration"
                                placeholder="Filter Zones"
                                class=""
                                icon="fas fa-search"
                                (searchValueDeleted)="alertsZonesFilterValue = ''"
                            >
                            </app-search>
                        </div>
                        <div class="container">
                            <div class="row ms-md-5 mt-2 mb-2">
                                <div class="col-12 ms-5 mt-0">
                                    <table class="table-compact table-hover w-75 mt-1 mb-5">
                                        <thead>
                                            <tr>
                                                <th class="w-50">Zone</th>
                                                <th class="text-center w-25">Color</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let zoneColor of alertsZonesTemporal"
                                                [hidden]="alertsZonesFilterValue !== '' && !zoneColor.zone.includes(alertsZonesFilterValue)"
                                                style="cursor: auto"
                                            >
                                                <td>
                                                    <span class="force-one-line">
                                                        {{ zoneColor.zone }}
                                                    </span>
                                                </td>
                                                <td class="text-center">
                                                    <shared-color-picker
                                                        class="level-2 badge me-1 w-50 py-2 my-1"
                                                        [colorRange]="zoneColor.color"
                                                        [fromDataRange]="''"
                                                        [toDataRange]="''"
                                                        [symbol]="''"
                                                        [middle]="zoneColor.color"
                                                        okButtonText="SELECT"
                                                        (colorSelected)="updateZoneColor($event, zoneColor.zone)"
                                                        [ngStyle]="{ 'background-color': zoneColor.color, color: zoneColor.color | textColor }"
                                                    ></shared-color-picker>
                                                </td>
                                                <td class="text-center">
                                                    <button
                                                        class="btn btn-sm btn-danger my-1"
                                                        title="Delete Zone Color"
                                                        (click)="deleteConfirmation(5, '', zoneColor.zone, '')"
                                                    >
                                                        <i class="fas fa-times-circle"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <app-select-multilevel
                                                        columns="3"
                                                        #settingsFAZ
                                                        key="faz-search-settings"
                                                        label="Select Zone"
                                                        [withAllValue]="false"
                                                        [withTopLevelsDisabled]="true"
                                                    ></app-select-multilevel>
                                                </td>
                                                <td class="text-center">
                                                    <shared-color-picker
                                                        class="level-2 badge me-1 w-50 py-2"
                                                        [colorRange]="newColorSelectedZone"
                                                        [fromDataRange]="''"
                                                        [toDataRange]="''"
                                                        [symbol]="''"
                                                        [middle]="newColorSelectedZone"
                                                        okButtonText="SELECT"
                                                        (colorSelected)="selectedNewColorZone($event)"
                                                        [ngStyle]="{ 'background-color': newColorSelectedZone, color: newColorSelectedZone | textColor }"
                                                    ></shared-color-picker>
                                                </td>
                                                <td class="text-center">
                                                    <button class="btn btn-sm btn-primary my-1" (click)="addNewZone()">
                                                        <i class="fas fa-check" title="Add Zone Color"></i>
                                                    </button>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
                    <div class="row mt-4 mb-5">
                        <div class="col-12 col-md-6 col-lg-7">
                            <h5 class="float-start text-start ms-5 me-3 mt-2">SCANNERS TREND COLORS</h5>
                        </div>
                        <div class="col-6 offset-3 col-md-6 col-lg-3 offset-md-0">
                            <app-search
                                #scannerTrendFilter
                                key="txtFindScannerTrendConfiguration"
                                placeholder="Filter Scanners"
                                columns="3"
                                icon="fas fa-search"
                                (searchValueDeleted)="scannerTrendFilterValue = ''"
                            >
                            </app-search>
                        </div>
                        <div class="container">
                            <div class="row ms-md-5 mt-2 mb-2">
                                <div class="col-12 ms-5 mt-0">
                                    <table class="table-compact table-hover w-75 mt-1 mb-5">
                                        <thead>
                                            <tr>
                                                <th style="width: 30%">Scanner</th>
                                                <th style="width: 60%" class="text-center">Ranges & Colors</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let scannerColor of scannerTrendTemporal"
                                                [hidden]="scannerTrendFilterValue !== '' && !scannerColor.scanner.includes(scannerTrendFilterValue)"
                                            >
                                                <td>
                                                    <span>{{ scannerColor.scanner }}</span>
                                                </td>
                                                <td class="h6">
                                                    <shared-config-color-picker
                                                        dimension=""
                                                        [firstRange]="scannerColor.firstRange"
                                                        [secondRange]="scannerColor.secondRange"
                                                        extraClasses="mt-0 mb-1 ms-5"
                                                        extraClassesInternalDiv="col-10"
                                                        [firstColorRange]="scannerColor.firstColorRange"
                                                        [secondColorRange]="scannerColor.secondColorRange"
                                                        [thirdColorRange]="scannerColor.thirdColorRange"
                                                        (colorSelected)="saveColorPickerScanner($event, scannerColor.scanner)"
                                                        (applyRanges)="calculateNewRangesScanner($event, scannerColor.scanner)"
                                                    ></shared-config-color-picker>
                                                </td>
                                                <td class="text-center">
                                                    <button
                                                        class="btn btn-sm btn-danger my-1"
                                                        title="Delete Scanner Color"
                                                        (click)="deleteConfirmation(6, '', scannerColor.scanner, '')"
                                                        [hidden]="scannerColor.scanner === initialEnviroment"
                                                    >
                                                        <i class="fas fa-times-circle"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <app-select
                                                        #scannersSelector
                                                        key="settings-scanners-search"
                                                        label="Scanner"
                                                        [item$]="scanner$"
                                                        columns="8"
                                                        placeholder="Select scanner"
                                                        searchable="true"
                                                    ></app-select>
                                                </td>
                                                <td class="h6">
                                                    <shared-config-color-picker
                                                        dimension=""
                                                        [firstRange]="scannerRangeColorsTemporal.firstRange"
                                                        [secondRange]="scannerRangeColorsTemporal.secondRange"
                                                        extraClasses="mt-0 mb-1 ms-5"
                                                        extraClassesInternalDiv="col-10"
                                                        [firstColorRange]="scannerRangeColorsTemporal.firstColorRange"
                                                        [secondColorRange]="scannerRangeColorsTemporal.secondColorRange"
                                                        [thirdColorRange]="scannerRangeColorsTemporal.thirdColorRange"
                                                        (colorSelected)="saveColorPickerScanner($event)"
                                                        (applyRanges)="calculateNewRangesScanner($event)"
                                                    ></shared-config-color-picker>
                                                </td>
                                                <td class="text-center">
                                                    <button class="btn btn-sm btn-primary my-1" (click)="addNewScanner()">
                                                        <i class="fas fa-check" title="Add Zone Color"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
                    <div class="row mt-4 mb-5">
                        <div class="col-12 col-md-6 col-lg-7">
                            <h5 class="float-start text-start ms-5 me-3 mt-2">ERRORS TREND COLORS</h5>
                        </div>
                        <div class="container">
                            <div class="row ms-md-5 mt-2 mb-2">
                                <div class="col-12 ms-5 mt-0">
                                    <table class="table-compact table-hover w-75 mt-1 mb-5">
                                        <thead>
                                            <tr>
                                                <th style="width: 30%">Errors</th>
                                                <th style="width: 60%" class="text-center">Ranges & Colors</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let errorsColor of errorTrendTemporal">
                                                <td>
                                                    <span>{{ errorsColor.fqn }}</span>
                                                </td>
                                                <td class="h6">
                                                    <shared-config-color-picker
                                                        dimension=""
                                                        [firstRange]="errorsColor.firstRange"
                                                        [secondRange]="errorsColor.secondRange"
                                                        extraClasses="mt-0 mb-1 ms-5"
                                                        extraClassesInternalDiv="col-10"
                                                        [firstColorRange]="errorsColor.firstColorRange"
                                                        [secondColorRange]="errorsColor.secondColorRange"
                                                        [thirdColorRange]="errorsColor.thirdColorRange"
                                                        (colorSelected)="saveColorPickerErrors($event, errorsColor.fqn)"
                                                        (applyRanges)="calculateNewRangesErrors($event, errorsColor.fqn)"
                                                    ></shared-config-color-picker>
                                                </td>
                                                <td class="text-center"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <settings-boxdetected
                [boxDetectedColors]="warehouseConfigurationBoxDetectedColors"
                [userConfiguration]="false"
                (newColor)="updateBoxDetectedColor($event)"
            ></settings-boxdetected>
        </div>
        <app-modal-centered #newVariableColorModal key="newVariableColorModal" title="Adding new Variable Color" [hideFooter]="true" extraSize="xl">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1">
                    <app-select
                        #linesSelector
                        [item$]="line$"
                        key="data-lines-dashboard-search"
                        label="Equipment-line"
                        columns="3"
                        placeholder="Please select an Equipment-line"
                        searchable="true"
                        dropdownParent="#newVariableColorModal"
                    ></app-select>
                </div>
                <div class="col-12 col-md-5 offset-md-1">
                    <app-select
                        #equipmentsSelector
                        [item$]="lineEquipments$"
                        key="data-equipments-dashboard-search"
                        label="Equipment"
                        columns="3"
                        placeholder="Please select an Equipment"
                        searchable="true"
                        dropdownParent="#newVariableColorModal"
                        [disabled]="!selectedLine"
                    ></app-select>
                </div>
                <div class="col-12 col-md-5">
                    <app-select
                        #variableSelector
                        [item$]="variable$"
                        key="variable-dashboard-search"
                        label="Variable"
                        columns="3"
                        placeholder="Please select a Variable"
                        searchable="true"
                        dropdownParent="#newVariableColorModal"
                        [disabled]="!selectedLine || !selectedEquipment"
                    ></app-select>
                    <span class="small" *ngIf="selectedLine && selectedEquipment && selectedVariable">Value Type: {{ selectedVariableDataType }}</span>
                </div>
            </div>
            <div [hidden]="!selectedLine || !selectedEquipment || !selectedVariable" class="mb-1 container ms-5">
                <table class="table-compact table-hover w-75 mt-3 mb-5 ms-5" [hidden]="!newVariableSettingsColor || newVariableSettingsColor?.length === 0">
                    <thead>
                        <tr>
                            <th class="w-25">Environment</th>
                            <th class="w-25 text-end">{{ selectedVariableDataType === variableNumericType ? 'To' : 'Value' }}</th>
                            <th class="w-25 text-center">Color</th>
                            <th class="w-25 text-center">Visibility (icons only)</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let setting of newVariableSettingsColor; index as i; trackBy: trackById">
                            <td [ngStyle]="{'cursor':  selectedVariableDataType !== variableNumericType || i === 0 ? 'auto': 'pointer'}"
                            >
                                {{ setting.enviroment }}
                                <span *ngIf="selectedVariableDataType !== variableNumericType">
                                    {{ setting.to === '1' ? ' - TRUE' : ' - FALSE' }}
                                </span>
                            </td>
                            <td class="text-end" [ngStyle]="{'cursor':  selectedVariableDataType !== variableNumericType || i === 0 ? 'auto': 'pointer'}">{{ setting.to }}</td>
                            <td class="text-center">
                                <shared-color-picker
                                    class="level-2 badge me-1 w-50 py-2 my-1"
                                    [colorRange]="setting.color"
                                    [fromDataRange]="''"
                                    [toDataRange]="''"
                                    [symbol]="''"
                                    [middle]="setting.color"
                                    okButtonText="SELECT"
                                    (colorSelected)="updateVariableSettingColor($event, setting.id, '')"
                                    [ngStyle]="{ 'background-color': setting.color, color: setting.color | textColor }"
                                ></shared-color-picker>
                            </td>
                            <td class="text-center">
                                <app-checkbox
                                    #txtNewSettingVisibilityListed
                                    [key]="setting.id"
                                    columns="12"
                                    [checked]="setting.visible"
                                    (clicked)="clickChkSettingBooleanTypeVisibility(txtNewSettingVisibilityListed, setting.id)"
                                ></app-checkbox>
                            </td>
                            <td class="text-center">
                                <button
                                    class="btn btn-sm btn-danger my-1"
                                    (click)="deleteConfirmation(2, setting.id, '', setting.enviroment)"
                                    [hidden]="setting.enviroment === initialEnviroment"
                                >
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="selectedVariableDataType === variableNumericType">
                            <td>
                                <app-input #txtVariableSettingColorEnviroment key="txtVariableSettingColorEnviroment" label="Enviroment"></app-input>
                            </td>
                            <td>
                                <app-input
                                    #txtVariableSettingColorTo
                                    key="txtVariableSettingColorTo"
                                    label="To"
                                    type="number"
                                    withoutArrows="true"
                                    extraClasses="w-75 float-end"
                                ></app-input>
                            </td>
                            <td class="text-center">
                                <shared-color-picker
                                    class="level-2 badge me-1 w-50 py-2"
                                    [colorRange]="newColorSelected"
                                    [fromDataRange]="''"
                                    [toDataRange]="''"
                                    [symbol]="''"
                                    [middle]="newColorSelected"
                                    okButtonText="SELECT"
                                    (colorSelected)="selectedNewColor($event)"
                                    [ngStyle]="{ 'background-color': newColorSelected, color: newColorSelected | textColor }"
                                ></shared-color-picker>
                            </td>
                            <td class="text-center">
                                <app-checkbox
                                    #txtNewSettingVisibility
                                    key="txtNewSettingVisibility"
                                    columns="12"
                                    checked="true"
                                ></app-checkbox>
                            </td>
                            <td class="text-center">
                                <button
                                    class="btn btn-sm btn-primary my-1"
                                    (click)="addNewEnviroment('', txtVariableSettingColorEnviroment, txtVariableSettingColorTo, txtNewSettingVisibility)"
                                >
                                    <i class="fas fa-check"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-2 p-0">
                <div class="col-12 text-center" *ngIf="variableColorAlreadyExists !== ''">
                    <span class="text-danger">{{ variableColorAlreadyExists }}</span>
                </div>
                <div class="col-12 mt-2 pt-0 mb-3 pb-3">
                    <button type="button" class="btn btn-auwa float-end me-5" (click)="newVariableColorModal.closeModal()">Close</button>
                    <button
                        class="btn btn-auwa float-end me-5 px-4"
                        (click)="saveNewVariableSettingColor()"
                        [hidden]="!newVariableSettingsColor || newVariableSettingsColor?.length === 0"
                    >
                        Save
                    </button>
                    <app-checkbox
                        #applyForAllVariablesCheck
                        class="float-end me-2 mt-2"
                        key="chkApplyForAllVariables"
                        label="Apply this color Settings for all Variables with the same name."
                        columns="8"
                        checked="true"
                        (clicked)="clickChkApplyForAll()"
                        [hidden]="!newVariableSettingsColor || newVariableSettingsColor?.length === 0"
                    ></app-checkbox>
                </div>
            </div>
        </app-modal-centered>
        <app-modal-confirmation #confirmationModal key="automationConfirmationModal" (responseChanged)="confirmationEmited($event)" [dangerMode]="dangerMode">
            <span [innerHTML]="confirmationMessage.text"></span>
        </app-modal-confirmation>
    </div>
</div>

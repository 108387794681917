/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import {
    Component,
    OnInit,
    OnChanges,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    ChangeDetectionStrategy,
    HostBinding,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'app-range-slider',
    templateUrl: './range-slider.component.html',
    styleUrls: ['./range-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeSliderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() columns: number;
    @Input() extraClasses: number;
    @Input() dataRangeMin: number;
    @Input() dataRangeMax: number;
    @HostBinding('class') classes;
    @Output() applyRanges = new EventEmitter();
    @ViewChild('min', { static: false }) private min: ElementRef;
    @ViewChild('max', { static: false }) private max: ElementRef;
    @ViewChild('range_min', { static: false }) private rangeMin: ElementRef;
    @ViewChild('range_max', { static: false }) private rangeMax: ElementRef;
    minVal: number;
    maxVal: number;

    constructor() {}

    ngOnInit() {
        this.minVal = this.dataRangeMin ? this.dataRangeMin : 20;
        this.maxVal = this.dataRangeMax ? this.dataRangeMax : 80;
        this.classes = `col-12 col-md-6 col-lg-${this.columns || 2} ${this.extraClasses || ''}`;
        $('input[type="range"]').on('input', (event) => {
            this.rangeInputChangeEventHandler(event);
        });
    }
    ngOnChanges(): void {
        this.minVal = this.dataRangeMin;
        this.maxVal = this.dataRangeMax;
    }

    ngAfterViewInit(): void {}
    ngOnDestroy(): void {}

    rangeInputChangeEventHandler(e) {
        const min = this.min.nativeElement;
        const max = this.max.nativeElement;
        const minVal = Number(min.value);
        const maxVal = Number(max.value);
        const origin = e.target.className;

        if (origin === 'min' && minVal > maxVal - 2) {
            $(min).val(maxVal - 2);
        }
        $(this.rangeMin.nativeElement).html(String($(min).val()));

        if (origin === 'max' && maxVal - 2 < minVal) {
            $(max).val(2 + minVal);
        }
        $(this.rangeMax.nativeElement).html(String($(max).val()));
    }

    saveRanges() {
        this.minVal = Number(this.min.nativeElement.value);
        this.maxVal = Number(this.max.nativeElement.value);
        this.applyRanges.emit({ minVal: this.minVal, maxVal: this.maxVal });
    }
    closeRanges() {
        this.min.nativeElement.value = this.minVal;
        this.max.nativeElement.value = this.maxVal;
        $(this.rangeMin.nativeElement).html(String(this.minVal));
        $(this.rangeMax.nativeElement).html(String(this.maxVal));
        this.applyRanges.emit({ minVal: null, maxVal: null });
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// tslint:disable-next-line: max-line-length
import { ApplicationInsights, IExceptionTelemetry, IMetricTelemetry, IEventTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { SettingsService } from '@app/core/shared/settings/settings.service';

@Injectable()
export class MonitoringService {
    private initialized = false;

    constructor(private router: Router, private appInsights: ApplicationInsights, private settingsService: SettingsService) {}

    initializeAppInsights() {
        if (!this.initialized && this.settingsService.applicationInsightsSettings) {
            const angularPlugin = new AngularPlugin();
            const instrumentationKey = this.settingsService.applicationInsightsSettings;
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey,
                    // enableAutoRouteTracking: true // option to log all route changes
                    extensions: [angularPlugin],
                    extensionConfig: { [angularPlugin.identifier]: { router: this.router } },
                    // https://learn.microsoft.com/es-es/azure/azure-monitor/app/javascript?tabs=npm#enable-distributed-tracing
                    // enableCorsCorrelation: true,
                    // enableRequestHeaderTracking: true,
                    // enableResponseHeaderTracking: true,
                },
            });
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
            this.initialized = true;
        }
    }

    public logPageView(name: string, url?: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }, duration?: number) {
        this.initializeAppInsights();
        const pageViewTelemetry: IPageViewTelemetry = {
            name,
            uri: url,
            measurements,
            properties: this.AddGlobalProperties(properties),
        };

        this.appInsights.trackPageView(pageViewTelemetry);
    }

    public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        this.initializeAppInsights();
        const event: IEventTelemetry = {
            name,
            properties: this.AddGlobalProperties(properties),
            measurements,
        };

        this.appInsights.trackEvent(event);
    }

    public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        this.initializeAppInsights();
        const exception: IExceptionTelemetry = {
            exception: error,
            measurements,
            properties: this.AddGlobalProperties(properties),
        };
        this.appInsights.trackException(exception);
    }

    // tslint:disable-next-line: max-line-length
    public logMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number, properties?: { [name: string]: string }) {
        this.initializeAppInsights();
        const telemetry: IMetricTelemetry = {
            name,
            average,
            sampleCount,
            min,
            max,
        };

        this.appInsights.trackMetric(telemetry, { ...properties });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }
        return properties;
    }
}

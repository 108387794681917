<div class="row mt-4">
    <div class="col-4" *ngFor="let warehouse of warehouses">
        <div class="card">
            <img class="card-img-top" src="https://via.placeholder.com/400x300?text={{ warehouse.displayName }}" alt="Card image cap" />
            <div class="card-body">
                <h5 class="card-title">{{ warehouse.displayName }}</h5>
                <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                    content.</p> -->
                <a href="#" class="btn btn-primary" (click)="navigateToWarehouse(warehouse)">Launch</a>
            </div>
        </div>
    </div>
</div>

<div class="row search-nav mt-3" style="display: none">
    <div class="col col-9">
        <h2>Select an warehouse</h2>
    </div>
    <app-select-warehouse
        #comboWarehouse
        [item$]="warehouses"
        key="data-lines-search"
        label="Warehouse"
        columns="3"
        placeholder="Select an warehouse"
    ></app-select-warehouse>
</div>
<div class="row mt-4" style="display: none">
    <div class="col-12">
        <div class="map-container mt-2">
            <div class="areaLegend"></div>
            <div class="map">
                <span>Alternative content for the map</span>
            </div>
        </div>
    </div>
</div>

<modal-lateral [open]="modalLateral" title="View warehouses">
    <div class="col">
        <br />
        <ngx-datatable
            #table
            class="bootstrap clickable"
            [rows]="itemsVariable"
            [limit]="10"
            [columnMode]="'flex'"
            [messages]="messages"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            (activate)="onActivate($event)"
        >
            <ngx-datatable-column name="displayName" [sortable]="true" [flexGrow]="3">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                    <span (click)="sort()">Warehouse</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>{{ value }}</ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
                >
                    <div class="col-4">
                        <p style="font-size: 0.8rem; margin-top: 16px">
                            Seeing
                            <select class="table-length-selector" style="margin: 0 5px" (change)="changePageSize($event)" [value]="pageSize">
                                <option [value]="size" *ngFor="let size of pageSizes">{{ size }}</option>
                            </select>
                            {{ pageSize }} of {{ rowCount | number }} entries
                        </p>
                    </div>
                    <div class="col-6">
                        <datatable-pager
                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'"
                            [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="curPage"
                            [size]="pageSize"
                            [count]="rowCount"
                            [hidden]="!(rowCount / pageSize > 1)"
                            (change)="table.onFooterPage($event)"
                        >
                        </datatable-pager>
                    </div>
                    <div class="col-2">
                        <button class="button-excel" tabindex="0" [title]="'Alerts'" (click)="generateCSV($event, [])">
                            <i class="icon-download"></i>Download
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</modal-lateral>

<a
    tabindex="0"
    class="kpi-doc-popover kpi-doc-link d-block"
    role="button"
    data-bs-toggle="popover"
    data-bs-placement="auto"
    data-bs-html="true"
    title="KPIs information"
    [attr.data-bs-content]="kpiList"
>
    <i class="fa fa-info-circle" title="View KPIs information"></i>
</a>

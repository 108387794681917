import { ChartDataset } from 'chart.js';

export class ChartItem {
    labels: string[];
    datasets: ChartDataset[];

    constructor(labels: string[], datasets: ChartDataset[]) {
        this.labels = labels;
        this.datasets = datasets;
    }
}

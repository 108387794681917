/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { StateService } from '@app/core/shared/state/state.service';
import { FGStatusNotification, OPCStatusNotification, PLCStatusNotification } from '../events/communications-status';
import { CommunicationStatusClient } from '../clients/communications-status.client';

const COMMUNICATIONS = 'Communications';

@Injectable()
export class CommunicationStatusService {
    onNotificationFGs: EventEmitter<FGStatusNotification> = new EventEmitter<FGStatusNotification>();
    onNotificationOPCs: EventEmitter<OPCStatusNotification> = new EventEmitter<OPCStatusNotification>();
    onNotificationPLCs: EventEmitter<PLCStatusNotification> = new EventEmitter<PLCStatusNotification>();
    notificationFGsSubscription: any;
    notificationOPCsSubscription: any;
    notificationPLCsSubscription: any;

    private readonly callbackFGs = (...args: FGStatusNotification[]) => {
        console.log(args);
        this.onNotificationFGs.emit(args[0]);
    };
    private readonly callbackOPCs = (...args: OPCStatusNotification[]) => {
        console.log(args);
        this.onNotificationOPCs.emit(args[0]);
    };
    private readonly callbackPLCs = (...args: PLCStatusNotification[]) => {
        console.log(args);
        this.onNotificationPLCs.emit(args[0]);
    };

    constructor(private communicationStatusClient: CommunicationStatusClient, private stateService: StateService) {}

    public async subscribeToFG(warehouse: string, context: any, delegateFunc: (context: any, notification: FGStatusNotification) => void) {
        const eventName = CommunicationStatusClient.SupportedEvents.FG_STATUS_CHANGED;
        // tslint:disable-next-line: max-line-length
        this.notificationFGsSubscription = this.onNotificationFGs.subscribe({
            next: (event: FGStatusNotification) => {
                // tslint:disable-next-line: max-line-length
                const notificationTS = event.timeStamp;
                if (this.stateService.isNewerNotification(this.constructor.name, COMMUNICATIONS, eventName, notificationTS, event)) {
                    delegateFunc(context, event);
                }
            },
        });
        await this.communicationStatusClient.subscribe(eventName, warehouse, this.callbackFGs);
    }

    public async unsubscribeFromFG(warehouse: string) {
        const eventName = CommunicationStatusClient.SupportedEvents.FG_STATUS_CHANGED;
        this.notificationFGsSubscription?.unsubscribe();
        await this.communicationStatusClient.unsubscribe(eventName, warehouse);
    }

    // tslint:disable-next-line:max-line-length
    public async subscribeToOPC(warehouse: string, context: any, delegateFunc: (context: any, notification: OPCStatusNotification) => void) {
        const eventName = CommunicationStatusClient.SupportedEvents.OPC_STATUS_CHANGED;
        this.notificationOPCsSubscription = this.onNotificationOPCs.subscribe({
            next: (event: OPCStatusNotification) => {
                const notificationTS = event.timeStamp;
                if (this.stateService.isNewerNotification(this.constructor.name, COMMUNICATIONS, eventName, notificationTS, event)) {
                    delegateFunc(context, event);
                }
            },
        });
        await this.communicationStatusClient.subscribe(eventName, warehouse, this.callbackOPCs);
    }

    public async unsubscribeFromOPC(warehouse: string) {
        const eventName = CommunicationStatusClient.SupportedEvents.OPC_STATUS_CHANGED;
        this.notificationOPCsSubscription?.unsubscribe();
        await this.communicationStatusClient.unsubscribe(eventName, warehouse);
    }

    // tslint:disable-next-line:max-line-length
    public async subscribeToPLC(warehouse: string, context: any, delegateFunc: (context: any, notification: PLCStatusNotification) => void) {
        const eventName = CommunicationStatusClient.SupportedEvents.NODE_STATUS_CHANGED;
        this.notificationPLCsSubscription = this.onNotificationPLCs.subscribe({
            next: (event: PLCStatusNotification) => {
                const notificationTS = event.timeStamp;
                if (this.stateService.isNewerNotification(this.constructor.name, COMMUNICATIONS, eventName, notificationTS, event)) {
                    delegateFunc(context, event);
                }
            },
        });
        await this.communicationStatusClient.subscribe(eventName, warehouse, this.callbackPLCs);
    }

    public async unsubscribeFromPLC(warehouse: string) {
        const eventName = CommunicationStatusClient.SupportedEvents.NODE_STATUS_CHANGED;
        this.notificationPLCsSubscription?.unsubscribe();
        this.notificationPLCsSubscription = null;
        await this.communicationStatusClient.unsubscribe(eventName, warehouse);
    }

    isConnected() {
        return this.communicationStatusClient.connection?.hubConnection.state === 'Connected';
    }
}

/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '@src/environments/environment';
import { StateService } from '../state/state.service';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public static readonly USER_ROLE = 'auwa-user-';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public static readonly ADMIN_ROLE = 'auwa-super-admin';
    private isAdmin = new BehaviorSubject(false);
    private userGroups = new BehaviorSubject([]);
    getIsAdmin = this.isAdmin.asObservable();
    getGroups = this.userGroups.asObservable();
    baseUrl: string;

    public get msalService() {
        return environment.mode !== 'front' ? this.injector.get(MsalService) : undefined;
    }

    constructor(private injector: Injector, private http: HttpClient, private stateService: StateService) {
        this.stateService.getApiUri.subscribe((res) => (this.baseUrl = res));
    }

    signOut(): void {
        if (this.msalService !== undefined) {
            this.msalService.logout();
        }
    }

    getUser(): any {
        const account = this.msalService.instance.getAllAccounts()[0];
        return { name: account?.name, userName: account?.username };
    }

    setGroups(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.http.get<any[]>(`${this.baseUrl}/users/me`).subscribe(
                (info: any) => {
                    const groups = info.roles;
                    if (groups.length > 0) {
                        this.isAdmin.next(groups.some((rol) => rol === AuthenticationService.ADMIN_ROLE));
                        this.userGroups.next(groups);
                    }
                    resolve(true);
                },
                (err) => {
                    resolve(false);
                }
            );
        });
    }

    checkGroups(warehouse): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.getGroups.subscribe((userGroups) => {
                if (!userGroups || userGroups.length === 0) {
                    resolve(false);
                } else {
                    const isAdmin = userGroups.some((rol) => rol === AuthenticationService.ADMIN_ROLE);
                    const isAuth =
                        isAdmin || userGroups.map((group) => group.replace(AuthenticationService.USER_ROLE, '').toUpperCase()).includes(warehouse.warehouse);
                    resolve(isAuth);
                }
            });
        });
    }
}

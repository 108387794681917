<div class="collapse" id="filterMenu" [ngClass]="{ show: !isFullscreen || showFilters }">
    <div class="row search-nav mt-3 pb-2">
        <div class="col-12 col-xl-8 col-lg-6">
            <app-breadcrumbs [columns]="9" [breadcrumbs]="[floorId, areaId]"></app-breadcrumbs>
        </div>
        <div class="col-12 col-xl-4 col-lg-6">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsView }">Alerts</button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-xl-8 col-lg-6 col-12 svg-wrapper">
        <div class="map-wrapper">
            <div class="row">
                <app-dropdown-checkbox
                    label="States"
                    [icon]="filterIcon"
                    [iconTitle]="filterTitle"
                    columns="3"
                    [availableItems]="availableStates"
                    (changeFilter)="handleChangeVisibleStates($event)"
                ></app-dropdown-checkbox>
                <div class="col-4 offset-5">
                    <h4><a [routerLink]="['/warehouse', warehouse.warehouse, 'floor', floorId]">View complete floor</a></h4>
                </div>
            </div>
            <app-areas-map
                *ngIf="mapRender"
                [map]="map"
                [visibleStates]="visibleStates"
                [lineChange$]="lineChange$"
                [tabState]="tabState"
                (clickZone)="navigateToZone($event, null)"
                (visibleLines)="refreshAlertsTable($event)"
                [activateZoom]="activateZoom"
                (newLineSelected)="newLineSelected($event)"
            >
            </app-areas-map>
        </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-4 px-lg-0">
        <div [ngClass]="{ 'hide-me': !alertsView }">
            <shared-alerts-table-sm
                #alertsTable
                [items]="areaAlerts"
                [filterItems]="linesVisible"
                [group]="floorId + '_' + areaId"
                [priority$]="priority$"
                fqdnMode="area"
            ></shared-alerts-table-sm>
        </div>
        <!-- <div [ngClass]="{ 'hide-me': !variablesView }">
            <shared-variables-table [warehouse]="warehouse" [floorId]="floorId" [areaId]="areaId" [equipmentTypes]="equipmentTypes" fqdnMode="area">
            </shared-variables-table>
        </div> -->
    </div>
</div>
<app-home-lines-minified-container [lineToShowOff]="lineToShow" [visibleStatesOff]="visibleStates" (offcanvasToggle)="offcanvasToggle($event)">
</app-home-lines-minified-container>

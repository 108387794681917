<div class="form-group">
    <label [for]="key">{{ label }}</label>
    <ng-container *ngIf="selectedValue; then withModel; else withoutModel"></ng-container>
    <i class="fas fa-chevron-down"></i>
</div>

<ng-template #withModel>
    <select #select [id]="key" class="form-control" [(ngModel)]="selectedValue">
        <option *ngIf="placeholder"></option>
        <option *ngFor="let item of item$" [value]="item.warehouse">{{ item.displayName }}</option>
    </select>
</ng-template>
<ng-template #withoutModel>
    <select #select [id]="key" class="form-control">
        <option *ngIf="placeholder"></option>
        <option *ngFor="let item of item$" [value]="item.warehouse">{{ item.displayName }}</option>
    </select>
</ng-template>

<div *ngIf="isLoading; else displayCharts">
    <div class="analytics-loader-wrapper text-center">
        <img src="assets/img/loader.gif" />
        <h4 class="mt-3 text-center font-weight-bold">Loading data...</h4>
    </div>
</div>

<ng-template #displayCharts>
    <div class="row" style="align-items: center">
        <div class="col-12" style="padding-left: 20px">
            <div class="row">
                <div class="col-12 performance-title">
                    <h3>Last 24 hours</h3>
                </div>
                <div class="col-md-12 col-lg-7">
                    <div class="row">
                        <div class="col performance-states">
                            <span><i class="fa fa-square state1"></i>Running</span>
                            {{ totalKpis.running.minutes | minutes }}<br />
                            <strong>{{ totalKpis.running.percent / 23 | number: '1.0-2' }}%</strong>
                        </div>
                        <div class="col performance-states">
                            <span><i class="fa fa-square state4"></i>Stopped</span>
                            {{ totalKpis.stopped.minutes | minutes }}<br />
                            <strong>{{ totalKpis.stopped.percent / 23 | number: '1.0-2' }}%</strong>
                        </div>
                        <div class="col performance-states">
                            <span><i class="fa fa-square state5"></i>No data</span>
                            {{ totalKpis.undeterminedStatus.minutes | minutes }}<br />
                            <strong>{{ totalKpis.undeterminedStatus.percent / 23 | number: '1.0-1' }}%</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-3">
        <shared-bar-chart [data]="runningData" height="250px"></shared-bar-chart>
    </div>
    <div class="mt-4">
        <ul class="nav nav-tabs secondary-tab" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    id="{{ onErrorId }}-tab"
                    data-bs-toggle="tab"
                    href="#{{ onErrorId }}"
                    role="tab"
                    [attr.aria-controls]="onErrorId"
                    aria-selected="true"
                >
                    On Error<span>{{ totalKpis.onError.percent / 23 | number: '1.0-2' }}%</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    id="{{ withoutPermissionId }}-tab"
                    data-bs-toggle="tab"
                    href="#{{ withoutPermissionId }}"
                    role="tab"
                    [attr.aria-controls]="withoutPermissionId"
                    aria-selected="true"
                >
                    Without Permission<span>{{ totalKpis.loadedWithoutDischargeOpt.percent / 23 | number: '1.0-2' }}%</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="{{ nokId }}-tab" data-bs-toggle="tab" href="#{{ nokId }}" role="tab" [attr.aria-controls]="nokId" aria-selected="true">
                    <!-- NOK<span>{{ totalKpis.nokPercent / 23 | number : '1.0-2'}}%</span> -->
                    NOK<span>{{ totalKpis.nokValue }}</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    id="{{ errorsId }}-tab"
                    data-bs-toggle="tab"
                    href="#{{ errorsId }}"
                    role="tab"
                    [attr.aria-controls]="errorsId"
                    aria-selected="false"
                >
                    Nº Errors<span>{{ totalKpis.numberOfErrors | number: '1.0-2' }}</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    id="{{ boxesId }}-tab"
                    data-bs-toggle="tab"
                    href="#{{ boxesId }}"
                    role="tab"
                    [attr.aria-controls]="boxesId"
                    aria-selected="false"
                >
                    Nº Items<span>{{ totalKpis.numberOfBoxes | number: '1.0-2' }}</span>
                </a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="{{ onErrorId }}" role="tabpanel" attr.aria-labelledby="{{ onErrorId }}-tab">
                <div class="row" style="align-items: center">
                    <div class="col-12" style="padding-left: 20px">
                        <div class="row">
                            <div class="col-md-12 col-lg-7">
                                <div class="row">
                                    <div class="col performance-states">
                                        <span><i class="fa fa-square state2"></i>On error</span>
                                        {{ totalKpis.onError.minutes | minutes }}<br />
                                        <strong>{{ totalKpis.onError.percent / 23 | number: '1.0-2' }}%</strong>
                                    </div>
                                    <div class="col performance-states">
                                        <span><i class="fa fa-square state4b"></i>Availability</span>
                                        {{ totalKpis.noError.minutes | minutes }}<br />
                                        <strong>{{ totalKpis.noError.percent / 23 | number: '1.0-2' }}%</strong>
                                    </div>
                                    <div class="col performance-states">
                                        <span><i class="fa fa-square state5"></i>No data</span>
                                        {{ totalKpis.undeterminedError.minutes | minutes }}<br />
                                        <strong>{{ totalKpis.undeterminedError.percent / 23 | number: '1.0-1' }}%</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <shared-bar-chart [data]="errorData" height="250px"></shared-bar-chart>
                </div>
            </div>
            <div class="tab-pane fade" id="{{ withoutPermissionId }}" role="tabpanel" attr.aria-labelledby="{{ withoutPermissionId }}-tab">
                <div class="row" style="align-items: center">
                    <div class="col-12" style="padding-left: 20px">
                        <div class="row">
                            <div class="col-md-12 col-lg-7">
                                <div class="row">
                                    <div class="col performance-states">
                                        <span><i class="fa fa-square state3"></i>Without permission</span>
                                        {{ totalKpis.loadedWithoutDischargeOpt.minutes | minutes }}<br />
                                        <strong>{{ totalKpis.loadedWithoutDischargeOpt.percent / 23 | number: '1.0-2' }}%</strong>
                                    </div>
                                    <div class="col performance-states">
                                        <span><i class="fa fa-square state5"></i>No data</span>
                                        {{ totalKpis.undeterminedLoadedWithoutDischargeOpt.minutes | minutes }}<br />
                                        <strong>{{ totalKpis.undeterminedLoadedWithoutDischargeOpt.percent / 23 | number: '1.0-1' }}%</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <shared-bar-chart [data]="loadedWithoutDischargeOptData" height="250px"></shared-bar-chart>
                </div>
            </div>
            <div class="tab-pane fade" id="{{ nokId }}" role="tabpanel" attr.aria-labelledby="{{ nokId }}-tab">
                <shared-bar-chart [data]="nokData" height="310px" percentage="false"></shared-bar-chart>
            </div>
            <div class="tab-pane fade" id="{{ errorsId }}" role="tabpanel" attr.aria-labelledby="{{ errorsId }}-tab">
                <shared-bar-chart [data]="errorsData" height="310px" percentage="false"></shared-bar-chart>
            </div>
            <div class="tab-pane fade" id="{{ boxesId }}" role="tabpanel" attr.aria-labelledby="{{ boxesId }}-tab">
                <shared-bar-chart [data]="boxesData" height="310px" percentage="false"></shared-bar-chart>
            </div>
        </div>
    </div>
</ng-template>

import { AlertsActiveStatus } from '../events/alert-status';

const alertsActiveStatusMapper = (alertActiveStatus: AlertsActiveStatus) => {
    return {
        floorId: alertActiveStatus.floorid,
        areaId: alertActiveStatus.areaid,
        zoneId: alertActiveStatus.zoneid,
        lineId: alertActiveStatus.lineid,
        equipmentId: alertActiveStatus.equipmentId,
        equipmentType: alertActiveStatus.equipmentType,
        state: alertActiveStatus.state,
        stateText: alertActiveStatus.stateText,
        priority: alertActiveStatus.priority,
        source: alertActiveStatus.source,
        sourceTimeStamp: alertActiveStatus.sourceTimeStamp,
        serverts: alertActiveStatus.serverTimeStamp,
    };
};

export default alertsActiveStatusMapper;

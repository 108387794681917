import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import { TableComponentDirective } from '@ays';
import { Warehouse } from '@app/core/shared/warehouse';
import 'jquery-mapael';
import 'jquery-mapael/js/maps/world_countries.js';
import * as _ from 'lodash-es';

@Component({
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent extends TableComponentDirective<Warehouse> implements OnInit {
    @ViewChild('comboWarehouse') comboWarehouse: SelectComponent;
    $comboWarehouse: JQuery;
    pageSizes: Array<number> = [10, 25, 50];
    warehouses: Array<Warehouse> = [];
    modalLateral: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private appLoadService: AppLoadService) {
        super();
        this.modalLateral = false;
    }

    ngOnInit() {
        this.warehouses = this.appLoadService.warehouses;
        if (this.warehouses.length === 1) {
            this.router.navigate([`/`]);
        }
        this.setDatatableItems(this.warehouses);
        // $('.map-container').mapael({
        //     map: {
        //         name: 'world_countries',
        //         zoom: {
        //             enabled: false,
        //         },
        //         defaultArea: {
        //             attrs: {
        //                 fill: '#f4f4e8',
        //                 stroke: '#ced8d0'
        //             },
        //             attrsHover: {
        //                 fill: '#f4f4e8'
        //             },
        //             eventHandlers: {
        //                 click: (e, id, mapElem, textElem, elemOptions) => {
        //                     this.checkWarehouseFromCountry(id);
        //                 }
        //             }
        //         }
        //     },
        //     legend: {
        //         area: {
        //             display: true,
        //             title: 'Number of warehouses',
        //             marginBottom: 7,
        //             slices: [
        //                 {
        //                     max: 1,
        //                     attrs: {
        //                         fill: '#bbb'
        //                     },
        //                     attrsHover: {
        //                         fill: '#f38a03',
        //                         cursor: 'pointer'
        //                     },
        //                     label: '1 Warehouse'
        //                 },
        //                 {
        //                     min: 1,
        //                     max: 5,
        //                     attrs: {
        //                         fill: '#6d9bc3'
        //                     },
        //                     attrsHover: {
        //                         fill: '#f38a03',
        //                         cursor: 'pointer'
        //                     },
        //                     label: 'Between 1 and 5 warehouses'
        //                 },
        //                 {
        //                     min: 5,
        //                     max: 10,
        //                     attrs: {
        //                         fill: '#2579b5'
        //                     },
        //                     attrsHover: {
        //                         fill: '#f38a03',
        //                         cursor: 'pointer'
        //                     },
        //                     label: 'Between 5 and 10 warehouses'
        //                 },
        //                 {
        //                     min: 10,
        //                     attrs: {
        //                         fill: '#475d6b'
        //                     },
        //                     attrsHover: {
        //                         fill: '#f38a03',
        //                         cursor: 'pointer'
        //                     },
        //                     label: 'More than 10 warehouses'
        //                 }
        //             ]
        //         }
        //     },
        //     areas: {
        //         ES: {
        //             value: 2,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>SPAIN</span><br />Number of warehouses : 2'
        //             }
        //         },
        //         IN: {
        //             value: 3,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>INDIA</span><br />Number of warehouses : 3'
        //             }
        //         },
        //         FR: {
        //             value: 1,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>FRANCE</span><br />Number of warehouses : 1'
        //             }
        //         },
        //         ZA: {
        //             value: 1,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>SOUTH AFRICA</span><br />Number of warehouses : 1'
        //             }
        //         },
        //         GB: {
        //             value: 1,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>UNITED KINGDOM</span><br />Number of warehouses : 1'
        //             }
        //         },
        //         RU: {
        //             value: 1,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>RUSIA</span><br />Number of warehouses : 1'
        //             }
        //         },
        //         BR: {
        //             value: 7,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>BRAZIL</span><br />Number of warehouses : 7'
        //             }
        //         },
        //         CN: {
        //             value: 6,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>CHINA</span><br />Number of warehouses : 6'
        //             }
        //         },
        //         US: {
        //             value: 12,
        //             tooltip: {
        //                 content: '<span style=\'font-weight:bold;\'>USA</span><br />Number of warehouses : 12'
        //             }
        //         }
        //     }
        // });
    }

    checkWarehouseFromCountry(id) {
        if (id === 'ES') {
            this.openModalLateral();
        }
    }

    openModalLateral() {
        this.modalLateral = true;
        setTimeout(() => {
            this.modalLateral = false;
        }, 500);
    }

    async onActivate(event) {
        if (event.type === 'click') {
            const warehouse = {
                warehouse: event.row.warehouse,
                displayName: event.row.displayName,
            };
            await this.navigateToWarehouse(warehouse);
        }
    }
    async navigateToWarehouse(warehouse) {
        await this.appLoadService.setCurrentWarehouse(warehouse);
        window.location.href = '/';
    }
}

// tslint:disable-next-line:max-line-length
import { Component, HostBinding, Input, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Warehouse } from '@app/core/shared/warehouse';

@Component({
    selector: 'app-select-warehouse',
    templateUrl: './select-warehouse.component.html',
    styleUrls: ['./select-warehouse.component.scss'],
})
export class SelectWarehouseComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() key: string;
    @Input() selectedValue?: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() columns: number;
    @Input() item$: Array<Warehouse>;
    @Input() searchable: string;
    @HostBinding('class') classes;
    @ViewChild('select') private select: ElementRef;
    $select: JQuery;

    constructor() {}

    ngOnInit() {
        this.classes = `col-${this.columns || 2}`;
    }

    ngAfterViewInit(): void {
        this.$select = $(this.select.nativeElement);
        this.initializeCombo();
        if (this.selectedValue !== undefined) {
            this.$select.val(this.selectedValue).trigger('change');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.$select && changes.selectedValue) {
            this.initializeCombo();
            this.$select.val(changes.selectedValue.currentValue).trigger('change');
        }
    }

    ngOnDestroy(): void {
        if (this.$select) {
            this.$select.select2('destroy');
        }
    }

    initializeCombo() {
        const s2Options: { [k: string]: any } = {};
        if (this.placeholder !== undefined) {
            s2Options.placeholder = this.placeholder;
        }
        if (this.searchable !== 'true') {
            s2Options.minimumResultsForSearch = -1;
        }
        if (this.$select.data('select2-id')) {
            this.$select.select2('destroy');
        }
        this.$select.select2(s2Options);
    }
}

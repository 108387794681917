import { Component, Input, OnChanges /*, OnInit*/ } from '@angular/core';
import { ChartItem } from '../charts/chart-item';
import { ColorsService } from '@app/shared/services/colors.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'shared-analytics-panel',
    templateUrl: './analytics-panel.component.html',
    styleUrls: ['./analytics-panel.component.scss'],
})
export class AnalyticsPanelComponent implements /*OnInit,*/ OnChanges {
    @Input() kpis;
    onErrorId = _.uniqueId('analytics-panel_');
    withoutPermissionId = _.uniqueId('analytics-panel_');
    nokId = _.uniqueId('analytics-panel_');
    errorsId = _.uniqueId('analytics-panel_');
    boxesId = _.uniqueId('analytics-panel_');
    totalKpis: any = {
        numberOfErrors: 0,
        numberOfBoxes: 0,
        nokPercent: 0,
        nokValue: 0,
        running: { percent: 0, minutes: 0 },
        undeterminedStatus: { percent: 0, minutes: 0 },
        stopped: { percent: 0, minutes: 0 },
        onError: { percent: 0, minutes: 0 },
        undeterminedError: { percent: 0, minutes: 0 },
        noError: { percent: 0, minutes: 0 },
        loadedWithoutDischargeOpt: { percent: 0, minutes: 0 },
        undeterminedLoadedWithoutDischargeOpt: { percent: 0, minutes: 0 },
    };
    runningData: ChartItem = new ChartItem(null, null);
    errorData: ChartItem = new ChartItem(null, null);
    loadedWithoutDischargeOptData: ChartItem = new ChartItem(null, null);
    nokData: ChartItem = new ChartItem(null, null);
    errorsData: ChartItem = new ChartItem(null, null);
    boxesData: ChartItem = new ChartItem(null, null);
    isLoading: boolean;
    colorBoxes: string = this.colorsService.getColor('status-boxes'); //'#8B572A';
    colorRunning: string = this.colorsService.getColor('status-running'); //'#2da45d';
    colorErrors: string = this.colorsService.getColor('status-errors'); //'#FE840E';
    colorNoData: string = this.colorsService.getColor('status-no-data'); //'#eee';
    colorWithoutPermission: string = this.colorsService.getColor('status-without-permission'); //'#f0c32f';

    constructor(private colorsService: ColorsService) {}

    //ngOnInit(): void {
    //this.isLoading = true;
    //}

    ngOnChanges() {
        this.isLoading = true;
        this.totalKpis = {
            numberOfErrors: 0,
            numberOfBoxes: 0,
            nokPercent: 0,
            nokValue: 0,
            running: { percent: 0, minutes: 0 },
            undeterminedStatus: { percent: 0, minutes: 0 },
            stopped: { percent: 0, minutes: 0 },
            onError: { percent: 0, minutes: 0 },
            undeterminedError: { percent: 0, minutes: 0 },
            noError: { percent: 0, minutes: 0 },
            loadedWithoutDischargeOpt: { percent: 0, minutes: 0 },
            undeterminedLoadedWithoutDischargeOpt: { percent: 0, minutes: 0 },
        };
        this.runningData = new ChartItem(null, null);
        this.errorData = new ChartItem(null, null);
        this.loadedWithoutDischargeOptData = new ChartItem(null, null);
        this.nokData = new ChartItem(null, null);
        this.errorsData = new ChartItem(null, null);
        this.boxesData = new ChartItem(null, null);
        this.kpis = _.reverse(this.kpis);
        this.createCharts();
    }

    createCharts() {
        this.isLoading = true;
        let labels = [];
        let running = [];
        let undeterminedStatus = [];
        let stopped = [];
        let onError = [];
        let undeterminedError = [];
        let noError = [];
        let loadedWithoutDischargeOpt = [];
        let undeterminedLoadedWithoutDischargeOpt = [];
        let nok = [];
        let nokValues = [];
        let errors = [];
        let boxes = [];
        const utcOffset = (new Date().getTimezoneOffset() / 60) * -1;
        if (this.kpis && this.kpis.length > 0) {
            const actualHour = this.kpis.pop();
            this.kpis.forEach((kpi) => {
                let realHour = kpi.hour + utcOffset;
                if (realHour > 23) {
                    realHour = realHour - 24;
                }
                labels.push(`${realHour}:00`);
                running.push(kpi.performance.running.percent);
                this.totalKpis.running.percent += kpi.performance.running.percent;
                this.totalKpis.running.minutes += kpi.performance.running.minutes;
                undeterminedStatus.push(kpi.performance.undeterminedStatus.percent);
                this.totalKpis.undeterminedStatus.percent += kpi.performance.undeterminedStatus.percent;
                this.totalKpis.undeterminedStatus.minutes += kpi.performance.undeterminedStatus.minutes;
                stopped.push(kpi.performance.stopped.percent);
                this.totalKpis.stopped.percent += kpi.performance.stopped.percent;
                this.totalKpis.stopped.minutes += kpi.performance.stopped.minutes;

                onError.push(kpi.performance.onError.percent);
                this.totalKpis.onError.percent += kpi.performance.onError.percent;
                this.totalKpis.onError.minutes += kpi.performance.onError.minutes;
                undeterminedError.push(kpi.performance.undeterminedError.percent);
                this.totalKpis.undeterminedError.percent += kpi.performance.undeterminedError.percent;
                this.totalKpis.undeterminedError.minutes += kpi.performance.undeterminedError.minutes;
                noError.push(kpi.performance.noError.percent);
                this.totalKpis.noError.percent += kpi.performance.noError.percent;
                this.totalKpis.noError.minutes += kpi.performance.noError.minutes;

                loadedWithoutDischargeOpt.push(kpi.performance.loadedWithoutDischargeOpt.percent);
                this.totalKpis.loadedWithoutDischargeOpt.percent += kpi.performance.loadedWithoutDischargeOpt.percent;
                this.totalKpis.loadedWithoutDischargeOpt.minutes += kpi.performance.loadedWithoutDischargeOpt.minutes;
                undeterminedLoadedWithoutDischargeOpt.push(kpi.performance.undeterminedLoadedWithoutDischargeOpt.percent);
                // tslint:disable-next-line:max-line-length
                this.totalKpis.undeterminedLoadedWithoutDischargeOpt.percent += kpi.performance.undeterminedLoadedWithoutDischargeOpt.percent;
                // tslint:disable-next-line:max-line-length
                this.totalKpis.undeterminedLoadedWithoutDischargeOpt.minutes += kpi.performance.undeterminedLoadedWithoutDischargeOpt.minutes;
                this.totalKpis.nokPercent += kpi.nokPercent;
                this.totalKpis.nokValue += kpi.nokValue;
                nok.push(kpi.nokPercent);
                nokValues.push(kpi.nokValue);
                this.totalKpis.numberOfErrors += kpi.numberOfErrors;
                errors.push(kpi.numberOfErrors);
                this.totalKpis.numberOfBoxes += kpi.numberOfBoxes;
                boxes.push(kpi.numberOfBoxes);
            });
            labels.push(`${actualHour.hour + utcOffset}:00`);
            running.push(actualHour.performance.running.percent);
            stopped.push(actualHour.performance.stopped.percent);
            undeterminedStatus.push(actualHour.performance.undeterminedStatus.percent);
            onError.push(actualHour.performance.onError.percent);
            noError.push(actualHour.performance.noError.percent);
            undeterminedError.push(actualHour.performance.undeterminedError.percent);
            loadedWithoutDischargeOpt.push(actualHour.performance.loadedWithoutDischargeOpt.percent);
            undeterminedLoadedWithoutDischargeOpt.push(actualHour.performance.undeterminedLoadedWithoutDischargeOpt.percent);
            nok.push(actualHour.nokPercent);
            nokValues.push(actualHour.nokValue);
            errors.push(actualHour.numberOfErrors);
            boxes.push(actualHour.numberOfBoxes);
        } else {
            const emptyData = Array(24).fill(0);
            const emptyLabels = Array(24)
                .fill(null)
                .map((u, i) => `${i}:00`);
            labels = emptyLabels;
            running = emptyData;
            undeterminedStatus = emptyData;
            stopped = emptyData;
            onError = emptyData;
            undeterminedError = emptyData;
            noError = emptyData;
            loadedWithoutDischargeOpt = emptyData;
            undeterminedLoadedWithoutDischargeOpt = emptyData;
            nok = emptyData;
            nokValues = emptyData;
            errors = emptyData;
            boxes = emptyData;
        }

        this.runningData = new ChartItem(labels, [
            {
                data: running,
                label: 'Running',
                backgroundColor: this.colorRunning,
            },
            {
                data: stopped,
                label: 'Stopped',
                backgroundColor: '#d2d2d2',
            },
            {
                data: undeterminedStatus,
                label: 'No data',
                backgroundColor: this.colorNoData,
            },
        ]);
        this.errorData = new ChartItem(labels, [
            {
                data: onError,
                label: 'On error',
                backgroundColor: this.colorErrors,
            },
            {
                data: noError,
                label: 'No error',
                backgroundColor: '#b4d7bf',
            },
            {
                data: undeterminedError,
                label: 'No data',
                backgroundColor: this.colorNoData,
            },
        ]);
        this.loadedWithoutDischargeOptData = new ChartItem(labels, [
            {
                data: loadedWithoutDischargeOpt,
                label: 'Loaded without permission',
                backgroundColor: this.colorWithoutPermission,
            },
            {
                data: undeterminedLoadedWithoutDischargeOpt,
                label: 'No data',
                backgroundColor: this.colorNoData,
            },
        ]);
        this.nokData = new ChartItem(labels, [
            {
                data: nokValues,
                label: 'NOK',
                backgroundColor: this.colorErrors,
            },
        ]);
        this.errorsData = new ChartItem(labels, [
            {
                data: errors,
                label: 'Nº of Errors',
                backgroundColor: this.colorErrors,
            },
        ]);
        this.boxesData = new ChartItem(labels, [
            {
                data: new Array(24).fill(this.totalKpis.numberOfBoxes / 24),
                type: 'line',
                label: 'Items/Hour',
                backgroundColor: this.colorRunning,
                fill: false,
            },
            {
                data: boxes,
                label: 'Nº of Items',
                backgroundColor: this.colorBoxes,
            },
        ]);
        this.isLoading = false;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorsService } from '@app/shared/services/colors.service';

@Component({
    selector: 'shared-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
    @Input() colorRange = '';
    @Input() fromDataRange = '';
    @Input() toDataRange = '';
    @Input() okButtonText = 'Save';
    @Input() symbol = '%';
    @Input() middle = '-';
    @Input() disabled: boolean = false;
    @Output() colorSelected = new EventEmitter();

    colorStarts: string = this.colorsService.getColor('status-starts'); //'#FCDE82';
    colorCritical: string = this.colorsService.getColor('status-critical'); //'#ff2800';
    colorErrors: string = this.colorsService.getColor('status-errors'); //'#FE840E';
    colorRunning: string = this.colorsService.getColor('status-running'); //'#2da45d';
    colorLWDO: string = this.colorsService.getColor('status-lwdo'); //'#a938cc';
    colorNoData: string = this.colorsService.getColor('status-no-data'); //'#eee';

    constructor(private colorsService: ColorsService) {}

    saveColorPicker(event) {
        this.colorSelected.emit(event);
    }
}

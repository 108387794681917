import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Structure } from '@home/shared/structure';
import { environment } from '@environments/environment';
import { AppLoadService } from '@app/app-load.service';
import { shareReplay, switchMap } from 'rxjs/operators';

@Injectable()
export class HomeService {
    baseUrl: string;
    warehouse: string;

    constructor(private http: HttpClient, private appLoadService: AppLoadService) {}

    getStructure(): Observable<Structure> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'map' : `map/${this.warehouse}`;
                return this.http.get<Structure>(`${this.baseUrl}/${endpoint}`);
            }),
            shareReplay()
        );
    }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { childRoutes } from '@variables/shared/variables.routes';
import { VariablesService } from '@variables/shared/variables.service';
import { VariablesResolver } from '@variables/shared/variables.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VariablesActiveComponent } from './components/active/variables-active.component';
import { VariablesHistoryComponent } from './components/history/variables-history.component';
import { VariablesDashboardComponent } from './components/dashboard/variables-dashboard.component';

@NgModule({
    imports: [SharedModule, NgxDatatableModule, RouterModule.forChild(childRoutes)],
    declarations: [VariablesActiveComponent, VariablesHistoryComponent, VariablesDashboardComponent],
    providers: [VariablesService, VariablesResolver],
})
export class VariablesModule {}

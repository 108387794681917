<div class="row search-nav mt-3">
    <div class="col-md-12 col-lg-6">
        <h2 class="mb-2">{{ warehouse.displayName | titlecase }} Custom Views</h2>
    </div>
    <app-dropdown-checkbox
        label="States"
        [icon]="filterIcon"
        [iconTitle]="filterTitle"
        [availableItems]="availableStates"
        (changeFilter)="handleChangeVisibleStates($event)"
        extraClasses="ps-lg-0"
    ></app-dropdown-checkbox>
    <app-select #gridSelector key="grid-selector" label="Select grid" columns="2" [item$]="grid$" [selectedValue]="grid"></app-select>
    <app-select-multilevel #viewSelector key="faz-search" label="Select view" [withAllValue]="false" columns="3"></app-select-multilevel>
</div>
<div class="row">
    <div class="col-md-6 col-12 border" [ngClass]="'col-lg-' + gridSize" *ngFor="let view of views">
        <div class="row">
            <div class="col-12 custom-title">
                <span class="float-start">
                    <small
                        class="force-one-line"
                        [title]="
                            view.name
                                | fazlDecorator
                                    : [
                                          ['F', identifiersPreferences.Floor],
                                          ['A', identifiersPreferences.Area],
                                          ['Z', identifiersPreferences.Zone],
                                          ['EQL', identifiersPreferences.Line]
                                      ]
                        "
                        >{{
                            view.name
                                | fazlDecorator
                                    : [
                                          ['F', identifiersPreferences.Floor],
                                          ['A', identifiersPreferences.Area],
                                          ['Z', identifiersPreferences.Zone],
                                          ['EQL', identifiersPreferences.Line]
                                      ]
                        }}</small
                    >
                </span>
                <a class="float-end icon-close" (click)="removeView(view.name)"></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 px-0">
                <app-custom-view
                    [warehouse]="warehouse"
                    [view]="view"
                    [icons]="icons"
                    [visibleStates]="visibleStates"
                    (childClick)="openInNewTab($event)"
                ></app-custom-view>
            </div>
        </div>
    </div>
</div>

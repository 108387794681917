import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CustomViewComponent } from './components/custom-view/custom-view.component';
import { CustomComponent } from '@custom/custom.component';
import { CustomResolver } from './custom.resolver';

@NgModule({
    imports: [SharedModule],
    declarations: [CustomComponent, CustomViewComponent],
    providers: [CustomResolver],
})
export class CustomModule {}

export class Alert {
    alarmSourceTimeStamp: Date;
    closedTimeStamp: Date;
    equipmentId: string;
    equipmentType: string;
    description: string;
    priority: string;
    floorId: string;
    areaId: string;
    zoneId: string;
    lineId: string;
    state: string;
    isCritical?: boolean;
    realDescription?: string; //used to enable app-search in alerts active

    constructor(
        alarmSourceTimeStamp: Date,
        closedTimeStamp: Date,
        equipmentId: string,
        equipmentType: string,
        description: string,
        priority: string,
        state: string,
        floorId: string,
        areaId: string,
        zoneId: string,
        lineId: string
    ) {
        this.alarmSourceTimeStamp = alarmSourceTimeStamp;
        this.closedTimeStamp = closedTimeStamp;
        this.equipmentId = equipmentId;
        this.equipmentType = equipmentType;
        this.description = description;
        this.priority = priority;
        this.floorId = floorId;
        this.areaId = areaId;
        this.zoneId = zoneId;
        this.lineId = lineId;
        this.state = state;
    }
}

export class AlertSnapshotVariable {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    fqn: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    value: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sourceTimestamp: Date;
}

import { Route } from '@angular/router';
import { VariablesActiveComponent } from '@variables/components/active/variables-active.component';
import { VariablesHistoryComponent } from '@variables/components/history/variables-history.component';
import { VariablesDashboardComponent } from '@variables/components/dashboard/variables-dashboard.component';
import { VariablesResolver } from '@variables/shared/variables.resolver';

export const childRoutes: Route[] = [
    { path: 'active', component: VariablesActiveComponent, resolve: { variables: VariablesResolver } },
    { path: 'history', component: VariablesHistoryComponent, resolve: { variables: VariablesResolver } },
    { path: 'dashboard', component: VariablesDashboardComponent, resolve: { variables: VariablesResolver } },
];

<svg width="0" height="0" viewBox="0 0 0 0" style="position: absolute">
    <defs>
        <marker id="triangle" viewBox="0 0 10 10" refX="1" refY="5" markerUnits="strokeWidth" markerWidth="10" markerHeight="10" orient="auto">
            <path d="M 0 0 L 10 5 L 0 10 z" fill="#555" />
        </marker>

        <!-- PROPOSAL -->
        <filter id="warning-shadow">
            <feDropShadow i="node_shadow" dx="0" dy="0" stdDeviation="0.2" flood-color="#FCDE82">
                <animate attributeName="stdDeviation" values="0;0.2;0" begin="0" dur="2s" repeatCount="indefinite" />
            </feDropShadow>
        </filter>
        <filter id="disconnected-shadow">
            <feDropShadow dx="0" dy="0" stdDeviation=".3" flood-color="red">
                <!-- <animate attributeName="stdDeviation" values="0;1;0"  begin="0"  dur="2s" repeatCount="indefinite"/> -->
            </feDropShadow>
        </filter>
        <filter id="warning-disconnected-shadow">
            <feDropShadow i="node_shadow" dx="0" dy="0" stdDeviation="0" flood-color="#000">
                <animate attributeName="stdDeviation" values="0;.2;.2;.2;.2;.2;.2;0" begin="0" dur="8s" repeatCount="indefinite" />
            </feDropShadow>
            <feDropShadow i="node_shadow" dx="0" dy="0" stdDeviation="0.2" flood-color="#fcde82">
                <animate attributeName="stdDeviation" values=".2;.2;.2;0;0;.2;.2;.2" begin="0" dur="8s" repeatCount="indefinite" />
            </feDropShadow>
        </filter>
    </defs>

    <pattern id="pattern-hex" x="0" y="0" width="5" height="5" patternUnits="userSpaceOnUse">
        <rect width="5" height="5" fill="#c7c7c7" />
        <path d="M0 5L5 0ZM6 4L4 6ZM-1 1L1 -1Z" stroke="#f5f5f5" stroke-width="5" />
    </pattern>

    <!-- UNKNOWN -->
    <pattern id="pattern-unknown-warning" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorNoData" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorWarning" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-unknown-disconnected" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" stroke="#000" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorNoData" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-unknown-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorNoData" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorBoxes" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-unknown-warning-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <rect width="100" height="100" [attr.fill]="colorNoData" />
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="16" />
        <line x1="33" y="0" x2="33" y2="100" [attr.stroke]="colorWarning" stroke-width="33" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>

    <!-- RUN -->
    <pattern id="pattern-run-warning" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorRunning" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorWarning" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-run-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorRunning" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-run-warning-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <rect width="100" height="100" [attr.fill]="colorRunning" />
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="16" />
        <line x1="33" y="0" x2="33" y2="100" [attr.stroke]="colorWarning" stroke-width="33" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>

    <!-- WITHOUT PERMISSION -->
    <pattern id="pattern-without-permission-warning" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorLWDO" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorWarning" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-without-permission-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorLWDO" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-without-permission-warning-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <rect width="100" height="100" [attr.fill]="colorLWDO" />
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="16" />
        <line x1="33" y="0" x2="33" y2="100" [attr.stroke]="colorWarning" stroke-width="33" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>

    <!-- ALERT -->
    <pattern id="pattern-alert-warning" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorErrors" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorWarning" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-alert-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorErrors" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-alert-warning-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <rect width="100" height="100" [attr.fill]="colorErrors" />
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="16" />
        <line x1="33" y="0" x2="33" y2="100" [attr.stroke]="colorWarning" stroke-width="33" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>

    <!-- CRITICAL -->
    <pattern id="pattern-critical-warning" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorCritical" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorWarning" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-critical-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="20" />
        <line x1="20" y="0" x2="20" y2="100" [attr.stroke]="colorCritical" stroke-width="20" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
    <pattern id="pattern-critical-warning-box" patternUnits="userSpaceOnUse" width="25" height="25" patternTransform="rotate(45)">
        <rect width="100" height="100" [attr.fill]="colorCritical" />
        <line x1="0" y="0" x2="0" y2="100" [attr.stroke]="colorBoxes" stroke-width="16" />
        <line x1="33" y="0" x2="33" y2="100" [attr.stroke]="colorWarning" stroke-width="33" />
        <animateTransform attributeName="patternTransform" type="translate" by="100 100" dur="15s" repeatCount="indefinite"></animateTransform>
    </pattern>
</svg>

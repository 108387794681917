import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FloorsService } from '@floors/shared/floors.service';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { AppLoadService } from '@app/app-load.service';
import { Floor, Structure } from '@home/shared/structure';

@Injectable()
export class FloorsResolver  {
    constructor(
        private floorsService: FloorsService,
        private equipmentsService: EquipmentsService,
        private appLoadService: AppLoadService,
    ) {}

    getFloorMap(route): Observable<Structure | Floor> {
        return this.floorsService.getFloor(route.params.floorId).pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema en el provider al recuperar el mapa de la planta');
                return of(null);
            }),
        );
    }

    getEquipmentTypes(): Observable<string[]> {
        return this.equipmentsService.getEquipmentTypes().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar los tipos de equipos');
                return of(null);
            }),
        );
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            this.getFloorMap(route),
            this.getEquipmentTypes(),
            this.appLoadService.getCurrentWarehouse.pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar el almacen actual', err);
                    return of(null);
                }),
            ),
            this.appLoadService.getCurrentConfiguration.pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar la configuracion', err);
                    return of(null);
                }),
            ),
        ]).pipe(
            map((x) => {
                return {
                    map: x[0],
                    equipmentsTypes: x[1],
                    warehouse: x[2],
                    configuration: x[3],
                };
            }),
        );
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { LinesComponent } from '@lines/components/lines.component';
import { LinesService } from '@lines/shared/lines.service';
import { LinesResolver } from '@lines/shared/lines.resolver';
import { AlertsService } from '@alerts/shared/alerts.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SignalRHubService } from '../../notifications/signalr-hub.service';

@NgModule({
    imports: [SharedModule, NgxDatatableModule, RouterModule],
    declarations: [LinesComponent],
    providers: [LinesService, AlertsService, LinesResolver, SignalRHubService],
})
export class LinesModule {}

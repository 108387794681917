import { Component } from '@angular/core';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent {
    acquireTokenFailure = '';
    loginFailure = '';

    constructor() {}
}

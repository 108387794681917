import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FloorsComponent } from '@floors/components/floors.component';
import { FloorsService } from '@floors/shared/floors.service';
import { FloorsResolver } from '@floors/shared/floors.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [SharedModule, NgxDatatableModule, RouterModule],
    declarations: [FloorsComponent],
    providers: [FloorsService, FloorsResolver],
})
export class FloorsModule {}

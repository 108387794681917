import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, shareReplay, switchMap } from 'rxjs/operators';
import { Area, Structure } from '@home/shared/structure';
import { environment } from '@environments/environment';
import { AppLoadService } from '@app/app-load.service';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';

@Injectable()
export class AreasService {
    baseUrl: string;
    warehouse: string;

    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) {}

    getArea(idFloor: string, idArea: string): Observable<Structure | Area> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;

                // tslint:disable-next-line:max-line-length
                const endpoint = environment.mode === 'front' ? 'map' : `map/${this.warehouse}/definitions/floors/${idFloor}/areas/${idArea}`;
                return this.http.get<Structure>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                if (environment.mode !== 'front') {
                    return resp;
                } else {
                    const floor = resp.floors.filter((f) => String(f.id) === idFloor);
                    const area = floor[0].areas.filter((a) => String(a.id) === idArea);
                    area[0].freeBacksLines = floor[0].freeBacksLines;
                    return area[0];
                }
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar el mapa del area', err);
                return of(null);
            }),
            shareReplay(),
        );
    }

    getAreaStatus(idFloor: string, idArea: string): Observable<Array<LineStatusNotification>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'status' : `state/${this.warehouse}/floors/${idFloor}/areas/${idArea}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => (environment.mode === 'front' ? resp : resp.lineStates)),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado del area -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }

    // getAreaKpis(idFloor: string, idArea: string): Observable<Kpis> {
    //     return this.appLoadService.getCurrentWarehouse.pipe(
    //         switchMap((res) => {
    //             this.baseUrl = `${res.hostName}/api`.toLowerCase();
    //             this.warehouse = res.warehouse;
    //             const endpoint = environment.mode === 'front' ? 'kpis' : `kpis/${this.warehouse}/floors/${idFloor}/areas/${idArea}`;
    //             return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
    //         }),
    //         catchError((err) => {
    //             console.error(`Ha ocurrido un problema en el service al recuperar los KPI´s del area -> ${err}`);
    //             return of(null);
    //         }),
    //         shareReplay()
    //     );
    // }

    areaNotificationReceieved(notificacion: any): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'state' : `state/${this.warehouse}/notifications/notificationReceived`;
                return this.http.post<any>(`${this.baseUrl}/${endpoint}`, notificacion);
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema al mandar la rececpción de una notificación del area -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }
}

import { Component, OnDestroy } from '@angular/core';
import { MapState } from '../../models/map-state';
import { StateService } from '@app/core/shared/state/state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServiceLocator } from '@app/shared/services/service-locator.service';

@Component({
    templateUrl: './map-container.component.html',
    styleUrls: ['./map-container.component.scss'],
})
export class MapContainerComponent implements OnDestroy {
    visibleStates: Array<string> = [];
    availableStates: Array<MapState> = [];
    stateService: StateService;
    filterIcon: string;
    filterTitle = 'There are some states filtered';
    private subscribed: Subject<any> = new Subject();

    constructor() {
        this.stateService = ServiceLocator.injector.get(StateService);
        this.applyVisibleStates();
        this.stateService.getFilterStates.pipe(takeUntil(this.subscribed)).subscribe((filters) => {
            this.availableStates = filters;
            this.applyVisibleStates();
        });
    }

    ngOnDestroy(): void {
        this.subscribed.next(true);
        this.subscribed.complete();
    }

    changeVisibleStates(states: Array<MapState>) {
        this.stateService.setFilterStates(states);
    }

    applyVisibleStates() {
        this.visibleStates = this.availableStates.filter(({ visible }) => visible).map(({ id }) => id);
        this.filterIcon = this.availableStates.length === this.visibleStates.length ? null : 'fa-filter';
    }
}

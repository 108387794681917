import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Structure, Zone } from '@home/shared/structure';
import { environment } from '@environments/environment';
import { Kpis } from '@app/shared/models/kpis';
import { KpisResume } from '@app/shared/models/kpis-resume';
import { AppLoadService } from '@app/app-load.service';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import * as _ from 'lodash-es';

@Injectable()
export class ZonesService {
    baseUrl: string;
    warehouse: string;

    constructor(private http: HttpClient, private appLoadService: AppLoadService) {}

    getZone(idFloor: string, idArea: string, idZone: string): Observable<Zone> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = environment.mode === 'front' ? 'map' : `map/${this.warehouse}/definitions/floors/${idFloor}/areas/${idArea}/zones/${idZone}`;
                return this.http.get<Structure>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                if (environment.mode !== 'front') {
                    return resp;
                } else {
                    const floor = _.find(resp.floors, { id: idFloor });
                    const area = _.find(floor.areas, { id: idArea });
                    const zone = _.find(area.zones, { id: idZone });
                    zone[0].freeBacksLines = floor[0].freeBacksLines;
                    return zone[0];
                }
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar el mapa de la zona', err);
                return of(null);
            }),
            shareReplay()
        );
    }

    getZoneStatus(idFloor: string, idArea: string, idZone: string): Observable<Array<LineStatusNotification>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = environment.mode === 'front' ? 'status' : `state/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                return environment.mode === 'front' ? resp : resp.lineStates;
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado de la zona -> ${err}`);
                return of(null);
            }),
            shareReplay()
        );
    }

    // getZoneKpis(idFloor: string, idArea: string, idZone: string): Observable<Kpis> {
    //     return this.appLoadService.getCurrentWarehouse.pipe(
    //         switchMap((res) => {
    //             this.baseUrl = `${res.hostName}/api`.toLowerCase();
    //             this.warehouse = res.warehouse;
    //             // tslint:disable-next-line:max-line-length
    //             const endpoint = environment.mode === 'front' ? 'kpis' : `kpis/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}`;
    //             return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
    //         }),
    //         map((resp) => {
    //             return resp;
    //         }),
    //         catchError((err) => {
    //             console.error(`Ha ocurrido un problema en el service al recuperar los KPI´s de la zona -> ${err}`);
    //             return of(null);
    //         }),
    //         shareReplay()
    //     );
    // }

    getZoneKpisResume(idFloor: string, idArea: string, idZone: string): Observable<KpisResume> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = environment.mode === 'front' ? 'kpisResume' : `floors/${idFloor}/areas/${idArea}/zones/${idZone}/kpisResume`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar los KPI´s de la zona -> ${err}`);
                return of(null);
            }),
            shareReplay()
        );
    }

    zoneNotificationReceieved(notificacion: any): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'state' : `state/${this.warehouse}/notifications/notificationReceived`;
                return this.http.post<any>(`${this.baseUrl}/${endpoint}`, notificacion);
            }),
            catchError((err) => {
                console.log(`Ha ocurrido un problema al mandar la rececpción de una notificación de la zona -> ${err}`);
                return of(null);
            }),
            shareReplay()
        );
    }
}

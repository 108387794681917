/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { NgModule, APP_INITIALIZER, ErrorHandler, Injector, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    MsalModule,
    MsalService,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalGuard,
    MsalGuardConfiguration,
    MsalBroadcastService,
    MsalRedirectComponent,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AppComponent } from '@app/app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { SettingsService } from './core/shared/settings/settings.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LandingModule } from './landing/landing.module';
import { HomeModule } from './map/home/home.module';
import { FloorsModule } from './map/floors/floors.module';
import { AreasModule } from './map/areas/areas.module';
import { ZonesModule } from './map/zones/zones.module';
import { LinesModule } from './map/lines/lines.module';
import { VariablesModule } from '@variables/variables.module';
import { UserManualModule } from './user-manual/user-manual.module';
import { AppRoutingModule } from './app-routing.module';
import { AppRoutingFrontModule } from './app-routing-front.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MonitoringService } from './shared/services/monitoring.service';
import { AppLoadService } from './app-load.service';
import { CachingInterceptor } from './core/interceptors/caching-interceptor';
import { MonitoringErrorHandler } from './shared/services/monitoring.error.handler';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { HttpErrorResponseInterceptor } from './core/interceptors/http-error-interceptor';
import { AysCommonsModule, HttpErrorInterceptor } from '@ays';
import { CustomModule } from '@custom/custom.module';
import { AlertsActiveStatusService } from './notifications/shared/handlers/alertsActive-status-service';
import { CommunicationStatusService } from './notifications/shared/handlers/connections-status-service';
import { AlertsCountStatusService } from './notifications/shared/handlers/alertsCount-status-service';
import { ReportCountStatusService } from './notifications/shared/handlers/reportCount-status-service';
//import { VariablesActiveStatusService } from './notifications/shared/handlers/variablesActive-status-service';
import { WarehouseStatusClient } from './notifications/shared/clients/warehouse-status.client';
//import { VariablesStatusClient } from './notifications/shared/clients/variables-status.client';
import { ReportStatusClient } from './notifications/shared/clients/report-status.client';
import { CommunicationStatusClient } from './notifications/shared/clients/communications-status.client';
import { ActiveAlertsClient } from './notifications/shared/clients/active-alerts.client';
import { ServiceLocator } from './shared/services/service-locator.service';
import { CustomsModule } from '@customs/customs.module';
import { WarehouseStatusService } from './notifications/shared/handlers/warehouse-status-service';
import { MarkdownModule } from 'ngx-markdown';

export function initApp(firstLoadService: AppLoadService) {
    return () => firstLoadService.initialize();
}

export function getConfig(settingsService: SettingsService): IPublicClientApplication {
    return settingsService.getMsalConfig();
}
export function getInterceptorConfig(settingsService: SettingsService): MsalInterceptorConfiguration {
    return settingsService.getMsalInterceptorConfig();
}
export function getGuardConfig(settingsService: SettingsService): MsalGuardConfiguration {
    return settingsService.getmsalGuardConfig();
}

export function appInsightsFactory(settingsService: SettingsService): ApplicationInsights {
    let appInsightsInstrumentationKey: string;
    // eslint-disable-next-line prefer-const
    appInsightsInstrumentationKey = undefined;

    return new ApplicationInsights({
        config: {
            instrumentationKey: appInsightsInstrumentationKey,
        },
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        MsalModule,
        AysCommonsModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        LandingModule,
        //PowerBiModule,
        CustomModule,
        HomeModule,
        CustomsModule,
        FloorsModule,
        AreasModule,
        ZonesModule,
        LinesModule,
        VariablesModule,
        UserManualModule,
        environment.mode === 'front' ? [AppRoutingFrontModule] : [AppRoutingModule],
        NgxDatatableModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        //MarkdownModule.forRoot() 
        MarkdownModule.forRoot({ loader: HttpClient }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        MsalGuard,
        MsalService,
        MsalBroadcastService,
        ActiveAlertsClient,
        CommunicationStatusClient,
        ReportStatusClient,
        //VariablesStatusClient,
        WarehouseStatusClient,
        AlertsActiveStatusService,
        CommunicationStatusService,
        AlertsCountStatusService,
        ReportCountStatusService,
        WarehouseStatusService,
        //VariablesActiveStatusService,
        MonitoringService,
        AppLoadService,
        SettingsService,
        ServiceLocator,
        { provide: ApplicationInsights, useFactory: appInsightsFactory },
        { provide: ErrorHandler, useClass: MonitoringErrorHandler },
        { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorResponseInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppLoadService], multi: true },
        { provide: MSAL_INSTANCE, useFactory: getConfig, deps: [SettingsService] },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: getInterceptorConfig, deps: [SettingsService] },
        { provide: MSAL_GUARD_CONFIG, useFactory: getGuardConfig, deps: [SettingsService] },
        //environment.mode !== 'front' ? [CanActivateRoute, { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }] : [],
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    constructor(private injector: Injector) {
        ServiceLocator.injector = this.injector;
    }
}

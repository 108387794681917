import { LineStatusNotification, RawLineStatusNotification } from '../events/line-status';

const lineStatusMapper = (lineStatusNotification: RawLineStatusNotification): LineStatusNotification => {
    return {
        floorId: lineStatusNotification.floorid,
        areaId: lineStatusNotification.areaid,
        zoneId: lineStatusNotification.zoneid,
        lineId: lineStatusNotification.lineid,
        state: lineStatusNotification.state,
        description: lineStatusNotification.stateText,
        device: lineStatusNotification.device,
        timestamp: lineStatusNotification.timestamp,
        serverts: lineStatusNotification.serverts,
        sourcets: lineStatusNotification.sourcets,
        source: lineStatusNotification.source,
    };
};

export default lineStatusMapper;

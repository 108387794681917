import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(error: any): void {
        try {
            this.injector.get<MonitoringService>(MonitoringService).logError(error, { name: 'Front.Exception', message: error.message }, {});
            //super.handleError(error);
        } catch (err) {
            console.log(`Unable log error ${error.message}`, err);
        }
    }
}

export const defaultZoomPercent = 5;

export const minViewBoxSizeToHaveZoom = 10; //20

/* get Click position inside the svg */
export const svgClickPosition = (e, svg2ForEvents): { x: number; y: number } => {
    const p = svg2ForEvents.createSVGPoint();
    p.x = e?.clientX || 0;
    p.y = e?.clientY || 0;
    const ctm = svg2ForEvents.getScreenCTM().inverse();
    const pa = p.matrixTransform(ctm);
    return {
        x: pa.x,
        y: pa.y,
    };
};

// https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/
export const isInViewport = (el: HTMLElement, rectSVG): boolean => {
    try {
        const rect = el.getBoundingClientRect();

        //this checks if part of the element is inside the svg element.
        return (
            (rect.top >= rectSVG.top && rect.top < rectSVG.bottom && rect.left >= rectSVG.left && rect.left < rectSVG.right) || //top-left point
            (rect.top >= rectSVG.top && rect.top < rectSVG.bottom && rect.right <= rectSVG.right && rect.right > rectSVG.left) || //top-right point
            (rect.bottom <= rectSVG.bottom && rect.bottom > rectSVG.top && rect.left >= rectSVG.left && rect.left < rectSVG.right) || //bottom-left point
            (rect.bottom <= rectSVG.bottom && rect.bottom > rectSVG.top && rect.right <= rectSVG.right && rect.right > rectSVG.left) //bottom-right point
        );
        
    } catch (error) {
        console.error(error);
        // if there is an error, ensure all elements are addressed as visible
        return true;
    }
};

export function createPathUrl(device) {
        const type = device.type.toLowerCase();
        const mode = type === 'external' ? device.mode.slice(device.mode.length - 1).toLowerCase() : device.mode.toLowerCase();
        return `assets/img/paths/${mode}.svg#${mode}`;
}

export function createEquipmentUrl(device, iconNames) {
    const acceptedLimits = ['start', 'end'];

    let type = device.type.toLowerCase();
    type = type === 'external' ? device.mode.slice(0, device.mode.length - 1).toLowerCase() : type;
    if (iconNames.includes(type)) {
        return `#${type}`;
    } else {
        if (!acceptedLimits.includes(type)) {
            return `#other`;
        }
    }
}

export function createArrow(data) {
    const acceptedArrows = ['hl', 'hr', 'vu', 'vd'];

    if (data) {
        const orientation = data.toLowerCase();
        return acceptedArrows.includes(orientation) ? `assets/img/arrows/${orientation}.svg#${orientation}` : ``;
    } else {
        return ``;
    }
}

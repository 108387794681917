<div [ngClass]="extraClasses">
    <strong *ngIf="dimension">{{ dimension }}</strong>
    <div class="mt-3">
        <div [ngClass]="extraClassesInternalDiv" *ngIf="dimension !== 'Errors'">
            <app-range-slider
                columns="3"
                [dataRangeMin]="firstRange"
                [dataRangeMax]="secondRange"
                (applyRanges)="applyingRanges(dimension, $event)"
                [ngClass]="{ 'd-none': !rangeEdition }"
            ></app-range-slider>
        </div>
        <div class="col-12 col-md-8 col-lg-6" *ngIf="dimension === 'Errors'" [ngClass]="{ 'd-none': !rangeEdition }">
            <table class="table-compact table-hover w-75 ms-5 mt-1 mb-5">
                <thead>
                    <tr>
                        <th class="w-25">Name</th>
                        <th class="text-end w-25">From</th>
                        <th class="text-center w-25" style="border-bottom: none">
                            <i
                                class="fas fa-times text-danger close-ranges-button"
                                title="Cancel"
                                data-bs-toggle="tooltip"
                                (click)="rangeEdition = !rangeEdition"
                            ></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of errorsDimensionRanges">
                        <td>
                            <span></span>
                            <span (dblclick)="editingErrorsNameValue(item.rangeId, item.name, errorsTxtNewName)" [hidden]="editingErrorsName === item.rangeId">
                                {{ item.name }}
                            </span>
                            <div [hidden]="editingErrorsName !== item.rangeId">
                                <app-input #errorsTxtNewName key="errorsTxtNewName{{ item.rangeId }}" label="Name" extraClasses="w-100"></app-input>
                                <button
                                    class="btn btn-sm btn-editor float-end ms-1"
                                    (click)="saveErrorsNameValue(item.rangeId, item.name, errorsTxtNewName)"
                                    title="SAVE"
                                >
                                    <i class="fa fa-save" aria-hidden="true"></i>
                                </button>
                                <button class="btn btn-sm btn-editor float-end ms-1" (click)="editingErrorsName = ''" title="CANCEL">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                        </td>
                        <td class="text-end">
                            <span (dblclick)="editingErrorsFromValue(item.rangeId, item.range, errorsTxtNewFrom)" [hidden]="editingErrorsFrom === item.rangeId">
                                {{ item.range }}
                            </span>
                            <div [hidden]="editingErrorsFrom !== item.rangeId">
                                <app-input
                                    #errorsTxtNewFrom
                                    key="errorsTxtNewFrom{{ item.rangeId }}"
                                    label="From"
                                    type="number"
                                    withoutArrows="true"
                                    extraClasses="w-100 float-end text-end"
                                ></app-input>

                                <button
                                    class="btn btn-sm btn-editor float-end ms-1"
                                    (click)="saveErrorsFromValue(item.rangeId, item.range, errorsTxtNewFrom)"
                                    title="SAVE"
                                >
                                    <i class="fa fa-save" aria-hidden="true"></i>
                                </button>
                                <button class="btn btn-sm btn-editor float-end ms-1" (click)="editingErrorsFrom = ''" title="CANCEL">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-8 ms-5" [ngClass]="{ 'd-none': rangeEdition }">
            <shared-color-picker
                #firstElement
                class="level-1 badge me-1"
                [colorRange]="firstColorRange"
                [symbol]="dimension === 'Errors' ? '' : '%'"
                [fromDataRange]="dimension === 'Errors' ? firstRange : '0'"
                [toDataRange]="dimension === 'Errors' ? errorsMiddleRange - 1 : firstRange"
                (colorSelected)="saveColorPicker(dimension, 'firstColorRange', (firstColorRange = $event))"
                [ngStyle]="{ color: firstColorRange | textColor }"
            ></shared-color-picker>

            <shared-color-picker
                #secondElement
                class="level-2 badge me-1"
                [colorRange]="secondColorRange"
                [symbol]="dimension === 'Errors' ? '' : '%'"
                [fromDataRange]="dimension === 'Errors' ? errorsMiddleRange : firstRange + 1"
                [toDataRange]="dimension === 'Errors' ? secondRange - 1 : secondRange"
                (colorSelected)="saveColorPicker(dimension, 'secondColorRange', (secondColorRange = $event))"
                [ngStyle]="{ color: secondColorRange | textColor }"
            ></shared-color-picker>

            <shared-color-picker
                #thirdElement
                id="thirdColorRange"
                class="level-3 badge me-1"
                [colorRange]="thirdColorRange"
                [symbol]="dimension === 'Errors' ? '' : '%'"
                [fromDataRange]="dimension === 'Errors' ? secondRange : secondRange + 1"
                [toDataRange]="dimension === 'Errors' ? '∞' : '100'"
                (colorSelected)="saveColorPicker(dimension, 'thirdColorRange', (thirdColorRange = $event))"
                [ngStyle]="{ color: thirdColorRange | textColor }"
            ></shared-color-picker>
            <i class="fas fa-edit ps-3" title="Change values" data-bs-toggle="tooltip" (click)="rangeEdition = true"></i>
        </div>
    </div>
</div>

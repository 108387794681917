import { Pipe, PipeTransform } from '@angular/core';
import { VariablesDescriptionService } from '@variables/shared/variables-description.service';

@Pipe({
    name: 'variableDescriptionPipe',
})
export class VariableDescriptionPipe implements PipeTransform {
    constructor(private variablesDescriptionService?: VariablesDescriptionService) {}

    transform(variable: string, type: string = ''): string {
        const variableAndType = (type === '' ? variable : `${type}.${variable}`).toString().toUpperCase();
        const variablesDescriptionLocal = this.variablesDescriptionService.getVariableDescriptionsLocal();

        return variablesDescriptionLocal && JSON.parse(variablesDescriptionLocal)[String(variableAndType)]
            ? `${JSON.parse(variablesDescriptionLocal)[String(variableAndType)]}`
            : variable;
    }
}

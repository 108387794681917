/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SignalRHubService, HubConnection } from '@notifications/signalr-hub.service';
import { AppLoadService } from '@app/app-load.service';
import { environment } from '@environments/environment';

@Injectable()
export class CommunicationStatusClient {
    static SupportedEvents = {
        FG_STATUS_CHANGED: 'FieldGatewayStatusChanged',
        OPC_STATUS_CHANGED: 'OpcServerStatusChanged',
        NODE_STATUS_CHANGED: 'NodeStatusChanged',
    };
    endPoint: string;
    connection: HubConnection;

    constructor(private hubService: SignalRHubService, private appLoadService: AppLoadService) {}

    public connectToHub(onReconnected: () => Promise<void>, onDisconnected: () => Promise<void>) {
        if (environment.mode !== 'front') {
            this.appLoadService.getCurrentWarehouse.subscribe((res) => {
                this.endPoint = `${res.hostName}/communicationstatus`.toLowerCase();
                this.hubService.connect(this.endPoint, onReconnected, onDisconnected).then((connection) => (this.connection = connection));
            });
        }
    }

    public disconnectFromHub() {
        if (environment.mode !== 'front') {
            this.hubService.disconnect(this.connection);
        }
    }

    public async subscribe(eventName: string, groupName: string, handler: (...args: any[]) => void) {
        const that = this;
        return new Promise((resolve) => {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            (function subscribe(innerEventName, InnerGroupName, innerHandler) {
                if (that.connection) {
                    resolve(that.hubService.subscribe(that.connection, innerEventName, InnerGroupName, innerHandler));
                } else {
                    // console.warn(`Unable to subscribe to ${innerEventName}`);
                    setTimeout(() => subscribe(innerEventName, InnerGroupName, innerHandler), 5000);
                }
            })(eventName, groupName, handler);
        });
    }

    public async unsubscribe(eventName: string, groupName: string) {
        await this.hubService.unsubscribe(this.connection, eventName, groupName);
    }
}

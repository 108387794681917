import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { PowerBiService } from './powerbi.service';
import * as dayjs from 'dayjs';

@Injectable()
export class PowerBiResolver  {
    constructor(private powerBiService: PowerBiService) {}

    getData(route): Observable<any> {
        const params = route.queryParams;
        const queryParams = {
            mode: 'zones',
            pageNumber: 1,
            orderColumn: 'id',
            orderType: 'desc',
            start: dayjs().subtract(7, 'days').startOf('day').format('D/M/YY HH:mm'),
            end: dayjs().format('D/M/YY HH:mm'),
        };

        // eslint-disable-next-line guard-for-in
        for (const key in params) {
            if (key === 'start') {
                queryParams.start = params[key];
            }
            if (key === 'end') {
                queryParams.end = params[key];
            }
        }
        return this.powerBiService.getData(queryParams).pipe(
            take(1),
            catchError((err) => {
                console.error(`Ha ocurrido un problema al recuperar los datos -> ${err}`);
                return of(null);
            })
        );
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([this.getData(route)]).pipe(
            map((x) => {
                return {
                    data: x[0],
                };
            })
        );
    }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ColorsService } from '@app/shared/services/colors.service';
import { BoxDetectedColors } from '@variables/shared/variable';
import { Observable, interval, of, switchMap } from 'rxjs';

@Component({
  selector: 'settings-boxdetected',
  templateUrl: './settings-boxdetected.component.html',
  styleUrls: ['./settings-boxdetected.component.scss']
})
export class SettingsBoxDetectedComponent implements OnInit {
  @Input({ required: true }) boxDetectedColors: BoxDetectedColors
  @Input({ required: true }) userConfiguration: boolean
  @Input() parentBoxDetectedColors: BoxDetectedColors
  @Output() newColor = new EventEmitter()

  colorBoxes: string = this.colorsService.getColor('default-status-boxes'); //'#8B572A';
  colorRunning: string = this.colorsService.getColor('status-running'); //'#2da45d';.
  colorLWDO: string = this.colorsService.getColor('status-lwdo'); //'#a938cc';
  colorWarning: string = this.colorsService.getColor('status-warning'); //'#a938cc';
  colorErrors: string = this.colorsService.getColor('status-errors'); //'#FE840E';
  colorCritical: string = this.colorsService.getColor('status-critical'); //'#ff2800';
  colorNoData: string = this.colorsService.getColor('status-no-data'); //'#eee';
  temporalColorsAlternatives: string[] = [this.colorRunning, this.colorLWDO, this.colorWarning, this.colorErrors, this.colorCritical, this.colorNoData];

  boxDetectedFillColor$: Observable<string>

  constructor(private colorsService: ColorsService) {
    
  }

  ngOnInit(): void {
    this.boxDetectedFillColor$ = interval(3500).pipe(
      switchMap((t) => {
        return of(this.temporalColorsAlternatives[t % this.temporalColorsAlternatives.length]);
      })
    )
  }

  updateBoxDetectedColor(newColor: string, lightMode: boolean) {
    this.newColor.emit({
      newColor,
      lightMode,
      userConfiguration: this.userConfiguration
  })
      // try {
      //     if (lightMode) {
      //         this.boxDetectedColorsTemporal.lightMode = newColor
      //     } else {
      //         this.boxDetectedColorsTemporal.darkMode = newColor
      //     }
      //     this.warehouseConfiguration.boxDetectedColors = this.boxDetectedColorsTemporal
      //     this.colorsService.setColor('status-boxes', newColor);
      // } catch (error) {
      //     console.log(`%c error => ${error}`, `background: red; color: gold`);            
      // }
      // this.saveWarehouseConfiguration(() => this.appLoadService.setGlobalConfiguration(this.warehouseConfiguration));
  }

  updateBoxDetectedColor_User(newColor: string, lightMode: boolean) {
      // try {
      //     if (lightMode) {
      //         this.boxDetectedColorsTemporal_User.lightMode = newColor
      //     } else {
      //         this.boxDetectedColorsTemporal_User.darkMode = newColor
      //     }
      //     this.userConfiguration.boxDetectedColors = this.boxDetectedColorsTemporal_User
      //     this.colorsService.setColor('status-boxes', newColor);
      // } catch (error) {
      //     console.log(`%c error => ${error}`, `background: red; color: gold`);            
      // }
      // this.saveUserConfiguration(() => this.appLoadService.setCurrentConfiguration(this.userConfiguration));
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { EquipmentAlarmNotification, AlertsActiveStatus } from '@app/notifications/shared/events/alert-status';
import { MonitoringService } from '../../../shared/services/monitoring.service';
import { StateService } from '@app/core/shared/state/state.service';
import * as dayjs from 'dayjs';
import { WarehouseStatusClient } from '../clients/warehouse-status.client';
import { tap, bufferTime, filter } from 'rxjs/operators';
import { MapStates } from '@app/shared/models/map-state';
import { WarehouseStatusSupportedEvents } from '../events/warehouse-status';

const ALERTS_ACTIVE = 'ActiveAlerts';

@Injectable()
export class AlertsActiveStatusService {
    alertsType = [MapStates.alert, 'maintenance_pending', 'maintenance_processing', 'maintenance_blocked', 'resolved'];
    onAlert: EventEmitter<EquipmentAlarmNotification> = new EventEmitter<EquipmentAlarmNotification>();
    onNewAlert: EventEmitter<EquipmentAlarmNotification> = new EventEmitter<EquipmentAlarmNotification>();
    alertSubscription: any;
    alertSubscriptionTracking: any;
    alertTSMap = new WeakMap();
    onReconnect: () => Promise<void>;
    handleReconnectionReference: () => Promise<void>;

    private readonly equipmentsCallback = (alertsActiveStatus: AlertsActiveStatus[]) => {
        alertsActiveStatus.forEach((alertActiveStatus) => {
            if (!this.onAlert.isStopped && this.alertsType.includes(alertActiveStatus.state)) {
                this.onAlert.emit({
                    floorId: alertActiveStatus.floorid,
                    areaId: alertActiveStatus.areaid,
                    zoneId: alertActiveStatus.zoneid,
                    lineId: alertActiveStatus.lineid,
                    equipmentId: alertActiveStatus.equipmentId,
                    equipmentType: alertActiveStatus.equipmentType,
                    state: alertActiveStatus.state,
                    stateText: alertActiveStatus.stateText,
                    priority: alertActiveStatus.priority,
                    source: alertActiveStatus.source,
                    sourceTimeStamp: alertActiveStatus.sourceTimeStamp,
                    serverts: alertActiveStatus.serverTimeStamp,
                });
            }
        });
    };

    constructor(private warehouseStatusClient: WarehouseStatusClient, private monitoringService: MonitoringService, private stateService: StateService) {}

    public async subscribeToAlert(
        warehouse: string,
        context: any,
        delegateFunc: (context: any, notification: EquipmentAlarmNotification) => void,
        onReconnect: () => Promise<void>
    ) {
        const eventName = WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED;
        this.stateService.clearNotificationState(this.constructor.name, ALERTS_ACTIVE, eventName);

        this.alertSubscription = this.onAlert.subscribe({
            next: (event: EquipmentAlarmNotification) => {
                const notificationTS = event.serverts;

                if (this.stateService.isNewerNotification(this.constructor.name, ALERTS_ACTIVE, eventName, notificationTS, event)) {
                    delegateFunc(context, event);
                    this.onNewAlert.emit(event);
                }
            },
        });
        this.alertSubscriptionTracking = this.onNewAlert
            .pipe(
                filter((event: EquipmentAlarmNotification) => event.source === 'EventHub'),

                tap((event: EquipmentAlarmNotification) =>
                    this.alertTSMap.set({ fqn: `${warehouse}-${event.equipmentId}`, ts: event.sourceTimeStamp, state: event.state }, new Date().getTime())
                ),
                bufferTime(5000)
            )
            .subscribe({
                next: (events: EquipmentAlarmNotification[]) => {
                    if (localStorage.getItem('AlertLatencyMetricEnabled') != null) {
                        try {
                            events.forEach((event) => {
                                const sourcets = dayjs(event.sourceTimeStamp).toDate();

                                const alertTS =
                                    this.alertTSMap.get({ fqn: `${warehouse}-${event.equipmentId}`, ts: event.sourceTimeStamp, state: event.state }) ||
                                    new Date().getTime();
                                const timeDiff = (alertTS - sourcets.getTime()) / 1000;
                                this.monitoringService.logMetric('Auwa.Web.SignalR.AlertLatency', timeDiff, null, null, null, {
                                    Equipment: event.equipmentId,
                                    Warehouse: warehouse,
                                });
                            });
                        } catch {
                            console.error('Error when ActiveAlerts socket');
                        }
                    }
                },
            });
        this.stateService.clearNotificationState(this.constructor.name, ALERTS_ACTIVE, eventName);
        this.onReconnect = onReconnect;
        this.handleReconnectionReference = this.handleReconnection.bind(this);
        await this.warehouseStatusClient.subscribe(eventName, warehouse, this.equipmentsCallback, this.handleReconnectionReference);
    }

    async handleReconnection() {
        const eventName = WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED;
        this.stateService.clearNotificationState(this.constructor.name, ALERTS_ACTIVE, eventName);
        await this.onReconnect();
    }

    public async unsubscribeFromAlert(warehouse: string) {
        this.alertSubscription?.unsubscribe();
        this.alertSubscription = null;
        this.alertSubscriptionTracking?.unsubscribe();
        this.alertSubscriptionTracking = null;
        const eventName = WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED;
        await this.warehouseStatusClient.unsubscribe(eventName, warehouse);
    }

    isConnected() {
        return this.warehouseStatusClient.connection?.hubConnection.state === 'Connected';
    }
}

import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PowerBiService } from './powerbi.service';
import { DatepickerComponent } from '@ays/lib/components/forms/datepicker/datepicker.component';
import { CheckboxComponent } from '@ays/lib/components/forms/checkbox/checkbox.component';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import { SearchComponent } from '@ays/lib/components/forms/search/search.component';
import { TableComponent } from '@app/shared/components/table/table.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppLoadService } from '@app/app-load.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { calendar15DaysRange } from '@app/shared/models/calendar-ranges';
import { ColorsService } from '@app/shared/services/colors.service';
import * as dayjs from 'dayjs';
import * as _ from 'lodash-es';

@Component({
    templateUrl: './powerbi.component.html',
    styleUrls: ['./powerbi.component.scss'],
})
export class PowerBiComponent extends TableComponent<any> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('powerBiSearch') powerBiSearch: SearchComponent;
    @ViewChild('boxesCheck') boxesCheck: CheckboxComponent;
    @ViewChild('errorsCheck') errorsCheck: CheckboxComponent;
    @ViewChild('startsCheck') startsCheck: CheckboxComponent;
    @ViewChild('timeRunningCheck') timeRunningCheck: CheckboxComponent;
    @ViewChild('timeStoppedCheck') timeStoppedCheck: CheckboxComponent;
    @ViewChild('timeErrorCheck') timeErrorCheck: CheckboxComponent;
    @ViewChild('timeNoErrorCheck') timeNoErrorCheck: CheckboxComponent;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    @ViewChild('LWDOCheck') LWDOCheck: CheckboxComponent;
    @ViewChild('performanceCheck') performanceCheck: CheckboxComponent;
    @ViewChild('powerBiDatePicker') powerBiDatePicker: DatepickerComponent;
    @ViewChild('powerBiMode') powerBiMode: SelectComponent;
    @ViewChild('copyButton', { static: false }) copyButton: ElementRef;
    $search: JQuery;
    availableColumns = [
        { position: 0, id: 'boxesFilter', label: 'Boxes', visible: true },
        { position: 1, id: 'errorsFilter', label: 'Errors', visible: true },
        { position: 2, id: 'startsFilter', label: 'Starts', visible: true },
        { position: 3, id: 'runningFilter', label: 'Running', visible: true },
        { position: 4, id: 'stoppedFilter', label: 'Stopped', visible: true },
        { position: 5, id: 'onErrorFilter', label: 'On error', visible: true },
        { position: 6, id: 'noErrorFilter', label: 'Availability', visible: true },
        { position: 7, id: 'lwdoFilter', label: 'LWDO', visible: true },
        { position: 8, id: 'performanceFilter', label: 'Performance', visible: true },
    ];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    identifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    boxesColumn = true;
    errorsColumn = true;
    startsColumn = true;
    timeRunningColumn = true;
    timeStoppedColumn = true;
    timeErrorColumn = true;
    timeNoErrorColumn = true;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    LWDOColumn = true;
    performanceColumn = true;
    colorBoxes: string = this.colorsService.getColor('status-boxes'); //'#8B572A';
    colorErrors: string = this.colorsService.getColor('status-errors'); //'#FE840E';
    colorStarts: string = this.colorsService.getColor('status-starts'); //'#FCDE82';
    colorRunning: string = this.colorsService.getColor('status-running'); //'#2da45d';
    colorNoData: string = this.colorsService.getColor('status-no-data'); //'#eee';
    colorOnError: string = this.colorsService.getColor('status-errors'); //'#fe840e';
    colorLWDO: string = this.colorsService.getColor('status-lwdo'); //'#a938cc';
    $powerBiDatePicker: JQuery;
    $powerBiMode: JQuery;
    $copyButton: JQuery;
    alwaysRefreshCheckBoxes = ['Running', 'Stopped', 'On error', 'Availability'];
    checkRefreshGraph = _.debounce(this.refreshGraph, 1000, { leading: false });
    buttonBack = false;
    graphView = true;
    currentDataCheck = 'Boxes';
    chartData = null;
    expandedId = '';
    selectedRows = [];
    tableChartConfig = {
        type: 'bar',
        colunms: 12,
        percentage: 'false',
        x: { label: 'Zone', param: 'id' },
        y: [{ label: 'Boxes', param: 'numberOfBoxes', color: this.colorBoxes }],
    };
    resetToGlobal: any;
    modes$ = ['Zones', 'Lines'];
    queryParams = {
        mode: 'zones',
        start: dayjs().subtract(7, 'days').startOf('day').format('D/M/YY HH:mm'),
        end: dayjs().format('D/M/YY HH:mm'),
    };
    calendarRange = calendar15DaysRange;
    maxRangeDays = 15;
    currentWarehouse: Warehouse;
    configuration: any;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private powerBiService: PowerBiService,
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private authenticationService: AuthenticationService,
        private colorsService: ColorsService,
    ) {
        super();
    }

    ngOnInit() {
        const data = this.parseData(this.route.snapshot.data.powerbi.data);
        this.setDatatableItems(data);
        setTimeout(() => {
            this.chartData = _.cloneDeep(this.table._internalRows);
        }, 0);
        const dateFilters = ['start', 'end'];
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
            // tslint:disable-next-line: forin
            for (const key in params) {
                if (dateFilters.includes(key)) {
                    this.queryParams[key] = params[key];
                }
            }
        });
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.currentWarehouse = res;
        });
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((configuration) => {
            this.configuration = configuration;
            this.identifiersPreferences = { ...this.configuration.identifiersPreferences };
            this.availableColumns = this.configuration?.columnsVisible?.powerBiAvailableColumns
                ? JSON.parse(this.configuration?.columnsVisible?.powerBiAvailableColumns)
                : this.availableColumns;

            this.changeVisibleColumnsConfigureTable(this.availableColumns);
        });
    }

    ngAfterViewInit(): void {
        const that = this;
        this.$search = this.powerBiSearch.$input;
        this.$search.on('keyup', function () {
            that.setDatatableFilter('All', String($(this).val()));
            that.checkRefreshGraph();
        });
        this.$copyButton = $(this.copyButton.nativeElement);
        this.$powerBiDatePicker = this.powerBiDatePicker.$datepicker;
        this.powerBiDatePicker.calendar.setDate([this.queryParams.start, this.queryParams.end], true);
        this.$powerBiDatePicker = this.powerBiDatePicker.$datepicker;
        this.powerBiDatePicker.calendar.config.onClose.push((selectedDates, dateStr, instance) => {
            setTimeout(() => {
                this.powerBiDatePicker.calendar.setDate([this.queryParams.start, this.queryParams.end], true);
            }, 0);
        });
        const minDate = dayjs().subtract(this.maxRangeDays, 'days').format('D/M/YY HH:mm');
        this.powerBiDatePicker.calendar.config.minDate = minDate;
        $(this.powerBiDatePicker.calendar.calendarContainer)
            .find('.flatpickr-confirm')
            .on('click', (e) => {
                const selectedDates = this.powerBiDatePicker.calendar.selectedDates;
                if (selectedDates.length > 1 && selectedDates[0].toISOString() !== selectedDates[1].toISOString()) {
                    this.queryParams.start = dayjs(selectedDates[0]).format('DD/MM/YY H:mm');
                    this.queryParams.end = dayjs(selectedDates[1]).format('DD/MM/YY H:mm');
                    this.enableLoader();
                    this.getData();
                }
            });
        $('.kpi-doc-popover').popover({ trigger: 'focus' });

        this.$powerBiMode = this.powerBiMode.$select;
        this.$powerBiMode.on('change', () => {
            const mode = this.$powerBiMode.val();
            if (mode !== 'zone') {
                this.buttonBack = false;
            }
            this.queryParams.mode = String(mode).toLowerCase();
            this.enableLoader();
            this.getData();
        });
        if (this.boxesColumn) this.boxesCheck?.$checkbox.prop('checked', true);
        $('.dropdown-item').on('click', (e) => e.stopPropagation());
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    onSort() {
        this.chartData = _.cloneDeep(this.table._internalRows);
    }
    rowClass(row): string {
        return row.type;
    }
    onTreeAction(event: any) {
        const index = event.rowIndex;
        const row = event.row;
        if (this.resetToGlobal) {
            this.tableChartConfig = _.cloneDeep(this.resetToGlobal);
            this.resetToGlobal = null;
        }
        // if (row.treeStatus === 'collapsed' && row.type === 'area') {
        //     this.selectedRows = [];
        //     this.table.rows.forEach(rw => {
        //         if (rw.type === 'area') {
        //             rw.treeStatus = 'collapsed';
        //         }
        //     });
        //     row.treeStatus = 'expanded';
        //     this.itemsVariable = [...this.itemsVariable];
        //     setTimeout(() => {
        //         this.chartData = _.cloneDeep(this.table._internalRows).filter(item => item.parent && item.type === 'zone');
        //     }, 0);
        // }
        if (row.treeStatus === 'collapsed' && row.type === 'zone') {
            this.buttonBack = true;
            this.selectedRows = [];
            this.table.rows.forEach((rw) => {
                if (rw.type === 'zone') {
                    rw.treeStatus = 'collapsed';
                }
            });
            row.treeStatus = 'expanded';
            this.itemsVariable = [...this.itemsVariable];
            setTimeout(() => {
                let onlyLineNames = _.cloneDeep(this.table._internalRows).filter((item) => item.parent && item.type === 'line');
                onlyLineNames = onlyLineNames.map((line) => {
                    const blocks = line.id.split('-');
                    line.id = blocks[3];
                    return line;
                });
                this.chartData = onlyLineNames;
            }, 0);
        } else {
            this.buttonBack = false;
            row.treeStatus = 'collapsed';
            this.itemsVariable = [...this.itemsVariable];
            setTimeout(() => {
                this.chartData = _.cloneDeep(this.table._internalRows).filter((item) => !item.parent);
            }, 0);
        }
    }

    goBack() {
        this.buttonBack = false;
        this.table.rows.map((item) => {
            if (item.treeStatus !== 'collapsed') {
                item.treeStatus = 'collapsed';
            }
            return item;
        });
        this.itemsVariable = [...this.itemsVariable];
        setTimeout(() => {
            this.chartData = _.cloneDeep(this.table._internalRows).filter((item) => !item.parent);
        }, 0);
    }

    changeVisibleColumnsConfigureTable(columns) {
        columns.forEach((column) => {
            switch (column.id) {
                case 'boxesFilter':
                    this.boxesColumn = column.visible;
                    break;
                case 'errorsFilter':
                    this.errorsColumn = column.visible;
                    break;
                case 'startsFilter':
                    this.startsColumn = column.visible;
                    break;
                case 'runningFilter':
                    this.timeRunningColumn = column.visible;
                    break;
                case 'stoppedFilter':
                    this.timeStoppedColumn = column.visible;
                    break;
                case 'onErrorFilter':
                    this.timeErrorColumn = column.visible;
                    break;
                case 'noErrorFilter':
                    this.timeNoErrorColumn = column.visible;
                    break;
                case 'lwdoFilter':
                    this.LWDOColumn = column.visible;
                    break;
                case 'performanceFilter':
                    this.performanceColumn = column.visible;
                    break;
            }
        });
        setTimeout(() => {
            this.resetCheckBoxes(this.currentDataCheck);
        }, 0);
    }

    changeVisibleColumns(columns) {
        this.changeVisibleColumnsConfigureTable(columns);

        this.configuration = {
            ...this.configuration,
            columnsVisible: {
                ...this.configuration.columnsVisible,
                powerBiAvailableColumns: JSON.stringify(this.availableColumns),
            },
        };
        this.saveConfiguration();
    }

    saveConfiguration() {
        const value = this.authenticationService.getUser();
        this.configurationsService.saveConfiguration(
            this.currentWarehouse.hostName,
            value.userName ? value.userName : '',
            value.name ? value.name : '',
            this.configuration,
        );
    }

    resetCheckBoxes(name) {
        this.boxesCheck?.$checkbox.prop('checked', name === 'Boxes');
        this.errorsCheck?.$checkbox.prop('checked', name === 'Errors');
        this.startsCheck?.$checkbox.prop('checked', name === 'Starts');
        this.LWDOCheck?.$checkbox.prop('checked', name === 'LWDO');
        this.performanceCheck?.$checkbox.prop('checked', name === 'Performance');
        if (name !== 'Running' && name !== 'Stopped') {
            this.timeRunningCheck?.$checkbox.prop('checked', false);
            this.timeStoppedCheck?.$checkbox.prop('checked', false);
        } else {
            if (name === 'Running' && !this.timeStoppedCheck?.$checkbox.is(':checked')) {
                this.timeRunningCheck?.$checkbox.prop('checked', true);
            } else if (name === 'Stopped' && !this.timeRunningCheck?.$checkbox.is(':checked')) {
                this.timeStoppedCheck?.$checkbox.prop('checked', true);
            }
        }
        if (name !== 'On error' && name !== 'No error') {
            this.timeErrorCheck?.$checkbox.prop('checked', false);
            this.timeNoErrorCheck?.$checkbox.prop('checked', false);
        } else {
            if (name === 'On error' && !this.timeNoErrorCheck?.$checkbox.is(':checked')) {
                this.timeErrorCheck?.$checkbox.prop('checked', true);
            } else if (name === 'No error' && !this.timeErrorCheck?.$checkbox.is(':checked')) {
                this.timeNoErrorCheck?.$checkbox.prop('checked', true);
            }
        }
    }

    activateCheckBoxes(name) {
        this.resetCheckBoxes(name);
        if (this.alwaysRefreshCheckBoxes.includes(name) || name !== this.currentDataCheck) {
            this.currentDataCheck = name;
            const timeData = [];
            if (name === 'Boxes') {
                timeData.push({ label: 'Boxes', param: 'numberOfBoxes', color: this.colorBoxes });
            } else if (name === 'Errors') {
                timeData.push({ label: 'Errors', param: 'numberOfErrors', color: this.colorErrors });
            } else if (name === 'Starts') {
                timeData.push({ label: 'Starts', param: 'numberOfStarts', color: this.colorStarts });
            } else if (name === 'Running' || name === 'Stopped') {
                if (this.timeRunningCheck?.$checkbox.is(':checked')) {
                    timeData.push({ label: 'Running', param: 'percentageRunning', color: this.colorRunning });
                }
                if (this.timeStoppedCheck?.$checkbox.is(':checked')) {
                    timeData.push({ label: 'Stopped', param: 'percentageStopped', color: this.colorNoData });
                }
            } else if (name === 'On error' || name === 'No error') {
                if (this.timeErrorCheck?.$checkbox.is(':checked')) {
                    timeData.push({ label: 'On error', param: 'percentageError', color: this.colorOnError });
                }
                if (this.timeNoErrorCheck?.$checkbox.is(':checked')) {
                    timeData.push({ label: 'Availability', param: 'percentageNoError', color: this.colorNoData });
                }
            } else if (name === 'LWDO') {
                timeData.push({ label: 'LWDO', param: 'percentageLoadedWithoutDischargeOpt', color: this.colorLWDO });
            } else if (name === 'Performance') {
                timeData.push({ label: 'Performance', param: 'performance', color: this.colorStarts });
            }
            this.tableChartConfig = {
                type: 'bar',
                colunms: 12,
                percentage: 'false',
                x: { label: 'Zone', param: 'id' },
                y: timeData,
            };
        }
    }

    onActivate(event) {
        if (event.type === 'click' && event.cellIndex === 0 && event.value === '') {
            const selected = this.selectedRows[0];
            this.selectedRows = selected?.id === event.row.id ? [] : [event.row];
            this.chartData = selected?.id === event.row.id ? _.cloneDeep(this.table._internalRows) : _.cloneDeep(this.selectedRows);
            if (this.selectedRows.length > 0) {
                setTimeout(() => {
                    $(event.rowElement).find('i.add-detail-button').removeClass('fa-plus-circle').addClass('fa-minus-circle');
                }, 0);
                if (this.tableChartConfig.type !== 'radar') {
                    this.resetToGlobal = _.cloneDeep(this.tableChartConfig);
                }
                this.tableChartConfig = {
                    type: 'radar',
                    colunms: 12,
                    percentage: 'true',
                    x: { label: 'Zone', param: 'id' },
                    y: [
                        { label: 'Running', param: 'percentageRunning', color: '#eee' },
                        { label: 'Stopped', param: 'percentageStopped', color: '#eee' },
                        { label: 'Error', param: 'percentageError', color: '#eee' },
                        { label: 'Availability', param: 'percentageNoError', color: '#eee' },
                        { label: 'Without Discharge', param: 'percentageLoadedWithoutDischargeOpt', color: '#eee' },
                    ],
                };
            } else {
                this.tableChartConfig = _.cloneDeep(this.resetToGlobal);
            }
        }
        if (event.type === 'click' && event.cellIndex === 1 && event.value === '') {
            if (!this.selectedRows.includes(event.row)) {
                this.selectedRows.push(event.row);
                this.chartData = _.cloneDeep(this.selectedRows);
            } else {
                this.selectedRows = this.selectedRows.filter((item) => item !== event.row);
                if (this.selectedRows.length > 0) {
                    this.chartData = _.cloneDeep(this.selectedRows);
                } else {
                    this.unselectRows();
                }
            }
            const $rowElement = $(event.rowElement);
            const $addDetailBtn = $rowElement.find('i.add-detail-button');
            if ($rowElement.hasClass('active')) {
                $addDetailBtn.removeClass('fa-minus-circle').addClass('fa-plus-circle');
            } else {
                $addDetailBtn.removeClass('fa-plus-circle').addClass('fa-minus-circle');
            }
        }
    }

    unselectRows() {
        this.selectedRows = [];
        this.chartData = _.cloneDeep(this.table._internalRows);
        this.tableChartConfig = _.cloneDeep(this.resetToGlobal);
    }
    parseData(data) {
        return data?.map((item) => {
            const parent = item.parent.split('-');
            const id = item.id.split('-');
            parent.shift();
            id.shift();
            item.parent = parent.join('-');
            item.id = id.join('-');
            return item;
        });
    }
    getData() {
        this.powerBiService
            .getData(this.queryParams)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                const items = this.parseData(res);
                this.setDatatableItems(items);
                this.applyDatatableNumericFilters();
                this.disableLoader();
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                    this.chartData = _.cloneDeep(this.table._internalRows);
                }, 0);
            });
    }
    copyToClipboard() {
        const uriToShare = `${window.location.origin}${window.location.pathname}?start=${this.queryParams.start}&end=${this.queryParams.end}`;
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', uriToShare);
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.$copyButton.attr('title', 'Copied!').tooltip('_fixTitle').tooltip('show').attr('title', 'Copy to clipboard').tooltip('_fixTitle');
    }

    expandTable(fqdn) {
        this.buttonBack = true;
        let searched = false;
        this.table.rows.map((item) => {
            if (item.id === fqdn && item.type === 'zone') {
                searched = true;
                item.treeStatus = 'expanded';
            }
            return item;
        });
        if (searched) {
            this.itemsVariable = [...this.itemsVariable];
            setTimeout(() => {
                this.chartData = _.cloneDeep(this.table._internalRows).filter((item) => item.parent === fqdn && item.type === 'line');
            }, 0);
        }
    }

    refreshGraph() {
        this.chartData = _.cloneDeep(this.table._internalRows);
    }

    searchValueDeleted() {
        this.setDatatableFilter('All', '');
        this.checkRefreshGraph();
    }
}

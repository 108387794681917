import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Structure } from '@app/map/home/shared/structure';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectMultilevelComponent } from '@app/shared/components/forms/select-multilevel/select-multilevel.component';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { AppLoadService } from '@app/app-load.service';
import { SelectComponent } from '@ays/commons/lib/components/forms/select/select.component';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { environment } from '@environments/environment';
import { UserConfiguration } from '@app/shared/models/configurations';
import { MapGlobalState, MapState, initialStates } from '@app/shared/models/map-state';
import * as _ from 'lodash-es';

@Component({
    templateUrl: './custom.component.html',
    styleUrls: ['./custom.component.scss'],
})
export class CustomComponent extends MapContainerComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('gridSelector') gridSelector: SelectComponent;
    @ViewChild('viewSelector') viewSelector: SelectMultilevelComponent;
    $gridSelector: JQuery;
    $viewSelector: JQuery;
    warehouse: any;
    structure: Structure;
    grid$: Array<any> = ['2', '3', '4', '6'];
    icons: Array<any> = [];
    views: Array<any> = [];
    savedViews: any = null;
    viewsIds: Array<any> = [];
    grid = '4';
    gridSize = 3;
    identifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    userName: string;
    userEmail: string;
    configurationFilterValues: Array<MapGlobalState>;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private win: Window,
        private equipmentService: EquipmentsService,
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private authenticationService: AuthenticationService,
    ) {
        super();
        this.warehouse = this.route.snapshot.data.home.warehouse;
        this.structure = this.route.snapshot.data.home.structure;
        this.icons = this.route.snapshot.data.home.icons;
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            this.grid = params.grid || '4';
            this.viewsIds = params.views ? params.views?.split(',') : [];
        });
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
        });
        if (this.userConfiguration.stateFilter) {
            this.configurationFilterValues = JSON.parse(this.userConfiguration.stateFilter);
            const valueInCustom = this.configurationFilterValues.filter((item) => item.id === 'custom')[0]?.states;
            this.availableStates = valueInCustom ?? initialStates;
        } else {
            this.availableStates = initialStates;
        }
        this.changeVisibleStates(this.availableStates);
        const value =
            environment.mode === 'front'
                ? {
                      name: 'Oscar Lijo Busto',
                      userName: 'oscar.lijo@inditex.es',
                  }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
    }

    ngOnInit() {
        if (this.viewsIds.length > 0) {
            this.savedViews = this.userConfiguration.dashboard ? JSON.parse(this.userConfiguration.dashboard) : { grid: '4' };
            this.savedViews.grid = this.grid;
            this.viewsIds.forEach((view) => this.createView(view));
            this.setGridSize(this.savedViews.grid);
            this.saveViews();
        } else if (this.userConfiguration.dashboard) {
            this.savedViews = JSON.parse(this.userConfiguration.dashboard);
            this.grid = this.savedViews.grid;
            this.viewsIds = this.savedViews[this.warehouse.warehouse] || [];
            this.viewsIds.forEach((view) => {
                this.createView(view);
            });
            this.setUrl();
            this.setGridSize(this.savedViews.grid);
        } else {
            this.savedViews = { grid: '4' };
        }
    }

    ngAfterViewInit(): void {
        this.$viewSelector = this.viewSelector.$selectMultilevel;
        this.$gridSelector = this.gridSelector.$select;
        this.$viewSelector.on('change', () => {
            const value = this.$viewSelector.val().toString();
            if (!this.viewsIds.includes(value)) {
                this.createView(value);
                this.saveViews();
            }
        });
        this.$gridSelector.on('change', () => {
            const value = this.$gridSelector.val().toString();
            this.setGridSize(value);
            this.savedViews.grid = value;
            this.saveViews();
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    handleChangeVisibleStates(states: Array<MapState>) {
        const newCustomConfiguration = {
            id: 'custom',
            states,
        };
        this.configurationFilterValues = [...this.configurationFilterValues, newCustomConfiguration];
        this.configurationFilterValues = _.uniqBy(this.configurationFilterValues, (item) => item.id);
        this.userConfiguration.stateFilter = JSON.stringify(this.configurationFilterValues);
        this.changeVisibleStates(states);
        this.saveConfiguration();
    }
    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    }

    getContent(mode, fqdn) {
        const floor = this.structure.floors.filter((f) => f.id === fqdn.floorId);
        if (mode === 'Floor' || mode === 'Area' || mode === 'Zone') {
            return floor[0];
        }
        if (mode === 'Line') {
            const area = floor[0].areas.filter((a) => a.id === fqdn.areaId);
            const zone = area[0].zones.filter((z) => z.id === fqdn.zoneId);
            const line = zone[0].lines.filter((l) => l.id === fqdn.lineId);
            return line[0];
        }
    }

    createView(viewId) {
        let fqdn = null;
        const valueGroups = viewId.split('-');
        switch (valueGroups.length) {
            case 1:
                fqdn = {
                    floorId: String(valueGroups[0]),
                    areaId: null,
                    zoneId: null,
                    lineId: null,
                };
                this.views.push({
                    name: valueGroups.join('-'),
                    mode: 'Floor',
                    content: this.getContent('Floor', fqdn),
                    fqdn,
                });
                this.viewsIds = this.views.map((view) => view.name);
                break;
            case 2:
                fqdn = {
                    floorId: String(valueGroups[0]),
                    areaId: String(valueGroups[1]),
                    zoneId: null,
                    lineId: null,
                };
                this.views.push({
                    name: valueGroups.join('-'),
                    mode: 'Area',
                    content: this.getContent('Area', fqdn),
                    fqdn,
                });
                this.viewsIds = this.views.map((view) => view.name);
                break;
            case 3:
                fqdn = {
                    floorId: String(valueGroups[0]),
                    areaId: String(valueGroups[1]),
                    zoneId: String(valueGroups[2]),
                    lineId: null,
                };
                this.views.push({
                    name: valueGroups.join('-'),
                    mode: 'Zone',
                    content: this.getContent('Zone', fqdn),
                    fqdn,
                });
                this.viewsIds = this.views.map((view) => view.name);
                break;
            case 4:
                fqdn = {
                    floorId: String(valueGroups[0]),
                    areaId: String(valueGroups[1]),
                    zoneId: String(valueGroups[2]),
                    lineId: String(valueGroups[3]),
                };
                this.views.push({
                    name: valueGroups.join('-'),
                    mode: 'Line',
                    content: this.getContent('Line', fqdn),
                    fqdn,
                });
                this.viewsIds = this.views.map((view) => view.name);
                break;
            default:
                break;
        }
    }

    setUrl() {
        const grid = this.savedViews.grid;
        const views = this.savedViews[this.warehouse.warehouse] ? this.savedViews[this.warehouse.warehouse].join(',') : '';
        const url = this.router
            .createUrlTree([], {
                relativeTo: this.route,
                queryParams: { grid, views },
            })
            .toString();
        this.location.go(url);
    }

    saveViews() {
        this.savedViews[this.warehouse.warehouse] = this.viewsIds;
        this.userConfiguration.dashboard = JSON.stringify(this.savedViews);
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
        // this.configurationsService
        //     .readConfiguration(this.warehouse.hostName, this.userEmail)
        //     .pipe(takeUntil(this.ngUnsubscribe))
        //     .subscribe((data) => {
        //         if (data) {
        //             data.data = JSON.stringify(this.userConfiguration);
        //             this.configurationsService
        //                 .updateConfiguration(this.warehouse.hostName, data)
        //                 .pipe(takeUntil(this.ngUnsubscribe))
        //                 .subscribe((res) => {
        //                     this.appLoadService.setCurrentConfiguration(this.userConfiguration);
        //                 });
        //         } else {
        //             const newConfiguration = {
        //                 application: 'Auwa',
        //                 user: this.userEmail,
        //                 name: this.userName,
        //                 data: JSON.stringify(this.userConfiguration),
        //             };
        //             this.configurationsService
        //                 .createConfiguration(this.warehouse.hostName, newConfiguration)
        //                 .pipe(takeUntil(this.ngUnsubscribe))
        //                 .subscribe((res) => {
        //                     this.appLoadService.setCurrentConfiguration(this.userConfiguration);
        //                 });
        //         }
        //     });
        this.setUrl();
    }

    removeView(viewName) {
        this.views = this.views.filter((view) => view.name !== viewName);
        this.viewsIds = this.views.map((view) => view.name);
        this.saveViews();
    }

    setGridSize(size: string) {
        switch (size) {
            case '2':
                this.gridSize = 6;
                break;
            case '3':
                this.gridSize = 4;
                break;
            case '4':
                this.gridSize = 3;
                break;
            case '6':
                this.gridSize = 2;
                break;
            default:
                this.gridSize = 3;
                break;
        }
    }

    openInNewTab({ keys, mode }) {
        if (mode === 'Equipment') {
            this.navigateToEquipment(keys);
        } else {
            const url =
                keys.length === 4
                    ? `warehouse/${this.warehouse.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}/line/${keys[3]}`
                    : keys.length === 3
                      ? `warehouse/${this.warehouse.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}`
                      : keys.length === 2
                        ? `warehouse/${this.warehouse.warehouse}/floor/${keys[0]}/area/${keys[1]}`
                        : keys.length === 1
                          ? `warehouse/${this.warehouse.warehouse}/floor/${keys[0]}`
                          : `warehouse/${this.warehouse.warehouse}`;
            this.win.open(url || 'main/default');
        }
    }

    navigateToEquipment({ equipment, type }) {
        this.equipmentService
            .getEquipmentLocation(equipment)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.win.open(
                        `warehouse/${res.warehouseId}/floor/${res.floorId}/area/${res.areaId}/zone/${res.zoneId}/line/${res.lineId}?equipmentId=${equipment}&equipmentType=${type}` ||
                            'main/default',
                    );
                }
            });
    }
}

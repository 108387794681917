import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MonitoringService } from '@app/shared/services/monitoring.service';

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
    constructor(private monitoringService: MonitoringService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // tslint:disable-next-line:max-line-length
                    this.monitoringService.logEvent('Front.Http.Ok', { url: request.url, verb: request.method }, { elapsedTime: Date.now() - started });
                }
                return event;
            })
        );
    }
}

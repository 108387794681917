import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import memo from 'memo-decorator';

@Pipe({
    name: 'equipmentType',
})
export class EquipmentTypePipe implements PipeTransform {
    icon: string;
    constructor(private sanitizer: DomSanitizer) {}

    @memo({
        resolver: (value) => ({ value }),
        cache: new WeakMap(),
    })
    transform(value: any = '', extraClass: boolean = false): any {
        switch (value.toUpperCase()) {
            case 'CONVEYOR':
                this.icon = `fa fa-cog`;
                break;
            case 'EXTERNAL':
                this.icon = `icon-instalations`;
                break;
            case 'SORTER':
                this.icon = `icon-ico_sorter`;
                break;
            case 'SCANNER':
                this.icon = `icon-fullscreen`;
                break;
            case 'DROPOFF':
                this.icon = `icon-ico_volumetrica`;
                break;
            default:
                this.icon = `fa fa-circle ${extraClass ? 'ms-1 pe-1' : ''}`;
        }
        //Removing any kind of HTML code
        value = value.replace(/(<([^>]+)>)/gi, '');
        const response = `<i class="${this.icon}" aria-hidden="true" title="${value}"></i>`;
        return this.sanitizer.bypassSecurityTrustHtml(response);
    }
}

<div class="row mt-3" style="position: relative">
    <div class="col-12">
        <div class="row">
            <!-- <div class="col-2"><h5 class="mt-2">Top mode</h5></div> -->
            <div class="col-3 col-md-5 col-lg-2 mobile-margin-bottom mobile-min-width-style">
                <app-dropdown-checkbox
                    label="Select columns"
                    columns="2"
                    [availableItems]="availableColumns"
                    (changeFilter)="changeVisibleColumns($event)"
                ></app-dropdown-checkbox>
            </div>
            <div class="col col-lg-2 mobile-margin-bottom">
                <app-select columns="2" #powerBiMode key="powerBi-mode" label="Mode" [item$]="modes$"></app-select>
            </div>
            <div class="col-10 col-md-5 col-lg-2 mobile-margin-bottom">
                <app-search columns="2" #powerBiSearch key="powerBi-search" (searchValueDeleted)="searchValueDeleted()" ></app-search>
            </div>
            <div id="clear-fix-div" *ngIf="!buttonBack"></div>
            <div id="back-button" class="col-2 col-lg-1 p-0 pt-3" *ngIf="buttonBack">
                <span (click)="goBack()">
                    <i class="fas fa-angle-double-left"></i><span class="ps-2"><strong>Back</strong></span></span
                >
            </div>
            <div class="col-1" style="text-align: end">
                <app-kpi-doc-popover></app-kpi-doc-popover>
            </div>
            <div class="col-md-5 col-lg-2 mobile-margin-bottom" [ngClass]="buttonBack ? 'col-6' : 'col-8'">
                <app-datepicker columns="3" #powerBiDatePicker key="powerBi-datepicker" label="Date" [ranges]="calendarRange"></app-datepicker>
            </div>
            <div class="col-2 mobile-margin-bottom mobile-min-width-style">
                <button #copyButton title="Copy to clipboard" (click)="copyToClipboard()" data-bs-toggle="tooltip" class="btn w-100 btn-auwa">
                    <i class="fas fa-share"></i> Share URL
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 mb-4 position-relative" style="overflow-x: hidden">
        <!-- <div class="unselect-rows" (click)="unselectRows()">
			<i class="fa fa-eye-slash" title="Detail" aria-hidden="true" style="font-size: 18px;"></i>
		</div> -->
        <div style="overflow-x: auto">
            <div style="min-width: 500px">
                <ngx-datatable
                    #table
                    class="bootstrap powerBi-table"
                    [ngClass]="queryParams.mode"
                    [rows]="itemsVariable"
                    [rowClass]="rowClass"
                    [limit]="17"
                    [columnMode]="'force'"
                    [messages]="messages"
                    [headerHeight]="50"
                    [rowHeight]="40"
                    [footerHeight]="50"
                    [scrollbarH]="true"
                    [scrollbarV]="true"
                    [selected]="selectedRows"
                    (activate)="onActivate($event)"
                    [treeFromRelation]="'parent'"
                    [treeToRelation]="'id'"
                    (treeAction)="onTreeAction($event)"
                    (sort)="onSort()"
                    [sorts]="[{ prop: 'id', dir: 'desc' }]"
                >
                    <ngx-datatable-column
                        name="id"
                        [sortable]="true"
                        [width]="300"
                        [frozenLeft]="true"
                        cellClass="ps-0"
                        [isTreeColumn]="queryParams.mode === 'zones' ? true : false"
                    >
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">ID</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                [innerHTML]="
                                    value
                                        | fazlDecorator
                                            : [
                                                  ['F', identifiersPreferences.Floor],
                                                  ['A', identifiersPreferences.Area],
                                                  ['Z', identifiersPreferences.Zone],
                                                  ['EQL', identifiersPreferences.Line]
                                              ]
                                "
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="numberOfBoxes" [sortable]="true" [width]="110" *ngIf="boxesColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #boxesCheck
                                key="BoxesCheck"
                                label=""
                                (clicked)="activateCheckBoxes('Boxes')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">Boxes</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="numberOfErrors" [sortable]="true" [width]="110" *ngIf="errorsColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #errorsCheck
                                key="ErrorsCheck"
                                label=""
                                (clicked)="activateCheckBoxes('Errors')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">Errors</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="numberOfStarts" [sortable]="true" [width]="110" *ngIf="startsColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #startsCheck
                                key="StartsCheck"
                                label=""
                                (clicked)="activateCheckBoxes('Starts')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">Starts</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="percentageRunning" [sortable]="true" [width]="110" *ngIf="timeRunningColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #timeRunningCheck
                                key="TimeRunningCheck"
                                label=""
                                (clicked)="activateCheckBoxes('Running')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">Running</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template> <span [innerHTML]="value | number"></span>% </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="percentageStopped" [sortable]="true" [width]="110" *ngIf="timeStoppedColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #timeStoppedCheck
                                key="StoppedCheck"
                                label=""
                                (clicked)="activateCheckBoxes('Stopped')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">Stopped</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template> <span [innerHTML]="value | number"></span>% </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="percentageError" [sortable]="true" [width]="110" *ngIf="timeErrorColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #timeErrorCheck
                                key="TimeErrorsCheck"
                                label=""
                                (clicked)="activateCheckBoxes('On error')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">On error</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template> <span [innerHTML]="value | number"></span>% </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="percentageNoError" [sortable]="true" [width]="110" *ngIf="timeNoErrorColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #timeNoErrorCheck
                                key="TimeNoErrorCheck"
                                label=""
                                (clicked)="activateCheckBoxes('No error')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">Availability</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template> <span [innerHTML]="value | number"></span>% </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="percentageLoadedWithoutDischargeOpt" [sortable]="true" [width]="110" *ngIf="timeErrorColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #LWDOCheck
                                key="LWDOCheck"
                                label=""
                                (clicked)="activateCheckBoxes('LWDO')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2" title="Loaded Without Discharge Opt">LWDO</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template> <span [innerHTML]="value | number"></span>% </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="performance" [sortable]="true" [width]="180" *ngIf="performanceColumn">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <app-checkbox
                                #performanceCheck
                                key="PerformanceCheck"
                                label=""
                                (clicked)="activateCheckBoxes('Performance')"
                                extraClasses="checkbox-table-header"
                            ></app-checkbox>
                            <span (click)="sort()" class="ps-2">Performance</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="value | number"></span> Boxes/Hour
                        </ng-template>
                    </ngx-datatable-column>
                    <!-- <ngx-datatable-column name="" [width]="40" headerClass="empty" cellClass="text-center" [frozenRight]="true">
					<ng-template ngx-datatable-header-template></ng-template>
					<ng-template let-row="row" ngx-datatable-cell-template>
							<i class="fa fa-eye" title="Detail" aria-hidden="true" style="font-size: 18px;"></i>
					</ng-template>
			</ngx-datatable-column>
			<ngx-datatable-column name="" [width]="40" headerClass="empty" cellClass="text-center" [frozenRight]="true" *ngIf="selectedRows.length > 0">
					<ng-template ngx-datatable-header-template></ng-template>
					<ng-template let-row="row" ngx-datatable-cell-template>
							<i class="fa fa-plus-circle add-detail-button" title="Add to detail" aria-hidden="true" style="font-size: 18px;"></i>
					</ng-template>
			</ngx-datatable-column> -->
                    <ngx-datatable-footer>
                        <ng-template
                            ngx-datatable-footer-template
                            let-rowCount="rowCount"
                            let-pageSize="pageSize"
                            let-selectedCount="selectedCount"
                            let-curPage="curPage"
                            let-offset="offset"
                        >
                            <div class="col-8">
                                <datatable-pager
                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                    [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage"
                                    [size]="pageSize"
                                    [count]="rowCount"
                                    [hidden]="!(rowCount / pageSize > 1)"
                                    (change)="table.onFooterPage($event)"
                                >
                                </datatable-pager>
                            </div>
                            <div class="col-4 text-end">
                                <p style="font-size: 0.8rem; margin-top: 16px">
                                    Seeing
                                    <!-- <select class="table-length-selector" style="margin: 0 5px;" (change)="changePageSize($event)" [value]="pageSize">
									<option [value]="size" *ngFor="let size of pageSizes">{{size}}</option>
							</select>
							{{pageSize}} of {{rowCount | number}} entries -->
                                    {{ rowCount }} entries
                                </p>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 mb-4">
        <app-table-chart [data]="chartData" [config]="tableChartConfig" (expandTable)="expandTable($event)"></app-table-chart>
        <app-table-comparator [data]="chartData" *ngIf="selectedRows.length === 0"></app-table-comparator>
        <!-- <app-time-chart [data]="chartData" *ngIf="selectedRows.length > 0"></app-time-chart> -->
    </div>
</div>

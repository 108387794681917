<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" [ngClass]="{ active: customName }">
            <a id="breadcrumb-root-dropdown" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="d-none d-lg-inline" style="position: relative; top: -2px">{{ customName ? customName : 'Home' }}</span>
                <span class="d-md-inline d-lg-none"><i class="fa fa-home"></i></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="breadcrumb-root-dropdown">
                <a [routerLink]="['/warehouse', warehouse]" class="dropdown-item" style="min-width: 105px">HOME</a>
                <ng-template ngFor let-custom [ngForOf]="structure?.custom" let-i="index">
                    <a class="dropdown-item" (click)="breadcrumbsCustomsRedirect(custom.name)" *ngIf="custom.name !== customName">{{ custom.name }}</a>
                </ng-template>
            </div>
        </li>
        <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-i="index">
            <li class="breadcrumb-item dropdown" [ngClass]="{ active: i === breadcrumbs.length - 1 }">
                <a id="breadcrumb-floors-dropdown" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="i === 0">
                    <span class="breadcrumbs-link" (click)="breadcrumbsRedirect(breadcrumbs[0])" *ngIf="breadcrumbs.length !== 1">
                        <span>{{ breadcrumb | fazlDecorator : [['F', identifiersPreferences.Floor], [], [], []] }}</span>
                    </span>
                    <span *ngIf="breadcrumbs.length === 1">
                        <span>{{ breadcrumb | fazlDecorator : [['F', identifiersPreferences.Floor], [], [], []] }}</span>
                    </span>
                </a>
                <div class="dropdown-menu" aria-labelledby="breadcrumb-floors-dropdown" *ngIf="i === 0">
                    <ng-template ngFor let-floor [ngForOf]="floors" let-i="index">
                        <a class="dropdown-item" (click)="breadcrumbsRedirect(floor)"
                            ><span *ngIf="identifiersPreferences.Floor; else floorWithPipe">{{ floor }}</span>
                            <ng-template #floorWithPipe
                                ><span>{{ floor | fazlDecorator : [['F', identifiersPreferences.Floor], [], [], []] }}</span></ng-template
                            ></a
                        >
                    </ng-template>
                </div>
                <a id="breadcrumb-areas-dropdown" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="i === 1">
                    <span class="breadcrumbs-link" (click)="breadcrumbsRedirect(breadcrumbs[0], breadcrumbs[1])" *ngIf="breadcrumbs.length !== 2">
                        <span>{{ breadcrumb | fazlDecorator : [[], ['A', identifiersPreferences.Area], [], []] }}</span>
                    </span>
                    <span *ngIf="breadcrumbs.length === 2">
                        <span>{{ breadcrumb | fazlDecorator : [[], ['A', identifiersPreferences.Area], [], []] }}</span>
                    </span>
                </a>
                <div class="dropdown-menu" aria-labelledby="breadcrumb-areas-dropdown" *ngIf="i === 1">
                    <ng-template ngFor let-area [ngForOf]="areas" let-i="index">
                        <a class="dropdown-item" (click)="breadcrumbsRedirect(breadcrumbs[0], area)">
                            <span>{{ area | fazlDecorator : [[], ['A', identifiersPreferences.Area], [], []] }}</span>
                        </a>
                    </ng-template>
                </div>
                <!-- <a id="breadcrumb-areas-without-dropdown" class="pe-none" *ngIf="i === 1">
                    <span>{{ breadcrumb | fazlDecorator : [[], ['A', identifiersPreferences.Area], [], []] }}</span>
                </a> -->
                <a id="breadcrumb-zones-dropdown" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="i === 2">
                    <span
                        class="breadcrumbs-link"
                        (click)="breadcrumbsRedirect(breadcrumbs[0], breadcrumbs[1], breadcrumbs[2])"
                        *ngIf="breadcrumbs.length !== 3"
                        ><span>{{ breadcrumb | fazlDecorator : [[], [], ['Z', identifiersPreferences.Zone], []] }}</span>
                    </span>
                    <span *ngIf="breadcrumbs.length === 3">
                        <span>{{ breadcrumb | fazlDecorator : [[], [], ['Z', identifiersPreferences.Zone], []] }}</span>
                    </span>
                </a>
                <div class="dropdown-menu" aria-labelledby="breadcrumb-zones-dropdown" *ngIf="i === 2">
                    <ng-template ngFor let-zone [ngForOf]="zones" let-i="index">
                        <a class="dropdown-item" (click)="breadcrumbsRedirect(breadcrumbs[0], breadcrumbs[1], zone)">
                            <span>{{ zone | fazlDecorator : [[], [], ['Z', identifiersPreferences.Zone], []] }}</span>
                        </a>
                    </ng-template>
                </div>
                <!-- <a id="breadcrumb-zones-without-dropdown" class="pe-none" *ngIf="i === 2">
                    <span>{{ breadcrumb | fazlDecorator : [[], [], ['Z', identifiersPreferences.Zone], []] }}</span>
                </a> -->
                <a id="breadcrumb-lines-dropdown" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="i === 3">
                    <span
                        class="breadcrumbs-link"
                        (click)="breadcrumbsRedirect(breadcrumbs[0], breadcrumbs[1], breadcrumbs[2], breadcrumbs[3])"
                        *ngIf="breadcrumbs.length !== 4"
                        ><span>{{ breadcrumb | fazlDecorator : [[], [], [], ['EQL', identifiersPreferences.Line]] }}</span>
                    </span>
                    <span *ngIf="breadcrumbs.length === 4">
                        <span>{{ breadcrumb | fazlDecorator : [[], [], [], ['EQL', identifiersPreferences.Line]] }}</span>
                    </span>
                </a>
                <div class="dropdown-menu" aria-labelledby="breadcrumb-lines-dropdown" *ngIf="i === 3">
                    <ng-template ngFor let-line [ngForOf]="lines" let-i="index">
                        <a class="dropdown-item" (click)="breadcrumbsRedirect(breadcrumbs[0], breadcrumbs[1], breadcrumbs[2], line)">
                            <span>{{ line | fazlDecorator : [[], [], [], ['EQL', identifiersPreferences.Line]] }}</span>
                        </a>
                    </ng-template>
                </div>
            </li>
        </ng-template>
    </ol>
</nav>

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsTableSmComponent } from '@app/shared/components/alerts-table-sm/alerts-table-sm.component';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { EquipmentAlarmNotification } from '@app/notifications/shared/events/alert-status';
import { LinesService } from '../shared/lines.service';
import { AppLoadService } from '@app/app-load.service';
import { Alert } from '@alerts/shared/alert';
import { Line } from '@lines/shared/line';
import { Observable, of, Subject } from 'rxjs';
import { Warehouse } from '@app/core/shared/warehouse';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { AlertsService } from '@alerts/shared/alerts.service';
import { PageLifecycleService, VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { MapGlobalState, MapState, initialStates } from '@app/shared/models/map-state';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { EquipmentStatusNotification } from '@app/notifications/shared/events/equipment-status';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { catchError, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';

const { ALERT_STATE_CHANGED, EQUIPMENT_STATE_CHANGED } = WarehouseStatusSupportedEvents;

@Component({
    templateUrl: './lines.component.html',
    styleUrls: ['./lines.component.scss'],
    providers: [LinesService],
})
export class LinesComponent extends MapContainerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('alertsTable') alertsTableSmComponent: AlertsTableSmComponent;
    warehouse: Warehouse;
    floorId = '0';
    areaId = '0';
    zoneId = '0';
    lineId = '0';
    lineTitle = '';
    //liteView: boolean;
    alertsView: boolean;
    priority$ = ['All', '1', '2', '3'];
    closeButton = false;
    lineMap: Line;
    lineKpis: any;
    lineAlerts: Array<Alert>;
    equipmentTypes: Array<string> = [];
    equipment: any;
    openVariableModal: boolean = false;
    icons: Array<any> = [];
    equipmentSelection: string = null;
    isFullscreen = false;
    showFilters = false;
    configuration: any;
    userName: string;
    userEmail: string;
    mapRender = false;
    groupName: string;
    configurationFilterValues: Array<MapGlobalState>;
    equipmentChange$: Observable<EquipmentStatusNotification>;
    supportedEvents = [ALERT_STATE_CHANGED, EQUIPMENT_STATE_CHANGED];
    tabState: VisibilityStates = VisibilityStates.active;
    equipmentVisible: Array<{
        equipmentId;
    }> = [];
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private authenticationService: AuthenticationService,
        private configurationsService: ConfigurationsService,
        private alertsService: AlertsService,
        private warehouseStatusService: WarehouseStatusService,
        private route: ActivatedRoute,
        private router: Router,
        private pageLifeCycleService: PageLifecycleService,
    ) {
        super();
        this.configuration = this.route.snapshot.data.line.configuration;
        this.warehouse = this.route.snapshot.data.line.warehouse;
        this.equipmentTypes = ['All', ...(this.route.snapshot.data.line.equipmentTypes || []).sort()];
        this.lineMap = this.route.snapshot.data.line.map;
        this.equipment = this.route.snapshot.paramMap.get('equipment');
        this.openVariableModal = this.route.snapshot.paramMap.get('openVariableModal') === 'true';
        const icons = this.route.snapshot.data.line.icons;
        const customIconNames = icons.filter((icon) => !icon.isDefaultIcon).map((icon) => icon.name.toLowerCase());
        this.icons = icons.filter((icon) => !(customIconNames.includes(icon.name) && icon.isDefaultIcon));

        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            if (params.floorId && params.areaId && params.zoneId) {
                this.floorId = params.floorId;
                this.areaId = params.areaId;
                this.zoneId = params.zoneId;
                this.lineId = params.lineId;
                this.lineTitle = `Line ${this.lineId}`;
            }

            if (this.lineMap?.id !== this.lineId) this.router.navigate(['warehouse', this.warehouse.warehouse]);
            else this.mapRender = true;
            this.groupName = `${this.warehouse.warehouse}-${this.floorId}-${this.areaId}-${this.zoneId}-${this.lineMap.id}`;
        });
        this.alertsView = true;

        if (this.configuration?.stateFilter) {
            this.configurationFilterValues = JSON.parse(this.configuration.stateFilter);
            const valueInLine = this.configurationFilterValues.filter((item) => item.id === 'line')[0]?.states;
            this.availableStates = valueInLine ?? initialStates;
        } else {
            this.availableStates = initialStates;
        }
        this.changeVisibleStates(this.availableStates);

        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
    }

    receiveRealTimeAlert(item: EquipmentAlarmNotification) {
        const contextId = `${this.floorId}-${this.areaId}-${this.zoneId}-${this.lineId}`;
        const itemId = item ? `${item.floorId}-${item.areaId}-${item.zoneId}-${item.lineId}` : '';
        if (contextId === itemId) {
            if (
                (!this.visibleStates.some((element) => element === 'alert_low') && !this.visibleStates.some((element) => element === 'alert')) ||
                (!this.visibleStates.some((element) => element === 'alert_low') && item.priority.toString() === '3') ||
                (!this.visibleStates.some((element) => element === 'alert') && item.priority.toString() !== '3')
            ) {
                return;
            } else {
                const alert = new Alert(
                    new Date(),
                    new Date(),
                    item.equipmentId,
                    item.equipmentType,
                    item.stateText,
                    item.priority,
                    item.state,
                    item.floorId,
                    item.areaId,
                    item.zoneId,
                    item.lineId,
                );
                this.alertsTableSmComponent.processRealTimeAlert(alert);
            }
        } else if (localStorage.getItem('showWrongNotifications') === 'true') {
            console.warn('Notification received for wrong fqn', {
                contextId,
                itemId,
            });
        }
    }

    ngOnInit() {
        this.changeView('alerts');
    }

    async ngAfterViewInit(): Promise<void> {
        this.stateService.isFullscreen.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isFullscreen) => {
            this.isFullscreen = isFullscreen;
        });
        this.stateService.showFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe((showFilters) => {
            this.showFilters = showFilters;
        });
        this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
            this.tabState = state;
            if (
                prevState !== VisibilityStates.active &&
                [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
                this.warehouseStatusService.isConnected()
            ) {
                this.onReconnect();
            }
        });
        await this.subscribeToRealTimeEvents();
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }
    setPriorityComboItems() {
        if (this.visibleStates.some((item) => item === 'alert_low') && this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2', '3'];
        } else if (this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2'];
        } else if (this.visibleStates.some((item) => item === 'alert_low')) {
            this.priority$ = ['All', '3'];
        } else {
            this.priority$ = [];
        }
    }

    checkVisibleStates(alerts) {
        if (!this.visibleStates.some((item) => item === 'alert_low') && !this.visibleStates.some((item) => item === 'alert')) {
            this.lineAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2' && item.priority !== '3');
        } else if (!this.visibleStates.some((item) => item === 'alert_low')) {
            this.lineAlerts = alerts.filter((item) => item.priority !== '3');
        } else if (!this.visibleStates.some((item) => item === 'alert')) {
            this.lineAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2');
        } else {
            this.lineAlerts = alerts;
        }
    }

    changeView(view) {
        this.alertsView = 'alerts' === view;
        if (this.alertsView) {
            this.alertsService
                .getAlertsByLine(this.floorId, this.areaId, this.zoneId, this.lineId)
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    //take(1),
                    catchError((err) => {
                        console.log('Ha ocurrido un problema en el provider al recuperar las alertas de la linea de equipos');
                        return of(null);
                    }),
                )
                .subscribe((res) => {
                    this.checkVisibleStates(res);
                    this.setPriorityComboItems();
                });
        }
        // if (this.analyticsView) {
        //     this.linesService
        //         .getLineKpis(this.floorId, this.areaId, this.zoneId, this.lineId)
        //         .pipe(takeUntil(this.ngUnsubscribe))
        //         .subscribe((res) => {
        //             this.lineKpis = !Array.isArray(res) ? [] : res;
        //         });
        // }
    }

    handleChangeVisibleStates(states: Array<MapState>) {
        const newLineConfiguration = {
            id: 'line',
            states,
        };
        this.configurationFilterValues = [...this.configurationFilterValues, newLineConfiguration];
        this.configurationFilterValues = _.uniqBy(this.configurationFilterValues, (item) => item.id);
        this.configuration.stateFilter = JSON.stringify(this.configurationFilterValues);
        this.changeVisibleStates(states);
        this.setPriorityComboItems();
        this.saveConfiguration();
    }

    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
    }

    filterTableAlarms(data) {
        this.equipmentSelection = null;
        this.closeButton = data.equipment ? true : false;
        this.lineTitle = data.equipment ? `Equipment ${data.equipment}` : `Line ${this.lineId}`;
        this.alertsTableSmComponent.setDatatableFilter('equipmentId', data.equipment);
    }

    async onReconnect() {
        this.alertsService
            .getAlertsByLine(this.floorId, this.areaId, this.zoneId, this.lineId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(async (alerts) => {
                this.checkVisibleStates(alerts);
                this.alertsTableSmComponent.setDatatableItems(this.lineAlerts);
                await this.subscribeToRealTimeEvents();
            });
    }

    async subscribeToRealTimeEvents(): Promise<void> {
        try {
            await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
            await this.warehouseStatusService.startSubscription(this.groupName, this.supportedEvents);
            const alertObservable = await this.warehouseStatusService.listenNotifications(this.groupName, ALERT_STATE_CHANGED);

            alertObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.receiveRealTimeAlert.bind(this));

            this.equipmentChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, EQUIPMENT_STATE_CHANGED);

            this.warehouseStatusService.onReconnecting.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.onReconnect());
        } catch (error) {
            console.error(error);
        }
    }

    refreshAlertsTable(equipmentVisible) {
        this.equipmentVisible = equipmentVisible.map((eq) => {
            return {
                equipmentId: eq.split('-')[0],
            };
        });

        this.equipmentVisible = [...this.equipmentVisible];
    }
}

<div [ngClass]="{ 'hide-me': liteView }">
    <div class="svg-wrapper" [ngClass]="viewFullSize ? 'expand' : ''">
        <button class="btn btn-light view-full-size" (click)="toogleFullScreen()" [title]="viewFullSize ? 'Hide extra info' : 'Show extra info'">
            <i class="fa" [ngClass]="viewFullSize ? 'fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'"></i>
        </button>
        <div class="map-content">
            <button class="btn btn-light" (click)="resetZoom()" title="Reposition map"><i class="fa fa-crosshairs" aria-hidden="true"></i></button>
            <svg
                #svg
                auwaSvgZoom
                directiveCurrentZoom="{{ currentZoom }}"
                directiveViewBox="{{ setViewBox }}"
                directiveActivateZoom="{{ activateZoom }}"
                (svgZoomEventEmmiter)="hideContextMenu(); refreshVisibleLines()"
                [attr.viewBox]="setViewBox"
                fill="#C9CCCD"
            >
                <rect
                    class="zone-rectangle-marker"
                    [attr.x]="setViewBox.split(' ')[0]"
                    [attr.y]="setViewBox.split(' ')[1]"
                    [attr.width]="setViewBox.split(' ')[2]"
                    [attr.height]="setViewBox.split(' ')[3]"
                />
                <svg:g #freeBacks class="freeBacksGroup" *ngIf="viewExtraData">
                    <ng-container *ngFor="let item of zone.freeBacksLines.shapes">
                        <svg:path class="shape" [attr.data-element]="'shape'" [attr.d]="item.data" [attr.style]="item.style"></svg:path>
                    </ng-container>
                    <ng-container *ngFor="let item of zone.freeBacksLines.texts">
                        <svg:text
                            filter="url(#outline)"
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.font-size]="item.size || '16'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                </svg:g>
                <svg:g #lines>
                    <ng-container *ngFor="let area of map.areas">
                        <ng-container *ngFor="let current_zone of area.zones">
                            <svg:g>
                                <ng-container *ngFor="let lines of current_zone.lines">
                                    <svg:path
                                        *ngIf="lines.data && lines.data !== 'null'"
                                        [attr.title]="lines.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]]"
                                        [attr.d]="lines.data"
                                        attr.data-key="{{ floorId }}-{{ area.id }}-{{ current_zone.id }}-{{ lines.id }}"
                                        data-element="line"
                                        (click)="emitLine($event, undefined)"
                                        (contextmenu)="onRightClick($event)"
                                    ></svg:path>
                                </ng-container>
                            </svg:g>
                        </ng-container>
                    </ng-container>
                </svg:g>
                <!-- <svg:g #lines>
					<ng-container *ngFor="let lines of zone.lines">
						<svg:path [attr.title]="lines.id" [attr.d]="lines.data" attr.data-key="{{floorId}}-{{areaId}}-{{zone.id}}-{{lines.id}}" data-element="line" (click)="emitLine($event)"></svg:path>
					</ng-container>
				</svg:g> -->
                <ng-container *ngFor="let lines of zone.lines">
                    <svg:use
                        class="arrow"
                        (click)="emitLine($event, undefined)"
                        attr.data-arrow="{{ floorId }}-{{ areaId }}-{{ zone.id }}-{{ lines.id }}"
                        [attr.xlink:href]="createArrow(lines.orientation)"
                        [attr.x]="lines.xArrow"
                        [attr.y]="lines.yArrow"
                        width="8"
                        height="8"
                    ></svg:use>
                </ng-container>
                <svg:g #freeFronts class="freeFrontsGroup" *ngIf="viewExtraData">
                    <ng-container *ngFor="let item of zone.freeFrontsLines.shapes">
                        <svg:path class="shape" [attr.data-element]="'shape'" [attr.d]="item.data" [attr.style]="item.style"></svg:path>
                    </ng-container>
                    <ng-container *ngFor="let item of zone.freeFrontsLines.texts">
                        <svg:text
                            filter="url(#outline)"
                            class="shape"
                            [attr.font-size]="item.size || '16'"
                            [attr.data-element]="'shape'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                </svg:g>
                <svg:g *ngIf="isVisibilityEnabled" class="variablesGroup">
                    <ng-container *ngFor="let block of metadataBlocks?.metadata">
                        <svg:g
                            class="shape block"
                            [attr.transform]="block?.transform"
                            [attr.variable]="block?.variable"
                            [attr.description]="block?.description"
                        >
                            <ng-container *ngFor="let item of block?.shapes">
                                <svg:path
                                    *ngIf="item && item.data !== 'null'"
                                    [attr.data-element]="'shape'"
                                    [attr.data-key]="item.variable"
                                    [ngClass]="{ withVariable: item.variable, highlightPath: item.highlightPath }"
                                    [attr.d]="item.data"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <title>{{ block.variable }} - {{ block.description }}</title>
                                </svg:path>
                            </ng-container>
                            <ng-container *ngFor="let item of block?.texts">
                                <svg:text
                                    class="shape"
                                    [attr.data-element]="'shape'"
                                    [attr.font-size]="item.size || '16'"
                                    [attr.x]="item.x"
                                    [attr.y]="item.y"
                                    [attr.data-key]="item.variable ? 'text' + item.variable : ''"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <tspan [attr.text-anchor]="item.variable ? 'middle' : 'left'" dy="20">{{ item.text }}</tspan>
                                    <title>{{ item.variable }}</title>
                                </svg:text>
                            </ng-container>
                        </svg:g>
                    </ng-container>
                    <ng-container *ngFor="let item of map.variablesLines?.texts">
                        <svg:text
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.font-size]="item.size || '16'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                            [attr.variable]="item.variable"
                        >
                            <!--[attr.data-key]="item.variable ? item.variable : ''"-->
                            <tspan dy="20">{{ item.text }}</tspan>
                            <!--[attr.text-anchor]="item.variable ? 'middle' : 'left'" -->
                            <title>{{ item.variable }}</title>
                        </svg:text>
                    </ng-container>
                    <ng-container *ngFor="let item of map.variablesLines?.shapes">
                        <svg:path
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.d]="item.data"
                            [attr.data-key]="item.variable"
                            [attr.variable]="item.variable"
                            *ngIf="item && item.data && item.data !== 'null'"
                        >
                            <title>{{ item.variable }}</title>
                        </svg:path>
                    </ng-container>
                </svg:g>
                <text [attr.x]="setViewBox.split(' ')[0]" [attr.y]="setTitleY()" font-family="Verdana" font-size="22" fill="white">
                    {{ zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []] }}
                </text>
                <svg:g #states></svg:g>
            </svg>
        </div>
    </div>
    <div class="row" #buttonsOverMap>
        <div [id]="'accordion-' + floorId + '-' + areaId + '-' + zone.id" class="accordion" *ngIf="linesOverMap.length > 0">
            <div class="accordion-item">
                <h2 class="accordion-header" [id]="'heading-' + floorId + '-' + areaId + '-' + zone.id">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse-' + floorId + '-' + areaId + '-' + zone.id"
                        aria-expanded="false"
                        [attr.aria-controls]="'collapse-' + floorId + '-' + areaId + '-' + zone.id"
                    >
                        <h6 class="linesOverMap-title my-2">
                            <span class="badge bg-dark me-2">{{ linesOverMap.length }}</span> Equipment-Lines without map
                        </h6>
                    </button>
                </h2>
                <div
                    [id]="'collapse-' + floorId + '-' + areaId + '-' + zone.id"
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="'heading-' + floorId + '-' + areaId + '-' + zone.id"
                    [attr.data-bs-parent]="'#accordion-' + floorId + '-' + areaId + '-' + zone.id"
                >
                    <div class="accordion-body">
                        <div class="row">
                            <div class="btn-zones col-6 col-md-4 col-lg-3" *ngFor="let line of linesOverMap">
                                <div
                                    role="button"
                                    attr.data-key="{{ floorId }}-{{ areaId }}-{{ zone.id }}-{{ line.id }}"
                                    data-element="zone"
                                    class="btn btn-default"
                                    (click)="emitClickLine($event)"
                                >
                                    {{ line.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="liteView">
    <div #buttons class="col-12 buttons-wrapper">
        <div class="row" *ngIf="zone.lines.length > 0" style="margin: 0">
            <div class="btn-zones col-6 col-md-4 col-lg-3" *ngFor="let line of zone.lines">
                <div
                    role="button"
                    attr.data-key="{{ floorId }}-{{ areaId }}-{{ zone.id }}-{{ line.id }}"
                    data-element="zone"
                    class="btn btn-default"
                    (click)="emitClickLine($event)"
                >
                    {{ line.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-12" *ngIf="zone.lines.length === 0">
    <h5 class="text-center mt-5 mb-5">No hay lineas de equipos</h5>
</div>
<app-context-menu
    [keySelectedWithRightClick]="keySelectedWithRightClick"
    [warehouse]="warehouse"
    [contextId]="zone.id"
    [x]="rightClickX"
    [y]="rightClickY"
    (newLineSelectedFromContextMenu)="emitLine(undefined, $event)"
    hideLink="Zone"
></app-context-menu>

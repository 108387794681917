import { Component, OnInit, OnDestroy, Input, Inject, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AlertsCountStatusService, WarehouseMapStatus } from '../../../notifications/shared/handlers/alertsCount-status-service';
import { ReportCountStatusService } from '../../../notifications/shared/handlers/reportCount-status-service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { AuthenticationService } from '../../shared/authentication/authentication.service';
import { ArchivesService } from '@app/core/shared/archives/archives.service';
import { IdbService, IDB_STORES } from '@app/core/shared/cache/idb.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NavComponent } from '@ays/lib/components/nav/nav.component';
import { StateService } from '@core/shared/state/state.service';
import { environment } from '@environments/environment';
import { AppLoadService } from '@app/app-load.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';
import { PageLifecycleService, VisibilityStates } from '@app/shared/services/page-lifecycle.service';

@Component({
    selector: 'core-nav-custom',
    templateUrl: './nav-custom.component.html',
    styleUrls: ['./nav-custom.component.scss'],
})
export class NavCustomComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() parent: NavComponent;
    numOfAlerts: number;
    numOfArchives: number;
    numOfVariables: number;
    showNav: boolean;
    liteView: boolean;
    darkMode: boolean;
    isFullscreen: boolean;
    isHome: boolean;
    showFilters: boolean;
    isAuthorized: boolean;
    userName: string;
    userEmail: string;
    warehouses: Array<Warehouse> = [];
    warehouse: Warehouse;
    mapVersion: string;
    version = environment.version;
    configuration: any;
    viewExtraData = true;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private alertsCountStatusService: AlertsCountStatusService,
        private reportCountStatusService: ReportCountStatusService,
        private authenticationService: AuthenticationService,
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private archivesService: ArchivesService,
        private stateService: StateService,
        private appInsights: ApplicationInsights,
        private idbService: IdbService,
        private pageLifeCycleService: PageLifecycleService,
        @Inject(DOCUMENT) private _document: any,
    ) {
        this.userName = '';
        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();

        if (value) {
            this.warehouses = _.sortBy(this.appLoadService.warehouses, 'warehouse');
            appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.warehouse = res));
            appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
                this.configuration = res;
                this.viewExtraData = this.configuration.viewExtraData;
                this.liteView = this.configuration.liteMode;
                this.darkMode = this.configuration.darkMode;
            });
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
            this.archivesService
                .getArchivesRemoteStorage()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    this.numOfArchives = (res || []).length;
                });
            this.stateService.getVersionMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.mapVersion = res));
        }
    }

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            const warehouse = this.warehouse.warehouse;
            this.isHome =
                this.router.url.includes(`/warehouse/${warehouse}/zones`) ||
                this.router.url.includes(`/warehouse/${warehouse}/floor/`) ||
                this.router.url.includes(`/warehouse/${warehouse}/lines`);
            if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.showNav = event.url === '/landing' ? false : true;
                $('.navbar-light .nav-item').removeClass('active');
                if ((event.url.includes('/alerts') && !event.url.includes('/reports/'))) {
                    $('.nav-item.item-2').addClass('active');
                } else if (event.url.includes('/warnings')) {
                    $('.nav-item.item-3').addClass('active');
                } else if (event.url.endsWith('/connections')) {
                    $('.nav-item.item-5').addClass('active');
                } else if (event.url.includes('/reports/')) {
                    $('.nav-item.item-6').addClass('active');
                } else if (event.url.includes('/variables')) {
                    $('.nav-item.item-4').addClass('active');
                } else if (event.url.includes('/scanners')) {
                    $('.nav-item.item-5').addClass('active');
                } else if (event.url.includes('/analytics/')) {
                    $('.nav-item.item-7').addClass('active');
                    $('div.full-screen-chart').removeClass('full-screen-chart');
                } else if (event.url.includes('/dashboard')) {
                    $('.nav-item.item-8').addClass('active');
                } else {
                    $('.nav-item.item-1').addClass('active');
                    $('.map-wrapper').removeClass('expand');
                }
            }
        });

        $(() => {
            $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
                const $el = $(this);
                $el.toggleClass('active-dropdown');
                if (!$(this).find('.dropdown-menu').hasClass('show')) {
                    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
                }
                const $subMenu = $(this).find('.dropdown-menu');
                $subMenu.toggleClass('show');
                $subMenu.css({ top: -11, left: ($subMenu.width() + 3) * -1 });
                return false;
            });
        });
    }

    async ngAfterViewInit(): Promise<void> {
        await this.subscribeToAlertCount();
        await this.subscribeToWarehouseMapChanged();
        await this.subscribeToReportCount();
        if (document.addEventListener) {
            document.addEventListener('fullscreenchange', this.fullscreenChangeHandler.bind(this), false);
            document.addEventListener('mozfullscreenchange', this.fullscreenChangeHandler.bind(this), false);
            document.addEventListener('MSFullscreenChange', this.fullscreenChangeHandler.bind(this), false);
            document.addEventListener('webkitfullscreenchange', this.fullscreenChangeHandler.bind(this), false);
        }
        this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
            if (
                prevState !== VisibilityStates.active &&
                [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
                this.alertsCountStatusService.isConnected()
            ) {
                this.subscribeToAlertCount();
                this.subscribeToReportCount();
                this.subscribeToWarehouseMapChanged();
            }
        });
        const navBarElement = document.querySelector('nav > div');
        const logoElement = document.querySelector('div > a');
        const buttonElement = document.querySelector('nav > div > button');
        const equipmentIcon = document.createElement('ng-container');
        if (logoElement) logoElement.classList.add('flex-grow-1', 'text-start');
        equipmentIcon.innerHTML = `<div class="d-block d-lg-none me-2 me-sm-3 mt-2">
        <a class="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEquipments" aria-controls="offcanvasEquipments">
            <i class="icon-velocimeter" style="font-size: 28px; color: #dee2e;"></i>
        </a>
        </div>`;
        navBarElement.insertBefore(equipmentIcon, buttonElement);
    }

    fullscreenChangeHandler() {
        this.isFullscreen = !!document.fullscreenElement;
        this.stateService.setFullscreen(this.isFullscreen);
        if (this.isFullscreen) {
            this.stateService.setShowFilters(false);
        }
    }

    goToHome() {
        const restrictedUris = [
            `/warehouse/${this.warehouse.warehouse}/zones`,
            `/warehouse/${this.warehouse.warehouse}/lines`,
            `/warehouse/${this.warehouse.warehouse}/customs`,
        ];
        if (!restrictedUris.includes(this.router.url)) {
            this.router.navigate([`warehouse/${this.warehouse.warehouse}`]);
        }
    }

    async ngOnDestroy(): Promise<void> {
        await this.alertsCountStatusService.unsubscribeFromWarehouseMapChanged(this.warehouse.warehouse);
        await this.alertsCountStatusService.unsubscribeFromAlertCount(this.warehouse.warehouse);
        await this.reportCountStatusService.unsubscribeFromReportCount(this.warehouse.warehouse, this.userEmail);
        document.removeEventListener('fullscreenchange', this.fullscreenChangeHandler);
        document.removeEventListener('mozfullscreenchange', this.fullscreenChangeHandler);
        document.removeEventListener('MSFullscreenChange', this.fullscreenChangeHandler);
        document.removeEventListener('webkitfullscreenchange', this.fullscreenChangeHandler);
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    processWarehouseChangedNotification(context: any, warehouseMap: WarehouseMapStatus) {
        context.appLoadService.getCurrentWarehouse.pipe(takeUntil(context.ngUnsubscribe)).subscribe(async (currentWarehouse) => {
            if (warehouseMap.whid.toUpperCase() === currentWarehouse.warehouse.toUpperCase()) {
                await context.clearCacheCallback();
                window.location.reload();
            }
        });
    }

    async subscribeToWarehouseMapChanged() {
        await this.alertsCountStatusService.unsubscribeFromWarehouseMapChanged(this.warehouse.warehouse);
        this.alertsCountStatusService.subscribeToWarehouseMapChanged(
            this.warehouse.warehouse,
            this,
            this.processWarehouseChangedNotification,
            this.subscribeToWarehouseMapChanged.bind(this),
        );
    }

    processAlertsCountNotification(context: any, alertsCount: number) {
        context.numOfAlerts = alertsCount;
    }

    async subscribeToAlertCount() {
        await this.alertsCountStatusService.unsubscribeFromAlertCount(this.warehouse.warehouse);
        await this.alertsCountStatusService.subscribeToAlertCount(
            this.warehouse.warehouse,
            this,
            this.processAlertsCountNotification,
            this.subscribeToAlertCount.bind(this),
        );
    }

    processReportsCountNotification(context: any, reportsCount: number) {
        context.numOfArchives = reportsCount;
    }

    async subscribeToReportCount() {
        await this.reportCountStatusService.unsubscribeFromReportCount(this.warehouse.warehouse, this.userEmail);
        this.reportCountStatusService.subscribeToReportCount(
            this.warehouse.warehouse,
            this.userEmail,
            this,
            this.processReportsCountNotification,
            this.subscribeToReportCount.bind(this),
        );
    }

    toggleFullscreen() {
        if (!this.isFullscreen) {
            const elem: any = this._document.body;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (this._document.exitFullscreen) {
                this._document.exitFullscreen();
            } else if (this._document.mozCancelFullScreen) {
                this._document.mozCancelFullScreen();
            } else if (this._document.webkitExitFullscreen) {
                this._document.webkitExitFullscreen();
            } else if (this._document.msExitFullscreen) {
                this._document.msExitFullscreen();
            }
        }
    }

    loginButtonClick(): void {
        this.authenticationService.getUser();
    }
    logoutButtonClick(): void {
        this.authenticationService.signOut();
        this.appInsights.clearAuthenticatedUserContext();
    }
    async changeWarehouse(warehouse) {
        let currentWarehouse = null;
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            currentWarehouse = res;
            if (currentWarehouse.hostName.toLowerCase() !== warehouse.hostName.toLowerCase()) {
                this.idbService.clear(IDB_STORES.HTTP);
            }
        });
        await this.appLoadService.setCurrentWarehouse({
            warehouse: String(warehouse.warehouse),
            displayName: String(warehouse.displayName),
            hostName: String(warehouse.hostName),
            warehouseMapCreationDate: warehouse.warehouseMapCreationDate,
        });

        this.configuration = this.appLoadService.cleanCurrentConfiguration(this.configuration)
        await this.configurationsService.saveConfigurationPromise(this.warehouse.hostName, this.userEmail, this.userName, this.configuration)

        window.location.href = '/'
    }

    toggleViewMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.configuration.liteMode = !this.liteView;
            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
        }
    }

    toggleDarkMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.configuration.darkMode = !this.darkMode;
            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
        }
    }

    toggleExtraData() {
        this.configuration.viewExtraData = !this.viewExtraData;
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
    }

    async refreshMap() {
        localStorage.clear();
        await this.idbService.clear(IDB_STORES.HTTP);
        window.location.reload();
    }

    async clearCacheCallback() {
        await this.idbService.clear(IDB_STORES.HTTP);
    }

    toggleFilters() {
        this.showFilters = !this.showFilters;
        this.stateService.setShowFilters(this.showFilters);
    }
}

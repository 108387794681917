import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { HostBinding } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
    @Input() columns: number;
    @Input() extraClasses: number;
    @HostBinding('class') classes;

    constructor() {}

    ngOnInit() {
        this.classes = `col-12 col-md-6 col-lg-${this.columns || 2} ${this.extraClasses}`;
    }
}

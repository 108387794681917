<div class="collapse" id="filterMenu" [ngClass]="{ show: !isFullscreen || showFilters }">
    <div class="row search-nav mt-3 pb-2">
        <div class="col-12 col-xl-8 col-lg-6">
            <app-breadcrumbs [columns]="9" [breadcrumbs]="[floorId, areaId, zoneId]"></app-breadcrumbs>
        </div>
        <div class="col-12 col-xl-4 col-lg-6">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsView }">Alerts</button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-xl-8 col-lg-6 col-12 svg-wrapper">
        <div class="map-wrapper">
            <div class="row">
                <app-dropdown-checkbox
                    label="States"
                    [icon]="filterIcon"
                    [iconTitle]="filterTitle"
                    columns="3"
                    [availableItems]="availableStates"
                    (changeFilter)="handleChangeVisibleStates($event)"
                ></app-dropdown-checkbox>
                <div class="col-4 offset-5">
                    <h4><a [routerLink]="['/warehouse', warehouse.warehouse, 'floor', floorId, 'area', areaId]">View complete area</a></h4>
                </div>
            </div>
            <app-zones-map
                *ngIf="mapRender"
                [warehouse]="warehouse.warehouse"
                [map]="map"
                [markedLine]="line"
                [visibleStates]="visibleStates"
                [lineChange$]="lineChange$"
                [tabState]="tabState"
                (visibleLines)="refreshAlertsTable($event)"
                (clickLine)="navigateToLine($event, null)"
                [activateZoom]="activateZoom"
                (newLineSelected)="newLineSelected($event)"
            ></app-zones-map>
        </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-4 px-lg-0">
        <div [ngClass]="{ 'hide-me': !alertsView }">
            <shared-alerts-table-sm
                #alertsTable
                [items]="zoneAlerts"
                [filterItems]="linesVisible"
                [group]="floorId + '_' + areaId + '_' + zoneId"
                [priority$]="priority$"
                fqdnMode="zone"
            ></shared-alerts-table-sm>
        </div>
    </div>
</div>
<app-home-lines-minified-container [lineToShowOff]="lineToShow" [visibleStatesOff]="visibleStates" (offcanvasToggle)="offcanvasToggle($event)">
</app-home-lines-minified-container>

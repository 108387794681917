/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ReplaySubject, Subscription, BehaviorSubject } from 'rxjs';
import { SettingsService } from '@app/core/shared/settings/settings.service';
import { IdbService, IDB_STORES } from './core/shared/cache/idb.service';
import { Warehouse } from './core/shared/warehouse';
import { UserConfiguration, WarehouseConfiguration } from './shared/models/configurations';
import { VariablesDescriptionService } from '@variables/shared/variables-description.service';

const MIN_DEFAULT_RANGE = 20;
const ERRORS_MIDDLE_DEFAULT_RANGE = 60;
const MAX_DEFAULT_RANGE = 80;

const FIRST_DEFAULT_COLOR = '#2da45d';
const SECOND_DEFAULT_COLOR = '#fe840e';
const THIRD_DEFAULT_COLOR = '#ff2800';

const FIRST_DEFAULT_ERRORS_NAME = 'Range 1';
const SECOND_DEFAULT_ERRORS_NAME = 'Range 2';
const THIRD_DEFAULT_ERRORS_NAME = 'Range 3';
@Injectable()
export class AppLoadService implements OnDestroy {
    subscription: Subscription;
    rolesComplete = false;
    warehouses = [];
    private currentEquipmentVariables = new BehaviorSubject({ equipment: null, type: null });
    private currentWarehouse = new ReplaySubject<{
        warehouse: string;
        displayName: string;
        hostName: string;
        warehouseMapCreationDate: Date;
    }>(1);
    currentConfiguration = new BehaviorSubject<UserConfiguration>({
        liteMode: false,
        darkMode: false,
        identifiersPreferences: {
            Floor: true,
            Area: true,
            Zone: true,
            Line: true,
        },
        homeMode: 'Lines',
        //homeTab: 'alerts',    //TODO: Delete when all configurations are cleaned
        viewExtraData: true,
        //heatMapMode: 'Lines',     //TODO: Delete when all configurations are cleaned
        dashboard: null,
        dashboardVariablesFilter: null,
        viewFullSize: null,
        activeVariablesFilter: null,
        activeAlertsFilter: null,
        activeWarningsFilter: null,
        activeScannersFilter: null,
        historyAlertsFilter: null,
        historyWarningsFilter: null,
        historyVariablesFilter: null,
        historyScannersFilter: null,
        historyVariablesFrom: null,
        historyVariablesTo: null,
        historyWarningsFrom: null,
        historyWarningsTo: null,
        kpiTableFilter: null,
        stateFilter: null,
        columnsVisible: null,
        trendScannersFilter: null,
        trendErrorFilter: null,
        boxDetectedColors: { lightMode: '', darkMode: '' }
    });
    globalConfiguration = new BehaviorSubject<WarehouseConfiguration>({
        scannerPredefinedFilterList: null,
        heatMap: null,
        variableColors: null, //VariableColors[]
        alertsZonesColors: null,
        scannerTrendColors: null,
        errorTrendColors: null,
        boxDetectedColors: { lightMode: '', darkMode: '' }
    });
    getCurrentWarehouse = this.currentWarehouse.asObservable();
    getCurrentConfiguration = this.currentConfiguration.asObservable();
    getGlobalConfiguration = this.globalConfiguration.asObservable();
    getEquipmentVariables = this.currentEquipmentVariables.asObservable();

    constructor(
        private location: Location,
        private settingsService: SettingsService,
        private idbService: IdbService,
        private variablesDescriptionService: VariablesDescriptionService
    ) { }

    setCurrentConfiguration(configuration: UserConfiguration) {
        this.currentConfiguration.next(configuration);
    }

    setGlobalConfiguration(configuration: WarehouseConfiguration) {
        this.globalConfiguration.next(configuration);
    }

    setEquipmentVariables(equipment) {
        this.currentEquipmentVariables.next(equipment);
    }

    setCurrentWarehouse(warehouse: Warehouse) {
        const instance = new URL(
            this.settingsService.authenticationSettings.instances[warehouse.warehouse] || this.settingsService.authenticationSettings.defaultApiBaseUrl
        );
        warehouse.hostName = instance.origin;
        localStorage.setItem('currentWarehouse', JSON.stringify(warehouse));
        this.currentWarehouse.next(warehouse);
    }

    setWarehouse(warehouses) {
        const urlWarehouseCode = this.location.path().split('/')[2];
        const urlWarehouse = urlWarehouseCode ? warehouses.filter((wh) => wh.warehouse === urlWarehouseCode) : [];
        if (urlWarehouseCode && urlWarehouse.length === 0) {
            window.location.replace(`${window.location.protocol}//${window.location.host}`);
        } else {
            const currentWarehouseBk = JSON.parse(localStorage.getItem('currentWarehouse'));
            const warehouse =
                urlWarehouse.length > 0
                    ? urlWarehouse[0]
                    : currentWarehouseBk
                        ? warehouses.filter((wh) => wh.warehouse === currentWarehouseBk.warehouse)[0]
                        : warehouses[0];
            if (
                !currentWarehouseBk ||
                (urlWarehouse.length > 0 && urlWarehouse[0].warehouse !== currentWarehouseBk.warehouse) ||
                warehouse.warehouseMapCreationDate !== currentWarehouseBk.warehouseMapCreationDate
            ) {
                this.idbService.clear(IDB_STORES.HTTP);
            }
            this.setCurrentWarehouse(warehouse);
        }
    }

    initialize(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.settingsService.initializeService().then(() => {
                this.getCurrentWarehouse.subscribe({
                    next: (res) => {
                        const baseUrl = `${res.hostName}/api`.toLowerCase();
                        const warehouse = res.warehouse;
                        this.variablesDescriptionService.getVariableDescriptionsLocal(baseUrl, warehouse);
                    },
                    error: (error) => {
                        console.log(error);
                    }
                });
                resolve(true);
            });
        });
    }

    castWarehouse(warehouses) {
        return warehouses.map((wh) => {
            const instance = new URL(
                this.settingsService.authenticationSettings.instances[wh.warehouse] || this.settingsService.authenticationSettings.defaultApiBaseUrl
            );
            return {
                warehouse: wh.warehouseId,
                displayName: wh.warehouseName,
                hostName: instance.origin,
                warehouseMapCreationDate: wh.canonicalMapCreationDate || null,
            };
        });
    }

    castConfiguration(config) {
        const {
            liteMode,
            darkMode,
            identifiersPreferences,
            homeMode,
            viewExtraData,
            //heatMapMode,
            dashboard,
            dashboardVariablesFilter,
            viewFullSize,
            activeVariablesFilter,
            activeAlertsFilter,
            activeWarningsFilter,
            activeScannersFilter,
            historyAlertsFilter,
            historyWarningsFilter,
            historyVariablesFilter,
            historyScannersFilter,
            historyVariablesFrom,
            historyVariablesTo,
            historyWarningsFrom,
            historyWarningsTo,
            kpiTableFilter,
            stateFilter,
            columnsVisible,
            ...rest
        } = JSON.parse(config.data);
        return {
            liteMode: liteMode || false,
            darkMode: darkMode || false,
            identifiersPreferences: identifiersPreferences || {
                Floor: true,
                Area: true,
                Zone: true,
                Line: true,
            },
            homeMode: homeMode || 'Lines',
            viewExtraData: viewExtraData ?? true,
            //heatMapMode: heatMapMode || 'Lines',
            viewFullSize: viewFullSize || null,
            activeVariablesFilter: activeVariablesFilter || null,
            activeWarningsFilter: activeWarningsFilter || null,
            activeAlertsFilter: activeAlertsFilter || null,
            activeScannersFilter: activeScannersFilter || null,
            historyAlertsFilter: historyAlertsFilter || null,
            historyWarningsFilter: historyWarningsFilter || null,
            historyVariablesFilter: historyVariablesFilter || null,
            historyScannersFilter: historyScannersFilter || null,
            kpiTableFilter: kpiTableFilter || null,
            dashboard: dashboard || null,
            stateFilter: stateFilter || null,
            columnsVisible: columnsVisible || null,
            dashboardVariablesFilter: dashboardVariablesFilter || null,
            ...rest,
        };
    }

    castGlobalConfiguration(config) {
        const { scannerPredefinedFilterList, heatMap, ...rest } = JSON.parse(config.data);
        return {
            scannerPredefinedFilterList: scannerPredefinedFilterList || [],
            heatMap: heatMap || {
                CurrentDimension: { value: 'percentageRunning', name: 'Running' },
                Running: { ranges: [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE], colors: [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR] },

                Stopped: { ranges: [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE], colors: [THIRD_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, FIRST_DEFAULT_COLOR] },
                'On error': { ranges: [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE], colors: [THIRD_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, FIRST_DEFAULT_COLOR] },

                Errors: {
                    ranges: [MIN_DEFAULT_RANGE, ERRORS_MIDDLE_DEFAULT_RANGE, MAX_DEFAULT_RANGE],
                    colors: [THIRD_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, FIRST_DEFAULT_COLOR],
                    names: [FIRST_DEFAULT_ERRORS_NAME, SECOND_DEFAULT_ERRORS_NAME, THIRD_DEFAULT_ERRORS_NAME],
                },
                'No error': { ranges: [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE], colors: [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR] },

                LWDO: { ranges: [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE], colors: [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR] },
            },
            ...rest,
        };
    }

    cleanCurrentConfiguration(actualConfiguration) {
        return {
            ...actualConfiguration,
            // liteMode: false,
            // darkMode: false,
            // identifiersPreferences: {
            //     Floor: true,
            //     Area: true,
            //     Zone: true,
            //     Line: true,
            // },
            homeMode: 'Lines',
            viewExtraData: true,
            dashboard: null,
            dashboardVariablesFilter: null,
            //viewFullSize: null,
            activeVariablesFilter: null,
            activeAlertsFilter: null,
            activeWarningsFilter: null,
            activeScannersFilter: null,
            historyAlertsFilter: null,
            historyWarningsFilter: null,
            historyVariablesFilter: null,
            historyScannersFilter: null,
            historyVariablesFrom: null,
            historyVariablesTo: null,
            historyWarningsFrom: null,
            historyWarningsTo: null,
            kpiTableFilter: null,
            //stateFilter: null,
            //columnsVisible: null,
            trendScannersFilter: null,
            trendErrorFilter: null,
            boxDetectedColors: { lightMode: '', darkMode: '' }
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

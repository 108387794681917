import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { PageLifecycleService, VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';

const { LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

@Component({
    selector: 'app-custom-view',
    templateUrl: './custom-view.component.html',
    styleUrls: ['./custom-view.component.scss'],
})
export class CustomViewComponent implements AfterViewInit, OnDestroy {
    @Input() warehouse: any;
    @Input() view: any;
    @Input() icons: any;
    @Input() visibleStates: Array<string>;
    @Output() childClick = new EventEmitter();
    tabState: VisibilityStates = VisibilityStates.active;
    supportedEvents = [LINE_STATE_CHANGED];
    groupName: string;
    lineChange$: Observable<LineStatusNotification>;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private pageLifeCycleService: PageLifecycleService,
        private warehouseStatusService: WarehouseStatusService,
    ) {}

    async ngOnDestroy(): Promise<void> {
        await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    async ngAfterViewInit(): Promise<void> {
        this.groupName = `${this.warehouse.warehouse}-${this.view.name}`;
        this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
            this.tabState = state;
            if (
                prevState !== VisibilityStates.active &&
                [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
                this.warehouseStatusService.isConnected()
            ) {
                this.onReconnect();
            }
        });
        await this.subscribeToRealTimeEvents();
    }

    clickItem(event, mode) {
        if (mode === 'Equipment') {
            this.childClick.emit({ keys: event, mode });
        } else {
            const keys = ['Area', 'Zone'].includes(mode) ? $(event.target).data('key').split('-').slice(0, -1) : $(event.target).data('key').split('-');
            this.childClick.emit({ keys, mode });
        }
    }

    async onReconnect() {
        await this.subscribeToRealTimeEvents();
    }

    async subscribeToRealTimeEvents(): Promise<void> {
        try {
            await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
            await this.warehouseStatusService.startSubscription(this.groupName, this.supportedEvents);

            this.lineChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, LINE_STATE_CHANGED);

            this.warehouseStatusService.onReconnecting.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.onReconnect());
        } catch (error) {
            console.error(error);
        }
    }
}

<div class="form-group">
    <input #input [type]="type || 'text'" [id]="key" />
    <label [for]="key"
        ><span>{{ label }}</span></label
    >
    <i class="fas fa-search"></i>
    <ul #myAutocomplete class="autocomplete" *ngIf="autocomplete">
        <cdk-virtual-scroll-viewport class="virtual-scroll" itemSize="10">
            <li *cdkVirtualFor="let item of autocompleteFiltered" [attr.data-value]="item" [innerHTML]="item | highlight: inputValue"></li>
        </cdk-virtual-scroll-viewport>
    </ul>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { VariablesHistory } from './variables';
import { VariableValueResponse } from './variable';
import { AppLoadService } from '@app/app-load.service';
import { environment } from '@environments/environment';
import { VariableValueBody } from './variable';
import * as dayjs from 'dayjs';

@Injectable()
export class VariablesService {
    baseUrl: string;
    warehouse: string;

    constructor(private http: HttpClient, private appLoadService: AppLoadService) {}

    getVariablesHistory({
        page = '0',
        pageSize = '10',
        from = '',
        to = '',
        floorid = '',
        areaid = '',
        zoneid = '',
        lineid = '',
        equipmentId = '',
        equipmentType = '',
        fqnPattern = '',
        variable = '',
        variableType = '',
        condition = null,
        orderColumn = 'floor',
        orderType = 'desc',
    }): Observable<VariablesHistory> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                let params = new HttpParams()
                    .set('pagesize', pageSize)
                    .set('pagenumber', `${page}`)
                    .set('orderColumn', orderColumn)
                    .set('orderType', orderType);
                if (from) {
                    const utcFrom = dayjs(from.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    params = params.set('from', utcFrom);
                }
                if (to) {
                    const utcTo = dayjs(to.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    params = params.set('to', utcTo);
                }
                if (lineid) {
                    params = params.set('lineid', lineid);
                }
                if (equipmentId) {
                    params = params.set('equipmentId', equipmentId);
                }
                if (equipmentType) {
                    params = params.set('equipmentType', equipmentType);
                }
                if (variable) {
                    params = params.set('variableName', variable);
                }
                if (variableType) {
                    params = params.set('variableType', variableType.toLowerCase());
                }
                if (fqnPattern) {
                    params = params.set('fqnPattern', fqnPattern);
                }
                const endpoint =
                    environment.mode === 'front'
                        ? `variablesActive`
                        : // tslint:disable-next-line: max-line-length
                        zoneid
                        ? `variables/${this.warehouse}/history/floors/${floorid}/areas/${areaid}/zones/${zoneid}`
                        : areaid
                        ? `variables/${this.warehouse}/history/floors/${floorid}/areas/${areaid}`
                        : floorid
                        ? `variables/${this.warehouse}/history/floors/${floorid}`
                        : `variables/${this.warehouse}/history`;
                return this.http.post<VariablesHistory>(`${this.baseUrl}/${endpoint}`, { condition }, { params });
            }),
            shareReplay()
        );
    }

    readVariable(fqn: string): Observable<VariableValueResponse> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const params = new HttpParams().set('fqdn', fqn);
                const endpoint = environment.mode === 'front' ? `variableValue` : `variables/${this.warehouse}`;
                return this.http.get<VariableValueResponse>(`${this.baseUrl}/${endpoint}`, { params });
            }),
            shareReplay()
        );
    }

    writeVariable(fqn: string, variableValue: VariableValueBody): Observable<VariableValueResponse> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const params = new HttpParams().set('fqdn', fqn);
                const endpoint = environment.mode === 'front' ? `variableValue` : `variables/${this.warehouse}`;
                return this.http.put<VariableValueResponse>(`${this.baseUrl}/${endpoint}`, variableValue, { params });
            }),
            shareReplay()
        );
    }
}

import * as _ from 'lodash-es';

export class Utils {
    public static removeHighlight(data) {
        const parsedData = _.cloneDeep(data);
        for (const key in parsedData) {
            if (parsedData.hasOwnProperty(key)) {
                if (typeof parsedData[key] === 'string') {
                    parsedData[key] = parsedData[key].replaceAll('<span class="highlight">', '').replaceAll('</span>', '');
                }
            }
        }
        return parsedData;
    }
    public static removeHighlightFromString(data) {
        return data.replaceAll('<span class="highlight">', '').replaceAll('</span>', '');
    }
}

import alertsActiveStatusMapper from './alertsActive-status.mapper';
import equipmentStatusMapper from './equipment-status.mapper';
import lineStatusMapper from './line-status.mapper';
import { WarehouseStatusSupportedEvents } from '../events/warehouse-status';

const { ALERT_STATE_CHANGED, EQUIPMENT_STATE_CHANGED, LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

export const mapNotification = (eventName: string, event: any) => {
    return eventName === ALERT_STATE_CHANGED
        ? alertsActiveStatusMapper(event)
        : eventName === LINE_STATE_CHANGED
        ? lineStatusMapper(event)
        : eventName === EQUIPMENT_STATE_CHANGED
        ? equipmentStatusMapper(event)
        : event;
};

export const getEventTS = (eventName: string, event: any) => {
    /* eslint-disable prettier/prettier */
    return eventName === ALERT_STATE_CHANGED
        ? event.serverts
        : eventName === EQUIPMENT_STATE_CHANGED
        ? event.serverts
        : eventName === LINE_STATE_CHANGED
        ? event.serverts
        : event.serverts;
    /* eslint-enable prettier/prettier */
};
